import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { localApi } from "utils/imgApi";
import Button from "components/CustomButtons/Button.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import style from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import styleSweet from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";

import { credentials } from "utils/imgApi";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(style);
const useStyleSweet = makeStyles(styleSweet);

export default function DeleteSet(props) {
  const [setsDescName, setSetsDescName] = React.useState("");
  const [pcs, setPcsName] = React.useState("");
  const [simpleSelect, setSimpleSelect] = React.useState("");
  const [getId, setGetId] = React.useState("");
  const [items, setItems] = React.useState([]);
  const [submitted, setSubmitted] = React.useState(false);
  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");
  const [btn, setBtn] = React.useState(false);

  const classes = useStyles();
  const classe = useStyle();
  const classSweet = useStyleSweet();

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  async function getUsers() {
    const api = localApi + `/sets/list/byAdminId/${userObj && userObj.id}`;
    const result = await fetch(api, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getResult = await result.json();
    setItems(getResult && getResult);
    // console.log(getResult.CategoriesData);
  }

  useEffect(() => {
    getUsers();
  }, []);

  const handleSimple = (event) => {
    // setSimpleSelect(event.target.value);
    const { value } = event.target;
    const item = items.find((item) => item.id === value);
    setSimpleSelect(value);
    setSetsDescName(item.setDesc);
    setPcsName(item.pcs);
    setGetId(item.id);
  };

  const SubmitDeleteSets = () => {
    setBtn(true);
    fetch(localApi + `/sets/delete/byAdminAndSetsId/${userObj.id}/${getId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        // console.log(res);
        if (res.message === "Deleted Successfully !!!") {
          alertTypeOps("success");
          alertMsgOps("Delete Succesfully");
          setSubmitted(true);
        }
      });
  };

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  return (
    <div>
      <GridContainer justify="center" alignItems="center">
        <GridItem xs={12} sm={6} md={6}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <DeleteForeverIcon />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Delete Piece/Sets</small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {submitted ? (
                  <SweetAlert
                    type={alertType}
                    style={{ display: "block", marginTop: "-100px" }}
                    title={alertMsg}
                    onConfirm={() => hideAlert()}
                    confirmBtnCssClass={
                      classSweet.button + " " + classSweet.success
                    }
                  />
                ) : null}{" "}
                <GridItem xs={12} sm={12} md={12}>
                  <FormControl fullWidth className={classe.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classe.selectLabel}
                    >
                      Select Piece/Sets
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classe.selectMenu,
                      }}
                      classes={{
                        select: classe.select,
                      }}
                      value={simpleSelect}
                      onChange={handleSimple}
                      inputProps={{
                        name: "simpleSelect",
                        id: "simple-select",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classe.selectMenuItem,
                        }}
                      >
                        Select Piece/Sets
                      </MenuItem>
                      {items &&
                        items.map((item) => (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                            classes={{
                              root: classe.selectMenuItem,
                              selected: classe.selectMenuItemSelected,
                            }}
                          >
                            {item.setDesc}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>
                {simpleSelect ? (
                  <GridItem xs={12} sm={12} md={12}>
                    <h5 className={classes.cardIconTitle}>
                      <small>
                        Description Name:-{" "}
                        <span style={{ color: "black" }}>{setsDescName}</span>
                      </small>
                      <br />
                      <small>
                        Piece/set:-{" "}
                        <span style={{ color: "black" }}>{pcs}</span>
                      </small>
                    </h5>
                  </GridItem>
                ) : (
                  ""
                )}
              </GridContainer>
              {/* <Button
                color="rose"
                onClick={SubmitDeleteSets}
                className={classes.updateProfileButton}
              >
                Delete
              </Button> */}
              {btn === true ? (
                <Button className={classes.updateProfileButton}>Delete</Button>
              ) : (
                <Button
                  color="rose"
                  onClick={SubmitDeleteSets}
                  className={classes.updateProfileButton}
                >
                  Delete
                </Button>
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

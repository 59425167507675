import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import Selects from "react-select";
import { localApi } from "utils/imgApi";
import Datetime from "react-datetime";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Radio from "@material-ui/core/Radio";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import styDrop from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import styRadio from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import SweetAlert from "react-bootstrap-sweetalert";
import styleSweet from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

const style = {
  cardTitle,
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center",
  },
  cardCategory: {
    margin: "0",
    color: "#999999",
  },
};

const useStyle = makeStyles(style);
const useStyles = makeStyles(styles);
const useStyl = makeStyles(styDrop);
const useStylRadio = makeStyles(styRadio);
const useStyleSweet = makeStyles(styleSweet);

export default function LabourAddPlating(props) {
  const [labourList, setLabourList] = React.useState([]);
  const [unit, setUnit] = React.useState([]);
  const [simpleSelectClient, setSimpleSelectClient] = React.useState("");
  const [simpleSelectClientErr, setSimpleSelectClientErr] = React.useState("");
  const [golwireThaw, setGolwireThaw] = React.useState("145 Thaw Golwair");
  const [golwireThawErr, setGolwireThawErr] = React.useState("");
  const [pattiLasiya, setPattiLasiya] = React.useState(
    "5MM Patti & 0.011 Grams"
  );
  const [pattiLasiyaErr, setPattiLasiyaErr] = React.useState("");
  const [quantity, setQuantity] = React.useState("0");
  const [quantityErr, setQuantityErr] = React.useState("");
  const [simpleSelectType, setSimpleSelectType] = React.useState("");
  const [simpleSelectTypeErr, setSimpleSelectTypeErr] = React.useState("");
  const [price, setPrice] = React.useState("0");
  const [priceErr, setPriceErr] = React.useState("");
  const [amount, setAmount] = React.useState("0");
  const [amountErr, setAmountErr] = React.useState("");
  const [igst, setIgst] = React.useState("");
  const [cgst, setCgst] = React.useState("");
  const [sgst, setSgst] = React.useState("");
  const [checkedA, setCheckedA] = React.useState(false);
  const [selectedGstCal, setSelectedGstCal] = React.useState("local");
  const [regId, setRegId] = React.useState("");
  const [clientid, setClientid] = React.useState("");

  const [submitted, setSubmitted] = React.useState(false);
  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");

  const [Date, setDate] = React.useState("");
  const [DateErr, setDateErr] = React.useState("");

  const classes = useStyles();
  const classe = useStyle();
  const classDrop = useStyl();
  const classesRadio = useStylRadio();
  const classSweet = useStyleSweet();

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));
  const favoritemovie = sessionStorage.getItem("favoriteMovie");

  async function getLabour() {
    const api = localApi + `/clientGroup/list/byAdminId/${userObj && userObj.id}`;
    const result = await fetch(api);
    const getResult = await result.json();
    setLabourList(getResult && getResult.filter((f) => f.type === "Labour"));
    // console.log(getResult);
  }

  useEffect(() => {
    getLabour();
  }, []);

  async function getUnit() {
    const api = localApi + `/unit/list/byAdminId/${userObj && userObj.id}`;
    const result = await fetch(api);
    const getResult = await result.json();
    setUnit(getResult && getResult);
    // console.log(getResult);
  }

  useEffect(() => {
    getUnit();
  }, []);

  const handleSimpleType = (event) => {
    const { value } = event.target;
    setSimpleSelectType(value);
    setSimpleSelectTypeErr("");
    // console.log(value);
    // if (value) {
    //   const val = (quantity / value.con_factor) * price;
    //   setAmount(val.toFixed(4));
    // }
  };

  const handleChangeGstCal = (event) => {
    // setSelectedGstCal(event.target.value);
    const { value } = event.target;
    setSelectedGstCal(value);
  };

  const handleDatePicker = (Date) => {
    setDate(Date);
    setDateErr("");
  };

  const handleClient = async (simpleSelectClient) => {
    // console.log(simpleSelectClient);
    if (simpleSelectClient.value) {
      let newSpli = simpleSelectClient.value.split("-");
      const ids = labourList.find((f) => f.mobile == newSpli[1]);
      setClientid(ids && ids.id);
      const api = localApi + `/register/byMobile/${ids.mobile}`;
      const result = await fetch(api);
      const getResult = await result.json();
      // console.log(getResult);
      setRegId(getResult && getResult);
    }
    setSimpleSelectClient(simpleSelectClient);
    setSimpleSelectClientErr("");
  };

  const totalPiece = "864";
  //   Math.ceil("864" / sets.pcs / box.box);


  const SubmitAddPlating = () => {
    // console.log("OneTwoThree = ", checkedFullSize === true ? checkedCutSize === true ? "3" : checkedFullSize === true ? "1" : "0" : checkedCutSize === true ? "2" : "0");

    let errorCount = 0;
    if (golwireThaw === "" || !golwireThaw) {
      errorCount++;
      setGolwireThawErr("Golwire is Required");
    }
    if (pattiLasiya === "" || !pattiLasiya) {
      errorCount++;
      setPattiLasiyaErr("Patti/Lasiya is Required");
    }
    if (quantity === "" || !quantity) {
      errorCount++;
      setQuantityErr("Quantity is Required");
    }
    if (simpleSelectType === "" || !simpleSelectType) {
      errorCount++;
      setSimpleSelectTypeErr("Please Select Unit Type");
    }
    if (simpleSelectClient === "" || !simpleSelectClient) {
      errorCount++;
      setSimpleSelectClientErr("Please Select Client Type");
    }

    if (price === "" || !price) {
      errorCount++;
      setPriceErr("Price is Required");
    }
    if (amount === "" || !amount) {
      errorCount++;
      setAmountErr("Amount is Required");
    }
    if (Date === "" || !Date) {
      errorCount++;
      setDateErr("Please Select Date");
    }

    // if (gst === "" || !gst) {
    //   errorCount++;
    //   setGstErr("GST is Required");
    // }

    if (errorCount === 0) {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          adminId: userObj.id,
          clientId: clientid,
          pickerdate:
            Date._d.getFullYear() +
            "-" +
            (Date._d.getMonth() + 1) +
            "-" +
            Date._d.getDate(),
          // openingBalance: 50.5,
          billType: "PLATTING",
          title: golwireThaw,
          description: pattiLasiya,
          quantity: quantity,
          unit: simpleSelectType.title,
          price: price,
          amount: amount,
          closingBalance: 0,
          taxApply: checkedA,
          saleType: selectedGstCal === "local" ? "0" : "1",
          cgst:
            checkedA === false
              ? 0
              : checkedA === true
              ? selectedGstCal === "local"
                ? cgst
                  ? cgst
                  : 0
                : 0
              : 0,
          sgst:
            checkedA === false
              ? 0
              : checkedA === true
              ? selectedGstCal === "local"
                ? sgst
                  ? sgst
                  : 0
                : 0
              : 0,
          igst:
            checkedA === false
              ? 0
              : checkedA === true
              ? selectedGstCal === "interested"
                ? igst
                  ? igst
                  : 0
                : 0
              : 0,
          taxsumary: {}
        }),
      };

      fetch(localApi + `/platting/add/plattingandTax/${regId.id}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          if (data.message === "Data Inserted Successfully !!!") {
            alertTypeOps("success");
            alertMsgOps("Platting Added Succesfully !!!");
            setSubmitted(true);
          }
        });
    }
  };

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  const select = {
    width: "100%",
    maxWidth: 600,
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      padding: 10,
    }),
    menu: (state) => ({
      color: "#d81b60",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  return (
    <div>
      <GridContainer justify="center" alignItems="center">
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <AddIcon />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Add Bills Plating</small>
              </h3>
            </CardHeader>
            <CardBody>
              <form>
                {submitted ? (
                  <SweetAlert
                    type={alertType}
                    style={{ display: "block", marginTop: "-100px" }}
                    title={alertMsg}
                    onConfirm={() => hideAlert()}
                    confirmBtnCssClass={
                      classSweet.button + " " + classSweet.success
                    }
                  />
                ) : null}
                <GridContainer>
                  <GridItem xs={12} sm={6} md={6}>
                    <InputLabel className={classDrop.label}>
                      Date Picker
                    </InputLabel>
                    <FormControl fullWidth>
                      <Datetime
                        timeFormat={false}
                        inputProps={{
                          placeholder: "Date Picker Here",
                        }}
                        onChange={(Date) => handleDatePicker(Date)}
                        name="Date"
                        value={Date}
                      />
                    </FormControl>
                    <span style={{ color: "red" }}>{DateErr}</span>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <label className={classes.selectLabel}>Select Labour Name</label>
                    <Selects
                      styles={customStyles}
                      style={select}
                      placeholder="Select Labour Name..."
                      value={simpleSelectClient}
                      onChange={handleClient}
                      options={
                        labourList &&
                        labourList.map((c) => {
                          return {
                            label:
                              c && c.company
                                ? c.company + "-" + c.mobile
                                : c.firstname +
                                  " " +
                                  c.lastname +
                                  "-" +
                                  c.mobile,
                            value:
                              c && c.company
                                ? c.company + "-" + c.mobile
                                : c.firstname +
                                  " " +
                                  c.lastname +
                                  "-" +
                                  c.mobile,
                          };
                        })
                      }
                    />

                    <span style={{ color: "red" }}>
                      {simpleSelectClientErr}
                    </span>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={6} md={6}>
                    <CustomInput
                      labelText="Golwire Thaw..."
                      id="golwireThaw"
                      inputProps={{
                        placeholder: "ex. 145 Golwire Thaw...",
                      }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      name="golwireThaw"
                      value={golwireThaw}
                      onChange={(e) => {
                        setGolwireThaw(e.target.value);
                        setGolwireThawErr("");
                      }}
                      required={true}
                    />
                    <span style={{ color: "red" }}>{golwireThawErr}</span>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={6}>
                    <CustomInput
                      labelText="Patti/Lasiya.."
                      id="pattiLasiya"
                      inputProps={{
                        placeholder: "ex. 5MM Patti & 0.011 Grams...",
                      }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      name="pattiLasiya"
                      value={pattiLasiya}
                      onChange={(e) => {
                        setPattiLasiya(e.target.value);
                        setPattiLasiyaErr("");
                      }}
                      required={true}
                    />
                    <span style={{ color: "red" }}>{pattiLasiyaErr}</span>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={2} md={2}>
                    <CustomInput
                      labelText="Quantity..."
                      id="quantity"
                      inputProps={{
                        placeholder: "Quantity ...",
                      }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      name="quantity"
                      value={quantity}
                      onChange={(e) => {
                        const { value } = e.target;
                        setQuantity(value);
                        setQuantityErr("");
                        const val =
                          value * price;
                        setAmount(val.toFixed(4));
                      }}
                      required={true}
                    />
                    <span style={{ color: "red" }}>{quantityErr}</span>
                  </GridItem>

                  <GridItem xs={12} sm={3} md={3}>
                    <FormControl
                      fullWidth
                      className={classDrop.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="simple-select"
                        className={classDrop.selectLabel}
                      >
                        Select Unit...
                      </InputLabel>

                      <Select
                        MenuProps={{
                          className: classDrop.selectMenu,
                        }}
                        classes={{
                          select: classDrop.select,
                        }}
                        value={simpleSelectType}
                        onChange={handleSimpleType}
                        inputProps={{
                          name: "stateSelect",
                          id: "simple-select",
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classDrop.selectMenuItem,
                          }}
                        >
                          Select Unit
                        </MenuItem>
                        {unit &&
                          unit.map((item) => (
                            <MenuItem
                              key={item.unitId}
                              value={item}
                              classes={{
                                root: classDrop.selectMenuItem,
                                selected: classDrop.selectMenuItemSelected,
                              }}
                            >
                              {item.title}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    <span style={{ color: "red" }}>{simpleSelectTypeErr}</span>
                  </GridItem>
                  <GridItem xs={12} sm={3} md={3}>
                    <CustomInput
                      labelText="Price..."
                      id="price"
                      inputProps={{
                        placeholder: "Price...",
                      }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      name="price"
                      value={price}
                      onChange={(e) => {
                        const { value } = e.target;
                        setPrice(value);
                        setPriceErr("");
                        const val =
                          quantity * value;
                        setAmount(val.toFixed(4));
                      }}
                      required={true}
                    />
                    <span style={{ color: "red" }}>{priceErr}</span>
                  </GridItem>
                  <GridItem xs={12} sm={4} md={4}>
                    <CustomInput
                      labelText="Amount..."
                      id="amount"
                      inputProps={{
                        placeholder: "Amount ...",
                      }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      name="amount"
                      value={amount}
                      onChange={(e) => {
                        setAmount(e.target.value);
                        setAmountErr("");
                      }}
                      required={true}
                    />
                    <span style={{ color: "red" }}>{amountErr}</span>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={checkedA}
                          onChange={(event) =>
                            setCheckedA(event.target.checked)
                          }
                          value="checkedA"
                          classes={{
                            switchBase: classe.switchBase,
                            checked: classes.switchChecked,
                            thumb: classe.switchIcon,
                            track: classe.switchBar,
                          }}
                        />
                      }
                      classes={{
                        label: classe.label,
                      }}
                      label={
                        checkedA === false ? (
                          <span>Tax Disable</span>
                        ) : (
                          <span
                            style={{
                              color: checkedA === true ? "#d81b60" : "",
                            }}
                          >
                            Tax Enable
                          </span>
                        )
                      }
                    />
                  </GridItem>
                  {checkedA === true ? (
                    <>
                      <GridItem xs={5} sm={6} md={4}>
                        <FormControlLabel
                          control={
                            <Radio
                              checked={selectedGstCal === "local"}
                              onChange={handleChangeGstCal}
                              value="local"
                              name="local"
                              aria-label="LOCAL"
                              icon={
                                <FiberManualRecord
                                  className={classesRadio.radioUnchecked}
                                />
                              }
                              checkedIcon={
                                <FiberManualRecord
                                  className={classesRadio.radioChecked}
                                />
                              }
                              classes={{
                                checked: classesRadio.radioRose,
                                root: classesRadio.radioRoot,
                              }}
                            />
                          }
                          classes={{
                            label: classesRadio.label,
                            root: classesRadio.labelRoot,
                          }}
                          // label="GST"
                        />
                        <label
                          style={{
                            color: selectedGstCal === "local" ? "#d81b60" : "",
                          }}
                        >
                          LOCAL
                        </label>
                      </GridItem>
                      <GridItem xs={5} sm={6} md={4}>
                        <FormControlLabel
                          control={
                            <Radio
                              checked={selectedGstCal === "interested"}
                              onClick={handleChangeGstCal}
                              value="interested"
                              name="interested"
                              aria-label="INTERESTED"
                              icon={
                                <FiberManualRecord
                                  className={classesRadio.radioUnchecked}
                                />
                              }
                              checkedIcon={
                                <FiberManualRecord
                                  className={classesRadio.radioChecked}
                                />
                              }
                              classes={{
                                checked: classesRadio.radioRose,
                                root: classesRadio.radioRoot,
                              }}
                            />
                          }
                          classes={{
                            label: classesRadio.label,
                            root: classesRadio.labelRoot,
                          }}
                          // label="CASH"
                        />
                        <label
                          style={{
                            color:
                              selectedGstCal === "interested" ? "#d81b60" : "",
                          }}
                        >
                          INTERESTED
                        </label>
                      </GridItem>
                      {selectedGstCal === "local" ? (
                        <>
                          <GridItem xs={12} sm={12} md={3}>
                            <CustomInput
                              labelText="CGST @9%..."
                              id="cgst"
                              inputProps={{
                                placeholder: "CGST @9%...",
                              }}
                              formControlProps={{
                                fullWidth: true,
                              }}
                              name="cgst"
                              value={cgst}
                              onChange={(e) => {
                                setCgst(e.target.value);
                                // setCgstErr("");
                              }}
                              required={true}
                            />
                            {/* <span style={{ color: "red" }}>
                                        {gstErr}
                                      </span> */}
                          </GridItem>
                          <GridItem xs={12} sm={12} md={3}>
                            <CustomInput
                              labelText="SGST @9%..."
                              id="sgst"
                              inputProps={{
                                placeholder: "SGST @9%...",
                              }}
                              formControlProps={{
                                fullWidth: true,
                              }}
                              name="sgst"
                              value={sgst}
                              onChange={(e) => {
                                setSgst(e.target.value);
                                // setSgstErr("");
                              }}
                              required={true}
                            />
                            {/* <span style={{ color: "red" }}>
                                        {gstErr}
                                      </span> */}
                          </GridItem>
                        </>
                      ) : (
                        ""
                      )}
                      {selectedGstCal === "interested" ? (
                        <GridItem xs={12} sm={12} md={3}>
                          <CustomInput
                            labelText="IGST @18%..."
                            id="igst"
                            inputProps={{
                              placeholder: "IGST @18%...",
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            name="igst"
                            value={igst}
                            onChange={(e) => {
                              setIgst(e.target.value);
                              // setGstErr("");
                            }}
                            required={true}
                          />
                          {/* <span style={{ color: "red" }}>
                                        {gstErr}
                                      </span> */}
                        </GridItem>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </GridContainer>
                <Button
                  color="rose"
                  onClick={SubmitAddPlating}
                  className={classes.updateProfileButton}
                >
                  Save
                </Button>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

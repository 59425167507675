import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { BiEdit } from "react-icons/bi";
import { headApi } from "utils/headApi";
import { localApi } from "utils/imgApi";
import Check from "@material-ui/icons/Check";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import style from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import { useEffect } from "react";
import styleCheck from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import styleSweet from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";

import { credentials } from "utils/imgApi";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(style);
const useStyleCheck = makeStyles(styleCheck);
const useStyleSweet = makeStyles(styleSweet);

export default function UpdatePacking(props) {
  const [items, setItems] = React.useState([]);
  const [dibbiName, setDibbiName] = React.useState("");
  const [dibbiCharge, setDibbiCharge] = React.useState("");
  const [hsncode, setHsncode] = React.useState("");
  const [price, setPrice] = React.useState("");
  const [gst, setGst] = React.useState("");
  const [dibbiErr, setDibbiErr] = React.useState("");
  const [hsncodeErr, setHsncodeErr] = React.useState("");
  const [priceErr, setPriceErr] = React.useState("");
  const [gstErr, setGstErr] = React.useState("");
  const [simpleSelect, setSimpleSelect] = React.useState("");
  const [simpleSelect1, setSimpleSelect1] = React.useState("");
  const [getId, setGetId] = React.useState("");
  const [active, setActive] = React.useState(false);

  const [submitted, setSubmitted] = React.useState(false);
  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");
  const [btn, setBtn] = React.useState(false);

  const classes = useStyles();
  const classe = useStyle();
  const classCheck = useStyleCheck();
  const classSweet = useStyleSweet();

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  const handleSimple = async (event) => {
    const { value } = event.target;
    setSimpleSelect(value);
    const api = await fetch(
      localApi +
        `/packingmaterial/list/byAdminIdAndType/${
          userObj && userObj.id
        }/${value}`, {
          method: "GET",
          headers: {
            Authorization: `Basic ${credentials}`,
          },
        }
    );
    const result = await api.json();
    // console.log(result);
    setItems(result && result);
  };

  const handleSimple1 = async (event) => {
    const { value } = event.target;
    const item = items.find((item) => item.id === value);
    setSimpleSelect1(value);
    // console.log(item);
    setDibbiName(item.pkgdesc);
    setHsncode(item.hsncode);
    setPrice(item.price);
    setGst(item.ligst);
    setActive(item.active);
    setGetId(item.id);
  };

  const SubmitUpdatePacking = (e) => {
    let errorCount = 0;
    if (dibbiName === "" || !dibbiName) {
      errorCount++;
      setDibbiErr("Dibbi Name is Required");
    }

    if (hsncode === "" || !hsncode) {
      errorCount++;
      setHsncodeErr("HSN Code is Required");
    }

    if (price === "" || !price) {
      errorCount++;
      setPriceErr("Price is Required");
    }

    if (gst === "" || !gst) {
      errorCount++;
      setGstErr("GST is Required");
    }

    if (errorCount === 0) {
      setBtn(true);
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Basic ${credentials}`,
        },
        body: JSON.stringify({
          type: simpleSelect,
          pkgdesc: dibbiName,
          hsncode: hsncode,
          price: price,
          ligst: gst,
        }),
      };

      fetch(localApi + `/packingmaterial/update/${getId}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          if (data.message === "Data Updated Successfully !!!") {
            alertTypeOps("success");
            alertMsgOps("Succesfully !!!");
            setSubmitted(true);
          }
        });
    }
  };

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  useEffect(() => {
    // console.log("Value Changed")
    setSimpleSelect1("");
    setDibbiName("");
    setHsncode("");
    setPrice("");
    setGst("");
    setGetId("");
  }, [simpleSelect]);

  return (
    <div>
      <GridContainer justify="center" alignItems="center">
        <GridItem xs={12} sm={6} md={6}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <BiEdit />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Update Packing Material</small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {submitted ? (
                  <SweetAlert
                    type={alertType}
                    style={{ display: "block", marginTop: "-100px" }}
                    title={alertMsg}
                    onConfirm={() => hideAlert()}
                    confirmBtnCssClass={
                      classSweet.button + " " + classSweet.success
                    }
                  >Updated Packing Material</SweetAlert>
                ) : null}{" "}
                <GridItem xs={12} sm={12} md={12}>
                  <FormControl fullWidth className={classe.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classe.selectLabel}
                    >
                      Select Packing Type...
                    </InputLabel>

                    <Select
                      MenuProps={{
                        className: classe.selectMenu,
                      }}
                      classes={{
                        select: classe.select,
                      }}
                      value={simpleSelect}
                      onChange={handleSimple}
                      inputProps={{
                        name: "simpleSelect",
                        id: "simple-select",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classe.selectMenuItem,
                        }}
                      >
                        Select Packing Type...
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classe.selectMenuItem,
                          selected: classe.selectMenuItemSelected,
                        }}
                        value="Crystal Dibbi"
                      >
                        Crystal Dibbi
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classe.selectMenuItem,
                          selected: classe.selectMenuItemSelected,
                        }}
                        value="Plastic Dibbi"
                      >
                        Plastic Dibbi
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classe.selectMenuItem,
                          selected: classe.selectMenuItemSelected,
                        }}
                        value="Polythene PP"
                      >
                        Polythene PP
                      </MenuItem>
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <FormControl fullWidth className={classe.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classe.selectLabel}
                    >
                      Select Material Name...
                    </InputLabel>

                    <Select
                      MenuProps={{
                        className: classe.selectMenu,
                      }}
                      classes={{
                        select: classe.select,
                      }}
                      value={simpleSelect1}
                      onChange={handleSimple1}
                      inputProps={{
                        name: "simpleSelect",
                        id: "simple-select",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classe.selectMenuItem,
                        }}
                      >
                        Select Material Name...
                      </MenuItem>
                      {items &&
                        items.map((item) => (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                            classes={{
                              root: classe.selectMenuItem,
                              selected: classe.selectMenuItemSelected,
                            }}
                          >
                            {item.pkgdesc}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Material Name..."
                    id="dibbiName"
                    inputProps={{
                      placeholder: "Material Name ...",
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="dibbiName"
                    value={dibbiName}
                    onChange={(e) => {
                      setDibbiName(e.target.value);
                      setDibbiErr("");
                    }}
                    required={true}
                  />
                  <span style={{ color: "red" }}>{dibbiErr}</span>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="HSN Code..."
                    id="hsncode"
                    inputProps={{
                      placeholder: "HSN Code ...",
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="hsncode"
                    value={hsncode}
                    onChange={(e) => {
                      setHsncode(e.target.value);
                      setHsncodeErr("");
                    }}
                    required={true}
                  />
                  <span style={{ color: "red" }}>{hsncodeErr}</span>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Price..."
                    id="price"
                    inputProps={{
                      placeholder: "Price ...",
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="price"
                    value={price}
                    onChange={(e) => {
                      setPrice(e.target.value);
                      setPriceErr("");
                    }}
                    required={true}
                  />
                  <span style={{ color: "red" }}>{priceErr}</span>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Tax Rate..."
                    id="gst"
                    inputProps={{
                      placeholder: "Ex. 18...",
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="gst"
                    value={gst}
                    onChange={(e) => {
                      setGst(e.target.value);
                      setGstErr("");
                    }}
                    required={true}
                  />
                  <span style={{ color: "red" }}>{gstErr}</span>
                </GridItem>
              </GridContainer>
              {/* <Button
                color="rose"
                onClick={SubmitUpdatePacking}
                className={classes.updateProfileButton}
              >
                Update
              </Button> */}
              {btn === true ? (
                <Button className={classes.updateProfileButton}>Update</Button>
              ) : (
                <Button
                  color="rose"
                  onClick={SubmitUpdatePacking}
                  className={classes.updateProfileButton}
                >
                  Update
                </Button>
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

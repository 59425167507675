import React, { useEffect } from "react";
import moment from "moment";
import { produce } from "immer";
import { makeStyles } from "@material-ui/core/styles";
import { BiEdit } from "react-icons/bi";
import Selects from "react-select";
import { useHistory, useLocation } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import SweetAlert from "react-bootstrap-sweetalert";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import styleSweet from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import style from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import { localApi } from "utils/imgApi";
import styRadio from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import styleTable from "assets/jss/material-dashboard-pro-react/views/viewCartStyle";
import styCheckbox from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(style);
const useStyless = makeStyles(styRadio);
const useStyleSweet = makeStyles(styleSweet);
const useStyleTable = makeStyles(styleTable);
const useSty = makeStyles(styCheckbox);

export default function LabourAllViewRolling(props) {
  const [billView, setBillView] = React.useState([]);
  const [labourList, setLabourList] = React.useState([]);
  const [simpleSelectClient, setSimpleSelectClient] = React.useState("");
  const [checkOldAddress, setCheckOldAddress] = React.useState(false);
  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);
  const [makingCheckboxMaking, setMakingCheckboxMaking] = React.useState("");
  const [checkBoxMaking, setCheckBoxmaking] = React.useState([]);
  const [makChecked, setMakChecked] = React.useState("");

  const classes = useStyles();
  const classe = useStyle();
  const classesRadio = useStyless();
  const classSweet = useStyleSweet();
  const classTable = useStyleTable();
  const history = useHistory();
  const classCheck = useSty();

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  async function getList() {
    const getList = await fetch(
      localApi + `/rolling/issued/list/byAdminId/${userObj && userObj.id}`
    );
    const res = await getList.json();
    setBillView(res && res.filter((f) => f.issued === "ISSUED"));
  }

  useEffect(() => {
    getList();
  }, []);

  const handleCheckBoxMaking = (event, c) => {
    // setCheckBoxmaking(e.target.checked);
    // const { checked } = event.target;

    setCheckBoxmaking((prev) => {
      if (prev.includes(c)) {
        return prev.filter((x) => x !== c);
      } else {
        return [...prev, c];
      }
    });

    setMakChecked((prev) => {
      if (prev.includes(c?.id)) {
        return prev.filter((x) => x !== c?.id);
      } else {
        return [...prev, c?.id];
      }
    });
  };

  const handleClient = async (simpleSelectClient) => {
    // console.log(simpleSelectClient);
    if (simpleSelectClient.value) {
      let newSpli = simpleSelectClient.value.split("-");
      const ids = labourList.find((f) => f.mobile == newSpli[1]);
      //   console.log(ids);
      const api = localApi + `/register/ByMobile/${ids.mobile}`;
      const result = await fetch(api);
      const getRes = await result.json();
      //   console.log(getRes);
      const apiClient =
        localApi + `/metal/list/byAdminAndClientId/${userObj.id}/${getRes.id}`;
      const resultClient = await fetch(apiClient);
      const getResult = await resultClient.json();
      //   console.log(getResult);
      setBillView(getResult);
    }
    setSimpleSelectClient(simpleSelectClient);
  };

  const handleDeleteMetal = (c) => {
    fetch(
      localApi + `/metal/delete/byAdminAndMetalId/${userObj.id}/${c.metalId}`,
      {
        method: "DELETE",
      }
    )
      .then((res) => res.json())
      .then((res) => {
        // console.log(res);
        if (res.message === "Deleted Successfully !!!") {
          alertTypeOps("success");
          alertMsgOps("Deleted Successfully !!!");
          setSubmitted(true);
        }
      });
  };

  // const handleGO = () => {
  //   console.log(checkBoxMaking);
  // }

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  const select = {
    width: "100%",
    maxWidth: 600,
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      padding: 10,
    }),
    menu: (state) => ({
      color: "#d81b60",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  return (
    <div>
      <GridContainer
        justify="center"
        alignItems="center"
        className={classTable.print}
      >
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <BiEdit />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>View All Bills History</small>
              </h3>
            </CardHeader>
            <CardBody></CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer
        justify="center"
        alignItems="center"
        className={classes.container}
      >
        {submitted ? (
          <SweetAlert
            type={alertType}
            style={{ display: "block", marginTop: "-100px" }}
            title={alertMsg}
            onConfirm={() => hideAlert()}
            confirmBtnCssClass={classSweet.button + " " + classSweet.success}
          />
        ) : null}
        <GridItem xs={12} sm={12} md={12}>
          <Table
            hover
            tableHead={[
              <span className={classTable.tableHead} key="sr">
                Sr.No.
              </span>,
              <span className={classTable.tableHead} key="bn">
                Bill.No.
              </span>,
              <span className={classTable.tableHead} key="dt">
                Date
              </span>,
              <span className={classTable.tableHead} key="pn">
                Party Name
              </span>,
              <span className={classTable.tableHead} key="des">
                Description of Goods
              </span>,
              <span
                className={classTable.tableHead + " " + classTable.headRight}
                key="qty"
              >
                Quantity
              </span>,
              <span
                className={classTable.tableHead + " " + classTable.headRight}
                key="ut"
              >
                Unit
              </span>,
              <span
                className={classTable.tableHead + " " + classTable.headRight}
                key="pri"
              >
                Price
              </span>,
              <span
                className={classTable.tableHead + " " + classTable.headRight}
                key="amt"
              >
                Amount
              </span>,
            ]}
            tableData={[
              ...billView.map((c, index) => ({
                color: c.taxApply === true ? "success" : "",
                data: [
                  <span className={classTable.listNumber} key={c.id}>
                    {index + 1}
                    <FormControlLabel
                        key={c.id}
                        control={
                          <Checkbox
                            tabIndex={-1}
                            checked={checkBoxMaking.includes(c)}
                            name={c}
                            onChange={(e) => handleCheckBoxMaking(e, c)}
                            checkedIcon={
                              <Check className={classCheck.checkedIcon} />
                            }
                            icon={
                              <Check className={classCheck.uncheckedIcon} />
                            }
                            classes={{
                              checked: classCheck.checkedRose,
                              root: classCheck.checkRoot,
                            }}
                          />
                        }
                        classes={{
                          label:
                            classCheck.label +
                            (makingCheckboxMaking === "error"
                              ? " " + classCheck.labelError
                              : ""),
                        }}
                      // label={making.description + "> " + making.price}
                      />
                  </span>,
                  <span className={classTable.listNumber} key={c.id}>
                    {c.id}
                  </span>,
                  <span className={classTable.listNumber} key={c.id}>
                    {moment(c.pickerdate).format('MM-DD-YYYY')}
                  </span>,
                  <span className={classTable.listNumber} key={c.id}>
                    {c.firstName + " " + c.lastName}
                  </span>,
                  <span key={c.id}>
                    <small className={classTable.listNumber}>
                      {c.title} - {c.description}
                    </small>
                  </span>,
                  <span
                    className={
                      classTable.headRight + " " + classTable.listNumber
                    }
                    key={c.id}
                  >
                    {c.quantity}
                  </span>,
                  <span
                    className={
                      classTable.headRight + " " + classTable.listNumber
                    }
                    key={c.id}
                  >
                    {c.unit}
                  </span>,
                  <span
                    className={
                      classTable.headRight + " " + classTable.listNumber
                    }
                    key={c.id}
                  >
                    ₹{c.price}
                  </span>,
                  <span
                    className={
                      classTable.headRight + " " + classTable.listNumber
                    }
                    key={c.id}
                  >
                    ₹{c.amount.toFixed(2)}
                  </span>,
                  // <Button
                  //   color="rose"
                  //   block
                  //   simple
                  //   onClick={() => {
                  //     handleDeleteMetal(c);
                  //   }}
                  // >
                  //   <DeleteForeverIcon />
                  // </Button>,
                ],
              })),
              [
                "",
                "",
                "",
                "",
                <span
                  className={classTable.headRight + " " + classTable.listNumber}
                  key={1}
                >
                  Grand Total
                </span>,
                <span key={3}>
                  <span
                    className={
                      classTable.headRight + " " + classTable.listNumber
                    }
                  >
                    {billView
                      .reduce(
                        (a, b) => parseFloat(a) + parseFloat(b.quantity),
                        0
                      )
                      .toFixed(2)}
                  </span>
                </span>,
                "",
                "",
                <span key={3}>
                  <span
                    className={
                      classTable.headRight + " " + classTable.listNumber
                    }
                  >
                    ₹
                    {billView
                      .reduce((a, b) => parseFloat(a) + parseFloat(b.amount), 0)
                      .toFixed(2)}
                  </span>
                </span>,
              ],
              [
                "",
                "",
                "",
                "",
                <span key={3}>
                  <span
                    className={
                      classTable.headRight + " " + classTable.listNumber
                    }
                  >
                    Closing Balance
                  </span>
                </span>,
                "",
                "",
                "",
                <span key={3}>
                  <span
                    className={
                      classTable.headRight + " " + classTable.listNumber
                    }
                  >
                    ₹
                    {billView
                      .reduce((a, b) => parseFloat(a) + parseFloat(b.amount), 0)
                      .toFixed(2)}
                  </span>
                </span>,
              ],
            ]}
          />
        </GridItem>
      </GridContainer>
      <Button
        className={classTable.print}
        color="rose"
        round 
        onClick={() => 
          history.push({
            pathname: `/labours-all-print`,
            state: checkBoxMaking,
          })}
      >
        Go
      </Button>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { isValidPhoneNumber } from "react-phone-number-input";
import { localApi } from "utils/imgApi";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MenuItem from "@material-ui/core/MenuItem";
import EmailIcon from "@material-ui/icons/Email";
import Select from "@material-ui/core/Select";
import PublicIcon from "@material-ui/icons/Public";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Face from "@material-ui/icons/Face";
import BusinessIcon from "@material-ui/icons/Business";
import Check from "@material-ui/icons/Check";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import styles from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import style from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styl from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import { getStates, getCities } from "utils/util";

import { credentials } from "utils/imgApi";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(style);
const useStyl = makeStyles(styl);

export default function RegisterPage() {
  const [countryList, setCountryList] = React.useState([]);
  const [firstName, setFirstName] = React.useState("");
  const [firstNameErr, setFirstNameErr] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [lastNameErr, setLastNameErr] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [mobileErr, setMobileErr] = React.useState("");
  const [mobileWhatsapp, setMobileWhatsapp] = React.useState("");
  const [company, setCompany] = React.useState("");
  const [companyErr, setCompanyErr] = React.useState("");
  const [countryCode, setCountryCode] = React.useState("in");
  const [countryName, setCountryName] = React.useState("India");
  const [cityErr, setCityErr] = React.useState("");
  const [stateCode, setStateCode] = React.useState("");
  const [stateNameErr, setStateNameErr] = React.useState("");
  const [active, setActive] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const [checkboxValue, setCheckboxValue] = React.useState(false);
  const [alertType, alertTypeOps] = useState("");
  const [alertMsg, alertMsgOps] = useState("");
  const [stateSelect, setStateSelect] = React.useState("");
  const [citySelect, setCitySelect] = React.useState("");
  const [webSelect, setWebSelect] = React.useState("");
  const [webSelectErr, setWebSelectErr] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [emailErr, setEmailErr] = React.useState("");
  const [type, setType] = React.useState("");
  const [domain, setDomain] = React.useState("");
  const [domainErr, setDomainErr] = React.useState("");
  const [btn, setBtn] = React.useState(false);

  const classes = useStyles();
  const classe = useStyle();
  const classDrop = useStyl();
  const history = useHistory();

  const currentSession = sessionStorage.getItem("sessionData");
  if (currentSession) {
    history.push("/admin/dashboard");
  }

  const { state } = useLocation();

  // async function fetchCountryListCode() {
  //   const api = localApi + `/countrylistcode/allList`;
  //   const result = await fetch(api);
  //   const getResult = await result.json();
  //   setCountryList(getResult);
  // }

  // useEffect(() => {
  //   fetchCountryListCode();
  // }, []);

  async function getUser() {
    const api = localApi + `/type/list`;
    const result = await fetch(api, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getResult = await result.json();
    // console.log(getResult.filter((item) => item.title !== "Admin"));
    // setType(getResult.LoginType.filter((item) => item.title !== "Admin"));
    setType(getResult && getResult.TypeList);
    // console.log(getResult);
  }

  useEffect(() => {
    getUser();
  }, []);

  const handleMobileNumber = (mobile) => {
    setMobile(mobile);
    fetch(localApi + `/admin/find/byMobile/${mobile}`, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log(result);
          if(result.message === "Not Found"){
            setMobileErr("")
          }else{
          setMobileErr(result.message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const handleEmail = (e) => {
    const { value } = e.target;
    setEmail(value);
    fetch(localApi + `/admin/find/byEmail/${value}`, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log(result);
          if(result.message === "Not Found"){
            setEmailErr("")
          }else{
          setEmailErr(result.message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const handleDomain = (e) => {
    const { value } = e.target;
    setDomain(value);
    setDomainErr("");
  };

  const handleCheckedBox = (e) => {
    // console.log(checkboxValue);
    if (e.target?.checked) {
      setMobileWhatsapp(mobile);
    } else {
      setMobileWhatsapp("");
    }
    setCheckboxValue(e.target.checked);
  };

  const handleState = (event) => {
    setStateSelect(event.target.value);
    setStateNameErr("");
  };

  const handleCity = (event) => {
    setCitySelect(event.target.value);
    setCityErr("");
  };

  const handleWeb = (event) => {
    setWebSelect(event.target.value);
    setWebSelectErr("");
  };

  // const handleTypeSimple = (e) => {
  //   const { value } = e.target;
  //   setSimpleTypeSelect(value);
  // };

  // const test = (e) => {
  //   //BASIC SHARED PLAN 34914
  //   //ADVANCED SHARED PLAN 59828
  //   //STANDARD SHARED PLAN 69828
  //   //CUSTOMIZED SHARED PLAN 118228

  //   const BasicDate = new Date();
  //   BasicDate.setMonth(BasicDate.getMonth() + 3);
  //   // console.log(BasicDate);

  //   const AdvancedDate = new Date();
  //   AdvancedDate.setFullYear(AdvancedDate.getFullYear() + 1);
  //   // console.log(AdvancedDate);

  //   const StandardDate = new Date();
  //   StandardDate.setFullYear(StandardDate.getFullYear() + 1);
  //   // console.log(StandardDate);

  //   const CustomizedDate = new Date();
  //   CustomizedDate.setFullYear(CustomizedDate.getFullYear() + 1);
  //   // console.log(CustomizedDate);

  //   console.log(
  //     state === "34914"
  //       ? BasicDate
  //       : state === "59828"
  //       ? AdvancedDate
  //       : state === "69828"
  //       ? StandardDate
  //       : state === "118228"
  //       ? CustomizedDate
  //       : ""
  //   );

  //   console.log(
  //     state === "34914"
  //       ? "Basic Shared Plan"
  //       : state === "59828"
  //       ? "Advanced Shared Plan"
  //       : state === "69828"
  //       ? "Standard Shared Plan"
  //       : state === "118228"
  //       ? "Customized Shared Plan"
  //       : ""
  //   );
  // };

  const SubmitBasic = (event) => {
    const getFind = type.find((f) => f.title === "Admin");
    // const countryListCode = countryList.find((f) => f.countryCode.toLowerCase() === countryCode);

    let emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;

    const FreeDate = new Date();
    FreeDate.setMonth(FreeDate.getMonth() + 3);
    // console.log(BasicDate);

    const BasicDate = new Date();
    BasicDate.getFullYear(BasicDate.getFullYear() + 1);
    // console.log(BasicDate);

    const AdvancedDate = new Date();
    AdvancedDate.setFullYear(AdvancedDate.getFullYear() + 1);
    // console.log(AdvancedDate);

    const StandardDate = new Date();
    StandardDate.setFullYear(StandardDate.getFullYear() + 1);
    // console.log(StandardDate);

    const CustomizedDate = new Date();
    CustomizedDate.setFullYear(CustomizedDate.getFullYear() + 1);
    // console.log(CustomizedDate);

    if (state === "3600") {
      if (webSelect) {
        if (webSelect === "FREE PLAN") {
          // console.log("FREE PLAN");
          let errorCount = 0;
          if (firstName === "" || !firstName) {
            errorCount++;
            setFirstNameErr("Please Enter First Name");
          }
          if (lastName === "" || !lastName) {
            errorCount++;
            setLastNameErr("Please Enter Last Name");
          }
          if (mobile === "" || !mobile || !isValidPhoneNumber(`+${mobile}`)) {
            errorCount++;
            setMobileErr("Please Enter Valid Mobile Number");
          }
          if (email === "" || !email || !emailRegex.test(email)) {
            errorCount++;
            setEmailErr("Please Enter Email Id");
          }
          if (company === "" || !company) {
            errorCount++;
            setCompanyErr("Please Enter Company Name");
          }
          if (domain === "" || !domain) {
            errorCount++;
            setDomainErr("Please Enter Domain");
          }
          if (webSelect === "" || !webSelect) {
            errorCount++;
            setWebSelectErr("Please Select Account Plan");
          }
          // if(simpleTypeSelect === "" || !simpleTypeSelect){
          //   errorCount++;
          //   setTypeErr("Please Select Type");
          // }
          if (citySelect === "" || !citySelect) {
            errorCount++;
            setCityErr("Please Select City Name");
          }
          if (stateSelect === "" || !stateSelect) {
            errorCount++;
            setStateNameErr("Please Select State Name");
          }
          if (mobileErr === "Mobile Already Registered") {
            errorCount++;
            alertTypeOps("danger");
            alertMsgOps("Number alerady register");
            setSubmitted(true);
          }
          if (emailErr === "Email Id Already Registered") {
            errorCount++;
            alertTypeOps("danger");
            alertMsgOps("Email alerady register");
            setSubmitted(true);
          }
          if (errorCount === 0) {
            setBtn(true);
            const requestOptions = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Basic ${credentials}`,
              },
              body: JSON.stringify({
                websitename: domain,
                endDate:
                  state === "3600"
                    ? webSelect
                      ? webSelect === "FREE PLAN"
                        ? FreeDate
                        : BasicDate
                      : ""
                    : state === "59828"
                    ? AdvancedDate
                    : state === "69828"
                    ? StandardDate
                    : state === "118228"
                    ? CustomizedDate
                    : "",
                webPlan:
                  state === "3600"
                    ? webSelect
                      ? webSelect === "FREE PLAN"
                        ? "FREE BASIC PLAN"
                        : "Basic Shared Plan"
                      : ""
                    : state === "59828"
                    ? "Advanced Shared Plan"
                    : state === "69828"
                    ? "Standard Shared Plan"
                    : state === "118228"
                    ? "Customized Shared Plan"
                    : "",
                typeId: getFind.typeId,
                profit: getFind.profit,
                type: "Admin",
                firstname: firstName,
                lastname: lastName,
                mobile: mobile,
                whatsapp: mobileWhatsapp,
                email: email,
                company: company,
                city: citySelect.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ""),
                state: stateSelect.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ""),
                country: countryName.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ""),
                smsMarketing: active,
                // countryCode: countryListCode.countryCode,
                // isoNumber: countryListCode.isoNumber,
                otp: {
                  otp: 0,
                  active: 0,
                },
              }),
            };

            fetch(localApi + "/admin/find/byMobile&Email/add", requestOptions)
              .then((response) => response.json())
              .then((data) => {
                // console.log(data);
                if (data.message === "Data Inserted Successfully !!!") {
                  alertTypeOps("success");
                  alertMsgOps("Succesfully registerd!");
                  setSubmitted(true);
                }
              });
          }
        } else {
          // console.log("BASIC PLAN");
          let errorCount = 0;
          if (firstName === "" || !firstName) {
            errorCount++;
            setFirstNameErr("Please Enter First Name");
          }
          if (lastName === "" || !lastName) {
            errorCount++;
            setLastNameErr("Please Enter Last Name");
          }
          if (mobile === "" || !mobile || !isValidPhoneNumber(`+${mobile}`)) {
            errorCount++;
            setMobileErr("Please Enter Valid Mobile Number");
          }
          if (email === "" || !email || !emailRegex.test(email)) {
            errorCount++;
            setEmailErr("Please Enter Email Id");
          }
          if (company === "" || !company) {
            errorCount++;
            setCompanyErr("Please Enter Company Name");
          }
          if (domain === "" || !domain) {
            errorCount++;
            setDomainErr("Please Enter Domain");
          }
          if (webSelect === "" || !webSelect) {
            errorCount++;
            setWebSelectErr("Please Select Account Plan");
          }
          // if(simpleTypeSelect === "" || !simpleTypeSelect){
          //   errorCount++;
          //   setTypeErr("Please Select Type");
          // }
          if (citySelect === "" || !citySelect) {
            errorCount++;
            setCityErr("Please Select City Name");
          }
          if (stateSelect === "" || !stateSelect) {
            errorCount++;
            setStateNameErr("Please Select State Name");
          }
          if (mobileErr === "Mobile Already Registered") {
            errorCount++;
            alertTypeOps("danger");
            alertMsgOps("Number alerady register");
            setSubmitted(true);
          }
          if (emailErr === "Email Id Already Registered") {
            errorCount++;
            alertTypeOps("danger");
            alertMsgOps("Email alerady register");
            setSubmitted(true);
          }
          if (errorCount === 0) {
            var options = {
              key: "rzp_live_SsKrYvXeVM5elD",
              amount: state * 100,
              currency: "INR",
              name: "Sharif H M",
              description: "Insha Bangles",
              handler: function (response) {
                // alert(response.razorpay_payment_id);
                if (response.razorpay_payment_id) {
                  // console.log(response.razorpay_payment_id);
                  const requestOptions = {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                      Accept: "application/json",
                      Authorization: `Basic ${credentials}`,
                    },
                    body: JSON.stringify({
                      websitename: domain,
                      endDate:
                        state === "3600"
                          ? webSelect
                            ? webSelect === "FREE PLAN"
                              ? FreeDate
                              : BasicDate
                            : ""
                          : state === "59828"
                          ? AdvancedDate
                          : state === "69828"
                          ? StandardDate
                          : state === "118228"
                          ? CustomizedDate
                          : "",
                      webPlan:
                        state === "3600"
                          ? webSelect
                            ? webSelect === "FREE PLAN"
                              ? "FREE BASIC PLAN"
                              : "Basic Shared Plan"
                            : ""
                          : state === "59828"
                          ? "Advanced Shared Plan"
                          : state === "69828"
                          ? "Standard Shared Plan"
                          : state === "118228"
                          ? "Customized Shared Plan"
                          : "",
                      typeId: getFind.typeId,
                      profit: getFind.profit,
                      type: "Admin",
                      firstname: firstName,
                      lastname: lastName,
                      mobile: mobile,
                      whatsapp: mobileWhatsapp,
                      email: email,
                      company: company,
                      city: citySelect.replace(
                        /[&\/\\#,+()$~%.'":*?<>{}]/g,
                        ""
                      ),
                      state: stateSelect.replace(
                        /[&\/\\#,+()$~%.'":*?<>{}]/g,
                        ""
                      ),
                      country: countryName.replace(
                        /[&\/\\#,+()$~%.'":*?<>{}]/g,
                        ""
                      ),
                      smsMarketing: active,
                      // countryCode: countryListCode.countryCode,
                      // isoNumber: countryListCode.isoNumber,
                      otp: {
                        otp: 0,
                        active: 0,
                      },
                    }),
                  };
                  fetch(
                    localApi + "/admin/find/byMobile&Email/add",
                    requestOptions
                  )
                    .then((response) => response.json())
                    .then((data) => {
                      // console.log(data);
                      if (data.message === "Data Inserted Successfully !!!") {
                        alertTypeOps("success");
                        alertMsgOps("Succesfully registerd!");
                        setSubmitted(true);
                      }
                    });
                  // setSubmitPayment(true);
                  // alertTypeOps("success");
                  // alertMsgOps(
                  //   `₹ ${
                  //     amountRecieved + "/- "
                  //   } Congratulation your Transaction is Successfully`
                  // );
                }
              },
              prefill: {
                name: firstName + "" + lastName,
                email: email,
                contact: mobile,
              },
              notes: {
                address: "Razorpay Corporate office",
              },
              theme: {
                color: "#528FF0",
              },
            };
            var pay = new window.Razorpay(options);
            pay.open();
          }
        }
      } else {
      }
    } else if (state === "59828" || state === "69828" || state === "118228") {
      event.preventDefault();
      let errorCount = 0;
      if (firstName === "" || !firstName) {
        errorCount++;
        setFirstNameErr("Please Enter First Name");
      }
      if (lastName === "" || !lastName) {
        errorCount++;
        setLastNameErr("Please Enter Last Name");
      }
      if (mobile === "" || !mobile || !isValidPhoneNumber(`+${mobile}`)) {
        errorCount++;
        setMobileErr("Please Enter Valid Mobile Number");
      }
      if (email === "" || !email || !emailRegex.test(email)) {
        errorCount++;
        setEmailErr("Please Enter Email Id");
      }
      if (company === "" || !company) {
        errorCount++;
        setCompanyErr("Please Enter Company Name");
      }
      if (domain === "" || !domain) {
        errorCount++;
        setDomainErr("Please Enter Domain");
      }
      // if (webSelect === "" || !webSelect) {
      //   errorCount++;
      //   setWebSelectErr("Please Select Account Plan");
      // }
      // if(simpleTypeSelect === "" || !simpleTypeSelect){
      //   errorCount++;
      //   setTypeErr("Please Select Type");
      // }
      if (citySelect === "" || !citySelect) {
        errorCount++;
        setCityErr("Please Select City Name");
      }
      if (stateSelect === "" || !stateSelect) {
        errorCount++;
        setStateNameErr("Please Select State Name");
      }
      if (mobileErr === "Mobile Already Registered") {
        errorCount++;
        alertTypeOps("danger");
        alertMsgOps("Number alerady register");
        setSubmitted(true);
      }
      if (emailErr === "Email Id Already Registered") {
        errorCount++;
        alertTypeOps("danger");
        alertMsgOps("Email alerady register");
        setSubmitted(true);
      }
      if (errorCount === 0) {
        var options = {
          key: "rzp_live_SsKrYvXeVM5elD",
          amount: state * 100,
          currency: "INR",
          name: "Sharif H M",
          description: "Insha Bangles",
          handler: function (response) {
            // alert(response.razorpay_payment_id);
            if (response.razorpay_payment_id) {
              // console.log(response.razorpay_payment_id);
              const requestOptions = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                  Authorization: `Basic ${credentials}`,
                },
                body: JSON.stringify({
                  websitename: domain,
                  endDate:
                    state === "3600"
                      ? BasicDate
                      : state === "59828"
                      ? AdvancedDate
                      : state === "69828"
                      ? StandardDate
                      : state === "118228"
                      ? CustomizedDate
                      : "",
                  webPlan:
                    state === "3600"
                      ? "Basic Shared Plan"
                      : state === "59828"
                      ? "Advanced Shared Plan"
                      : state === "69828"
                      ? "Standard Shared Plan"
                      : state === "118228"
                      ? "Customized Shared Plan"
                      : "",
                  typeId: getFind.typeId,
                  profit: getFind.profit,
                  type: "Admin",
                  firstname: firstName,
                  lastname: lastName,
                  mobile: mobile,
                  whatsapp: mobileWhatsapp,
                  email: email,
                  company: company,
                  city: citySelect.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ""),
                  state: stateSelect.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ""),
                  country: countryName.replace(
                    /[&\/\\#,+()$~%.'":*?<>{}]/g,
                    ""
                  ),
                  smsMarketing: active,
                  // countryCode: countryListCode.countryCode,
                  // isoNumber: countryListCode.isoNumber,
                  otp: {
                    otp: 0,
                    active: 0,
                  },
                }),
              };
              fetch(localApi + "/admin/find/byMobile&Email/add", requestOptions)
                .then((response) => response.json())
                .then((data) => {
                  // console.log(data);
                  if (data.message === "Data Inserted Successfully !!!") {
                    alertTypeOps("success");
                    alertMsgOps("Succesfully registerd!");
                    setSubmitted(true);
                  }
                });
              // setSubmitPayment(true);
              // alertTypeOps("success");
              // alertMsgOps(
              //   `₹ ${
              //     amountRecieved + "/- "
              //   } Congratulation your Transaction is Successfully`
              // );
            }
          },
          prefill: {
            name: firstName + "" + lastName,
            email: email,
            contact: mobile,
          },
          notes: {
            address: "Razorpay Corporate office",
          },
          theme: {
            color: "#528FF0",
          },
        };
        var pay = new window.Razorpay(options);
        pay.open();
      }
    }
  };

  const hideAlert = () => {
    setSubmitted(false);
    // history.push("/auth/login-page");
    if (!mobileErr || mobileErr === "") {
      if (!emailErr || emailErr === "") {
        history.push("/auth/login-page");
      }
    }
  };

  const mystyle = {
    paddingLeft: 12,
    flex: 1,
  };

  return (
    <div className={classes.container}>
      <GridContainer justify="center" alignItems="center">
        <GridItem xs={12} sm={10} md={6}>
          <Card className={classes.cardSignup}>
            <h2 className={classes.cardTitle}>Admin Register</h2>
            <CardBody>
              <GridContainer justify="center">
                <GridItem xs={12} sm={10} md={8}>
                  <div className={classes.center}>
                    <Button justIcon round color="twitter">
                      <i className="fab fa-twitter" />
                    </Button>
                    {` `}
                    <Button justIcon round color="dribbble">
                      <i className="fab fa-dribbble" />
                    </Button>
                    {` `}
                    <Button justIcon round color="facebook">
                      <i className="fab fa-facebook-f" />
                    </Button>
                    {` `}
                    <h4 className={classes.socialTitle}>or be classical</h4>
                  </div>

                  <form className={classes.form}>
                    {submitted ? (
                      <SweetAlert
                        type={alertType}
                        style={{ display: "block", marginTop: "-100px" }}
                        title={alertMsg}
                        onConfirm={() => hideAlert()}
                        confirmBtnCssClass={
                          classe.button + " " + classe.success
                        }
                      />
                    ) : null}

                    <CustomInput
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <Face className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        placeholder: "First Name...",
                      }}
                      id="firstName"
                      name="firstName"
                      value={firstName}
                      onChange={(e) => {
                        setFirstName(e.target.value);
                        setFirstNameErr("");
                      }}
                      required={true}
                    />
                    <span style={{ color: "red", marginLeft: "12px" }}>
                      {firstNameErr}
                    </span>
                    <CustomInput
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <Face className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        placeholder: "Last Name...",
                      }}
                      id="lastName"
                      name="lastName"
                      value={lastName}
                      onChange={(e) => {
                        setLastName(e.target.value);
                        setLastNameErr("");
                      }}
                      required={true}
                    />
                    <span style={{ color: "red", marginLeft: "12px" }}>
                      {lastNameErr}
                    </span>

                    <br />
                    <PhoneInput
                      style={mystyle}
                      inputStyle={{
                        width: "105%",
                        border: "none",
                        borderBottom: "1px solid #040000",
                        borderRadius: "0",
                      }}
                      country={"in"}
                      name="mobile"
                      value={mobile}
                      onChange={(mobile) => {
                        let country_code = document.body.querySelector(
                          "div.flag"
                        )?.className;
                        country_code =
                          country_code && country_code.replace("flag", "");
                        setCountryCode(country_code.trim());
                        // console.log(country_code);
                        handleMobileNumber(mobile);
                        let country_name = document.body.querySelector(
                          "div.selected-flag"
                        )?.title;
                        setCountryName(country_name.slice(0, -6));
                      }}
                      // onChange={(mobile) => {
                      //   setMobile(mobile.length <= 12 ? mobile : mobile);
                      // setMobileErr("");
                      // }}
                      required={true}
                    />
                    <span style={{ color: "red", marginLeft: "12px" }}>
                      {mobileErr}
                    </span>
                    <br />
                    <FormControlLabel
                      classes={{
                        root: classes.checkboxLabelControl,
                        label: classes.checkboxLabel,
                      }}
                      control={
                        <Checkbox
                          // id="active"
                          checked={checkboxValue || false}
                          onChange={(e) => handleCheckedBox(e)}
                          tabIndex={-1}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                        />
                      }
                      label={
                        <span style={{ color: "#9c27b0" }}>
                          If your WhatsApp Number is same, click on this
                          checkbox.
                        </span>
                      }
                    />
                    <br />
                    <PhoneInput
                      style={mystyle}
                      inputStyle={{
                        width: "105%",
                        border: "none",
                        borderBottom: "1px solid #040000 ",
                        borderRadius: "0",
                      }}
                      country={"in"}
                      name="mobile"
                      value={mobileWhatsapp}
                      onChange={(mobileWhatsapp) => {
                        if (mobile === mobileWhatsapp) {
                          setCheckboxValue(true);
                        } else {
                          setCheckboxValue(false);
                        }
                        setMobileWhatsapp(mobileWhatsapp);
                        // setMobileWhatsappErr("");
                      }}
                      // required={true}
                    />
                    {/* <span style={{ color: "red" }}>{mobileWhatsappErr}</span> */}
                    <CustomInput
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <BusinessIcon
                              className={classes.inputAdornmentIcon}
                            />
                          </InputAdornment>
                        ),
                        placeholder: "Company...",
                      }}
                      id="company"
                      name="company"
                      value={company}
                      onChange={(e) => {
                        setCompany(e.target.value);
                        setCompanyErr("");
                      }}
                      required={true}
                    />
                    <span style={{ color: "red", marginLeft: "12px" }}>
                      {companyErr}
                    </span>

                    <CustomInput
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <EmailIcon className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        placeholder: "Email...",
                      }}
                      id="email"
                      name="email"
                      value={email}
                      onChange={handleEmail}
                      required={true}
                    />
                    <span style={{ color: "red", marginLeft: "12px" }}>
                      {emailErr}
                    </span>

                    <CustomInput
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <PublicIcon
                              className={classes.inputAdornmentIcon}
                            />
                          </InputAdornment>
                        ),
                        placeholder: "Domain...",
                      }}
                      id="domain"
                      name="domain"
                      value={domain}
                      onChange={handleDomain}
                      required={true}
                    />
                    <span style={{ color: "red", marginLeft: "12px" }}>
                      {domainErr}
                    </span>

                    <GridItem style={{ width: "110%" }}>
                      <FormControl
                        fullWidth
                        className={classDrop.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classDrop.selectLabel}
                        >
                          Select State...
                        </InputLabel>

                        <Select
                          MenuProps={{
                            className: classDrop.selectMenu,
                          }}
                          classes={{
                            select: classDrop.select,
                          }}
                          value={stateSelect}
                          onChange={handleState}
                          required={true}
                          inputProps={{
                            name: "stateSelect",
                            id: "simple-select",
                          }}
                        >
                          <MenuItem
                            disabled
                            classes={{
                              root: classDrop.selectMenuItem,
                            }}
                          >
                            Select State
                          </MenuItem>
                          {getStates(countryCode).map((item) => (
                            <MenuItem
                              key={item.name}
                              value={item.name}
                              onClick={() => setStateCode(item?.state_code)}
                              classes={{
                                root: classDrop.selectMenuItem,
                                selected: classDrop.selectMenuItemSelected,
                              }}
                            >
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <span style={{ color: "red" }}>{stateNameErr}</span>
                    </GridItem>

                    <GridItem style={{ width: "110%" }}>
                      <FormControl
                        fullWidth
                        className={classDrop.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classDrop.selectLabel}
                        >
                          Select City...
                        </InputLabel>

                        <Select
                          MenuProps={{
                            className: classDrop.selectMenu,
                          }}
                          classes={{
                            select: classDrop.select,
                          }}
                          value={citySelect}
                          onChange={handleCity}
                          required={true}
                          inputProps={{
                            name: "stateSelect",
                            id: "simple-select",
                          }}
                        >
                          <MenuItem
                            disabled
                            classes={{
                              root: classDrop.selectMenuItem,
                            }}
                          >
                            Select City
                          </MenuItem>
                          {getCities(countryCode, stateCode).map((item) => (
                            <MenuItem
                              key={item.name}
                              value={item.name}
                              classes={{
                                root: classDrop.selectMenuItem,
                                selected: classDrop.selectMenuItemSelected,
                              }}
                            >
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <span style={{ color: "red" }}>{cityErr}</span>
                    </GridItem>
                    <br />

                    <FormControlLabel
                      classes={{
                        root: classes.checkboxLabelControl,
                        label: classes.checkboxLabel,
                      }}
                      control={
                        <Checkbox
                          id="active"
                          value={active}
                          onChange={(e) => {
                            setActive(e.target.checked);
                          }}
                          tabIndex={-1}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                        />
                      }
                      label={
                        <span style={{ color: "#9c27b0", fontSize: "12px" }}>
                          I would like to recieve the latest news from the
                          Company by phone/whatsapp
                        </span>
                      }
                    />
                  </form>
                  <div>
                    <h4>Package Information</h4>
                    <div className={classes.center}>
                      {state === "3600" ? (
                        <GridContainer justify="center">
                          <GridItem style={{ width: "80%" }}>
                            <FormControl
                              fullWidth
                              className={classDrop.selectFormControl}
                            >
                              <InputLabel
                                htmlFor="simple-select"
                                className={classDrop.selectLabel}
                              >
                                Select Account Plan...
                              </InputLabel>

                              <Select
                                MenuProps={{
                                  className: classDrop.selectMenu,
                                }}
                                classes={{
                                  select: classDrop.select,
                                }}
                                value={webSelect}
                                onChange={handleWeb}
                                required={true}
                                inputProps={{
                                  name: "stateSelect",
                                  id: "simple-select",
                                }}
                              >
                                <MenuItem
                                  disabled
                                  classes={{
                                    root: classDrop.selectMenuItem,
                                  }}
                                >
                                  Select Account Plan
                                </MenuItem>
                                <MenuItem
                                  classes={{
                                    root: classDrop.selectMenuItem,
                                    selected: classDrop.selectMenuItemSelected,
                                  }}
                                  value="FREE PLAN"
                                >
                                  FREE BASIC PLAN 3 MONTHS
                                </MenuItem>
                                <MenuItem
                                  classes={{
                                    root: classDrop.selectMenuItem,
                                    selected: classDrop.selectMenuItemSelected,
                                  }}
                                  value="12 MONTHS"
                                >
                                  BASIC SHARED PLAN 12 MONTHS
                                </MenuItem>
                              </Select>
                            </FormControl>
                            <span style={{ color: "red" }}>{webSelectErr}</span>
                          </GridItem>
                        </GridContainer>
                      ) : (
                        ""
                      )}
                      {state === "3600" ? (
                        webSelect ? (
                          <h5 style={{ color: "#9c27b0" }}>
                            Account:-{" "}
                            {webSelect === "FREE PLAN"
                              ? "FREE BASIC PLAN"
                              : "BASIC SHARED PLAN"}
                            <br />
                            Hosting Price:-{" "}
                            {webSelect === "FREE PLAN"
                              ? "₹ 0/- (3 Months)"
                              : "₹ 3,600/- (12 Months)"}
                          </h5>
                        ) : (
                          ""
                        )
                      ) : state === "59828" ? (
                        <h5 style={{ color: "#9c27b0" }}>
                          Account:- Advanced Shared Plan
                          <br />
                          Hosting Price:- ₹ 59,828/- (12 Months)
                        </h5>
                      ) : state === "69828" ? (
                        <h5 style={{ color: "#9c27b0" }}>
                          Account:- Standard Shared Plan
                          <br />
                          Hosting Price:- ₹ 69,828/- (12 Months)
                        </h5>
                      ) : state === "118228" ? (
                        <h5 style={{ color: "#9c27b0" }}>
                          Account:- Customized Shared Plan
                          <br />
                          Hosting Price:- ₹ 1,18,228/- (12 Months)
                        </h5>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className={classes.center}>
                    {/* <Button round color="primary" onClick={Submit}>
                      Sign Up
                    </Button> */}
                    {/* <Button color="rose" onClick={test}>
                      test
                    </Button> */}
                    {btn === true ? (
                      <Button round>Sign Up</Button>
                    ) : (
                      <Button color="rose" onClick={SubmitBasic} round>
                        Sign Up
                      </Button>
                    )}
                  </div>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

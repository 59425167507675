import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import { localApi } from "utils/imgApi";
import axios from "axios";
import Datetime from "react-datetime";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import Table from "components/Table/Tables.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Radio from "@material-ui/core/Radio";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import styDrop from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import styRadio from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import SweetAlert from "react-bootstrap-sweetalert";
import styleSweet from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import stylesTable from "assets/jss/material-dashboard-pro-react/views/viewCartStyle";

import { credentials } from "utils/imgApi";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

const style = {
  cardTitle,
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center",
  },
  cardCategory: {
    margin: "0",
    color: "#999999",
  },
};

const useStyle = makeStyles(style);
const useStyles = makeStyles(styles);
const useStyl = makeStyles(styDrop);
const useStylRadio = makeStyles(styRadio);
const useStyleSweet = makeStyles(styleSweet);
const useStyleTable = makeStyles(stylesTable);

export default function LabourStatus(props) {
  const [labourStatusList, setLabourStatusList] = React.useState([]);
  const [metalList, setMetalList] = React.useState([]);
  const [rollingList, setRollingList] = React.useState([]);
  const [dieList, setDieList] = React.useState([]);
  const [metalId, setMetalId] = React.useState("");
  const [metalIdErr, setMetalIdErr] = React.useState("");

  const [expandedMetalRows, setExpandedMetalRows] = React.useState([]);
  const [expandedRollingRows, setExpandedRollingRows] = React.useState([]);
  const [expandedDieRows, setExpandedDieRows] = React.useState([]);

  const [expandMetalState, setExpandMetalState] = React.useState({});
  const [expandRollingState, setExpandRollingState] = React.useState({});
  const [expandDieState, setExpandDieState] = React.useState({});

  const [anchorElTop, setAnchorElTop] = React.useState(null);

  const classes = useStyles();
  const classe = useStyle();
  const classDrop = useStyl();
  const classesRadio = useStylRadio();
  const classSweet = useStyleSweet();
  const classTable = useStyleTable();

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));
  const favoritemovie = sessionStorage.getItem("favoriteMovie");

  setTimeout(() => {
    // if(size1x8 || size1x10){
    setAnchorElTop(null);
    // }
  }, 4000);

  ////merge multiple Array
  //   function merge(...arrays) {
  //     let newArray = [];
  //     for(let i = 0; i < arrays.length; i++) {
  //         newArray.push(...arrays[i]);
  //     }
  //     return newArray;
  // }

  // const SearchEditMetal = async (id) => {
  //   var tempArray = [];
  //   var tempArray2 = [];
  //   var tempData = {};
  //   var tempData1 = {};
  //   var tempData2 = {};
  //   var tempData3 = {};

  //   const api =
  //     localApi +
  //     `/metal/issued/findByAdminAndMetalId/${userObj && userObj.id}/${
  //       metalId ? metalId : ""
  //     }`;
  //   const result = await fetch(api);
  //   const getResult = await result.json();
  //   tempArray.push(getResult);
  //   // console.log(tempArray);

  //   const mId =
  //     tempArray &&
  //     tempArray.map((i) => {
  //       return i.id;
  //     });

  //   // console.log(mId);

  //   Promise.all(
  //     mId.map(async (id) => {
  //       const usedApi = await fetch(
  //         localApi + `/metal/list/byAdminIdAndBillReference/2/${id}`
  //       );
  //       const data = await usedApi.json();
  //       // console.log(data);
  //       tempData = { ...tempData, [id]: data };
  //       // console.log(tempData);
  //     })
  //   ).then(() => {
  //     //   console.log(tempData);
  //     const tempItems = tempArray.map((i) => {
  //       return { ...i, u: tempData[i.id] || 0 };
  //     });
  //     // console.log(tempItems);

  //     const rId = tempItems.map((f) => {
  //       return f.u.map((i) => {
  //         return i.id;
  //       });
  //     });

  //     // console.log(rId[0]);

  //     Promise.all(
  //       rId[0].map(async (id) => {
  //         const usedApi = await fetch(
  //           localApi + `/rolling/list/byAdminIdAndBillReference/2/${id}`
  //         );
  //         const data = await usedApi.json();
  //         // console.log(data);
  //         tempData1 = { ...tempData1, [id]: data };
  //         // console.log(tempData1);
  //       })
  //     ).then(() => {
  //       const tempItems1 = tempItems.map((i) => {
  //         return {
  //           ...i,
  //           u:
  //             i.u.map((t) => {
  //               return { ...t, u: tempData1[t.id] || 0 };
  //             }) || 0,
  //         };
  //       });
  //       // console.log(tempItems1);

  //       const dId = tempItems1.map((g) => {
  //         return g.u.map((i) => {
  //           return i.u.map((z) => {
  //             return z.id;
  //           });
  //         });
  //       });

  //       // console.log(dId);

  //       let newArray = [];
  //       for (let i = 0; i < dId[0].length; i++) {
  //         newArray.push(...dId[0][i]);
  //       }

  //       // console.log(newArray);

  //       Promise.all(
  //         newArray.map(async (id) => {
  //           const usedApi = await fetch(
  //             localApi + `/rolling/list/byAdminIdAndBillReference/2/${id}`
  //           );
  //           const data = await usedApi.json();
  //           // console.log(data);
  //           tempData2 = { ...tempData2, [id]: data };
  //           // console.log(tempData2);
  //         })
  //       ).then(() => {
  //         const tempItems2 = tempItems1.map((i) => {
  //           return {
  //             ...i,
  //             u:
  //               i.u.map((t) => {
  //                 return {
  //                   ...t,
  //                   u:
  //                     t.u.map((s) => {
  //                       return { ...s, u: tempData2[s.id] || 0 };
  //                     }) || 0,
  //                 };
  //               }) || 0,
  //           };
  //         });

  //         // console.log(tempItems2);

  //         const diId = tempItems2.map((g) => {
  //           return g.u.map((i) => {
  //             return i.u.map((z) => {
  //               return z.u.map((p) => {
  //                 return p.id;
  //               });
  //             });
  //           });
  //         });

  //         // console.log(diId);

  //         let newArrays = [];
  //         for (let i = 0; i < diId[0][0].length; i++) {
  //           newArrays.push(...diId[0][0][i]);
  //         }

  //         // console.log(newArrays);

  //         Promise.all(
  //           newArrays.map(async (id) => {
  //             const usedApi = await fetch(
  //               localApi + `/diecutting/list/byAdminIdAndBillReference/2/${id}`
  //             );
  //             const data = await usedApi.json();
  //             // console.log(data);
  //             tempData3 = { ...tempData3, [id]: data };
  //             // console.log(tempData3);
  //           })
  //         ).then(() => {
  //           // console.log(tempData3);
  //           // tempData3[s.id]
  //           const tempItems3 = tempItems2.map((i) => {
  //             return {
  //               ...i,
  //               u:
  //                 i.u.map((t) => {
  //                   return {
  //                     ...t,
  //                     u:
  //                       t.u.map((s) => {
  //                         return {
  //                           ...s,
  //                           u:
  //                             s.u.map((g) => {
  //                               return { ...g, u: tempData3[g.id] };
  //                             }) || 0,
  //                         };
  //                       }) || 0,
  //                   };
  //                 }) || 0,
  //             };
  //           });
  //           console.log(tempItems3);
  //           setLabourStatusList(tempItems3);
  //           // console.log(tempData3.map((m) => ));
  //         });
  //       });
  //     });
  //   });
  // };

  // const SearchEditMetal = async (id) => {
  //   var tempMetalArray = [];
  //   var tempMetalData = {};

  //   var tempRollingArray = [];
  //   var tempRollingData = {};

  //   var tempDieArray = [];
  //   var tempDieData = {};

  //   const api =
  //     localApi +
  //     `/metal/issued/findByAdminAndMetalId/${userObj && userObj.id}/${
  //       metalId ? metalId : ""
  //     }`;
  //   const result = await fetch(api);
  //   const getResult = await result.json();
  //   tempMetalArray.push(getResult);
  //   // console.log(tempArray);

  //   const mId =
  //     tempMetalArray &&
  //     tempMetalArray.map((i) => {
  //       return i.id;
  //     });

  //   // console.log(mId);

  //   Promise.all(
  //     mId.map(async (id) => {
  //       const usedApi = await fetch(
  //         localApi + `/metal/list/byAdminIdAndBillReference/2/${id}`
  //       );
  //       const data = await usedApi.json();
  //       // console.log(data);
  //       tempMetalData = { ...tempMetalData, [id]: data };
  //       // console.log(tempMetalData);
  //     })
  //   ).then(() => {
  //     //   console.log(tempMetalData);
  //     const tempMetalList = tempMetalArray.map((i) => {
  //       return { ...i, u: tempMetalData[i.id] || 0 };
  //     });
  //     setMetalList(tempMetalList && tempMetalList);
  //     console.log("Metal", tempMetalList);

  //     const mId = tempMetalList.map((f) => {
  //       return f.u.map((i) => {
  //         return i.id;
  //       });
  //     });

  //     // console.log(mId[0]);

  //     Promise.all(
  //       mId[0].map(async (id) => {
  //         const usedApi = await fetch(
  //           localApi + `/rolling/list/byAdminIdAndBillReference/2/${id}`
  //         );
  //         const data = await usedApi.json();
  //         // console.log(...data);
  //         tempRollingArray.push(...data);
  //       })
  //     ).then(() => {
  //       // console.log(tempRollingArray);

  //       const rId = tempRollingArray.map((r) => {
  //         return r.id;
  //       });
  //       // console.log(rId);

  //       Promise.all(
  //         rId.map(async (id) => {
  //           const usedApi = await fetch(
  //             localApi + `/rolling/list/byAdminIdAndBillReference/2/${id}`
  //           );
  //           const data = await usedApi.json();
  //           // console.log(data);
  //           tempRollingData = { ...tempRollingData, [id]: data };
  //           // console.log(tempRollingData);
  //         })
  //       ).then(() => {
  //         const tempRollingList = tempRollingArray.map((i) => {
  //           return { ...i, u: tempRollingData[i.id] || 0 };
  //         });
  //         console.log("Rolling", tempRollingList);
  //         setRollingList(tempRollingList && tempRollingList);

  //         const ruId = tempRollingList.map((r) => {
  //           return r.u.map((ru) => {
  //             return ru.id;
  //           });
  //         });

  //         // console.log(ruId[0]);

  //         Promise.all(
  //           ruId[0].map(async (id) => {
  //             const usedApi = await fetch(
  //               localApi + `/diecutting/list/byAdminIdAndBillReference/2/${id}`
  //             );
  //             const data = await usedApi.json();
  //             // console.log(...data);
  //             tempDieArray.push(...data);
  //           })
  //         ).then(() => {
  //           // setDieList(tempDieArray);

  //           const dId = tempDieArray.map((d) => {
  //             return d.id;
  //           });

  //           // console.log(dId);

  //           Promise.all(
  //             dId.map(async (id) => {
  //               const usedApi = await fetch(
  //                 localApi +
  //                   `/diecutting/list/byAdminIdAndBillReference/2/${id}`
  //               );
  //               const data = await usedApi.json();
  //               // console.log(data);
  //               tempDieData = { ...tempDieData, [id]: data };
  //               // console.log(tempDieData);
  //             })
  //           ).then(() => {
  //             const tempDieList = tempDieArray.map((i) => {
  //               return { ...i, u: tempDieData[i.id] || 0 };
  //             });
  //             console.log("Die", tempDieList);
  //             setDieList(tempDieList && tempDieList);
  //           });
  //         });
  //       });
  //     });
  //   });
  // };

  // const SearchEditMetal = async (id) => {
  //   var tempMetalArray = [];
  //   var tempMetalData = {};

  //   var tempRollingArray = [];
  //   var tempRollingData = {};

  //   var tempDieArray = [];
  //   var tempDieData = {};

  //   const api =
  //     localApi +
  //     `/metal/issued/findByAdminAndMetalId/${userObj && userObj.id}/${
  //       metalId ? metalId : ""
  //     }`;
  //   const result = await fetch(api);
  //   const getResult = await result.json();
  //   tempMetalArray.push(getResult);
  //   // console.log(tempArray);

  //   const mId =
  //     tempMetalArray &&
  //     tempMetalArray.map((i) => {
  //       return i.id;
  //     });

  //   // console.log(mId);

  //   Promise.all(
  //     mId.map(async (id) => {
  //       const metalApi = await fetch(
  //         localApi + `/metal/list/byAdminIdAndOrdersReference/2/${id}`
  //       );
  //       const metalData = await metalApi.json();

  //       const rollingApi = await fetch(
  //         localApi + `/rolling/list/byAdminIdAndOrdersReference/2/${id}`
  //       );
  //       const rollingData = await rollingApi.json();

  //       const dieApi = await fetch(
  //         localApi + `/diecutting/list/byAdminIdAndOrdersReference/2/${id}`
  //       );
  //       const dieData = await dieApi.json();

  //       tempMetalData = {
  //         ...tempMetalData,
  //         [id]: [...metalData, ...rollingData, ...dieData],
  //       };
  //       // console.log(tempMetalData);
  //     })
  //   ).then(() => {
  //     //   console.log(tempMetalData);
  //     const tempMetalList = tempMetalArray.map((i) => {
  //       return { ...i, u: tempMetalData[i.id] || 0 };
  //     });
  //     setMetalList(tempMetalList && tempMetalList);
  //     console.log("Metal", tempMetalList);
  //   });
  // };

  // const SearchEditMetal = async (id) => {
  //   var tempMetalArray = [];
  //   var tempMetalData = {};
  //   var tempMetalIdArray = [];

  //   var tempRollingArray = [];
  //   var tempRollingData = {};

  //   var tempDieArray = [];
  //   var tempDieData = {};

  //   const api =
  //     localApi +
  //     `/metal/issued/findByAdminAndMetalId/${userObj && userObj.id}/${
  //       metalId ? metalId : ""
  //     }`;
  //   const result = await fetch(api);
  //   const getResult = await result.json();
  //   tempMetalArray.push(getResult);
  //   // console.log(tempArray);

  //   const mId =
  //     tempMetalArray &&
  //     tempMetalArray.map((i) => {
  //       return i.id;
  //     });

  //   // console.log(mId);

  //   Promise.all(
  //     mId.map(async (id) => {
  //       const metalApi = await fetch(
  //         localApi + `/metal/list/byAdminIdAndOrdersReference/2/${id}`
  //       );
  //       const metalData = await metalApi.json();

  //       tempMetalData = {
  //         ...tempMetalData,
  //         [id]: metalData,
  //       };
  //       // console.log(tempMetalData);
  //     })
  //   ).then(() => {
  //     //   console.log(tempMetalData);
  //     const tempMetalList = tempMetalArray.map((i) => {
  //       return { ...i, u: tempMetalData[i.id] || 0 };
  //     });
  //     setMetalList(tempMetalList && tempMetalList);
  //     tempMetalIdArray.push(tempMetalList.map((m) => {
  //       return m.u.map((mu) => {
  //         return mu.id;
  //       })
  //     }));
  //     // console.log("Metal", tempMetalList);
  //   });

  //   console.log(tempMetalIdArray);

  //   Promise.all(
  //     mId.map(async (id) => {
  //       const rollingApi = await fetch(
  //         localApi + `/rolling/list/byAdminIdAndOrdersReference/2/${id}`
  //       );
  //       const rollingData = await rollingApi.json();
  //       tempRollingData = {
  //         ...tempRollingData,
  //         [id]: rollingData,
  //       };
  //       // console.log(tempMetalData);
  //     })
  //   ).then(() => {
  //     //   console.log(tempMetalData);
  //     const tempRollingList = tempMetalArray.map((i) => {
  //       return { ...i, u: tempRollingData[i.id] || 0 };
  //     });
  //     setRollingList(tempRollingList && tempRollingList);
  //     console.log("Rolling", tempRollingList);
  //   });
  // };

  const SearchEditMetal = async (id) => {
    var tempMetalArray = [];
    var tempMetalData = {};

    var tempRollingArray = [];
    var tempRollingData = {};

    var tempDieArray = [];
    var tempDieData = {};

    const api =
      localApi +
      `/labourissued/findByAdminAndIssuedId/${userObj && userObj.id}/${
        metalId ? metalId : ""
      }`;
    const result = await fetch(api, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getResult = await result.json();
    tempMetalArray.push(getResult);
    // console.log(tempArray);

    setMetalList(tempMetalArray)

  };

  const handleEpandMetalRow = (event, userId) => {
    const currentExpandedRows = expandedMetalRows;
    const isRowExpanded = currentExpandedRows.includes(userId);

    let obj = {};
    isRowExpanded ? (obj[userId] = false) : (obj[userId] = true);
    setExpandMetalState(obj);

    // If the row is expanded, we are here to hide it. Hence remove
    // it from the state variable. Otherwise add to it.
    const newExpandedRows = isRowExpanded
      ? currentExpandedRows.filter((id) => id !== userId)
      : currentExpandedRows.concat(userId);

    setExpandedMetalRows(newExpandedRows);
  };

  const handleEpandRollingRow = (event, userId) => {
    const currentExpandedRows = expandedRollingRows;
    const isRowExpanded = currentExpandedRows.includes(userId);

    let obj = {};
    isRowExpanded ? (obj[userId] = false) : (obj[userId] = true);
    setExpandRollingState(obj);

    // If the row is expanded, we are here to hide it. Hence remove
    // it from the state variable. Otherwise add to it.
    const newExpandedRows = isRowExpanded
      ? currentExpandedRows.filter((id) => id !== userId)
      : currentExpandedRows.concat(userId);

    setExpandedRollingRows(newExpandedRows);
  };

  const handleEpandDieRow = (event, userId) => {
    const currentExpandedRows = expandedDieRows;
    const isRowExpanded = currentExpandedRows.includes(userId);

    let obj = {};
    isRowExpanded ? (obj[userId] = false) : (obj[userId] = true);
    setExpandDieState(obj);

    // If the row is expanded, we are here to hide it. Hence remove
    // it from the state variable. Otherwise add to it.
    const newExpandedRows = isRowExpanded
      ? currentExpandedRows.filter((id) => id !== userId)
      : currentExpandedRows.concat(userId);

    setExpandedDieRows(newExpandedRows);
  };

  return (
    <div>
      <GridContainer justify="center" alignItems="center">
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <AddIcon />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Get Labour Status</small>
              </h3>
            </CardHeader>
            <CardBody>
              <form>
                <GridContainer
                  justify="center"
                  alignItems="center"
                  style={{ marginBottom: "7%" }}
                >
                  <GridContainer justify="center" alignItems="center">
                    <GridItem xs={12} sm={4} md={4}>
                      <CustomInput
                        labelText="Enter Metal Id..."
                        id="enterMetalId"
                        inputProps={{
                          placeholder: "Enter Metal Id",
                        }}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        name="metalId"
                        value={metalId}
                        onChange={(e) => {
                          setMetalId(e.target.value);
                        }}
                        required={true}
                      />
                      <span style={{ color: "red" }}>{metalIdErr}</span>
                    </GridItem>
                  </GridContainer>
                  <GridContainer justify="center" alignItems="center">
                    <Button
                      color="rose"
                      onClick={SearchEditMetal}
                      className={classes.updateProfileButton}
                    >
                      Search
                    </Button>
                  </GridContainer>
                </GridContainer>
              </form>
            </CardBody>
          </Card>
        </GridItem>

        {/* {metalList.length == 0 ? (
          ""
        ) : (
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <AddIcon />
                </CardIcon>
                <h3 className={classes.cardIconTitle}>
                  <small>Labour Status Metal List</small>
                </h3>
              </CardHeader>
              <CardBody>
                <form>
                  {metalList && metalList.length == 0
                    ? ""
                    : metalList.map((m) => (
                        <>
                          <p key={m.id}>
                            {m.billType} Id:- {m.id}{" "}
                            {m.issued == null ? "" : m.issued}{" "}
                            {m.received == null ? "" : m.received}
                          </p>
                          {m.u.length == 0
                            ? ""
                            : m.u.map((mu) => (
                                <>
                                  <small key={mu.id}>
                                    {mu.billType} Id:- {mu.id}{" "}
                                    {mu.issued == null ? "" : mu.issued}{" "}
                                    {mu.received == null ? "" : mu.received}
                                    <br />
                                  </small>
                                  <br />
                                </>
                              ))}
                        </>
                      ))}
                </form>
              </CardBody>
            </Card>
          </GridItem>
        )} */}

        {metalList.length == 0 ? (
          ""
        ) : (
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <AddIcon />
                </CardIcon>
                <h3 className={classes.cardIconTitle}>
                  <small>Metal Labour Status List</small>
                </h3>
              </CardHeader>
              <CardBody>
                <div className={classTable.container}>
                  {/* <h3 className={classTable.cardTitle}>Call Allocation Report</h3> */}
                  <Table
                    hover
                    tableHead={[
                      <span className={classTable.tableHead} key="sr">
                        Sr.No.
                      </span>,
                      <span
                        className={
                          classTable.tableHead + " " + classTable.headLeft
                        }
                        key="name"
                      >
                        Name
                      </span>,
                      <span
                        className={
                          classTable.tableHead + " " + classTable.headLeft
                        }
                        key="billType"
                      >
                        Billtype
                      </span>,
                      <span
                        className={
                          classTable.tableHead + " " + classTable.headLeft
                        }
                        key="issued"
                      >
                        Issued
                      </span>,
                      <span
                        className={
                          classTable.tableHead + " " + classTable.headLeft
                        }
                        key="received"
                      >
                        Received
                      </span>,
                      <span
                        className={
                          classTable.tableHead + " " + classTable.headLeft
                        }
                        key="quantity"
                      >
                        Quantity
                      </span>,
                      <span
                        className={
                          classTable.tableHead + " " + classTable.headLeft
                        }
                        key="unit"
                      >
                        Unit
                      </span>,
                      <span
                        className={
                          classTable.tableHead + " " + classTable.headLeft
                        }
                        key="price"
                      >
                        Price
                      </span>,
                      <span
                        className={
                          classTable.tableHead + " " + classTable.headLeft
                        }
                        key="amount"
                      >
                        Amount
                      </span>,
                    ]}
                    tableData={
                      metalList &&
                      metalList.map((c, index) => [
                        [
                          <span className={classTable.OrderlistDescription}>
                            {/* {index + 1} */}
                            {c.id}
                          </span>,
                          <span
                            className={
                              classTable.headLeft + " " + classTable.listNumber
                            }
                          >
                            {c.firstName + " " + c.lastName}
                          </span>,
                          <span
                            className={
                              classTable.headLeft + " " + classTable.listNumber
                            }
                          >
                            {c.billType}
                          </span>,
                          <span
                            className={
                              classTable.headLeft + " " + classTable.listNumber
                            }
                          >
                            {c.issued}
                          </span>,
                          <span
                            className={
                              classTable.headLeft + " " + classTable.listNumber
                            }
                          >
                            {c.received}
                          </span>,
                          <span
                            className={
                              classTable.headLeft + " " + classTable.listNumber
                            }
                          >
                            {c.quantity}
                          </span>,
                          <span
                            className={
                              classTable.headLeft + " " + classTable.listNumber
                            }
                          >
                            {c.unit}
                          </span>,
                          <span
                            className={
                              classTable.headLeft + " " + classTable.listNumber
                            }
                          >
                            {c.price}
                          </span>,
                          <span
                            className={
                              classTable.headLeft + " " + classTable.listNumber
                            }
                          >
                            {c.amount}
                          </span>,
                          <span>
                            {c.u.length !== 0 ? (
                              <Button
                                link
                                className={
                                  classTable.headLeft +
                                  " " +
                                  classTable.actionButton
                                }
                                onClick={(event) =>
                                  handleEpandMetalRow(event, c.id)
                                }
                              >
                                {expandMetalState[c.id] ? (
                                  <ArrowUpwardIcon style={{ color: "red" }} />
                                ) : (
                                  <ArrowDownwardIcon style={{ color: "red" }} />
                                )}
                              </Button>
                            ) : (
                              ""
                            )}
                          </span>,
                        ],
                        [
                          expandedMetalRows.includes(c.id) ? (
                            <span>
                              {c.u.map((p, index) => (
                                <span
                                  style={{
                                    marginTop: "20px",
                                    marginBottom: "20px",
                                    lineHeight: "20px",
                                  }}
                                  key={p.id}
                                >
                                  {/* {index + 1} */}
                                  {p.id}
                                  <br />
                                </span>
                              ))}
                            </span>
                          ) : null,
                          expandedMetalRows.includes(c.id) ? (
                            <span
                              className={
                                classTable.headLeft + " " + classTable.subList
                              }
                            >
                              {c.u.map((p) => (
                                <span
                                  style={{
                                    marginTop: "20px",
                                    marginBottom: "20px",
                                    lineHeight: "20px",
                                  }}
                                  key={p.id}
                                >
                                  {p.firstName + " " + p.lastName}
                                  <br />
                                </span>
                              ))}
                            </span>
                          ) : null,
                          expandedMetalRows.includes(c.id) ? (
                            <span
                              className={
                                classTable.headLeft + " " + classTable.subList
                              }
                            >
                              {c.u.map((p) => (
                                <span
                                  style={{
                                    marginTop: "20px",
                                    marginBottom: "20px",
                                    lineHeight: "20px",
                                  }}
                                  key={p.id}
                                >
                                  {p.billType}
                                  <br />
                                </span>
                              ))}
                            </span>
                          ) : null,
                          expandedMetalRows.includes(c.id) ? (
                            <span
                              className={
                                classTable.headLeft + " " + classTable.subList
                              }
                            >
                              {c.u.map((p) => (
                                <span
                                  style={{
                                    marginTop: "20px",
                                    marginBottom: "20px",
                                    lineHeight: "20px",
                                  }}
                                  key={p.id}
                                >
                                  {p.issued}
                                  <br />
                                </span>
                              ))}
                            </span>
                          ) : null,
                          expandedMetalRows.includes(c.id) ? (
                            <span
                              className={
                                classTable.headLeft + " " + classTable.subList
                              }
                            >
                              {c.u.map((p) => (
                                <span
                                  style={{
                                    marginTop: "20px",
                                    marginBottom: "20px",
                                    lineHeight: "20px",
                                  }}
                                  key={p.id}
                                >
                                  {p.received}
                                  <br />
                                </span>
                              ))}
                            </span>
                          ) : null,
                          expandedMetalRows.includes(c.id) ? (
                            <span
                              className={
                                classTable.headLeft + " " + classTable.subList
                              }
                            >
                              {c.u.map((p) => (
                                <span
                                  style={{
                                    marginTop: "20px",
                                    marginBottom: "20px",
                                    lineHeight: "20px",
                                  }}
                                  key={p.id}
                                >
                                  {p.quantity}
                                  <br />
                                </span>
                              ))}
                            </span>
                          ) : null,
                          expandedMetalRows.includes(c.id) ? (
                            <span
                              className={
                                classTable.headLeft + " " + classTable.subList
                              }
                            >
                              {c.u.map((p) => (
                                <span
                                  style={{
                                    marginTop: "20px",
                                    marginBottom: "20px",
                                    lineHeight: "20px",
                                  }}
                                  key={p.id}
                                >
                                  {p.unit}
                                  <br />
                                </span>
                              ))}
                            </span>
                          ) : null,
                          expandedMetalRows.includes(c.id) ? (
                            <span
                              className={
                                classTable.headLeft + " " + classTable.subList
                              }
                            >
                              {c.u.map((p) => (
                                <span
                                  style={{
                                    marginTop: "20px",
                                    marginBottom: "20px",
                                    lineHeight: "20px",
                                  }}
                                  key={p.id}
                                >
                                  {p.price}
                                  <br />
                                </span>
                              ))}
                            </span>
                          ) : null,
                          expandedMetalRows.includes(c.id) ? (
                            <span
                              className={
                                classTable.headLeft + " " + classTable.subList
                              }
                            >
                              {c.u.map((p) => (
                                <span
                                  style={{
                                    marginTop: "20px",
                                    marginBottom: "20px",
                                    lineHeight: "20px",
                                  }}
                                  key={p.id}
                                >
                                  {p.amount}
                                  <br />
                                </span>
                              ))}
                            </span>
                          ) : null,
                          "",
                        ],
                      ])
                    }
                  />
                </div>
              </CardBody>
            </Card>
          </GridItem>
        )}

        {/* {rollingList.length == 0 ? (
          ""
        ) : (
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <AddIcon />
                </CardIcon>
                <h3 className={classes.cardIconTitle}>
                  <small>Rolling Labour Status List</small>
                </h3>
              </CardHeader>
              <CardBody>
                <div className={classTable.container}>
                   <Table
                    hover
                    tableHead={[
                      <span className={classTable.tableHead} key="sr">
                        Sr.No.
                      </span>,
                      <span
                        className={
                          classTable.tableHead + " " + classTable.headLeft
                        }
                        key="name"
                      >
                        Name
                      </span>,
                      <span
                        className={
                          classTable.tableHead + " " + classTable.headLeft
                        }
                        key="billType"
                      >
                        Billtype
                      </span>,
                      <span
                        className={
                          classTable.tableHead + " " + classTable.headLeft
                        }
                        key="issued"
                      >
                        Issued
                      </span>,
                      <span
                        className={
                          classTable.tableHead + " " + classTable.headLeft
                        }
                        key="received"
                      >
                        Received
                      </span>,
                      <span
                        className={
                          classTable.tableHead + " " + classTable.headLeft
                        }
                        key="quantity"
                      >
                        Quantity
                      </span>,
                      <span
                        className={
                          classTable.tableHead + " " + classTable.headLeft
                        }
                        key="unit"
                      >
                        Unit
                      </span>,
                      <span
                        className={
                          classTable.tableHead + " " + classTable.headLeft
                        }
                        key="price"
                      >
                        Price
                      </span>,
                      <span
                        className={
                          classTable.tableHead + " " + classTable.headLeft
                        }
                        key="amount"
                      >
                        Amount
                      </span>,
                    ]}
                    tableData={
                      rollingList &&
                      rollingList.map((c, index) => [
                        [
                          <span className={classTable.OrderlistDescription}>
                            {c.id}
                          </span>,
                          <span
                            className={
                              classTable.headLeft + " " + classTable.listNumber
                            }
                          >
                            {c.firstName + " " + c.lastName}
                          </span>,
                          <span
                            className={
                              classTable.headLeft + " " + classTable.listNumber
                            }
                          >
                            {c.billType}
                          </span>,
                          <span
                            className={
                              classTable.headLeft + " " + classTable.listNumber
                            }
                          >
                            {c.issued}
                          </span>,
                          <span
                            className={
                              classTable.headLeft + " " + classTable.listNumber
                            }
                          >
                            {c.received}
                          </span>,
                          <span
                            className={
                              classTable.headLeft + " " + classTable.listNumber
                            }
                          >
                            {c.quantity}
                          </span>,
                          <span
                            className={
                              classTable.headLeft + " " + classTable.listNumber
                            }
                          >
                            {c.unit}
                          </span>,
                          <span
                            className={
                              classTable.headLeft + " " + classTable.listNumber
                            }
                          >
                            {c.price}
                          </span>,
                          <span
                            className={
                              classTable.headLeft + " " + classTable.listNumber
                            }
                          >
                            {c.amount}
                          </span>,
                          <span>
                            {c.u.length !== 0 ? (
                              <Button
                                link
                                className={
                                  classTable.headLeft +
                                  " " +
                                  classTable.actionButton
                                }
                                onClick={(event) =>
                                  handleEpandRollingRow(event, c.id)
                                }
                              >
                                {expandRollingState[c.id] ? (
                                  <ArrowUpwardIcon style={{ color: "red" }} />
                                ) : (
                                  <ArrowDownwardIcon style={{ color: "red" }} />
                                )}
                              </Button>
                            ) : (
                              ""
                            )}
                          </span>,
                        ],
                        [
                          expandedRollingRows.includes(c.id) ? (
                            <span>
                              {c.u.map((p, index) => (
                                <span
                                  style={{
                                    marginTop: "20px",
                                    marginBottom: "20px",
                                    lineHeight: "20px",
                                  }}
                                  key={p.id}
                                >
                                  {p.id}
                                  <br />
                                </span>
                              ))}
                            </span>
                          ) : null,
                          expandedRollingRows.includes(c.id) ? (
                            <span
                              className={
                                classTable.headLeft + " " + classTable.subList
                              }
                            >
                              {c.u.map((p) => (
                                <span
                                  style={{
                                    marginTop: "20px",
                                    marginBottom: "20px",
                                    lineHeight: "20px",
                                  }}
                                  key={p.id}
                                >
                                  {p.firstName + " " + p.lastName}
                                  <br />
                                </span>
                              ))}
                            </span>
                          ) : null,
                          expandedRollingRows.includes(c.id) ? (
                            <span
                              className={
                                classTable.headLeft + " " + classTable.subList
                              }
                            >
                              {c.u.map((p) => (
                                <span
                                  style={{
                                    marginTop: "20px",
                                    marginBottom: "20px",
                                    lineHeight: "20px",
                                  }}
                                  key={p.id}
                                >
                                  {p.billType}
                                  <br />
                                </span>
                              ))}
                            </span>
                          ) : null,
                          expandedRollingRows.includes(c.id) ? (
                            <span
                              className={
                                classTable.headLeft + " " + classTable.subList
                              }
                            >
                              {c.u.map((p) => (
                                <span
                                  style={{
                                    marginTop: "20px",
                                    marginBottom: "20px",
                                    lineHeight: "20px",
                                  }}
                                  key={p.id}
                                >
                                  {p.issued}
                                  <br />
                                </span>
                              ))}
                            </span>
                          ) : null,
                          expandedRollingRows.includes(c.id) ? (
                            <span
                              className={
                                classTable.headLeft + " " + classTable.subList
                              }
                            >
                              {c.u.map((p) => (
                                <span
                                  style={{
                                    marginTop: "20px",
                                    marginBottom: "20px",
                                    lineHeight: "20px",
                                  }}
                                  key={p.id}
                                >
                                  {p.received}
                                  <br />
                                </span>
                              ))}
                            </span>
                          ) : null,
                          expandedRollingRows.includes(c.id) ? (
                            <span
                              className={
                                classTable.headLeft + " " + classTable.subList
                              }
                            >
                              {c.u.map((p) => (
                                <span
                                  style={{
                                    marginTop: "20px",
                                    marginBottom: "20px",
                                    lineHeight: "20px",
                                  }}
                                  key={p.id}
                                >
                                  {p.quantity}
                                  <br />
                                </span>
                              ))}
                            </span>
                          ) : null,
                          expandedRollingRows.includes(c.id) ? (
                            <span
                              className={
                                classTable.headLeft + " " + classTable.subList
                              }
                            >
                              {c.u.map((p) => (
                                <span
                                  style={{
                                    marginTop: "20px",
                                    marginBottom: "20px",
                                    lineHeight: "20px",
                                  }}
                                  key={p.id}
                                >
                                  {p.unit}
                                  <br />
                                </span>
                              ))}
                            </span>
                          ) : null,
                          expandedRollingRows.includes(c.id) ? (
                            <span
                              className={
                                classTable.headLeft + " " + classTable.subList
                              }
                            >
                              {c.u.map((p) => (
                                <span
                                  style={{
                                    marginTop: "20px",
                                    marginBottom: "20px",
                                    lineHeight: "20px",
                                  }}
                                  key={p.id}
                                >
                                  {p.price}
                                  <br />
                                </span>
                              ))}
                            </span>
                          ) : null,
                          expandedRollingRows.includes(c.id) ? (
                            <span
                              className={
                                classTable.headLeft + " " + classTable.subList
                              }
                            >
                              {c.u.map((p) => (
                                <span
                                  style={{
                                    marginTop: "20px",
                                    marginBottom: "20px",
                                    lineHeight: "20px",
                                  }}
                                  key={p.id}
                                >
                                  {p.amount}
                                  <br />
                                </span>
                              ))}
                            </span>
                          ) : null,
                          "",
                        ],
                      ])
                    }
                  />
                </div>
              </CardBody>
            </Card>
          </GridItem>
        )} */}

        {/* {dieList.length == 0 ? (
          ""
        ) : (
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <AddIcon />
                </CardIcon>
                <h3 className={classes.cardIconTitle}>
                  <small>Die Cutting Labour Status List</small>
                </h3>
              </CardHeader>
              <CardBody>
                <div className={classTable.container}>
                  <Table
                    hover
                    tableHead={[
                      <span className={classTable.tableHead} key="sr">
                        Sr.No.
                      </span>,
                      <span
                        className={
                          classTable.tableHead + " " + classTable.headLeft
                        }
                        key="name"
                      >
                        Name
                      </span>,
                      <span
                        className={
                          classTable.tableHead + " " + classTable.headLeft
                        }
                        key="billType"
                      >
                        Billtype
                      </span>,
                      <span
                        className={
                          classTable.tableHead + " " + classTable.headLeft
                        }
                        key="issued"
                      >
                        Issued
                      </span>,
                      <span
                        className={
                          classTable.tableHead + " " + classTable.headLeft
                        }
                        key="received"
                      >
                        Received
                      </span>,
                      <span
                        className={
                          classTable.tableHead + " " + classTable.headLeft
                        }
                        key="quantity"
                      >
                        Quantity
                      </span>,
                      <span
                        className={
                          classTable.tableHead + " " + classTable.headLeft
                        }
                        key="unit"
                      >
                        Unit
                      </span>,
                      <span
                        className={
                          classTable.tableHead + " " + classTable.headLeft
                        }
                        key="price"
                      >
                        Price
                      </span>,
                      <span
                        className={
                          classTable.tableHead + " " + classTable.headLeft
                        }
                        key="amount"
                      >
                        Amount
                      </span>,
                    ]}
                    tableData={
                      dieList &&
                      dieList.map((c, index) => [
                        [
                          <span className={classTable.OrderlistDescription}>
                            {c.id}
                          </span>,
                          <span
                            className={
                              classTable.headLeft + " " + classTable.listNumber
                            }
                          >
                            {c.firstName + " " + c.lastName}
                          </span>,
                          <span
                            className={
                              classTable.headLeft + " " + classTable.listNumber
                            }
                          >
                            {c.billType}
                          </span>,
                          <span
                            className={
                              classTable.headLeft + " " + classTable.listNumber
                            }
                          >
                            {c.issued}
                          </span>,
                          <span
                            className={
                              classTable.headLeft + " " + classTable.listNumber
                            }
                          >
                            {c.received}
                          </span>,
                          <span
                            className={
                              classTable.headLeft + " " + classTable.listNumber
                            }
                          >
                            {c.quantity}
                          </span>,
                          <span
                            className={
                              classTable.headLeft + " " + classTable.listNumber
                            }
                          >
                            {c.unit}
                          </span>,
                          <span
                            className={
                              classTable.headLeft + " " + classTable.listNumber
                            }
                          >
                            {c.price}
                          </span>,
                          <span
                            className={
                              classTable.headLeft + " " + classTable.listNumber
                            }
                          >
                            {c.amount}
                          </span>,
                          <span>
                            {c.u.length !== 0 ? (
                              <Button
                                link
                                className={
                                  classTable.headLeft +
                                  " " +
                                  classTable.actionButton
                                }
                                onClick={(event) =>
                                  handleEpandDieRow(event, c.id)
                                }
                              >
                                {expandDieState[c.id] ? (
                                  <ArrowUpwardIcon style={{ color: "red" }} />
                                ) : (
                                  <ArrowDownwardIcon style={{ color: "red" }} />
                                )}
                              </Button>
                            ) : (
                              ""
                            )}
                          </span>,
                        ],
                        [
                          expandedDieRows.includes(c.id) ? (
                            <span>
                              {c.u.map((p, index) => (
                                <span
                                  style={{
                                    marginTop: "20px",
                                    marginBottom: "20px",
                                    lineHeight: "20px",
                                  }}
                                  key={p.id}
                                >
                                  {p.id}
                                  <br />
                                </span>
                              ))}
                            </span>
                          ) : null,
                          expandedDieRows.includes(c.id) ? (
                            <span
                              className={
                                classTable.headLeft + " " + classTable.subList
                              }
                            >
                              {c.u.map((p) => (
                                <span
                                  style={{
                                    marginTop: "20px",
                                    marginBottom: "20px",
                                    lineHeight: "20px",
                                  }}
                                  key={p.id}
                                >
                                  {p.firstName + " " + p.lastName}
                                  <br />
                                </span>
                              ))}
                            </span>
                          ) : null,
                          expandedDieRows.includes(c.id) ? (
                            <span
                              className={
                                classTable.headLeft + " " + classTable.subList
                              }
                            >
                              {c.u.map((p) => (
                                <span
                                  style={{
                                    marginTop: "20px",
                                    marginBottom: "20px",
                                    lineHeight: "20px",
                                  }}
                                  key={p.id}
                                >
                                  {p.billType}
                                  <br />
                                </span>
                              ))}
                            </span>
                          ) : null,
                          expandedDieRows.includes(c.id) ? (
                            <span
                              className={
                                classTable.headLeft + " " + classTable.subList
                              }
                            >
                              {c.u.map((p) => (
                                <span
                                  style={{
                                    marginTop: "20px",
                                    marginBottom: "20px",
                                    lineHeight: "20px",
                                  }}
                                  key={p.id}
                                >
                                  {p.issued}
                                  <br />
                                </span>
                              ))}
                            </span>
                          ) : null,
                          expandedDieRows.includes(c.id) ? (
                            <span
                              className={
                                classTable.headLeft + " " + classTable.subList
                              }
                            >
                              {c.u.map((p) => (
                                <span
                                  style={{
                                    marginTop: "20px",
                                    marginBottom: "20px",
                                    lineHeight: "20px",
                                  }}
                                  key={p.id}
                                >
                                  {p.received}
                                  <br />
                                </span>
                              ))}
                            </span>
                          ) : null,
                          expandedDieRows.includes(c.id) ? (
                            <span
                              className={
                                classTable.headLeft + " " + classTable.subList
                              }
                            >
                              {c.u.map((p) => (
                                <span
                                  style={{
                                    marginTop: "20px",
                                    marginBottom: "20px",
                                    lineHeight: "20px",
                                  }}
                                  key={p.id}
                                >
                                  {p.quantity}
                                  <br />
                                </span>
                              ))}
                            </span>
                          ) : null,
                          expandedDieRows.includes(c.id) ? (
                            <span
                              className={
                                classTable.headLeft + " " + classTable.subList
                              }
                            >
                              {c.u.map((p) => (
                                <span
                                  style={{
                                    marginTop: "20px",
                                    marginBottom: "20px",
                                    lineHeight: "20px",
                                  }}
                                  key={p.id}
                                >
                                  {p.unit}
                                  <br />
                                </span>
                              ))}
                            </span>
                          ) : null,
                          expandedDieRows.includes(c.id) ? (
                            <span
                              className={
                                classTable.headLeft + " " + classTable.subList
                              }
                            >
                              {c.u.map((p) => (
                                <span
                                  style={{
                                    marginTop: "20px",
                                    marginBottom: "20px",
                                    lineHeight: "20px",
                                  }}
                                  key={p.id}
                                >
                                  {p.price}
                                  <br />
                                </span>
                              ))}
                            </span>
                          ) : null,
                          expandedDieRows.includes(c.id) ? (
                            <span
                              className={
                                classTable.headLeft + " " + classTable.subList
                              }
                            >
                              {c.u.map((p) => (
                                <span
                                  style={{
                                    marginTop: "20px",
                                    marginBottom: "20px",
                                    lineHeight: "20px",
                                  }}
                                  key={p.id}
                                >
                                  {p.amount}
                                  <br />
                                </span>
                              ))}
                            </span>
                          ) : null,
                          "",
                        ],
                      ])
                    }
                  />
                </div>
              </CardBody>
            </Card>
          </GridItem>
        )} */}
      </GridContainer>
    </div>
  );
}

import React, { useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { localApi, catApi } from "utils/imgApi";
import { BiFile } from "react-icons/bi";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useLocation } from "react-router-dom";
import Selects from "react-select";
import InputLabel from "@material-ui/core/InputLabel";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import AddIcon from "@material-ui/icons/Add";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import { FaBox, FaRing, FaUsers } from "react-icons/fa";
import { GiWireCoil, GiBoxUnpacking } from "react-icons/gi";
import { BsImages } from "react-icons/bs";
import { AiOutlineCalculator } from "react-icons/ai";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import styRadio from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import styDrop from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import styCheckbox from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import styleSweet from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import { credentials } from "utils/imgApi";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(styRadio);
const useStyl = makeStyles(styDrop);
const useSty = makeStyles(styCheckbox);
const useStyleSweet = makeStyles(styleSweet);

export default function UpdateProduct(props) {
  const [itemProd, setItemProd] = React.useState([]);
  const [items, setItems] = React.useState([]);
  const [items1, setItems1] = React.useState([]);
  const [items2, setItems2] = React.useState([]);
  const [itemSets, setItemSets] = React.useState([]);
  const [itemBox, setItemBox] = React.useState([]);
  const [metal, setMetal] = React.useState([]);
  const [plastic, setPlastic] = React.useState([]);
  const [crystal, setCrystal] = React.useState([]);
  const [ploythene, setPloythene] = React.useState([]);
  const [simpleProductSelect, setSimpleProductSelect] = React.useState("");
  const [imgList, setImgList] = React.useState("");
  const [simpleSelectProd, setSimpleSelectProd] = React.useState("");
  const [simpleSelectProdErr, setSimpleSelectProdErr] = React.useState("");
  const [newUpdateProduct, setNewUpdateProduct] = React.useState(false);
  const [simpleSelect, setSimpleSelect] = React.useState("");
  const [simpleSelect1, setSimpleSelect1] = React.useState("");
  const [selectedGstCal, setSelectedGstCal] = React.useState("cash");
  const [checkedA, setCheckedA] = React.useState(false);
  const [selectedProfit, setSelectedProfit] = React.useState("profit");
  const [selectedPieceSets, setSelectedPieceSets] = React.useState(0);
  const [selectedBox, setSelectedBox] = React.useState(0);
  const [makingCheckboxMaking, setMakingCheckboxMaking] = React.useState("");
  const [simpleSelectMetal, setSimpleSelectMetal] = React.useState("");
  const [readyGram, setReadyGram] = React.useState("0.0075");
  const [metalGram, setMetalGram] = React.useState("0.0100");
  const [metalSGram, setMetalSGram] = React.useState("0.0005");
  const [golwireThaw, setGolwireThaw] = React.useState("105 Thaw Golwire");
  const [packingOption, setPackingOption] = React.useState("");
  const [simpleSelectPlastic, setSimpleSelectPlastic] = React.useState("");
  const [simpleSelectCrystal, setSimpleSelectCrystal] = React.useState("");
  const [simpleSelectPloythene, setSimpleSelectPloythene] = React.useState("");
  const [checkBoxMaking, setCheckBoxmaking] = React.useState([]);
  const [makingSingleId, setMakingSingleId] = React.useState("");
  const [includeGST, setIncludeGST] = React.useState(0);
  const [includeSets, setIncludeSets] = React.useState(0);
  const [totalCash, setTotalCash] = React.useState(0);
  const [sets, setSets] = React.useState(0);
  const [prodItemCode, setProdItemCode] = React.useState("");
  const [prodDescription, setProdDescription] = React.useState("");
  const [prodMfgDetails, setProdMfgDetails] = React.useState("");
  const [cartonDescription, setCartonDescription] = React.useState(
    "96 boxes of in 1 Carton"
  );
  const [cartonQuantity, setCartonQuantity] = React.useState("1");
  const [cartonPackingCharge, setCartonPackingCharge] = React.useState(
    "500.00"
  );
  const [makingPrice, setMakingPrice] = React.useState(0);
  const [makingGst, setMakingGst] = React.useState(0);
  const [makIncludeGst, setMakIncludeGst] = React.useState(0);
  const [metalPrice, setMetalPrice] = React.useState(0);
  const [metalDiff, setMetalDiff] = React.useState(0);
  const [metalGst, setMetalGst] = React.useState(0);
  const [plasticPrice, setPlasticPrice] = React.useState(0);
  const [plasticGst, setPlasticGst] = React.useState(0);
  const [crystalPrice, setCrystalPrice] = React.useState(0);
  const [crystalGst, setCrystalGst] = React.useState(0);
  const [ployPrice, setPloyPrice] = React.useState(0);
  const [ployGst, setPloyGst] = React.useState(0);
  const [makChecked, setMakChecked] = React.useState("");
  const [boxVal, setBoxVal] = React.useState(0);
  const [platVal, setPlatVal] = React.useState(0);
  const [makVal, setMakVal] = React.useState(0);
  const [metGramVal, setMetGramVal] = React.useState(0);
  const [packVal, setPackVal] = React.useState(0);

  const [totalProfit, setTotalProfit] = React.useState(0);
  const [setProfit, setSetProfit] = React.useState(0);

  const [includeProfit, setIncludeProfit] = React.useState(0);
  const [includeProfitSet, setIncludeProfitSet] = React.useState(0);

  const [tax, setTax] = React.useState("0");

  const [igst, setIgst] = React.useState("");
  const [cgst, setCgst] = React.useState("");
  const [sgst, setSgst] = React.useState("");

  const [catDes, setCatDes] = React.useState("");
  const [sizeDes, setSizeDes] = React.useState("");
  const [prdId, setPrdID] = React.useState("");

  const [cat, setCat] = React.useState("");
  const [catSize, setCatSize] = React.useState("");
  const [checkBoxImage, setCheckBoxImage] = React.useState([]);
  const [makingCheckboxImage, setMakingCheckboxImage] = React.useState("");
  const [imgChecked, setImgChecked] = React.useState("");
  const [moqkg, setMoqkg] = React.useState("");

  const [metalDetails, setMetalDetails] = React.useState("");

  const [submitted, setSubmitted] = React.useState(false);
  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");
  const [btn, setBtn] = React.useState(false);

  const [hsnCode, setHsnCode] = React.useState("");
  const [hsnCodeErr, setHsnCodeErr] = React.useState("");
  const [discount, setDiscount] = React.useState("");

  const classes = useStyles();
  const classesRadio = useStyle();
  const classe = useStyl();
  const classCheck = useSty();
  const classSweet = useStyleSweet();
  const history = useHistory();

  let { avatar } = props;

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  const { state } = useLocation();

  // const myForm = useRef(null);
  // console.log(state);

  async function getUsers() {
    const api = localApi + `/product/list/byAdminId/${userObj && userObj.id}`;
    const result = await fetch(api, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getResult = await result.json();
    setItemProd(getResult && getResult);
    // console.log(getResult);
  }

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    // console.log(state);

    if (state !== null) {
      const v = {
        label:
          state.id +
          " - " +
          state.category?.title +
          " - " +
          state.parent?.title,
        value:
          state.id +
          " - " +
          state.category?.title +
          " - " +
          state.parent?.title,
      };
      setSimpleSelectProd(state && state ? v : "");
      setPrdID(state && state ? state.id : "");
      setSimpleSelect(state && state ? state.category?.id : "");
      axios
        .get(
          localApi +
            `/parent/list/byCategoryId/${
              state && state ? state.category?.id : ""
            }`,
          {
            headers: {
              Authorization: `Basic ${credentials}`,
            },
          }
        )
        .then((res) => setItems1(res.data));
      setSimpleSelect1(state && state ? state.parent?.id : "");
      setSelectedPieceSets(state && state ? state.sets : "");
      setSelectedBox(state && state ? state.box : "");
      let boxVa = 0;
      let boxLoadGst = 0;
      if (state && state ? state.box?.id : "") {
        boxVa =
          (state && state ? state.box?.price : "") +
          (state && state ? state.box?.pkgCharge : "");
        boxLoadGst =
          ((state && state ? state.box?.price : "") *
            (state && state ? state.box?.tax_percentage : "")) /
          100;
        setBoxVal(boxVa.toFixed(2));
      }
      axios
        .get(
          localApi +
            `/child/list/byParentId/${state && state ? state.parent.id : ""}`,
          {
            headers: {
              Authorization: `Basic ${credentials}`,
            },
          }
        )
        .then((res) => setItems2(res.data));

      let tempPrice = 0;
      let tempMakVal = 0;
      let tempMakGstVal = 0;
      let tempMakGst = 0;
      setCheckBoxmaking(
        state && state
          ? state.productchild.map((ltist) => {
              tempPrice += ltist.price;
              tempMakVal +=
                ltist?.price *
                (state && state ? state.sets?.pcs : "") *
                (state && state ? state.box?.box : "");
              tempMakGstVal +=
                ltist.tax *
                (state && state ? state.sets?.pcs : "") *
                (state && state ? state.box?.box : "");
              tempMakGst += ltist.tax;
              return ltist.lbrId;
            })
          : ""
      );
      setMakingPrice(tempPrice);
      setMakVal(tempMakVal.toFixed(2));
      setMakIncludeGst(tempMakGst);
      setSimpleSelectMetal(state && state ? state.rawPrice.id : "");
      setMetalDetails(state && state ? state.rawPrice : "");
      if (state && state ? state.rawPrice?.id : "") {
        setMetalPrice(state && state ? state.rawPrice?.price : "");
        setMetalDiff(state && state ? state.rawPrice?.different : "");
        setMetalGst(state && state ? state.rawPrice?.taxPercentage : "");
      }
      setMetalGram(state && state ? state.metalGram : "");
      setMoqkg(state && state ? state.moqkg : "");
      setCgst(state && state ? state.cgst : "");
      setSgst(state && state ? state.sgst : "");
      setIgst(state && state ? state.igst : "");
      setReadyGram(state && state ? state.readyGram : "");
      setDiscount(state && state ? state.discount : "");
      let newMet = 0;
      let metLoadGst = 0;
      if (state && state ? state.metalGram : "") {
        newMet =
          (state && state ? state.metalGram : "") *
          (state && state ? state.sets?.pcs : "") *
          (state && state ? state.box?.box : "") *
          ((state && state ? state.rawPrice?.price : "") +
            (state && state ? state.rawPrice?.different : ""));

        metLoadGst =
          ((state && state ? state.metalGram : "") *
            (state && state ? state.sets?.pcs : "") *
            (state && state ? state.box?.box : "") *
            ((state && state ? state.rawPrice?.price : "") +
              (state && state ? state.rawPrice?.different : "")) *
            (state && state ? state.rawPrice?.taxPercentage : "")) /
          100;
        setMetGramVal(newMet.toFixed(2));
      }
      // console.log(newMet);

      setMetalSGram(state && state ? state.extraGram : "");
      setGolwireThaw(state && state ? state.golwireThaw : "");
      setHsnCode(state && state ? state.hsnSacCode : "");

      setPackingOption(state && state ? state.packingMaterial?.type : "");

      if (
        state && state ? state.packingMaterial?.type : "" === "Crystal Dibbi"
      ) {
        axios
          .get(
            localApi +
              `/packingmaterial/list/byAdminIdAndType/${userObj.id}/Crystal Dibbi`,
            {
              headers: {
                Authorization: `Basic ${credentials}`,
              },
            }
          )
          .then((res) => setCrystal(res.data));
        setSimpleSelectCrystal(state && state ? state.packingMaterial?.id : "");
        setCrystalPrice(state && state ? state.packingMaterial?.price : "");
        setCrystalGst(state && state ? state.packingMaterial?.ligst : "");
      }

      if (
        state && state ? state.packingMaterial?.type : "" === "Polythene PP"
      ) {
        axios
          .get(
            localApi +
              `/packingmaterial/list/byAdminIdAndType/${userObj.id}/Polythene PP`,
            {
              headers: {
                Authorization: `Basic ${credentials}`,
              },
            }
          )
          .then((res) => setPloythene(res.data));
        setSimpleSelectPloythene(
          state && state ? state.packingMaterial?.id : ""
        );
        setPloyPrice(state && state ? state.packingMaterial?.price : "");
        setPloyGst(state && state ? state.packingMaterial?.ligst : "");
      }

      if (
        state && state ? state.packingMaterial?.type : "" === "Plastic Dibbi"
      ) {
        axios
          .get(
            localApi +
              `/packingmaterial/list/byAdminIdAndType/${userObj.id}/Plastic Dibbi`,
            {
              headers: {
                Authorization: `Basic ${credentials}`,
              },
            }
          )
          .then((res) => setPlastic(res.data));
        setSimpleSelectPlastic(state && state ? state.packingMaterial?.id : "");
        setPlasticPrice(state && state ? state.packingMaterial?.price : "");
        setPlasticGst(state && state ? state.packingMaterial?.ligst : "");
      }

      let ployTotal = 0;
      let packLoadGst = 0;
      if (state && state ? state.packingMaterial?.id : "") {
        ployTotal =
          (state && state ? state.packingMaterial?.price : "") *
          (state && state ? state.box?.box : "");
        packLoadGst =
          ((state && state ? state.packingMaterial?.price : "") *
            (state && state ? state.box?.box : "") *
            (state && state ? state.packingMaterial?.ligst : "")) /
          100;
        setPackVal(ployTotal.toFixed(2));
        // setPloyPrice(state && state ? state.productPacking?.price : "");
        // setPloyGst(state && state ? state.productPacking?.ligst : "");
        // setPlasticPrice(state && state ? state.productPacking?.price : "");
        // setPlasticGst(state && state ? state.productPacking?.ligst : "");
        // setCrystalPrice(state && state ? state.productPacking?.price : "");
        // setCrystalGst(state && state ? state.productPacking?.ligst : "");
      }

      const imId =
        state && state
          ? state.productimage.map((iList) => {
              return iList.imgId;
            })
          : "";

      axios
        .get(
          localApi +
            `/image/list/byAdminAndCategoryAndParentId/${userObj.id}/${
              state && state ? state.category.id : ""
            }/${state && state ? state.parent.id : ""}`,
          {
            headers: {
              Authorization: `Basic ${credentials}`,
            },
          }
        )
        .then((res) => {
          setImgList(res.data);
          // console.log(res.data);
          // var tempData = {};
          // const userUsed = res.data.map((d) => d?.id);
          // Promise.all(
          //   userUsed.map(async (id) => {
          //     const usedApi = await fetch(
          //       localApi +
          //         `/productimage/find/byCountAdminAndImageId/${userObj.id}/${id}`
          //     );
          //     const data = await usedApi.json();
          //     // console.log(data);
          //     tempData = { ...tempData, [id]: data };
          //   })
          // ).then(() => {
          //   // console.log(tempData);
          //   const tempItems2 = res.data.map((i) => {
          //     return { ...i, usedCount: tempData[i.id] || 0 };
          //   });
          //   // setImgList(tempItems2);
          //   const d = tempItems2.filter((f) => imId.includes(f.id));
          //   // console.log(d);

          //   const b = tempItems2.filter((f) => !imId.includes(f.id));
          //   // console.log(b);

          //   const arr = [...d, ...b];
          //   setImgList(arr);
          // });
        });

      setCheckBoxImage(
        state && state
          ? state.productimage.map((iList) => {
              return iList.imgId;
            })
          : ""
      );

      setProdItemCode(state && state ? state.itemCodeNumber : "");
      setProdMfgDetails(state && state ? state.prodManfDetails : "");
      setProdDescription(state && state ? state.prodDescription : "");
      setCartonDescription(state && state ? state.ctnDescription : "");
      setCartonQuantity(state && state ? state.ctnQuantity : "");
      setCartonPackingCharge(state && state ? state.ctnPackCharge : "");
      setCheckedA(state && state ? state.active : "");
      // setSelectedGstCal(state.gstCash);

      if (state && state ? state.gstCash : "" === true) {
        setSelectedGstCal("gst");
      } else {
        setSelectedGstCal("cash");
      }

      //////////////////////////// Calculation Part Start ///////////////////////
      let boxPTotalCash = boxVa + tempMakVal + newMet + ployTotal;
      // console.log(boxPTotalCash);

      let boxPTotalGst = boxLoadGst + tempMakGstVal + metLoadGst + packLoadGst;
      setIncludeGST((boxPTotalCash + boxPTotalGst).toFixed(2));
      setTax(boxPTotalGst.toFixed(2));
      // console.log(boxPTotalGst);

      let setPGst =
        (boxPTotalCash + boxPTotalGst) / (state && state ? state.box.box : "");
      setIncludeSets(setPGst.toFixed(2));
      // console.log(setPGst);

      let prodGst =
        (boxPTotalCash * userObj.profit) / 100 + boxPTotalGst + boxPTotalCash;
      setIncludeProfit(prodGst.toFixed(2));
      // console.log(prodGst);

      let prodSet = prodGst / (state && state ? state.box.box : "");
      setIncludeProfitSet(prodSet.toFixed(2));
      // console.log(prodSet);

      //// cash ////

      let boxPrices = boxVa + tempMakVal + newMet + ployTotal;
      setTotalCash(boxPrices.toFixed(2));

      let setPrice = boxPrices / (state && state ? state.box.box : "");
      setSets(setPrice.toFixed(2));
      // console.log(setPrice);

      let profit =
        (boxPrices * userObj.profit) / 100 + boxPrices + boxPTotalGst / 2;
      setTotalProfit(profit.toFixed(2));
      // console.log(profit);

      let setProfit = profit / (state && state ? state.box.box : "");
      setSetProfit(setProfit.toFixed(2));
      // console.log(setProfit);

      /////////////////////// Calculation Part End //////////////////////
    }
  }, []);

  const handleSimpleProd = async (simpleSelectProd) => {
    // console.log(simpleSelectProd);

    setSimpleSelectProd(simpleSelectProd);
    if (simpleSelectProd.value) {
      let newSpli = simpleSelectProd.value.split("-");
      // console.log(newSpli[0]);
      const itemz = itemProd.find((f) => f.id == newSpli[0]);
      // console.log(itemz);
      setPrdID(itemz.id);
      setSimpleSelect(itemz.category?.id);
      axios
        .get(localApi + `/parent/list/byCategoryId/${itemz.category?.id}`, {
          headers: {
            Authorization: `Basic ${credentials}`,
          },
        })
        .then((res) => setItems1(res && res.data));
      setSimpleSelect1(itemz.parent?.id);
      setSelectedPieceSets(itemz.sets);
      setSelectedBox(itemz.box);
      // console.log(itemz.productBox);
      let boxVa = 0;
      let boxLoadGst = 0;
      if (itemz.box?.id) {
        boxVa = itemz.box?.price + itemz.box?.pkgCharge;
        boxLoadGst = (itemz.box?.price * itemz.box?.tax_percentage) / 100;
        setBoxVal(boxVa.toFixed(2));
      }
      const cApi = localApi + `/child/list/byParentId/${itemz.parent.id}`;
      const cRresult = await fetch(cApi, {
        method: "GET",
        headers: {
          Authorization: `Basic ${credentials}`,
        },
      });
      const getC = await cRresult.json();
      // console.log(getC);
      setItems2(getC);

      let tempPrice = 0;
      let tempMakVal = 0;
      let tempMakGstVal = 0;
      let tempMakGst = 0;
      setCheckBoxmaking(
        itemz.productchild.map((ltist) => {
          tempPrice += ltist.price;
          tempMakVal += ltist?.price * itemz.sets?.pcs * itemz.box?.box;
          tempMakGstVal += ltist.tax * itemz.sets?.pcs * itemz.box?.box;
          tempMakGst += ltist.tax;
          return ltist.lbrId;
        })
      );
      setMakingPrice(tempPrice);
      setMakVal(tempMakVal.toFixed(2));
      setMakIncludeGst(tempMakGst);
      setSimpleSelectMetal(itemz.rawPrice.id);
      setMetalDetails(itemz.rawPrice);
      if (itemz.rawPrice?.id) {
        setMetalPrice(itemz.rawPrice?.price);
        setMetalDiff(itemz.rawPrice?.different);
        setMetalGst(itemz.rawPrice?.taxPercentage);
      }
      setMetalGram(itemz.metalGram);
      setMoqkg(itemz.moqkg);
      setCgst(itemz.cgst);
      setSgst(itemz.sgst);
      setIgst(itemz.igst);
      setDiscount(itemz.discount);
      setReadyGram(itemz.readyGram);
      let newMet = 0;
      let metLoadGst = 0;
      if (itemz.metalGram) {
        newMet =
          itemz.metalGram *
          itemz.sets?.pcs *
          itemz.box?.box *
          (itemz.rawPrice?.price + itemz.rawPrice?.different);

        metLoadGst =
          (itemz.metalGram *
            itemz.sets?.pcs *
            itemz.box?.box *
            (itemz.rawPrice?.price + itemz.rawPrice?.different) *
            itemz.rawPrice?.taxPercentage) /
          100;
        setMetGramVal(newMet.toFixed(2));
      }
      // console.log(newMet);

      setMetalSGram(itemz.extraGram);
      setGolwireThaw(itemz.golwireThaw);
      setHsnCode(itemz.hsnSacCode);

      setPackingOption(itemz.packingMaterial?.type);

      if (itemz.packingMaterial?.type === "Crystal Dibbi") {
        axios
          .get(
            localApi +
              `/packingmaterial/list/byAdminIdAndType/${userObj.id}/Crystal Dibbi`,
            {
              headers: {
                Authorization: `Basic ${credentials}`,
              },
            }
          )
          .then((res) => setCrystal(res.data));
        setSimpleSelectCrystal(itemz.packingMaterial.id);
        setCrystalPrice(itemz.packingMaterial?.price);
        setCrystalGst(itemz.packingMaterial?.ligst);
      }

      if (itemz.packingMaterial?.type === "Polythene PP") {
        axios
          .get(
            localApi +
              `/packingmaterial/list/byAdminIdAndType/${userObj.id}/Polythene PP`,
            {
              headers: {
                Authorization: `Basic ${credentials}`,
              },
            }
          )
          .then((res) => setPloythene(res.data));
        setSimpleSelectPloythene(itemz.packingMaterial.id);
        setPloyPrice(itemz.packingMaterial?.price);
        setPloyGst(itemz.packingMaterial?.ligst);
      }

      if (itemz.packingMaterial?.type === "Plastic Dibbi") {
        axios
          .get(
            localApi +
              `/packingmaterial/list/byAdminIdAndType/${userObj.id}/Plastic Dibbi`,
            {
              headers: {
                Authorization: `Basic ${credentials}`,
              },
            }
          )
          .then((res) => setPlastic(res.data));
        setSimpleSelectPlastic(itemz.packingMaterial.id);
        setPlasticPrice(itemz.packingMaterial?.price);
        setPlasticGst(itemz.packingMaterial?.ligst);
      }

      let ployTotal = 0;
      let packLoadGst = 0;
      if (itemz.packingMaterial?.id) {
        ployTotal = itemz.packingMaterial?.price * itemz.box?.box;
        packLoadGst =
          (itemz.packingMaterial?.price *
            itemz.box?.box *
            itemz.packingMaterial?.ligst) /
          100;
        setPackVal(ployTotal.toFixed(2));
      }

      const imId = itemz.productimage.map((iList) => {
        return iList.imgId;
      });

      axios
        .get(
          localApi +
            `/image/list/byAdminAndCategoryAndParentId/${
              userObj && userObj.id
            }/${itemz.category.id}/${itemz.parent.id}`,
          {
            headers: {
              Authorization: `Basic ${credentials}`,
            },
          }
        )
        .then((res) => {
          setImgList(res && res.data);
          // console.log(res.data);
          // var tempData = {};
          // const userUsed = res.data.map((d) => d?.id);
          // Promise.all(
          //   userUsed.map(async (id) => {
          //     const usedApi = await fetch(
          //       localApi +
          //         `/productimage/find/byCountAdminAndImageId/${userObj.id}/${id}`
          //     );
          //     const data = await usedApi.json();
          //     // console.log(data);
          //     tempData = { ...tempData, [id]: data };
          //   })
          // ).then(() => {
          //   // console.log(tempData);
          //   const tempItems2 = res.data.map((i) => {
          //     return { ...i, usedCount: tempData[i.id] || 0 };
          //   });
          //   // setImgList(tempItems2);
          //   const d = tempItems2.filter((f) => imId.includes(f.id));
          //   // console.log(d);

          //   const b = tempItems2.filter((f) => !imId.includes(f.id));
          //   // console.log(b);

          //   const arr = [...d, ...b];
          //   setImgList(arr);
          // });
        });

      setCheckBoxImage(
        itemz.productimage.map((iList) => {
          return iList.imgId;
        })
      );

      setProdItemCode(itemz.itemCodeNumber);
      setProdMfgDetails(itemz.prodManfDetails);
      setProdDescription(itemz.prodDescription);
      setCartonDescription(itemz.ctnDescription);
      setCartonQuantity(itemz.ctnQuantity);
      setCartonPackingCharge(itemz.ctnPackCharge);
      setCheckedA(itemz.active);
      // setSelectedGstCal(itemz.gstCash);

      if (itemz.gstCash === true) {
        setSelectedGstCal("gst");
      } else {
        setSelectedGstCal("cash");
      }

      //////////////////////////// Calculation Part Start ///////////////////////
      let boxPTotalCash = boxVa + tempMakVal + newMet + ployTotal;
      // console.log(boxPTotalCash);

      let boxPTotalGst = boxLoadGst + tempMakGstVal + metLoadGst + packLoadGst;
      setIncludeGST((boxPTotalCash + boxPTotalGst).toFixed(2));
      setTax(boxPTotalGst.toFixed(2));
      // console.log(boxPTotalGst);

      let setPGst = (boxPTotalCash + boxPTotalGst) / itemz.box?.box;
      setIncludeSets(setPGst.toFixed(2));
      // console.log(setPGst);

      let prodGst =
        (boxPTotalCash * userObj.profit) / 100 + boxPTotalGst + boxPTotalCash;
      setIncludeProfit(prodGst.toFixed(2));
      // console.log(prodGst);

      let prodSet = prodGst / itemz.box?.box;
      setIncludeProfitSet(prodSet.toFixed(2));
      // console.log(prodSet);

      //// cash ////

      let boxPrices = boxVa + tempMakVal + newMet + ployTotal;
      setTotalCash(boxPrices.toFixed(2));

      let setPrice = boxPrices / itemz.box?.box;
      setSets(setPrice.toFixed(2));
      // console.log(setPrice);

      let profit =
        (boxPrices * userObj.profit) / 100 + boxPrices + boxPTotalGst / 2;
      setTotalProfit(profit.toFixed(2));
      // console.log(profit);

      let setProfit = profit / itemz.box?.box;
      setSetProfit(setProfit.toFixed(2));
      // console.log(setProfit);

      /////////////////////// Calculation Part End //////////////////////

      // console.log(itemz);
    }
  };

  // const handleSimpleProd = async (event) => {
  //   // setSimpleSelect(event.target.value);
  //   const { value } = event.target;
  //   setSimpleSelectProd(value);
  //   setSimpleSelectProdErr("")
  //   const itemz = itemProd.find((item) => item.id == value);
  //   // console.log(itemz);
  //   setPrdID(itemz.id);
  //   setSimpleSelect(itemz.productCategory?.categoryId);
  //   axios
  //     .get(
  //       localApi +
  //         `/parent/list/byAdminIdAndCategoryId/${userObj && userObj.id}/${
  //           itemz.productCategory?.categoryId
  //         }`
  //     )
  //     .then((res) => setItems1(res && res.data));
  //   setSimpleSelect1(itemz.productParent?.parentId);
  //   setSelectedPieceSets(itemz.productSets);
  //   setSelectedBox(itemz.productBox);
  //   // console.log(itemz.productBox);
  //   let boxVa = 0;
  //   let boxLoadGst = 0;
  //   if (itemz.productBox?.boxId) {
  //     boxVa = itemz.productBox?.price + itemz.productBox?.pkgCharge;
  //     boxLoadGst =
  //       (itemz.productBox?.price * itemz.productBox?.tax_percentage) / 100;
  //     setBoxVal(boxVa.toFixed(2));
  //   }
  //   const cApi =
  //     localApi +
  //     `/child/list/byAdminIdAndParentId/${userObj.id}/${itemz.productParent.parentId}`;
  //   const cRresult = await fetch(cApi);
  //   const getC = await cRresult.json();
  //   // console.log(getC);
  //   setItems2(getC);

  //   let tempPrice = 0;
  //   let tempMakVal = 0;
  //   let tempMakGstVal = 0;
  //   let tempMakGst = 0;
  //   setCheckBoxmaking(
  //     itemz.productChild.map((ltist) => {
  //       tempPrice += ltist.price;
  //       tempMakVal +=
  //         ltist?.price * itemz.productSets?.pcs * itemz.productBox?.box;
  //       tempMakGstVal +=
  //         ltist.tax * itemz.productSets?.pcs * itemz.productBox?.box;
  //       tempMakGst += ltist.tax;
  //       return ltist.lbrId;
  //     })
  //   );
  //   setMakingPrice(tempPrice);
  //   setMakVal(tempMakVal);
  //   setMakIncludeGst(tempMakGst);
  //   setSimpleSelectMetal(itemz.productRawPrice.rawpriceId);
  //   setMetalDetails(itemz.productRawPrice);
  //   if (itemz.productRawPrice?.rawpriceId) {
  //     setMetalPrice(itemz.productRawPrice?.price);
  //     setMetalDiff(itemz.productRawPrice?.different);
  //     setMetalGst(itemz.productRawPrice?.taxPercentage);
  //   }
  //   setMetalGram(itemz.metalGram);
  //   setMoqkg(itemz.moqkg);
  //   setCgst(itemz.cgst);
  //   setSgst(itemz.sgst);
  //   setIgst(itemz.igst);
  //   setReadyGram(itemz.readyGram);
  //   let newMet = 0;
  //   let metLoadGst = 0;
  //   if (itemz.metalGram) {
  //     newMet =
  //       itemz.metalGram *
  //       itemz.productSets?.pcs *
  //       itemz.productBox?.box *
  //       (itemz.productRawPrice?.price + itemz.productRawPrice?.different);

  //     metLoadGst =
  //       (itemz.metalGram *
  //         itemz.productSets?.pcs *
  //         itemz.productBox?.box *
  //         (itemz.productRawPrice?.price + itemz.productRawPrice?.different) *
  //         itemz.productRawPrice?.taxPercentage) /
  //       100;
  //     setMetGramVal(newMet.toFixed(2));
  //   }
  //   // console.log(newMet);

  //   setMetalSGram(itemz.extraGram);
  //   setGolwireThaw(itemz.golwireThaw);

  //   setPackingOption(itemz.productPacking?.type);

  //   if (itemz.productPacking?.type === "Crystal Dibbi") {
  //     axios
  //       .get(
  //         localApi +
  //           `/packingmaterial/list/byAdminIdAndType/${userObj.id}/Crystal Dibbi`
  //       )
  //       .then((res) => setCrystal(res.data));
  //     setSimpleSelectCrystal(itemz.productPacking?.packingmaterialId);
  //     setCrystalPrice(itemz.productPacking?.price);
  //     setCrystalGst(itemz.productPacking?.ligst);
  //   }

  //   if (itemz.productPacking?.type === "Polythene PP") {
  //     axios
  //       .get(
  //         localApi +
  //           `/packingmaterial/list/byAdminIdAndType/${userObj.id}/Polythene PP`
  //       )
  //       .then((res) => setPloythene(res.data));
  //     setSimpleSelectPloythene(itemz.productPacking?.packingmaterialId);
  //     setPloyPrice(itemz.productPacking?.price);
  //     setPloyGst(itemz.productPacking?.ligst);
  //   }

  //   if (itemz.productPacking?.type === "Plastic Dibbi") {
  //     axios
  //       .get(
  //         localApi +
  //           `/packingmaterial/list/byAdminIdAndType/${userObj.id}/Plastic Dibbi`
  //       )
  //       .then((res) => setPlastic(res.data));
  //     setSimpleSelectPlastic(itemz.productPacking?.packingmaterialId);
  //     setPlasticPrice(itemz.productPacking?.price);
  //     setPlasticGst(itemz.productPacking?.ligst);
  //   }

  //   let ployTotal = 0;
  //   let packLoadGst = 0;
  //   if (itemz.productPacking?.packingmaterialId) {
  //     ployTotal = itemz.productPacking?.price * itemz.productBox?.box;
  //     packLoadGst =
  //       (itemz.productPacking?.price *
  //         itemz.productBox?.box *
  //         itemz.productPacking?.ligst) /
  //       100;
  //     setPackVal(ployTotal.toFixed(2));
  //   }

  //   const imId = itemz.productImage.map((iList) => {
  //     return iList.imgId;
  //   });

  //   axios
  //     .get(
  //       localApi +
  //         `/image/list/byAdminAndCategoryAndParentId/${userObj && userObj.id}/${
  //           itemz.productCategory.categoryId
  //         }/${itemz.productParent.parentId}`
  //     )
  //     .then((res) => {
  //       // setImgList(res && res.data);
  //       // console.log(res.data);
  //       var tempData = {};
  //       const userUsed = res.data.map((d) => d?.id);
  //       Promise.all(
  //         userUsed.map(async (id) => {
  //           const usedApi = await fetch(
  //             localApi +
  //               `/productimage/find/byCountAdminAndImageId/${userObj.id}/${id}`
  //           );
  //           const data = await usedApi.json();
  //           // console.log(data);
  //           tempData = { ...tempData, [id]: data };
  //         })
  //       ).then(() => {
  //         // console.log(tempData);
  //         const tempItems2 = res.data.map((i) => {
  //           return { ...i, usedCount: tempData[i.id] || 0 };
  //         });
  //         // setImgList(tempItems2);
  //         const d = tempItems2.filter((f) => imId.includes(f.id));
  //         // console.log(d);

  //         const b = tempItems2.filter((f) => !imId.includes(f.id));
  //         // console.log(b);

  //         const arr = [...d, ...b];
  //         setImgList(arr);
  //       });
  //     });

  //   setCheckBoxImage(
  //     itemz.productImage.map((iList) => {
  //       return iList.imgId;
  //     })
  //   );

  //   setProdItemCode(itemz.itemCodeNumber);
  //   setProdMfgDetails(itemz.prodManfDetails);
  //   setProdDescription(itemz.prodDescription);
  //   setCartonDescription(itemz.ctnDescription);
  //   setCartonQuantity(itemz.ctnQuantity);
  //   setCartonPackingCharge(itemz.ctnPackCharge);
  //   setCheckedA(itemz.active);
  //   // setSelectedGstCal(itemz.gstCash);

  //   if (itemz.gstCash === true) {
  //     setSelectedGstCal("gst");
  //   } else {
  //     setSelectedGstCal("cash");
  //   }

  //   //////////////////////////// Calculation Part Start ///////////////////////
  //   let boxPTotalCash = boxVa + tempMakVal + newMet + ployTotal;
  //   // console.log(boxPTotalCash);

  //   let boxPTotalGst = boxLoadGst + tempMakGstVal + metLoadGst + packLoadGst;
  //   setIncludeGST((boxPTotalCash + boxPTotalGst).toFixed(2));
  //   setTax(boxPTotalGst.toFixed(2));
  //   // console.log(boxPTotalGst);

  //   let setPGst = (boxPTotalCash + boxPTotalGst) / itemz.productBox?.box;
  //   setIncludeSets(setPGst.toFixed(2));
  //   // console.log(setPGst);

  //   let prodGst =
  //     (boxPTotalCash * userObj.profit) / 100 + boxPTotalGst + boxPTotalCash;
  //   setIncludeProfit(prodGst.toFixed(2));
  //   // console.log(prodGst);

  //   let prodSet = prodGst / itemz.productBox?.box;
  //   setIncludeProfitSet(prodSet.toFixed(2));
  //   // console.log(prodSet);

  //   //// cash ////

  //   let boxPrices = boxVa + tempMakVal + newMet + ployTotal;
  //   setTotalCash(boxPrices.toFixed(2));

  //   let setPrice = boxPrices / itemz.productBox?.box;
  //   setSets(setPrice.toFixed(2));
  //   // console.log(setPrice);

  //   let profit =
  //     (boxPrices * userObj.profit) / 100 + boxPrices + boxPTotalGst / 2;
  //   setTotalProfit(profit.toFixed(2));
  //   // console.log(profit);

  //   let setProfit = profit / itemz.productBox?.box;
  //   setSetProfit(setProfit.toFixed(2));
  //   // console.log(setProfit);

  //   /////////////////////// Calculation Part End //////////////////////

  //   // console.log(itemz);
  // };

  const fetchCat = async () => {
    const catApi =
      localApi + `/category/list/byAdminId/${userObj && userObj.id}`;
    const catRresult = await fetch(catApi, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getCat = await catRresult.json();
    // console.log(getSet);
    setItems(getCat && getCat);
  };

  const fetchSet = async () => {
    const setsApi = localApi + `/sets/list/byAdminId/${userObj && userObj.id}`;
    const setRresult = await fetch(setsApi, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getSet = await setRresult.json();
    // console.log(getSet);
    setItemSets(getSet && getSet);
  };

  const fetchBox = async () => {
    const boxApi = localApi + `/box/list/byAdminId/${userObj && userObj.id}`;
    const boxRresult = await fetch(boxApi, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getBox = await boxRresult.json();
    // console.log(getSet);
    setItemBox(getBox && getBox);
  };

  const fetchMetal = async () => {
    const metalApi =
      localApi + `/rawprice/list/byAdminId/${userObj && userObj.id}`;
    const metalRresult = await fetch(metalApi, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getMetal = await metalRresult.json();
    // console.log(getChild);
    setMetal(getMetal && getMetal);
  };

  useEffect(() => {
    // catApi().then((res) => setItems(res));
    fetchCat();
    fetchSet();
    fetchBox();
    fetchMetal();
  }, []);

  const handleSimple = async (event) => {
    // setSimpleSelect(event.target.value);
    const { value } = event.target;
    const item = items.find((item) => item.id == value);
    setSimpleSelect(value);
    if (value) {
      setCatDes(item.title);
    }
    // setGetId(item.id);
    const response = await axios.get(
      `${localApi}/parent/list/byCategoryId/${item.id}`,
      {
        headers: {
          Authorization: `Basic ${credentials}`,
        },
      }
    );
    setItems1(response.data);
    setCat(item);
  };

  const handleSimple1 = async (event) => {
    // setSimpleSelect(event.target.value);
    var tempData = {};
    const { value } = event.target;
    setSimpleSelect1(value);
    const itemm = items1.find((itemm) => itemm.id == value);
    setCatSize(itemm);
    const childApi = localApi + `/child/list/byParentId/${itemm.id}`;
    const childRresult = await fetch(childApi, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getChild = await childRresult.json();
    // console.log(getChild);
    setItems2(getChild);
    const imageApi =
      localApi +
      `/image/list/byAdminAndCategoryAndParentId/${userObj.id}/${cat.id}/${itemm.id}`;
    const imageRresult = await fetch(imageApi, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getImage = await imageRresult.json();
    // console.log(getImage);
    setImgList(getImage);
    // const userUsed = getImage.map((d) => d?.id);
    // Promise.all(
    //   userUsed.map(async (id) => {
    //     const usedApi = await fetch(
    //       localApi +
    //         `/productimage/find/byCountAdminAndImageId/${userObj.id}/${id}`
    //     );
    //     const data = await usedApi.json();
    //     // console.log(data);
    //     tempData = { ...tempData, [id]: data };
    //   })
    // ).then(() => {
    //   // console.log(tempData);
    //   const tempItems2 = getImage.map((i) => {
    //     return { ...i, usedCount: tempData[i.id] || 0 };
    //   });
    //   setImgList(tempItems2);
    //   // console.log(tempItems2);
    // });
  };

  const handleChangeGstCal = (event, id) => {
    // setSelectedGstCal(event.target.value);
    const { value } = event.target;
    setSelectedGstCal(value);
    if (value === "gst") {
      setSelectedGstCal(value);

      let boxPkgGst = (selectedBox?.price * selectedBox?.tax_percentage) / 100;
      let boxPkgCash = selectedBox?.price + selectedBox?.pkgCharge;

      let makPriceGst =
        makIncludeGst * selectedPieceSets?.pcs * selectedBox?.box || 0;
      let makPriceCash =
        makingPrice * selectedPieceSets?.pcs * selectedBox?.box || 0;

      let metPriceGst =
        (metalGram *
          selectedPieceSets?.pcs *
          selectedBox?.box *
          (metalPrice + metalDiff) *
          metalGst) /
          100 || 0;
      let metPriceCash =
        metalGram *
          selectedPieceSets?.pcs *
          selectedBox?.box *
          (metalPrice + metalDiff) || 0;

      let plasPriceGst =
        packingOption === "Plastic Dibbi"
          ? (plasticPrice * selectedBox?.box * plasticGst) / 100
          : 0;
      let plasPriceCash =
        packingOption === "Plastic Dibbi" ? plasticPrice * selectedBox?.box : 0;

      let crysPriceGst =
        packingOption === "Crystal Dibbi"
          ? (crystalPrice * selectedBox?.box * crystalGst) / 100
          : 0;
      let crysPriceCash =
        packingOption === "Crystal Dibbi" ? crystalPrice * selectedBox?.box : 0;

      let ployPriceGst =
        packingOption === "Polythene PP"
          ? (ployPrice * selectedBox?.box * ployGst) / 100
          : 0;
      let ployPriceCash =
        packingOption === "Polythene PP" ? ployPrice * selectedBox?.box : 0;

      let boxPriceGst =
        boxPkgGst +
        makPriceGst +
        metPriceGst +
        plasPriceGst +
        crysPriceGst +
        ployPriceGst +
        mixplatPriceGst +
        mixmakPriceGst +
        mixmetPriceGst;

      let boxPriceCash =
        boxPkgCash +
        makPriceCash +
        metPriceCash +
        plasPriceCash +
        crysPriceCash +
        ployPriceCash +
        mixplatingPrice +
        mixmakPrice +
        mixmetPrice;

      setTax(boxPriceGst.toFixed(2));
      setIncludeGST((boxPriceCash + boxPriceGst).toFixed(2));

      let setPriceGst = (boxPriceGst + boxPriceCash) / selectedBox?.box;
      setIncludeSets(setPriceGst.toFixed(2));

      let profGst =
        (boxPriceCash * userObj.profit) / 100 + boxPriceGst + boxPriceCash;
      setIncludeProfit(profGst.toFixed(2));

      let profSet = profGst / selectedBox?.box;
      setIncludeProfitSet(profSet.toFixed(2));
    } else {
      let boxPkg = selectedBox?.price + selectedBox?.pkgCharge;
      let makPrice =
        makingPrice * selectedPieceSets?.pcs * selectedBox?.box || 0;
      let metPrice =
        metalGram *
          selectedPieceSets?.pcs *
          selectedBox?.box *
          (metalPrice + metalDiff) || 0;
      let plasPrice =
        packingOption === "Plastic Dibbi" ? plasticPrice * selectedBox?.box : 0;
      let crysPrice =
        packingOption === "Crystal Dibbi" ? crystalPrice * selectedBox?.box : 0;
      let ployPrices =
        packingOption === "Polythene PP" ? ployPrice * selectedBox?.box : 0;

      /// Gst Part ////
      let boxPkgGst = (selectedBox?.price * selectedBox?.tax_percentage) / 100;

      let makPriceGst =
        makIncludeGst * selectedPieceSets?.pcs * selectedBox?.box || 0;

      let metPriceGst =
        (metalGram *
          selectedPieceSets?.pcs *
          selectedBox?.box *
          (metalPrice + metalDiff) *
          metalGst) /
          100 || 0;

      let plasPriceGst =
        packingOption === "Plastic Dibbi"
          ? (plasticPrice * selectedBox?.box * plasticGst) / 100
          : 0;

      let crysPriceGst =
        packingOption === "Crystal Dibbi"
          ? (crystalPrice * selectedBox?.box * crystalGst) / 100
          : 0;

      let ployPriceGst =
        packingOption === "Polythene PP"
          ? (ployPrice * selectedBox?.box * ployGst) / 100
          : 0;

      let boxPriceGst =
        boxPkgGst +
        makPriceGst +
        metPriceGst +
        plasPriceGst +
        crysPriceGst +
        ployPriceGst +
        mixplatPriceGst +
        mixmakPriceGst +
        mixmetPriceGst;
      //// Gst part End ///////

      let boxPrices =
        boxPkg +
        makPrice +
        metPrice +
        plasPrice +
        crysPrice +
        ployPrices +
        mixplatingPrice +
        mixmakPrice +
        mixmetPrice;
      setTotalCash(boxPrices.toFixed(2));

      let setPrice = boxPrices / selectedBox?.box;
      setSets(setPrice.toFixed(2));

      let profit =
        (boxPrices * userObj.profit) / 100 + boxPrices + boxPriceGst / 2;
      setTotalProfit(profit.toFixed(2));

      let setProfit = profit / selectedBox?.box;
      setSetProfit(setProfit.toFixed(2));
    }
  };

  const handleProfit = (e) => {
    setSelectedProfit(e.target.value);
  };

  const handleCheckBoxMaking = (event, making) => {
    // setCheckBoxmaking(e.target.checked);
    const { checked } = event.target;
    if (checked) {
      setMakingPrice(makingPrice + making?.price);
    } else {
      setMakingPrice(makingPrice - making?.price);
    }

    // console.log(selectedPieceSets);

    if (checked) {
      setMakVal(
        makVal + making?.price * selectedPieceSets?.pcs * selectedBox?.box
      );
    } else {
      setMakVal(
        makVal - making?.price * selectedPieceSets?.pcs * selectedBox?.box
      );
    }

    if (checked) {
      setMakingGst(making?.taxPercentage);
      setMakIncludeGst(makIncludeGst + making?.tax);
    } else {
      setMakingGst("");
      setMakIncludeGst(makIncludeGst - making?.tax);
    }

    if (checked) {
      setMakingSingleId(making?.id);
    }

    setCheckBoxmaking((prev) => {
      if (prev.includes(making.id)) {
        return prev.filter((x) => x !== making.id);
      } else {
        return [...prev, making.id];
      }
    });

    // setMakChecked((prev) => {
    //   if (prev.includes(making?.id)) {
    //     return prev.filter((x) => x !== making?.id);
    //   } else {
    //     return [...prev, making?.id];
    //   }
    // });
  };

  const handleSimpleMetal = (event) => {
    // alert(event.target.value);
    const { value } = event.target;
    const metItem = metal.find((metItem) => metItem.id == value);
    setSimpleSelectMetal(value);
    setMetalDetails(metItem);
    setMetalPrice(metItem.price);
    setMetalDiff(metItem.different);
    setMetalGst(metItem.taxPercentage);
    if (value) {
      let newMet =
        metalGram *
        selectedPieceSets?.pcs *
        selectedBox?.box *
        (metItem.price + metItem.different);
      setMetGramVal(newMet.toFixed(2));
    }
  };

  const handlePackingOption = async (event) => {
    // setPackingOption(event.target.value);
    const { value } = event.target;
    setPackingOption(value);
    if (value === "Crystal Dibbi") {
      const api =
        localApi +
        `/packingmaterial/list/byAdminIdAndType/${userObj.id}/Crystal Dibbi`;
      const result = await fetch(api, {
        method: "GET",
        headers: {
          Authorization: `Basic ${credentials}`,
        },
      });
      const getResult = await result.json();
      setCrystal(getResult);
    } else if (value === "Plastic Dibbi") {
      const api =
        localApi +
        `/packingmaterial/list/byAdminIdAndType/${userObj.id}/Plastic Dibbi`;
      const result = await fetch(api, {
        method: "GET",
        headers: {
          Authorization: `Basic ${credentials}`,
        },
      });
      const getResult = await result.json();
      setPlastic(getResult);
    } else if (value === "Polythene PP") {
      const api =
        localApi +
        `/packingmaterial/list/byAdminIdAndType/${userObj.id}/Polythene PP`;
      const result = await fetch(api, {
        method: "GET",
        headers: {
          Authorization: `Basic ${credentials}`,
        },
      });
      const getResult = await result.json();
      setPloythene(getResult);
    }
    if (value === "Plastic Dibbi") {
      let packTotal = plasticPrice * selectedBox?.box;
      // console.log("plas", packTotal);
      setPackVal(packTotal.toFixed(2));
    } else if (value === "Crystal Dibbi") {
      let crysTotal = crystalPrice * selectedBox?.box;
      setPackVal(crysTotal.toFixed(2));
    } else if (value === "Polythene PP") {
      let ployTotal = ployPrice * selectedBox?.box;
      setPackVal(ployTotal.toFixed(2));
    }
  };

  const handleSimplePlastic = (event) => {
    // setSimpleSelectPlastic(event.target.value);
    const { value } = event.target;
    setSimpleSelectPlastic(value);
    const plaItem = plastic.find((plaItem) => plaItem.id == value);
    setPlasticPrice(plaItem.price);
    setPlasticGst(plaItem.ligst);
    if (packingOption === "Plastic Dibbi" && value) {
      let packTotal = plaItem.price * selectedBox?.box;
      setPackVal(packTotal.toFixed(2));
    }
  };

  const handleSimpleCrystal = (event) => {
    // setSimpleSelectCrystal(event.target.value);
    const { value } = event.target;
    setSimpleSelectCrystal(value);
    const crysItem = crystal.find((crysItem) => crysItem.id == value);
    setCrystalPrice(crysItem.price);
    setCrystalGst(crysItem.ligst);
    if (packingOption === "Crystal Dibbi" && value) {
      let crysTotal = crysItem.price * selectedBox?.box;
      setPackVal(crysTotal.toFixed(2));
    }
  };

  const handleSimplePloythene = (event) => {
    // setSimpleSelectPloythene(event.target.value);
    const { value } = event.target;
    setSimpleSelectPloythene(value);
    const ployItem = ploythene.find((ployItem) => ployItem.id == value);
    setPloyPrice(ployItem.price);
    setPloyGst(ployItem.ligst);
    if (packingOption === "Polythene PP" && value) {
      let ployTotal = ployItem.price * selectedBox?.box;
      setPackVal(ployTotal.toFixed(2));
    }
  };

  const handleCheckBoxImage = (e, i) => {
    // setCheckBoxmaking(e.target.checked);
    const { checked } = e.target;

    setCheckBoxImage((prev) => {
      if (prev.includes(i.id)) {
        return prev.filter((x) => x !== i.id);
      } else {
        return [...prev, i.id];
      }
    });

    // setImgChecked((prev) => {
    //   if (prev.includes(i?.id)) {
    //     return prev.filter((x) => x !== i?.id);
    //   } else {
    //     return [...prev, i?.id];
    //   }
    // });
  };

  const mystyle = {
    position: "fixed",
    bottom: "10px",
    right: "30px",
  };

  const SubmitUpdateProduct = () => {
    // console.log(checkBoxMaking);
    // console.log(checkBoxImage);

    setNewUpdateProduct(true);
    let errorCount = 0;
    if (simpleSelectProd === "" || !simpleSelectProd) {
      errorCount++;
      setSimpleSelectProdErr("Select Product Id");
    }
    if (errorCount === 0) {
      setBtn(true);
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Basic ${credentials}`,
        },
        body: JSON.stringify({
          metalGram: parseFloat(metalGram),
          moqkg: parseFloat(moqkg),
          taxApply: selectedGstCal === "gst" ? true : false,
          cgst: selectedGstCal === "gst" ? parseFloat(cgst) : parseFloat(cgst),
          sgst: selectedGstCal === "gst" ? parseFloat(sgst) : parseFloat(sgst),
          igst: selectedGstCal === "gst" ? parseFloat(igst) : parseFloat(igst),
          extraGram: parseFloat(metalSGram),
          readyGram: parseFloat(readyGram),
          golwireThaw: golwireThaw,
          hsnSacCode: hsnCode ? hsnCode : null,
          itemCodeNumber: prodItemCode ? prodItemCode : null,
          prodManfDetails: prodMfgDetails ? prodMfgDetails : null,
          prodDescription: prodDescription ? prodDescription : null,
          ctnDescription: cartonDescription,
          ctnQuantity: parseFloat(cartonQuantity),
          ctnPackCharge: parseFloat(cartonPackingCharge),
          active: checkedA,
          discount: discount,
          gstCash: selectedGstCal === "gst" ? true : false,
          productchild: checkBoxMaking.map((c) => {
            const ch = items2.find((it) => it.id === c);
            return {
              lbrId: ch.id,
              price: ch.price,
              taxPercentage: ch.taxPercentage,
              hsncode: ch.hsncode,
              title: ch.title,
              tax: ch.tax,
            };
          }),
          productimage: checkBoxImage.map((item) => {
            const im = imgList.find((it) => it.id === item);
            return {
              imgId: im.id,
              categoryId: im.categoryId,
              parentId: im.parentId,
              cropImageUrl: null,
              cropName: im.cropName,
            };
          }),
        }),
      };

      fetch(
        localApi +
          `/product/update/byProductAndCategoryAndParentAndBoxAndSetsAndRawPriceAndPackingMaterialId/${prdId}/${simpleSelect}/${simpleSelect1}/${
            selectedBox.id
          }/${selectedPieceSets.id}/${metalDetails.id}/${
            packingOption === "Plastic Dibbi"
              ? simpleSelectPlastic
              : packingOption === "Crystal Dibbi"
              ? simpleSelectCrystal
              : packingOption === "Polythene PP"
              ? simpleSelectPloythene
              : ""
          }`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          if (data.message === "Data Updated Successfully !!!") {
            alertTypeOps("success");
            alertMsgOps("Succesfully !!!");
            setSubmitted(true);
          }
        });
    }
  };

  const hideAlert = () => {
    setSubmitted(false);
    if (state) {
      history.push({
        pathname: `/admin/viewProduct`,
        state: simpleSelect + "," + simpleSelect1,
      });
    } else {
      window.location.reload(false);
    }

    // window.history.replaceState({state: null}, document.title);
  };

  useEffect(() => {
    if (selectedPieceSets) {
      let platingPrice = selectedPieceSets?.pcs * selectedBox?.box;
      setPlatVal(platingPrice ? platingPrice.toFixed(2) : 0);
    }
    if (selectedBox) {
      let boxTotal = selectedBox?.price + selectedBox?.pkgCharge;
      setBoxVal(boxTotal);
    }
    if (metalGram) {
      let newMet =
        metalGram *
        selectedPieceSets?.pcs *
        selectedBox?.box *
        (metalPrice + metalDiff);
      setMetGramVal(newMet.toFixed(2));
    }
    if (simpleSelectMetal) {
      let newMet =
        metalGram *
        selectedPieceSets?.pcs *
        selectedBox?.box *
        (metalPrice + metalDiff);
      setMetGramVal(newMet.toFixed(2));
    }
    if (packingOption === "Plastic Dibbi") {
      const ployTotals = plasticPrice * selectedBox?.box;
      setPackVal(ployTotals.toFixed(2));
    }
    if (packingOption === "Crystal Dibbi") {
      const crysTotals = crystalPrice * selectedBox?.box;
      setPackVal(crysTotals.toFixed(2));
    }
    if (packingOption === "Polythene PP") {
      const ployTotals = ployPrice * selectedBox?.box;
      setPackVal(ployTotals.toFixed(2));
    }
    // if (simpleSelectPloythene) {
    //   let ployTotal = ployPrice * selectedBox?.box;
    //   setPackVal(ployTotal.toFixed(2));
    //   // console.log("change", simpleSelectPloythene);
    // }
    // if (simpleSelectCrystal) {
    //   let crysTotal = crystalPrice * selectedBox?.box;
    //   setPackVal(crysTotal.toFixed(2));
    // }
    // if (simpleSelectPlastic) {
    //   let packTotal = plasticPrice * selectedBox?.box;
    //   setPackVal(packTotal.toFixed(2));
    // }
    if (checkBoxMaking) {
      let makPrice = makingPrice * selectedPieceSets?.pcs * selectedBox?.box;
      setMakVal(makPrice.toFixed(2));
    }
  }, [
    selectedPieceSets,
    selectedBox,
    metalGram,
    simpleSelectMetal,
    checkBoxMaking,
    // simpleSelectPloythene,
    // simpleSelectCrystal,
    // simpleSelectPlastic,
    packingOption === "Plastic Dibbi",
    packingOption === "Crystal Dibbi",
    packingOption === "Polythene PP",
  ]);

  const select = {
    width: "100%",
    maxWidth: 600,
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      padding: 10,
    }),
    menu: (state) => ({
      color: "#d81b60",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  var mixmakPrice = 0;
  var mixmetPrice = 0;
  var mixplatingPrice = 0;
  var lbrmetprice = 0;
  var mixmakPriceGst = 0;
  var mixplatPriceGst = 0;
  var mixmetPriceGst = 0;

  // useEffect(() => {
  // console.log("Value Changed")
  // setPrdID("");
  // setSimpleSelect("");
  // setItems1("");
  // setSimpleSelect1("");
  // setSelectedPieceSets("");
  // setSelectedBox("");
  // setBoxVal("");
  // setItems2("");
  // setCheckBoxmaking("");
  // setMakingPrice("");
  // setMakVal("");
  // setMakIncludeGst("");
  // setSimpleSelectMetal("");
  // setMetalDetails("");
  // setMetalPrice("");
  // setMetalDiff("");
  // setMetalGst("");
  // setMetalGram("");
  // setMoqkg("");
  // setCgst("");
  // setSgst("");
  // setIgst("");
  // setDiscount("");
  // setReadyGram("");
  // setMetGramVal("");
  // setMetalSGram("");
  // setGolwireThaw("");
  // setHsnCode("");
  // setPackingOption("");
  // setCrystal("");
  // setSimpleSelectCrystal("");
  // setCrystalPrice("");
  // setCrystalGst("");
  // setPloythene("");
  // setSimpleSelectPloythene("");
  // setPloyPrice("");
  // setPloyGst("");
  // setPlastic("");
  // setSimpleSelectPlastic("");
  // setPlasticPrice("");
  // setPlasticGst("");
  // setPackVal("");
  // setImgList("");
  // setCheckBoxImage("");
  // setProdItemCode("");
  // setProdMfgDetails("");
  // setProdDescription("");
  // setCartonDescription("");
  // setCartonQuantity("");
  // setCartonPackingCharge("");
  // setCheckedA("");
  // setSelectedGstCal("");
  // setIncludeGST("");
  // setTax("");
  // setIncludeSets("");
  // setIncludeProfit("");
  // setIncludeProfitSet("");
  // setTotalCash("");
  // setSets("");
  // setTotalProfit("");
  // setSetProfit("");
  // }, [simpleSelectProd]);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <AddIcon />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Update Product</small>
                {newUpdateProduct === true ? (
                  <small
                    style={{
                      fontSize: "12px",
                      color: "red",
                      marginLeft: "10px",
                    }}
                  >
                    {simpleSelectProdErr}
                  </small>
                ) : (
                  <small style={{ fontSize: "12px", marginLeft: "10px" }}>
                    Select Product Id
                  </small>
                )}
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {submitted ? (
                  <SweetAlert
                    type={alertType}
                    style={{ display: "block", marginTop: "-100px" }}
                    title={alertMsg}
                    onConfirm={() => hideAlert()}
                    confirmBtnCssClass={
                      classSweet.button + " " + classSweet.success
                    }
                  >
                    Updated Product
                  </SweetAlert>
                ) : null}
                <GridItem xs={12} sm={12} md={12}>
                  <label className={classe.selectLabel}>
                    Select Product Id
                  </label>
                  <Selects
                    styles={customStyles}
                    style={select}
                    required={true}
                    placeholder="Select Product Id..."
                    value={simpleSelectProd}
                    name="simpleSelectProd"
                    onChange={handleSimpleProd}
                    options={
                      itemProd &&
                      itemProd.map((c) => {
                        return {
                          label:
                            c.id +
                            " - " +
                            c.category.title +
                            " - " +
                            c.parent.title,
                          value:
                            c.id +
                            " - " +
                            c.category.title +
                            " - " +
                            c.parent.title,
                        };
                      })
                    }
                  />
                </GridItem>
                {/* <GridItem xs={12} sm={12} md={12}>
                  <FormControl fullWidth className={classe.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classe.selectLabel}
                    >
                      Select Product Id...
                    </InputLabel>

                    <Select
                      MenuProps={{
                        className: classe.selectMenu,
                      }}
                      classes={{
                        select: classe.select,
                      }}
                      value={simpleSelectProd}
                      onChange={handleSimpleProd}
                      inputProps={{
                        name: "simpleSelect",
                        id: "simple-select",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classe.selectMenuItem,
                        }}
                      >
                        Select Product Id...
                      </MenuItem>
                      {itemProd &&
                        itemProd.map((item) => (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                            classes={{
                              root: classe.selectMenuItem,
                              selected: classe.selectMenuItemSelected,
                            }}
                          >
                            {item.id}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>{" "} */}
                <GridItem xs={12} sm={12} md={12}>
                  <FormControl fullWidth className={classe.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classe.selectLabel}
                    >
                      Select Category Name...
                    </InputLabel>

                    <Select
                      MenuProps={{
                        className: classe.selectMenu,
                      }}
                      classes={{
                        select: classe.select,
                      }}
                      value={simpleSelect}
                      onChange={handleSimple}
                      inputProps={{
                        name: "simpleSelect",
                        id: "simple-select",
                      }}
                      disabled={true}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classe.selectMenuItem,
                        }}
                      >
                        Select Category Name...
                      </MenuItem>
                      {items &&
                        items.map((item) => (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                            classes={{
                              root: classe.selectMenuItem,
                              selected: classe.selectMenuItemSelected,
                            }}
                          >
                            {item.title}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>{" "}
                <GridItem xs={12} sm={12} md={12}>
                  <FormControl fullWidth className={classe.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classe.selectLabel}
                    >
                      Select Bangles Size-MM...
                    </InputLabel>

                    <Select
                      MenuProps={{
                        className: classe.selectMenu,
                      }}
                      classes={{
                        select: classe.select,
                      }}
                      value={simpleSelect1}
                      onChange={handleSimple1}
                      inputProps={{
                        name: "simpleSelect",
                        id: "simple-select",
                      }}
                      disabled={true}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classe.selectMenuItem,
                        }}
                      >
                        Select Bangles Size-MM...
                      </MenuItem>
                      {items1 &&
                        items1.map((item1) => (
                          <MenuItem
                            key={item1.id}
                            value={item1.id}
                            classes={{
                              root: classe.selectMenuItem,
                              selected: classe.selectMenuItemSelected,
                            }}
                          >
                            {item1.title}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <FaRing />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Piece/Sets</small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {itemSets &&
                  itemSets.map((sets) => (
                    <GridItem xs={12} sm={4} md={3} key={sets.id}>
                      <FormControlLabel
                        key={sets.id}
                        control={
                          <Radio
                            // checked={selectedPieceSets && selectedPieceSets
                            //     ? selectedPieceSets === sets.id
                            //     : sets.active === 1}
                            // selectedPieceSets && selectedPieceSets.id
                            //     ? selectedPieceSets?.id === sets?.id
                            //     : ""
                            checked={
                              selectedPieceSets && selectedPieceSets.setsId
                                ? selectedPieceSets?.setsId === sets?.id
                                : selectedPieceSets.id
                                ? selectedPieceSets?.id === sets?.id
                                : ""
                            }
                            onChange={(e) => {
                              setSelectedPieceSets(sets);
                              // console.log(sets);
                            }}
                            value={sets.id}
                            name="radioButton"
                            aria-label="A"
                            icon={
                              <FiberManualRecord
                                className={classesRadio.radioUnchecked}
                              />
                            }
                            checkedIcon={
                              <FiberManualRecord
                                className={classesRadio.radioChecked}
                              />
                            }
                            classes={{
                              checked: classesRadio.radioRose,
                              root: classesRadio.radioRoot,
                            }}
                          />
                        }
                        classes={{
                          label: classesRadio.label,
                          root: classesRadio.labelRoot,
                        }}
                        // label={sets.setdesc}
                      />
                      <label
                        style={{
                          color:
                            selectedPieceSets?.setsId === sets?.id
                              ? "#d81b60"
                              : selectedPieceSets?.id === sets?.id
                              ? "#d81b60"
                              : "",
                        }}
                      >
                        {sets.setDesc}
                      </label>
                    </GridItem>
                  ))}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <FaBox />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Box + Labour ₹ {boxVal}/-</small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {itemBox &&
                  itemBox.map((box) => (
                    <GridItem xs={12} sm={6} md={3} key={box.id}>
                      <FormControlLabel
                        key={box.id}
                        control={
                          <Radio
                            // checked={
                            //     selectedBox &&
                            //         selectedBox ? selectedBox === box.id
                            //         : box.active === 1
                            // }
                            // selectedBox && selectedBox.id
                            //     ? selectedBox?.id === box?.id
                            //     : box?.active === 1
                            // checked={
                            //   (selectedBox && selectedBox.id) ||
                            //   selectedBox.boxId
                            //     ? selectedBox?.id === box?.id ||
                            //       selectedBox?.boxId === box?.id
                            //     : box?.active === 1
                            // }
                            checked={
                              selectedBox && selectedBox.boxId
                                ? selectedBox?.boxId === box?.id
                                : selectedBox.id
                                ? selectedBox?.id === box?.id
                                : ""
                            }
                            onChange={(e) => {
                              setSelectedBox(box);
                              // console.log(box);
                              if (box?.id) {
                                let boxTotal = box?.price + box?.pkgCharge;
                                setBoxVal(boxTotal);
                              }
                            }}
                            value={box.id}
                            name="radio button demo"
                            aria-label="A"
                            icon={
                              <FiberManualRecord
                                className={classesRadio.radioUnchecked}
                              />
                            }
                            checkedIcon={
                              <FiberManualRecord
                                className={classesRadio.radioChecked}
                              />
                            }
                            classes={{
                              checked: classesRadio.radioRose,
                              root: classesRadio.radioRoot,
                            }}
                          />
                        }
                        classes={{
                          label: classesRadio.label,
                          root: classesRadio.labelRoot,
                        }}
                        // label={box.box + " " + box.brandname}
                      />
                      <label
                        style={{
                          color:
                            box?.id === selectedBox?.boxId
                              ? "#d81b60"
                              : box?.id === selectedBox?.id
                              ? "#d81b60"
                              : "",
                        }}
                      >
                        {box.box + " " + box.brandName}
                      </label>
                    </GridItem>
                  ))}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <FaUsers />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Making Charge Per (Box : ₹ {makVal}) </small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {items2 &&
                  items2.map((making) => (
                    <GridItem xs={12} sm={6} md={4} key={making.id}>
                      <FormControlLabel
                        key={making.id}
                        control={
                          <Checkbox
                            tabIndex={-1}
                            checked={checkBoxMaking.includes(making.id)}
                            name={making.title}
                            onChange={(e) => handleCheckBoxMaking(e, making)}
                            checkedIcon={
                              <Check className={classCheck.checkedIcon} />
                            }
                            icon={
                              <Check className={classCheck.uncheckedIcon} />
                            }
                            classes={{
                              checked: classCheck.checkedRose,
                              root: classCheck.checkRoot,
                            }}
                          />
                        }
                        classes={{
                          label:
                            classCheck.label +
                            (makingCheckboxMaking === "error"
                              ? " " + classCheck.labelError
                              : ""),
                        }}
                        // label={making.description + "> " + making.price}
                      />
                      <label
                        style={{
                          color: checkBoxMaking.includes(making)
                            ? "#d81b60"
                            : "",
                        }}
                      >
                        {making.title + "> " + making.price}
                      </label>
                    </GridItem>
                  ))}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <GiWireCoil />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>
                  {" "}
                  Metal & Ready Gram Per Piece (Box ₹ {metGramVal})
                </small>
              </h3>
            </CardHeader>
            <CardBody>
              <h5 className={classes.cardIconTitle}>
                Metal Price ₹{" "}
                {simpleSelectMetal ? metalPrice + " + " + metalDiff : ""}
              </h5>
              <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                  <FormControl fullWidth className={classe.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classe.selectLabel}
                    >
                      Select Metal
                    </InputLabel>

                    <Select
                      MenuProps={{
                        className: classe.selectMenu,
                      }}
                      classes={{
                        select: classe.select,
                      }}
                      value={simpleSelectMetal}
                      onChange={handleSimpleMetal}
                      inputProps={{
                        name: "simpleSelect",
                        id: "simple-select",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classe.selectMenuItem,
                        }}
                      >
                        Select Metal
                      </MenuItem>
                      {metal &&
                        metal.map((item) => (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                            classes={{
                              root: classe.selectMenuItem,
                              selected: classe.selectMenuItemSelected,
                            }}
                          >
                            {item.title +
                              "> " +
                              item.price +
                              " + " +
                              item.different}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <CustomInput
                    labelText="Ready Gram"
                    id="readyGram"
                    // inputProps={{
                    //   placeholder: "Ready Gram",
                    // }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="readyGram"
                    value={readyGram}
                    onChange={(e) => setReadyGram(e.target.value)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <CustomInput
                    labelText="Metal Gram"
                    id="metalGram"
                    // inputProps={{
                    //   placeholder: "Metal Gram",
                    // }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="metalGram"
                    value={metalGram}
                    onChange={(e) => {
                      const { value } = e.target;
                      setMetalGram(value);
                      if (value) {
                        let newMet =
                          value *
                          selectedPieceSets?.pcs *
                          selectedBox?.box *
                          (metalPrice + metalDiff);
                        setMetGramVal(newMet.toFixed(2));
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <CustomInput
                    labelText="Extra S Gram"
                    id="metalSGram"
                    // inputProps={{
                    //   placeholder: "Metal S Gram",
                    // }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="metalSGram"
                    value={metalSGram}
                    onChange={(e) => setMetalSGram(e.target.value)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <CustomInput
                    labelText="Thaw Golwire"
                    id="golwireThaw"
                    // inputProps={{
                    //   placeholder: "Golwire Thaw",
                    // }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="golwireThaw"
                    value={golwireThaw}
                    onChange={(e) => setGolwireThaw(e.target.value)}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <GiBoxUnpacking />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small> Packing Option (Box ₹ {packVal})</small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={packingOption === "Plastic Dibbi"}
                          onChange={handlePackingOption}
                          value="Plastic Dibbi"
                          name="Plastic Dibbi"
                          aria-label="Plastic Dibbi"
                          icon={
                            <FiberManualRecord
                              className={classesRadio.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classesRadio.radioChecked}
                            />
                          }
                          classes={{
                            checked: classesRadio.radioRose,
                            root: classesRadio.radioRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classesRadio.label,
                        root: classesRadio.labelRoot,
                      }}
                      // label="Plastic Dibbi"
                    />
                    <label
                      style={{
                        color:
                          packingOption === "Plastic Dibbi" ? "#d81b60" : "",
                      }}
                    >
                      Plastic Dibbi
                    </label>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControl fullWidth className={classe.selectFormControl}>
                      <InputLabel
                        htmlFor="simple-select"
                        className={classe.selectLabel}
                      >
                        Select Plastic
                      </InputLabel>

                      <Select
                        MenuProps={{
                          className: classe.selectMenu,
                        }}
                        classes={{
                          select: classe.select,
                        }}
                        value={simpleSelectPlastic}
                        onChange={handleSimplePlastic}
                        inputProps={{
                          name: "simpleSelect",
                          id: "simple-select",
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classe.selectMenuItem,
                          }}
                        >
                          Select Plastic
                        </MenuItem>
                        {plastic &&
                          plastic.map((plas) => (
                            <MenuItem
                              key={plas.id}
                              value={plas.id}
                              classes={{
                                root: classe.selectMenuItem,
                                selected: classe.selectMenuItemSelected,
                              }}
                            >
                              {plas.pkgdesc + "> " + plas.price}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={packingOption === "Crystal Dibbi"}
                          onChange={handlePackingOption}
                          value="Crystal Dibbi"
                          name="Crystal Dibbi"
                          aria-label="Crystal Dibbi"
                          icon={
                            <FiberManualRecord
                              className={classesRadio.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classesRadio.radioChecked}
                            />
                          }
                          classes={{
                            checked: classesRadio.radioRose,
                            root: classesRadio.radioRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classesRadio.label,
                        root: classesRadio.labelRoot,
                      }}
                      // label="Crystal Dibbi"
                    />
                    <label
                      style={{
                        color:
                          packingOption === "Crystal Dibbi" ? "#d81b60" : "",
                      }}
                    >
                      Crystal Dibbi
                    </label>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControl fullWidth className={classe.selectFormControl}>
                      <InputLabel
                        htmlFor="simple-select"
                        className={classe.selectLabel}
                      >
                        Select Crystal
                      </InputLabel>

                      <Select
                        MenuProps={{
                          className: classe.selectMenu,
                        }}
                        classes={{
                          select: classe.select,
                        }}
                        value={simpleSelectCrystal}
                        onChange={handleSimpleCrystal}
                        inputProps={{
                          name: "simpleSelect",
                          id: "simple-select",
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classe.selectMenuItem,
                          }}
                        >
                          Select Crystal
                        </MenuItem>
                        {crystal &&
                          crystal.map((crys) => (
                            <MenuItem
                              key={crys.id}
                              value={crys.id}
                              classes={{
                                root: classe.selectMenuItem,
                                selected: classe.selectMenuItemSelected,
                              }}
                            >
                              {crys.pkgdesc + "> " + crys.price}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={packingOption === "Polythene PP"}
                          onChange={handlePackingOption}
                          value="Polythene PP"
                          name="Polythene PP"
                          aria-label="Polythene PP"
                          icon={
                            <FiberManualRecord
                              className={classesRadio.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classesRadio.radioChecked}
                            />
                          }
                          classes={{
                            checked: classesRadio.radioRose,
                            root: classesRadio.radioRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classesRadio.label,
                        root: classesRadio.labelRoot,
                      }}
                      // label="Polythene PP"
                    />
                    <label
                      style={{
                        color:
                          packingOption === "Polythene PP" ? "#d81b60" : "",
                      }}
                    >
                      Polythene PP
                    </label>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControl fullWidth className={classe.selectFormControl}>
                      <InputLabel
                        htmlFor="simple-select"
                        className={classe.selectLabel}
                      >
                        Select Polythene
                      </InputLabel>

                      <Select
                        MenuProps={{
                          className: classe.selectMenu,
                        }}
                        classes={{
                          select: classe.select,
                        }}
                        value={simpleSelectPloythene}
                        onChange={handleSimplePloythene}
                        inputProps={{
                          name: "simpleSelect",
                          id: "simple-select",
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classe.selectMenuItem,
                          }}
                        >
                          Select Polythene
                        </MenuItem>
                        {ploythene &&
                          ploythene.map((ploy) => (
                            <MenuItem
                              key={ploy.id}
                              value={ploy.id}
                              classes={{
                                root: classe.selectMenuItem,
                                selected: classe.selectMenuItemSelected,
                              }}
                            >
                              {ploy.pkgdesc + "> " + ploy.price}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <AiOutlineCalculator />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Price Calculation</small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={6} sm={6} md={4}>
                  {/* <GridItem xs={12} sm={12} md={12}> */}
                  <FormControlLabel
                    control={
                      <Radio
                        checked={selectedGstCal === "gst"}
                        onChange={handleChangeGstCal}
                        value="gst"
                        name="gst"
                        aria-label="GST"
                        icon={
                          <FiberManualRecord
                            className={classesRadio.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord
                            className={classesRadio.radioChecked}
                          />
                        }
                        classes={{
                          checked: classesRadio.radioRose,
                          root: classesRadio.radioRoot,
                        }}
                      />
                    }
                    classes={{
                      label: classesRadio.label,
                      root: classesRadio.labelRoot,
                    }}
                    // label="GST"
                  />
                  <label
                    style={{ color: selectedGstCal === "gst" ? "#d81b60" : "" }}
                  >
                    TaxInclude ({tax})
                  </label>
                </GridItem>
                <GridItem xs={6} sm={6} md={4}>
                  {/* <GridItem xs={12} sm={12} md={12}> */}
                  <FormControlLabel
                    control={
                      <Radio
                        checked={selectedGstCal === "cash"}
                        onClick={handleChangeGstCal}
                        value="cash"
                        name="cash"
                        aria-label="Cash"
                        icon={
                          <FiberManualRecord
                            className={classesRadio.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord
                            className={classesRadio.radioChecked}
                          />
                        }
                        classes={{
                          checked: classesRadio.radioRose,
                          root: classesRadio.radioRoot,
                        }}
                      />
                    }
                    classes={{
                      label: classesRadio.label,
                      root: classesRadio.labelRoot,
                    }}
                    // label="CASH"
                  />
                  <label
                    style={{
                      color: selectedGstCal === "cash" ? "#d81b60" : "",
                    }}
                  >
                    CASH
                  </label>
                </GridItem>
                <GridItem xs={6} sm={6} md={4}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={checkedA}
                        onChange={(event) => setCheckedA(event.target.checked)}
                        value="checkedA"
                        classes={{
                          switchBase: classe.switchBase,
                          checked: classes.switchChecked,
                          thumb: classe.switchIcon,
                          track: classe.switchBar,
                        }}
                      />
                    }
                    classes={{
                      label: classe.label,
                    }}
                    label={
                      checkedA === false ? (
                        "Offline"
                      ) : (
                        <span
                          style={{ color: checkedA === true ? "#d81b60" : "" }}
                        >
                          Online
                        </span>
                      )
                    }
                  />
                </GridItem>
              </GridContainer>
              <GridContainer style={{ border: "1px solid #eeeeee" }}>
                <GridItem xs={12} sm={4} ms={4}>
                  <h5 className={classes.cardIconTitle}>
                    <small>Making Charge</small>
                  </h5>
                </GridItem>
                <GridItem xs={6} sm={4} ms={4}>
                  {" "}
                  <h5 className={classes.cardIconTitle}>
                    <small>
                      ₹{" "}
                      {selectedGstCal && selectedGstCal === "gst"
                        ? includeGST
                        : totalCash}
                      /-
                    </small>
                  </h5>
                </GridItem>
                <GridItem xs={6} sm={4} ms={4}>
                  <h5 className={classes.cardIconTitle}>
                    <small>
                      Sets ₹{" "}
                      {selectedGstCal && selectedGstCal === "gst"
                        ? includeSets
                        : sets}
                      /-
                    </small>
                  </h5>
                </GridItem>
              </GridContainer>
              <GridContainer style={{ border: "1px solid #eeeeee" }}>
                <GridItem xs={12} sm={4} ms={4}>
                  <FormControlLabel
                    control={
                      <Radio
                        checked={selectedProfit === "profit"}
                        onClick={handleProfit}
                        value="profit"
                        name="profit"
                        aria-label="profit"
                        icon={
                          <FiberManualRecord
                            className={classesRadio.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord
                            className={classesRadio.radioChecked}
                          />
                        }
                        classes={{
                          checked: classesRadio.radioRose,
                          root: classesRadio.radioRoot,
                        }}
                      />
                    }
                    classes={{
                      label: classesRadio.label,
                      root: classesRadio.labelRoot,
                    }}
                    label={`Profit ${userObj.profit}%`}
                  />
                </GridItem>
                <GridItem xs={6} sm={4} ms={4}>
                  <h5 className={classes.cardIconTitle}>
                    <small>
                      ₹{" "}
                      {selectedGstCal && selectedGstCal === "gst"
                        ? includeProfit
                        : totalProfit}
                      /-
                    </small>
                  </h5>
                </GridItem>
                <GridItem xs={6} sm={4} ms={4}>
                  <h5 className={classes.cardIconTitle}>
                    <small>
                      {" "}
                      Sets ₹{" "}
                      {selectedGstCal && selectedGstCal === "gst"
                        ? includeProfitSet
                        : setProfit}
                      /-
                    </small>
                  </h5>
                </GridItem>
              </GridContainer>
              {selectedGstCal === "gst" ? (
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="CGST @9%..."
                      id="cgst"
                      inputProps={{
                        placeholder: "CGST @9%...",
                      }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      name="cgst"
                      value={cgst}
                      onChange={(e) => {
                        setCgst(e.target.value);
                      }}
                      required={true}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="SGST @9%..."
                      id="sgst"
                      inputProps={{
                        placeholder: "SGST @9%...",
                      }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      name="sgst"
                      value={sgst}
                      onChange={(e) => {
                        setSgst(e.target.value);
                      }}
                      required={true}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="IGST @18%..."
                      id="igst"
                      inputProps={{
                        placeholder: "IGST @18%...",
                      }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      name="igst"
                      value={igst}
                      onChange={(e) => {
                        setIgst(e.target.value);
                      }}
                      required={true}
                    />
                  </GridItem>
                </GridContainer>
              ) : (
                ""
              )}
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Discount..."
                    id="discount"
                    inputProps={{
                      placeholder: "Discount...",
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="discount"
                    value={discount}
                    onChange={(e) => {
                      setDiscount(e.target.value);
                    }}
                    required={true}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <BiFile />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Product Detail</small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={2}>
                  <CustomInput
                    labelText="Item Code"
                    id="prodItemCode"
                    // inputProps={{
                    //   placeholder: "Golwire Thaw",
                    // }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="prodItemCode"
                    value={prodItemCode}
                    onChange={(e) => setProdItemCode(e.target.value)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <CustomInput
                    labelText="Moq kg"
                    id="moqkg"
                    // inputProps={{
                    //   placeholder: "Golwire Thaw",
                    // }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="moqkg"
                    value={moqkg}
                    onChange={(e) => setMoqkg(e.target.value)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Product Manufacture Details"
                    id="prodMfgDetails"
                    // inputProps={{
                    //   placeholder: "Golwire Thaw",
                    // }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="prodMfgDetails"
                    value={prodMfgDetails}
                    onChange={(e) => setProdMfgDetails(e.target.value)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Product Description Min(100) Max(150)"
                    id="prodDescription"
                    // inputProps={{
                    //   placeholder: "Golwire Thaw",
                    // }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="prodDescription"
                    value={prodDescription}
                    onChange={(e) => {
                      const { value } = e.target;
                      setProdDescription(
                        value.length <= 150
                          ? value.slice(0, 150)
                          : value.slice(0, 150)
                      );
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Carton Description"
                    id="cartonDescription"
                    // inputProps={{
                    //   placeholder: "Golwire Thaw",
                    // }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="cartonDescription"
                    value={cartonDescription}
                    onChange={(e) => setCartonDescription(e.target.value)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Carton Quantity"
                    id="cartonQuantity"
                    // inputProps={{
                    //   placeholder: "Golwire Thaw",
                    // }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="cartonQuantity"
                    value={cartonQuantity}
                    onChange={(e) => setCartonQuantity(e.target.value)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Carton Packing Charge"
                    id="cartonPackingCharge"
                    // inputProps={{
                    //   placeholder: "Golwire Thaw",
                    // }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="cartonPackingCharge"
                    value={cartonPackingCharge}
                    onChange={(e) => setCartonPackingCharge(e.target.value)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="HSN/SAC CODE"
                    id="hsnsaccode"
                    // inputProps={{
                    //   placeholder: "Golwire Thaw",
                    // }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="hsnCode"
                    value={hsnCode}
                    onChange={(e) => setHsnCode(e.target.value)}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <BsImages />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Select Design Images</small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {imgList &&
                  imgList.map((i) => (
                    <GridItem xs={3} sm={2} md={2} key={i.id}>
                      <div className="fileinput text-center">
                        <div
                          className={
                            "thumbnail" + (avatar ? " img-circle" : "")
                          }
                        >
                          <img src={i.cropImageUrl + "?id=" + i.id} alt="..." />
                        </div>
                      </div>
                      <br />
                      <div>
                        <span style={{ marginRight: "5px" }}>{i.id}</span>
                        <FormControlLabel
                          key={i.id}
                          control={
                            <Checkbox
                              tabIndex={-1}
                              checked={checkBoxImage.includes(i.id)}
                              name={i.cropName}
                              onChange={(e) => {
                                handleCheckBoxImage(e, i);
                              }}
                              checkedIcon={
                                <Check className={classCheck.checkedIcon} />
                              }
                              icon={
                                <Check className={classCheck.uncheckedIcon} />
                              }
                              classes={{
                                checked: classCheck.checkedRose,
                                root: classCheck.checkRoot,
                              }}
                            />
                          }
                          classes={{
                            label:
                              classCheck.label +
                              (makingCheckboxImage === "error"
                                ? " " + classCheck.labelError
                                : ""),
                          }}
                          // label={i.id}
                        />
                        {/*{"using - " + i.usedCount}*/}
                      </div>
                    </GridItem>
                  ))}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <div style={mystyle}>
        {/* <Button
          color="rose"
          className={classes.updateProfileButton}
          onClick={SubmitUpdateProduct}
        >
          Save
        </Button> */}
        {btn === true ? (
          <Button className={classes.updateProfileButton}>Update</Button>
        ) : (
          <Button
            color="rose"
            onClick={SubmitUpdateProduct}
            className={classes.updateProfileButton}
          >
            Update
          </Button>
        )}
      </div>
    </div>
  );
}

UpdateProduct.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
};

import React, { useState, useEffect } from "react";
import "react-phone-input-2/lib/bootstrap.css";
import { makeStyles } from "@material-ui/core/styles";
import { localApi } from "utils/imgApi";
import AddIcon from "@material-ui/icons/Add";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Selects from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import style from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";

import { credentials } from "utils/imgApi";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(style);

export default function AddTerms() {
  const [items, setItems] = React.useState([]);
  const [discription, setDiscription] = React.useState("");
  const [type, setType] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);
  const [alertType, alertTypeOps] = useState("");
  const [alertMsg, alertMsgOps] = useState("");
  const [clientid, setClientid] = React.useState("");
  const [simpleSelect, setSimpleSelect] = React.useState("");
  const [btn, setBtn] = React.useState(false);

  const classes = useStyles();
  const classe = useStyle();

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  async function getUsers() {
    const api =
      localApi + `/clientGroup/list/byAdminId/${userObj && userObj.id}`;
    const results = await fetch(api, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getResults = await results.json();
    setItems(getResults && getResults.filter((f) => f.type != "Crm"));
    // console.log(getResults);
  }

  useEffect(() => {
    getUsers();
  }, []);

  const handleBoots = async (simpleSelect) => {
    if (simpleSelect.value) {
      let newSpli = simpleSelect.value.split("-");
      const ids = items.find((f) => f.mobile === newSpli[1]);
      setClientid(ids && ids);
    }
    setSimpleSelect(simpleSelect);
  };

  const select = {
    width: "100%",
    maxWidth: 600,
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      padding: 10,
    }),
    menu: (state) => ({
      color: "#d81b60",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  const SubmitAddTerms = async () => {
    setBtn(true);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Basic ${credentials}`,
      },
      body: JSON.stringify({
        type: type,
        discription: discription,
        clientId: clientid.id,
      }),
    };
    fetch(localApi + `/termsAndCondition/add/${userObj.id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        if (data.message === "Data Inserted Successfully !!!") {
          alertTypeOps("success");
          alertMsgOps("Added Succesfully!");
          setSubmitted(true);
        }
      });
  };

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  return (
    <div>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={6}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <AddIcon />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Add Terms & Condition</small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer justify="center" alignItems="center">
                {submitted ? (
                  <SweetAlert
                    type={alertType}
                    style={{ display: "block", marginTop: "-100px" }}
                    title={alertMsg}
                    onConfirm={() => hideAlert()}
                    confirmBtnCssClass={classe.button + " " + classe.success}
                  />
                ) : null}
                <GridItem xs={12} sm={12} md={12}>
                  <label className={classe.selectLabel}>
                    Select Company & Client Name
                  </label>
                  <Selects
                    styles={customStyles}
                    style={select}
                    required={true}
                    placeholder="Select Compant & Client Name..."
                    value={simpleSelect}
                    name="simpleSelect"
                    onChange={handleBoots}
                    options={
                      items &&
                      items.map((c) => {
                        return {
                          label:
                            c && c.company
                              ? c.company + "-" + c.mobile
                              : c.firstname + " " + c.lastname + "-" + c.mobile,
                          value:
                            c && c.company
                              ? c.company + "-" + c.mobile
                              : c.firstname + " " + c.lastname + "-" + c.mobile,
                        };
                      })
                    }
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Type..."
                    id="type"
                    inputProps={{
                      placeholder: "Type...",
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="type"
                    value={type}
                    onChange={(e) => {
                      setType(e.target.value);
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Discription..."
                    id="discription"
                    inputProps={{
                      placeholder: "Discription...",
                      multiline: true,
                      rows: 4,
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="discription"
                    value={discription}
                    onChange={(e) => {
                      setDiscription(e.target.value);
                    }}
                  />
                </GridItem>
              </GridContainer>
              <br />
              <div className={classes.center}>
                {/* <Button
                  color="rose"
                  onClick={SubmitAddTerms}
                  className={classes.updateProfileButton}
                >
                  save
                </Button> */}
                {btn === true ? (
                  <Button className={classes.updateProfileButton}>Save</Button>
                ) : (
                  <Button
                    color="rose"
                    onClick={SubmitAddTerms}
                    className={classes.updateProfileButton}
                  >
                    Save
                  </Button>
                )}
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

import React, { useEffect } from "react";
import { produce } from "immer";
import { makeStyles } from "@material-ui/core/styles";
import { BiEdit } from "react-icons/bi";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import SweetAlert from "react-bootstrap-sweetalert";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import styleSweet from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import style from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import { localApi } from "utils/imgApi";
import styRadio from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import styleTable from "assets/jss/material-dashboard-pro-react/views/viewCartStyle";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(style);
const useStyless = makeStyles(styRadio);
const useStyleSweet = makeStyles(styleSweet);
const useStyleTable = makeStyles(styleTable);

export default function LabourReceiveDeletePacking(props) {
  const [billView, setBillView] = React.useState([]);
  const [checkOldAddress, setCheckOldAddress] = React.useState(false);
  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);

  const classes = useStyles();
  const classe = useStyle();
  const classesRadio = useStyless();
  const classSweet = useStyleSweet();
  const classTable = useStyleTable();

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  async function getList() {
    const getList = await fetch(
      localApi + `/metal/list/byAdminId/${userObj && userObj.id}`
    );
    const res = await getList.json();
    setBillView(res && res);
  }

  useEffect(() => {
    getList();
  }, []);

  const handleDeletePacking = (c) => {
    fetch(
      localApi + `/metal/delete/byAdminAndMetalId/${userObj && userObj.id}/${c.metalId}`,
      {
        method: "DELETE",
      }
    )
      .then((res) => res.json())
      .then((res) => {
        // console.log(res);
        if (res.message === "Deleted Successfully !!!") {
          alertTypeOps("success");
          alertMsgOps("Deleted Successfully !!!");
          setSubmitted(true);
        }
      });
  };

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  return (
    <div>
      <GridContainer justify="center" alignItems="center">
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <BiEdit />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Delete Receieve Packing</small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer justify="center" alignItems="center">
                {submitted ? (
                  <SweetAlert
                    type={alertType}
                    style={{ display: "block", marginTop: "-100px" }}
                    title={alertMsg}
                    onConfirm={() => hideAlert()}
                    confirmBtnCssClass={
                      classSweet.button + " " + classSweet.success
                    }
                  />
                ) : null}
                <Table
                  hover
                  tableHead={[
                    <span className={classTable.tableHead} key="sr">
                      Sr.No.
                    </span>,
                    <span className={classTable.tableHead} key="bn">
                      Bill.No.
                    </span>,
                    <span className={classTable.tableHead} key="dt">
                      Date
                    </span>,
                    <span className={classTable.tableHead} key="pn">
                      Party Name
                    </span>,
                    <span className={classTable.tableHead} key="des">
                      Description of Goods
                    </span>,
                    <span
                      className={
                        classTable.tableHead + " " + classTable.headRight
                      }
                      key="qty"
                    >
                      Quantity
                    </span>,
                    <span
                      className={
                        classTable.tableHead + " " + classTable.headRight
                      }
                      key="ut"
                    >
                      Unit
                    </span>,
                    <span
                      className={
                        classTable.tableHead + " " + classTable.headRight
                      }
                      key="pri"
                    >
                      Price
                    </span>,
                    <span
                      className={
                        classTable.tableHead + " " + classTable.headRight
                      }
                      key="amt"
                    >
                      Amount
                    </span>,
                    "",
                  ]}
                  tableData={[
                    ...billView.map((c, index) => ({
                      color: c.taxApply === true ? "success" : "",
                      data: [
                        <span className={classTable.listNumber} key={c.id}>
                          {index + 1}
                        </span>,
                        <span className={classTable.listNumber} key={c.id}>
                          {c.id}
                        </span>,
                        <span className={classTable.listNumber} key={c.id}>
                          {c.pickerdate}
                        </span>,
                        <span className={classTable.listNumber} key={c.id}>
                          {c.firstName + " " + c.lastName}
                        </span>,
                        <span key={c.id}>
                          <small className={classTable.listNumber}>
                            {c.title} - {c.description}
                          </small>
                          <br />
                          <GridContainer className={classTable.sizePadding}>
                            {c.productSize.s1x8 != 0 ? (
                              <span
                                className={
                                  classTable.textCenter +
                                  " " +
                                  classTable.sizeSpan +
                                  " " +
                                  classTable.listDescriptionMM
                                }
                              >
                                {c.productSize.s1x8 == null
                                  ? ""
                                  : c.productSize.s1x8 == 0
                                  ? ""
                                  : "1x8"}
                                <br />
                                {c.productSize.s1x8 == null
                                  ? ""
                                  : c.productSize.s1x8 == 0
                                  ? ""
                                  : c.productSize.s1x8}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s1x10 != 0 ? (
                              <span
                                className={
                                  classTable.textCenter +
                                  " " +
                                  classTable.sizeSpan +
                                  " " +
                                  classTable.listDescriptionMM
                                }
                              >
                                {c.productSize.s1x10 == null
                                  ? ""
                                  : c.productSize.s1x10 == 0
                                  ? ""
                                  : "1x10"}
                                <br />
                                {c.productSize.s1x10 == null
                                  ? ""
                                  : c.productSize.s1x10 == 0
                                  ? ""
                                  : c.productSize.s1x10}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s1x12 != 0 ? (
                              <span
                                className={
                                  classTable.textCenter +
                                  " " +
                                  classTable.sizeSpan +
                                  " " +
                                  classTable.listDescriptionMM
                                }
                              >
                                {c.productSize.s1x12 == null
                                  ? ""
                                  : c.productSize.s1x12 == 0
                                  ? ""
                                  : "1x12"}
                                <br />
                                {c.productSize.s1x12 == null
                                  ? ""
                                  : c.productSize.s1x12 == 0
                                  ? ""
                                  : c.productSize.s1x12}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s1x14 != 0 ? (
                              <span
                                className={
                                  classTable.textCenter +
                                  " " +
                                  classTable.sizeSpan +
                                  " " +
                                  classTable.listDescriptionMM
                                }
                              >
                                {c.productSize.s1x14 == null
                                  ? ""
                                  : c.productSize.s1x14 == 0
                                  ? ""
                                  : "1x14"}
                                <br />
                                {c.productSize.s1x14 == null
                                  ? ""
                                  : c.productSize.s1x14 == 0
                                  ? ""
                                  : c.productSize.s1x14}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x0 != 0 ? (
                              <span
                                className={
                                  classTable.textCenter +
                                  " " +
                                  classTable.sizeSpan +
                                  " " +
                                  classTable.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x0 == null
                                  ? ""
                                  : c.productSize.s2x0 == 0
                                  ? ""
                                  : "2x0"}
                                <br />
                                {c.productSize.s2x0 == null
                                  ? ""
                                  : c.productSize.s2x0 == 0
                                  ? ""
                                  : c.productSize.s2x0}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x2 != 0 ? (
                              <span
                                className={
                                  classTable.textCenter +
                                  " " +
                                  classTable.sizeSpan +
                                  " " +
                                  classTable.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x2 == null
                                  ? ""
                                  : c.productSize.s2x2 == 0
                                  ? ""
                                  : "2x2"}
                                <br />
                                {c.productSize.s2x2 == null
                                  ? ""
                                  : c.productSize.s2x2 == 0
                                  ? ""
                                  : c.productSize.s2x2}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x4 != 0 ? (
                              <span
                                className={
                                  classTable.textCenter +
                                  " " +
                                  classTable.sizeSpan +
                                  " " +
                                  classTable.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x4 == null
                                  ? ""
                                  : c.productSize.s2x4 == 0
                                  ? ""
                                  : "2x4"}
                                <br />
                                {c.productSize.s2x4 == null
                                  ? ""
                                  : c.productSize.s2x4 == 0
                                  ? ""
                                  : c.productSize.s2x4}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x6 != 0 ? (
                              <span
                                className={
                                  classTable.textCenter +
                                  " " +
                                  classTable.sizeSpan +
                                  " " +
                                  classTable.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x6 == null
                                  ? ""
                                  : c.productSize.s2x6 == 0
                                  ? ""
                                  : "2x6"}
                                <br />
                                {c.productSize.s2x6 == null
                                  ? ""
                                  : c.productSize.s2x6 == 0
                                  ? ""
                                  : c.productSize.s2x6}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x8 != 0 ? (
                              <span
                                className={
                                  classTable.textCenter +
                                  " " +
                                  classTable.sizeSpan +
                                  " " +
                                  classTable.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x8 == null
                                  ? ""
                                  : c.productSize.s2x8 == 0
                                  ? ""
                                  : "2x8"}
                                <br />
                                {c.productSize.s2x8 == null
                                  ? ""
                                  : c.productSize.s2x8 == 0
                                  ? ""
                                  : c.productSize.s2x8}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x10 != 0 ? (
                              <span
                                className={
                                  classTable.textCenter +
                                  " " +
                                  classTable.sizeSpan +
                                  " " +
                                  classTable.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x10 == null
                                  ? ""
                                  : c.productSize.s2x10 == 0
                                  ? ""
                                  : "2x10"}
                                <br />
                                {c.productSize.s2x10 == null
                                  ? ""
                                  : c.productSize.s2x10 == 0
                                  ? ""
                                  : c.productSize.s2x10}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x12 != 0 ? (
                              <span
                                className={
                                  classTable.textCenter +
                                  " " +
                                  classTable.sizeSpan +
                                  " " +
                                  classTable.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x12 == null
                                  ? ""
                                  : c.productSize.s2x12 == 0
                                  ? ""
                                  : "2x12"}
                                <br />
                                {c.productSize.s2x12 == null
                                  ? ""
                                  : c.productSize.s2x12 == 0
                                  ? ""
                                  : c.productSize.s2x12}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x14 != 0 ? (
                              <span
                                className={
                                  classTable.textCenter +
                                  " " +
                                  classTable.sizeSpan +
                                  " " +
                                  classTable.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x14 == null
                                  ? ""
                                  : c.productSize.s2x14 == 0
                                  ? ""
                                  : "2x14"}
                                <br />
                                {c.productSize.s2x14 == null
                                  ? ""
                                  : c.productSize.s2x14 == 0
                                  ? ""
                                  : c.productSize.s2x14}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s3x0 != 0 ? (
                              <span
                                className={
                                  classTable.textCenter +
                                  " " +
                                  classTable.sizeSpan +
                                  " " +
                                  classTable.listDescriptionMM
                                }
                              >
                                {c.productSize.s3x0 == null
                                  ? ""
                                  : c.productSize.s3x0 == 0
                                  ? ""
                                  : "3x0"}
                                <br />
                                {c.productSize.s3x0 == null
                                  ? ""
                                  : c.productSize.s3x0 == 0
                                  ? ""
                                  : c.productSize.s3x0}
                              </span>
                            ) : (
                              ""
                            )}
                          </GridContainer>
                          <GridContainer className={classTable.sizePadding}>
                            {c.productSize.s1x9 != 0 ? (
                              <span
                                className={
                                  classTable.textCenter +
                                  " " +
                                  classTable.sizeSpan +
                                  " " +
                                  classTable.listDescriptionMM
                                }
                              >
                                {c.productSize.s1x9 == null
                                  ? ""
                                  : c.productSize.s1x9 == 0
                                  ? ""
                                  : "1x9"}
                                <br />
                                {c.productSize.s1x9 == null
                                  ? ""
                                  : c.productSize.s1x9 == 0
                                  ? ""
                                  : c.productSize.s1x9}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s1x11 != 0 ? (
                              <span
                                className={
                                  classTable.textCenter +
                                  " " +
                                  classTable.sizeSpan +
                                  " " +
                                  classTable.listDescriptionMM
                                }
                              >
                                {c.productSize.s1x11 == null
                                  ? ""
                                  : c.productSize.s1x11 == 0
                                  ? ""
                                  : "1x11"}
                                <br />
                                {c.productSize.s1x11 == null
                                  ? ""
                                  : c.productSize.s1x11 == 0
                                  ? ""
                                  : c.productSize.s1x11}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s1x13 != 0 ? (
                              <span
                                className={
                                  classTable.textCenter +
                                  " " +
                                  classTable.sizeSpan +
                                  " " +
                                  classTable.listDescriptionMM
                                }
                              >
                                {c.productSize.s1x13 == null
                                  ? ""
                                  : c.productSize.s1x13 == 0
                                  ? ""
                                  : "1x13"}
                                <br />
                                {c.productSize.s1x13 == null
                                  ? ""
                                  : c.productSize.s1x13 == 0
                                  ? ""
                                  : c.productSize.s1x13}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s1x15 != 0 ? (
                              <span
                                className={
                                  classTable.textCenter +
                                  " " +
                                  classTable.sizeSpan +
                                  " " +
                                  classTable.listDescriptionMM
                                }
                              >
                                {c.productSize.s1x15 == null
                                  ? ""
                                  : c.productSize.s1x15 == 0
                                  ? ""
                                  : "1x15"}
                                <br />
                                {c.productSize.s1x15 == null
                                  ? ""
                                  : c.productSize.s1x15 == 0
                                  ? ""
                                  : c.productSize.s1x15}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x1 != 0 ? (
                              <span
                                className={
                                  classTable.textCenter +
                                  " " +
                                  classTable.sizeSpan +
                                  " " +
                                  classTable.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x1 == null
                                  ? ""
                                  : c.productSize.s2x1 == 0
                                  ? ""
                                  : "2x1"}
                                <br />
                                {c.productSize.s2x1 == null
                                  ? ""
                                  : c.productSize.s2x1 == 0
                                  ? ""
                                  : c.productSize.s2x1}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x3 != 0 ? (
                              <span
                                className={
                                  classTable.textCenter +
                                  " " +
                                  classTable.sizeSpan +
                                  " " +
                                  classTable.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x3 == null
                                  ? ""
                                  : c.productSize.s2x3 == 0
                                  ? ""
                                  : "2x3"}
                                <br />
                                {c.productSize.s2x3 == null
                                  ? ""
                                  : c.productSize.s2x3 == 0
                                  ? ""
                                  : c.productSize.s2x3}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x5 != 0 ? (
                              <span
                                className={
                                  classTable.textCenter +
                                  " " +
                                  classTable.sizeSpan +
                                  " " +
                                  classTable.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x5 == null
                                  ? ""
                                  : c.productSize.s2x5 == 0
                                  ? ""
                                  : "2x5"}
                                <br />
                                {c.productSize.s2x5 == null
                                  ? ""
                                  : c.productSize.s2x5 == 0
                                  ? ""
                                  : c.productSize.s2x5}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x7 != 0 ? (
                              <span
                                className={
                                  classTable.textCenter +
                                  " " +
                                  classTable.sizeSpan +
                                  " " +
                                  classTable.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x7 == null
                                  ? ""
                                  : c.productSize.s2x7 == 0
                                  ? ""
                                  : "2x7"}
                                <br />
                                {c.productSize.s2x7 == null
                                  ? ""
                                  : c.productSize.s2x7 == 0
                                  ? ""
                                  : c.productSize.s2x7}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x9 != 0 ? (
                              <span
                                className={
                                  classTable.textCenter +
                                  " " +
                                  classTable.sizeSpan +
                                  " " +
                                  classTable.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x9 == null
                                  ? ""
                                  : c.productSize.s2x9 == 0
                                  ? ""
                                  : "2x9"}
                                <br />
                                {c.productSize.s2x9 == null
                                  ? ""
                                  : c.productSize.s2x9 == 0
                                  ? ""
                                  : c.productSize.s2x9}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x11 != 0 ? (
                              <span
                                className={
                                  classTable.textCenter +
                                  " " +
                                  classTable.sizeSpan +
                                  " " +
                                  classTable.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x11 == null
                                  ? ""
                                  : c.productSize.s2x11 == 0
                                  ? ""
                                  : "2x11"}
                                <br />
                                {c.productSize.s2x11 == null
                                  ? ""
                                  : c.productSize.s2x11 == 0
                                  ? ""
                                  : c.productSize.s2x11}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x13 != 0 ? (
                              <span
                                className={
                                  classTable.textCenter +
                                  " " +
                                  classTable.sizeSpan +
                                  " " +
                                  classTable.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x13 == null
                                  ? ""
                                  : c.productSize.s2x13 == 0
                                  ? ""
                                  : "2x13"}
                                <br />
                                {c.productSize.s2x13 == null
                                  ? ""
                                  : c.productSize.s2x13 == 0
                                  ? ""
                                  : c.productSize.s2x13}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s2x15 != 0 ? (
                              <span
                                className={
                                  classTable.textCenter +
                                  " " +
                                  classTable.sizeSpan +
                                  " " +
                                  classTable.listDescriptionMM
                                }
                              >
                                {c.productSize.s2x15 == null
                                  ? ""
                                  : c.productSize.s2x15 == 0
                                  ? ""
                                  : "2x15"}
                                <br />
                                {c.productSize.s2x15 == null
                                  ? ""
                                  : c.productSize.s2x15 == 0
                                  ? ""
                                  : c.productSize.s2x15}
                              </span>
                            ) : (
                              ""
                            )}
                            {c.productSize.s3x1 != 0 ? (
                              <span
                                className={
                                  classTable.textCenter +
                                  " " +
                                  classTable.sizeSpan +
                                  " " +
                                  classTable.listDescriptionMM
                                }
                              >
                                {c.productSize.s3x1 == null
                                  ? ""
                                  : c.productSize.s3x1 == 0
                                  ? ""
                                  : "3x1"}
                                <br />
                                {c.productSize.s3x1 == null
                                  ? ""
                                  : c.productSize.s3x1 == 0
                                  ? ""
                                  : c.productSize.s3x1}
                              </span>
                            ) : (
                              ""
                            )}
                          </GridContainer>
                        </span>,
                        <span
                          className={
                            classTable.headRight + " " + classTable.listNumber
                          }
                          key={c.id}
                        >
                          {c.quantity}
                        </span>,
                        <span
                          className={
                            classTable.headRight + " " + classTable.listNumber
                          }
                          key={c.id}
                        >
                          {c.unit}
                        </span>,
                        <span
                          className={
                            classTable.headRight + " " + classTable.listNumber
                          }
                          key={c.id}
                        >
                          ₹{c.price}
                        </span>,
                        <span
                          className={
                            classTable.headRight + " " + classTable.listNumber
                          }
                          key={c.id}
                        >
                          ₹{c.amount.toFixed(2)}
                        </span>,
                        <Button
                          color="rose"
                          block
                          simple
                          onClick={() => {
                            handleDeletePacking(c);
                          }}
                        >
                          <DeleteForeverIcon />
                        </Button>,
                      ],
                    })),
                    [
                      "",
                      "",
                      "",
                      "",
                      <span
                        className={
                          classTable.headRight + " " + classTable.listNumber
                        }
                        key={1}
                      >
                        Grand Total
                      </span>,
                      <span key={3}>
                        <span
                          className={
                            classTable.headRight + " " + classTable.listNumber
                          }
                        >
                          {billView
                            .reduce(
                              (a, b) => parseFloat(a) + parseFloat(b.quantity),
                              0
                            )
                            .toFixed(2)}
                        </span>
                      </span>,
                      "",
                      "",
                      <span key={3}>
                        <span
                          className={
                            classTable.headRight + " " + classTable.listNumber
                          }
                        >
                          ₹
                          {billView
                            .reduce(
                              (a, b) => parseFloat(a) + parseFloat(b.amount),
                              0
                            )
                            .toFixed(2)}
                        </span>
                      </span>,
                      "",
                    ],
                    [
                      "",
                      "",
                      "",
                      "",
                      <span key={3}>
                        <span
                          className={
                            classTable.headRight + " " + classTable.listNumber
                          }
                        >
                          Closing Balance
                        </span>
                      </span>,
                      "",
                      "",
                      "",
                      <span key={3}>
                        <span
                          className={
                            classTable.headRight + " " + classTable.listNumber
                          }
                        >
                          ₹
                          {billView
                            .reduce(
                              (a, b) => parseFloat(a) + parseFloat(b.amount),
                              0
                            )
                            .toFixed(2)}
                        </span>
                      </span>,
                      "",
                    ],
                  ]}
                />
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

import React, { useEffect } from "react";
import { produce } from "immer";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { headApi } from "utils/headApi";
import { imgApi } from "utils/imgApi";
import { AiOutlineFolderView } from "react-icons/ai";
import { IoLogoWhatsapp } from "react-icons/io";
import { ImMobile } from "react-icons/im";
import { TiPhone } from "react-icons/ti";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import style from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import { localApi } from "utils/imgApi";

import { credentials } from "utils/imgApi";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(style);

export default function UpdateLabourRate(props) {
  const [items2, setItems2] = React.useState([]);
  const [day, setDay] = React.useState("");

  const classes = useStyles();
  const classe = useStyle();

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  function arrSort(arr) {
    arr.sort((a, b) => a - b);
    arr.reverse();
    return arr;
  }

  const handleFind = () => {
    const d = new Date();
    // console.log("After Date", formatDate(d));

    var newDate = new Date(Date.now() - day * 24 * 60 * 60 * 1000);
    // console.log("Before date", formatDate(newDate));
    // console.log("From", formatDate(FromDate._d));
    // console.log("To", formatDate(ToDate._d));

    // axios
    //   .get(
    //     headApi + `/user/byTimeInterval?userid=${userObj && userObj.id}&interval=${day}`
    //   )
    //   .then((res) => setItems2(res && res.data));

    axios
      .get(
        localApi +
          `/register/list/byAdminIdBetweenDate/${userObj.id}/${
            day === "1" ? formatDate(d) : formatDate(newDate)
          }/${formatDate(d)}`,
        {
          headers: {
            Authorization: `Basic ${credentials}`,
          },
        }
      )
      .then((res) => {
        setItems2(arrSort(res && res.data));
        // console.log(res.data);
      });
  };

  useEffect(() => {
    // console.log("Value Changed")
    // setSimpleSelect1("");
    setItems2("");
  }, [day]);

  return (
    <div>
      <GridContainer justify="center" alignItems="center">
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <AiOutlineFolderView />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Last Updated Client</small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer justify="center" alignItems="center">
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Enter Day"
                    id="EnterDay"
                    inputProps={{
                      placeholder: "Enter Day Ex. 1",
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="day"
                    value={day}
                    onChange={(e) => setDay(e.target.value)}
                  />
                </GridItem>
                {/* <GridItem xs={2} sm={2} md={2}> */}
                <Button
                  color="rose"
                  onClick={handleFind}
                  className={classes.updateProfileButton}
                >
                  Find
                </Button>
                {/* </GridItem> */}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        {items2 &&
          items2.map((Ele) => (
            <GridItem xs={12} sm={6} md={4} key={Ele.mobile}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="rose">{Ele.type}</CardIcon>
                  {/* <h3 className={classes.cardIconTitle}>
                  <small>Select Design Images</small>
                </h3> */}
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <span>
                        <strong>{Ele.firstname + " " + Ele.lastname}</strong>
                      </span>
                    </GridItem>
                    <GridItem xs={6} sm={4} md={4}>
                      <span>Company</span>
                      <br />
                      <sapn>Mobile</sapn>
                      <br />
                      <span>WhatsApp</span>
                      <br />
                      <span>City</span>
                      <br />
                      <span>Last Update</span>
                      <br />
                    </GridItem>
                    <GridItem xs={6} sm={8} md={8}>
                      <span style={{ color: "red", fontWeight: "bold" }}>
                        {Ele.company}
                      </span>
                      <br />
                      <ImMobile />
                      &nbsp;&nbsp;
                      <span>
                        <a href={`tel: ${Ele.mobile}`}>{Ele.mobile}</a>
                      </span>
                      <br />
                      <IoLogoWhatsapp color="green" />
                      &nbsp;&nbsp;
                      <span>
                        <a
                          href={`https://api.whatsapp.com/send/?phone=${Ele.whatsapp}&text=Hello&app_absent=0`}
                        >
                          {Ele.whatsapp}
                        </a>
                      </span>
                      <br />
                      <span>{Ele.city}</span>
                      <br />
                      <span>{Ele.loginDate.slice(0, -19)}</span> &nbsp;{" "}
                      {/* <br /> */}
                      <span style={{ color: "#d81b60", fontSize: "12px" }}>
                        {Ele.lastDays}
                      </span>
                      <br />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          ))}
      </GridContainer>
    </div>
  );
}

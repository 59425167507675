import buttonGroupStyle from "assets/jss/material-dashboard-pro-react/buttonGroupStyle.js";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import {
  cardTitle,
  grayColor,
} from "assets/jss/material-dashboard-pro-react.js";

const extendedTablesStyle = {
  ...customCheckboxRadioSwitch,
  ...buttonGroupStyle,
  right: {
    textAlign: "right",
  },
  center: {
    textAlign: "center",
  },
  description: {
    maxWidth: "150px",
  },
  actionButton: {
    margin: "0 0 0 5px",
    padding: "5px",
    "& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
      marginRight: "0px",
    },
  },
  icon: {
    verticalAlign: "middle",
    width: "17px",
    height: "17px",
    top: "-1px",
    position: "relative",
  },
  imgContainer: {
    width: "120px",
    maxHeight: "160px",
    overflow: "hidden",
    display: "block",
  },
  img: {
    width: "100%",
    height: "auto",
    verticalAlign: "middle",
    border: "0",
  },
  tdName: {
    minWidth: "200px",
    fontWeight: "400",
    fontSize: "1.5em",
  },
  tdNameAnchor: {
    color: grayColor[2],
  },
  tdNameSmall: {
    color: grayColor[0],
    fontSize: "0.75em",
    fontWeight: "300",
  },
  tdNumber: {
    textAlign: "right",
    minWidth: "145px",
    fontWeight: "300",
    fontSize: "1.3em !important",
  },
  tdNumberSmall: {
    marginRight: "3px",
  },
  tdNumberAndButtonGroup: {
    lineHeight: "1 !important",
  },
  positionAbsolute: {
    position: "absolute",
    right: "0",
    top: "0",
  },
  customFont: {
    fontSize: "16px !important",
  },
  actionButtonRound: {
    width: "auto",
    height: "auto",
    minWidth: "auto",
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  select: {
    padding: "12px 0 7px",
    fontSize: ".75rem",
    fontWeight: "400",
    lineHeight: "1.42857",
    textDecoration: "none",
    // textTransform: "uppercase",
    color: grayColor[2],
    letterSpacing: "0",
    display: "block",
    "&:focus": {
      backgroundColor: "transparent",
    },
    "&[aria-owns] + input + svg": {
      transform: "rotate(180deg)",
    },
    "& + input + svg": {
      transition: "all 300ms linear",
    },
  },
  productPage: {
    "& $mainRaised": {
      margin: "-40vh 0 0",
      padding: "40px",
    },
    "& .image-gallery-slide img": {
      borderRadius: "3px",
      maxWidth: "300px",
      height: "auto",
    },
    "& .image-gallery-swipe": {
      margin: "30px 0px",
      overflow: "hidden",
      width: "100%",
      height: "auto",
      textAlign: "center",
    },
    "& .image-gallery-thumbnails > .image-gallery-thumbnails-container .image-gallery-thumbnail": {
      "&.active > .image-gallery-thumbnail-inner": {
        opacity: "1",
        borderColor: grayColor[6],
      },
      "& > .image-gallery-thumbnail-inner": {
        width: "80%",
        maxWidth: "85px",
        margin: "0 auto",
        padding: "8px",
        display: "block",
        border: "1px solid transparent",
        background: "transparent",
        borderRadius: "3px",
        opacity: ".8",
      },
      "& > .image-gallery-thumbnail-inner img": {
        borderRadius: "3px",
        width: "100%",
        height: "auto",
        textAlign: "center",
      },
    },
  },
  borders: {
    border: "1px solid #000000",
  },
  borderLine: {
    padding: "2px",
    borderBottom: "1px solid #000000",
  },
  textCenter: {
    textAlign: "center!important",
  },
  mrAuto: {
    marginRight: "auto",
  },
  mlAuto: {
    marginLeft: "auto",
  },
  invoiceName: {
    // float:"center",
    textAlign: "center!important",
    color: "#000000",
    fontSize: 13,
    fontWeight: "bold",
    textDecoration: "underline",
    lineHeight: "1.3em",
    fontFamily: "Tahoma",
  },
  companyName: {
    textAlign: "center",
    color: "#000000",
    fontSize: "24px",
    fontWeight: "bold",
    fontFamily: "Tahoma",
    lineHeight: "1.3em",
  },
  space: {
    lineHeight: "1.2",
  },
  address: {
    textAlign: "center",
    color: "#000000",
    fontSize: "14px",
    fontFamily: "Tahoma",
  },
  gst: {
    textAlign: "center",
    color: "#000000",
    fontSize: "17px",
    fontWeight: "bold",
  },
  mobileTel: {
    textAlign: "center",
    color: "#000000",
    fontSize: "13px",
    fontFamily: "Tahoma",
    fontWeight: "bold",
  },
  originalCopy: {
    // float:"right",
    textAlign: "right",
    color: "#000000",
    fontSize: "10px",
    // fontWeight: "bold",
    fontStyle: "italic",
  },
  verticalLine: {
    borderRight: "1px solid #000000",
  },
  invoiceDetailsHead: {
    fontWeight: "bold",
    textAlign: "center",
    color: "#000000",
    fontSize: "13px",
    // fontFamily: "Tahoma",
    fontStyle: "italic",
  },
  invoicedetailCom: {
    textAlign: "center",
    color: "#000000",
    fontSize: "13px",
    fontFamily: "Tahoma",
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  invoicedetail: {
    textAlign: "center",
    color: "#000000",
    fontSize: "13px",
    fontFamily: "Tahoma",
    textTransform: "capitalize",
  },
  gstIn: {
    fontWeight: "bold",
  },
  tableHead: {
    fontWeight: "bold",
    fontFamily: "Tahoma",
    color: "#000000",
    fontSize: "9.5px", //14
    width: "auto",
  },
  headRight: {
    float: "right !important",
  },
  headLeft: {
    float: "left !important",
  },
  listNumber: {
    fontWeight: "bold",
    fontFamily: "Tahoma",
    color: "#000000",
    fontSize: "8px", //7.5 //12
    transform: "scaleY(1.5)"
  },
  listNumbers: {
    fontWeight: "bold",
    fontFamily: "Tahoma",
    color: "#000000",
    fontSize: "10px", //7.5 //12
  },
  listDescriptions: {
    fontWeight: "bold",
    fontFamily: "Tahoma",
    color: "#000000",
    fontSize: "7px",
  },
  listDates: {
    fontWeight: "bold",
    fontFamily: "Tahoma",
    color: "#000000",
    fontSize: "8px",
  },
  listDescription: {
    // fontWeight: "bold",
    // fontFamily: "Taho8a",
    color: "#000000",
    fontSize: "10px",
  },
  sizePadding: {
    marginTop: "5px",
    // textAlign: "center",
    marginLeft: "0px",
  },
  sizeSpan: {
    marginRight: "5px",
    marginBottom: "5px",
  },
  listDescriptionMM: {
    fontWeight: "bold",
    fontFamily: "Tahoma",
    color: "#000000",
    fontSize: "8px", // 7.5
  },
  headRight: {
    float: "right !important",
  },
  listNumber: {
    fontWeight: "bold",
    fontFamily: "Tahoma",
    color: "#000000",
    fontSize: "8px", // 7.5
    transform: "scaleY(1.5)"
  },
  listDate: {
    fontWeight: "bold",
    fontFamily: "Tahoma",
    color: "#000000",
    fontSize: "10px",
  },
  subTotal: {
    marginLeft: "8%",
    fontWeight: "bold",
    fontFamily: "Tahoma",
    color: "#000000",
    fontSize: "12px",
  },
  advance: {
    marginLeft: "14%",
    fontWeight: "bold",
    fontFamily: "Tahoma",
    color: "#000000",
    fontSize: "12px",
  },
  boxTl: {
    marginLeft: "30%",
    fontWeight: "bold",
    fontFamily: "Tahoma",
    color: "#000000",
    fontSize: "12px",
  },
  subAmount: {
    marginLeft: "7%",
    fontWeight: "bold",
    fontFamily: "Tahoma",
    color: "#000000",
    fontSize: "12px",
  },
  termDescription: {
    fontWeight: "bold",
    fontFamily: "Tahoma",
    color: "#000000",
    fontSize: "13px",
  },
  OrderlistDescription: {
    fontWeight: "bold",
    fontFamily: "Tahoma",
    color: "#000000",
    fontSize: "12px",
  },
  brand: {
    marginTop: "30px",
    marginBottom: "15px",
  },
  container: {
    paddingRight: "15px",
    paddingLeft: "15px",
    marginRight: "auto",
    marginLeft: "auto",
    width: "100%",
    // zIndex: 2,
  },

  "@media (min-width: 576px)": {
    container: {
      maxWidth: "540px",
    },
  },
  "@media (min-width: 768px)": {
    container: {
      maxWidth: "720px",
    },
  },
  "@media (min-width: 992px)": {
    container: {
      maxWidth: "960px",
    },
  },
  "@media (min-width: 1200px)": {
    container: {
      maxWidth: "1140px",
    },
  },

  // "@media only screen and (max-width:320px)": {
  //     container: {
  //         maxWidth: "1108px",
  //         overflow: "scroll",
  //         position: "absolute"
  //     },
  //     condition: {
  //         minWidth: "11086px"
  //     }
  // },

  // "@media only screen and (max-width:411px)": {
  //     container: {
  //         width: "1108px",
  //         overflow: "scroll",
  //         position: "absolute"
  //     },
  //     condition: {
  //         minWidth: "1108px"
  //     }
  // },

  // "@media only screen and (max-width:414px)": {
  //     container: {
  //         width: "1108px",
  //         overflow: "scroll",
  //         position: "absolute"
  //     },
  //     condition: {
  //         minWidth: "1108px"
  //     }
  // },

  // "@media only screen and (max-width:540px)": {
  //     container: {
  //         width: "1108px",
  //         overflow: "scroll",
  //         position: "absolute"
  //     },
  //     condition: {
  //         minWidth: "1108px"
  //     }
  // },

  // "@media only screen and (max-width:240px)": {
  //     container: {
  //         width: "1108px",
  //         overflow: "scroll",
  //         position: "absolute"
  //     },
  //     condition: {
  //         minWidth: "1108px"
  //     }
  // },

  "@page": {
    size: "a3",
  },

  "@media print": {
    container: {
      overflow: "initial !important",
      height: "initial !important",
      position: "sticky",
      left: "0px",
      right: "0px",
      top: "0px",
      display: "block !important",
      pageBreakBefore: "always", // auto
      minWidth: "734px", //734
      margin: "none"
    },
    print: {
      display: "none",
    },
    selects: {
      display: "none",
    },
    // condition: {
    //     minWidth: "1108px"
    // }
  },

};

export default extendedTablesStyle;

import {
  cardTitle,
  grayColor,
} from "assets/jss/material-dashboard-pro-react.js";

const userProfileStyles = {
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    "& small": {
      fontSize: "80%",
      fontWeight: "400",
    },
  },
  cardCategory: {
    marginTop: "10px",
    color: grayColor[0] + " !important",
    textAlign: "center",
  },
  description: {
    color: grayColor[0],
  },
  sizePadding: {
    marginTop: "5px",
    // textAlign: "center",
    marginLeft: "0px",
  },
  sizePadding: {
    marginTop: "0px",
    // textAlign: "center",
    marginLeft: "0px",
  },
  textCenter: {
    textAlign: "center !important",
  },
  sizeSpan: {
    marginRight: "5px",
    marginBottom: "5px",
  },
  listDescriptionMM: {
    fontWeight: "bold",
    fontFamily: "Tahoma",
    color: "#000000",
    fontSize: "11px",
  },
  companyAlign: {
    textAlign: "center",
  },
  updateProfileButton: {
    float: "right",
  },
  select: {
    padding: "12px 0 7px",
    fontSize: ".75rem",
    fontWeight: "400",
    lineHeight: "1.42857",
    textDecoration: "none",
    // textTransform: "uppercase",
    color: grayColor[2],
    letterSpacing: "0",
    display: "block",
    "&:focus": {
      backgroundColor: "transparent",
    },
    "&[aria-owns] + input + svg": {
      transform: "rotate(180deg)",
    },
    "& + input + svg": {
      transition: "all 300ms linear",
    },
  },

  "@media only screen and (max-width:320px)": {
    container: {
      // clear: "both",
      display: "inline-block",
      // position: "sticky",
      overflowX: "scroll",
      // whiteSpace: "nowrap",
      // padding: "2px 10px 2px 10px",
    },
  },
};
export default userProfileStyles;

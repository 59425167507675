import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { localApi } from "utils/imgApi";
import { getStates, getCities } from "utils/util";
import PersonPinCircleIcon from "@material-ui/icons/PersonPinCircle";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import SweetAlert from "react-bootstrap-sweetalert";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import style from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styleCheck from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import styDrop from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

import { credentials } from "utils/imgApi";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(style);
const useStyleCheck = makeStyles(styleCheck);
const useStyl = makeStyles(styDrop);

export default function Address() {
  const [firstName, setFirstName] = React.useState("");
  const [firstNameErr, setFirstNameErr] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [lastNameErr, setLastNameErr] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [mobileErr, setMobileErr] = React.useState("");
  const [whatsApp, setWhatApp] = React.useState("");
  const [whatsAppErr, setWhatAppErr] = React.useState("");
  const [aadharNumber, setAadharNumber] = React.useState("");
  const [panNumber, setPanNumber] = React.useState("");
  const [gstIn, setGstIn] = React.useState("");
  const [gstInErr, setGstInErr] = React.useState("");
  const [company, setCompany] = React.useState("");
  const [aliasName, setAliasName] = React.useState("");
  const [plotHouseNo, setPlotHouseNo] = React.useState("");
  const [areaColony, setAreaColony] = React.useState("");
  const [landMark, setLandMark] = React.useState("");
  const [road, setRoad] = React.useState("");
  const [zipCode, setZipCode] = React.useState("");
  const [zipCodeErr, setZipCodeErr] = React.useState("");
  const [country, setCountry] = React.useState("India");
  const [active, setActive] = React.useState(false);
  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);

  const [countryCode, setCountryCode] = React.useState("in");
  const [stateSelect, setStateSelect] = React.useState("");
  const [stateSelectErr, setStateSelectErr] = React.useState("");
  const [citySelect, setCitySelect] = React.useState("");
  const [citySelectErr, setCitySelectErr] = React.useState("");
  const [stateCode, setStateCode] = React.useState("");

  const [simpleSelectType, setSimpleSelectType] = React.useState("");
  const [simpleSelectTypeErr, setSimpleSelectTypeErr] = React.useState("");
  const [btn, setBtn] = React.useState(false);

  const classes = useStyles();
  const classSweet = useStyle();
  const classCheck = useStyleCheck();
  const classe = useStyl();

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  const handleMobileNumber = (mobile) => {
    setMobile(mobile);
    setMobileErr("");
  };

  const handleState = (event) => {
    setStateSelect(event.target.value);
    setStateSelectErr("");
  };

  const handleCity = (event) => {
    setCitySelect(event.target.value);
    setCitySelectErr("");
  };

  const handleSimpleType = (event) => {
    setSimpleSelectType(event.target.value);
    setSimpleSelectTypeErr("");
  };

  const handleGST = (e) => {
    const { value } = e.target;
    setGstIn(value);
    setGstInErr("");

    fetch(
      `https://sheet.gstincheck.co.in/check/028ec5a297d1ae03ba935ac49586e228/${value}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          accept: "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((res) => {
        if (res.message === "GSTIN  found.") {
          setAreaColony(res.data.pradr.adr);
          setStateSelect(res.data.pradr.addr.stcd);
          setCitySelect(res.data.pradr.addr.dst);
        } else if (res.message === "Invalid GSTIN Number") {
          setGstInErr("Invalid GSTIN Number");
        } else if (res.message === "Credit Not Available") {
          setGstInErr("Not Available");
        }
        // console.log(res);
      });
  };

  const SubmitAddress = () => {
    let errorCount = 0;
    if (firstName === "" || !firstName) {
      errorCount++;
      setFirstNameErr("Please Enter First Name");
    }
    if (lastName === "" || !lastName) {
      errorCount++;
      setLastNameErr("Please Enter Last Name");
    }
    if (mobile === "" || !mobile) {
      errorCount++;
      setMobileErr("Please Enter Mobile Number");
    }
    if (whatsApp === "" || !whatsApp) {
      errorCount++;
      setWhatAppErr("Please Enter WhatsApp Number");
    }
    if (zipCode === "" || !zipCode) {
      errorCount++;
      setZipCodeErr("Please Enter Zip Code");
    }
    if (citySelect === "" || !citySelect) {
      errorCount++;
      setCitySelectErr("Please Select City Name");
    }
    if (stateSelect === "" || !stateSelect) {
      errorCount++;
      setStateSelectErr("Please Select State Name");
    }
    if (simpleSelectType === "" || !simpleSelectType) {
      errorCount++;
      setSimpleSelectTypeErr("Please Select Addredd Type");
    }
    // let regTest = /^([0-9]){2}([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}([0-9]){1}([a-zA-Z]){1}([0-9]){1}?$/;
    let regTest = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    if (!regTest.test(gstIn)) {
      errorCount++;
      setGstInErr("GST No. Not valid");
    }

    let gstVal = gstIn;
    let statecode = gstVal.substring(0, 2);
    let patternstatecode = /^[0-9]{2}$/;
    let threetoseven = gstVal.substring(2, 7);
    let patternthreetoseven = /^[A-Z]{5}$/;
    let seventoten = gstVal.substring(7, 11);
    let patternseventoten = /^[0-9]{4}$/;
    let Twelveth = gstVal.substring(11, 12);
    let patternTwelveth = /^[A-Z]{1}$/;
    let Thirteen = gstVal.substring(12, 13);
    let patternThirteen = /^[1-9A-Z]{1}$/;
    let fourteen = gstVal.substring(13, 14);
    let patternfourteen = /^Z$/;
    let fifteen = gstVal.substring(14, 15);
    let patternfifteen = /^[0-9A-Z]{1}$/;
    if (gstVal.length != 15) {
      setGstInErr(
        "Length should be restricted to 15 digits and should not allow anything more or less"
      );
    } else if (!patternstatecode.test(statecode)) {
      setGstInErr("First two characters of GSTIN should be Number");
    } else if (!patternthreetoseven.test(threetoseven)) {
      setGstInErr("Third to seventh characters of GSTIN should be Alphabets Capital Letter");
    } else if (!patternseventoten.test(seventoten)) {
      setGstInErr("Eighth to Eleventh characters of GSTIN should be Number");
    } else if (!patternTwelveth.test(Twelveth)) {
      setGstInErr("Twelveth character of GSTIN should be Alphabet Capital Letter");
    } else if (!patternThirteen.test(Thirteen)) {
      setGstInErr(
        "Thirteen characters of GSTIN can be either Alphabet Capital Letter or Numeric"
      );
    } else if (!patternfourteen.test(fourteen)) {
      setGstInErr("fourteen characters of GSTIN should be Alphabet Capital Letter");
    } else if (!patternfifteen.test(fifteen)) {
      setGstInErr(
        "fifteen characters of GSTIN can be either Alphabet Capital Letter or Numeric"
      );
    } else if (statecode < 1 || statecode > 37) {
      setGstInErr("Invalid First Two Characters of GSTIN");
    }
    if (errorCount === 0) {
      setBtn(true);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Basic ${credentials}`,
        },
        body: JSON.stringify({
          adminId: userObj.id,
          addressType: simpleSelectType,
          firstName: firstName,
          lastName: lastName,
          mobile: mobile,
          whatsApp: whatsApp,
          aadharNumber:
            simpleSelectType == "GSTIN ADDRESS" ||
            simpleSelectType == "DELIVERY ADDRESS"
              ? null
              : aadharNumber,
          panNumber: panNumber ? panNumber : null,
          gstIn: simpleSelectType == "AADHAR ADDRESS" ? null : gstIn,
          company: company ? company : null,
          aliasName: aliasName ? aliasName : null,
          plotHouseNo: plotHouseNo ? plotHouseNo : null,
          areaColony: areaColony ? areaColony : null,
          landMark: landMark ? landMark : null,
          road: road ? road : null,
          zipCode: zipCode,
          city: citySelect,
          state: stateSelect,
          country: country,
          // active: active,
        }),
      };

      fetch(localApi + "/adminAddress/add", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          if (data.message === "Data Inserted Successfully !!!") {
            alertTypeOps("success");
            alertMsgOps("Succesfully !!!");
            setSubmitted(true);
          }
        });
    }
  };

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  const mystyle = {
    paddingLeft: 12,
    flex: 1,
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <PersonPinCircleIcon />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Address</small>
              </h3>
            </CardHeader>
            <CardBody>
              <form>
                {submitted ? (
                  <SweetAlert
                    type={alertType}
                    style={{ display: "block", marginTop: "-100px" }}
                    title={alertMsg}
                    onConfirm={() => hideAlert()}
                    confirmBtnCssClass={
                      classSweet.button + " " + classSweet.success
                    }
                  >Added Address</SweetAlert>
                ) : null}
                <GridContainer justify="center">
                  <GridItem xs={12} sm={6} md={4}>
                    <FormControl fullWidth className={classe.selectFormControl}>
                      <InputLabel
                        htmlFor="simple-select"
                        className={classe.selectLabel}
                      >
                        Select Address Type...
                      </InputLabel>

                      <Select
                        MenuProps={{
                          className: classe.selectMenu,
                        }}
                        classes={{
                          select: classe.select,
                        }}
                        value={simpleSelectType}
                        onChange={handleSimpleType}
                        inputProps={{
                          name: "stateSelect",
                          id: "simple-select",
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classe.selectMenuItem,
                          }}
                        >
                          Select Address Type
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classe.selectMenuItem,
                            selected: classe.selectMenuItemSelected,
                          }}
                          value="AADHAR ADDRESS"
                        >
                          AADHAR ADDRESS
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classe.selectMenuItem,
                            selected: classe.selectMenuItemSelected,
                          }}
                          value="GSTIN ADDRESS"
                        >
                          GSTIN ADDRESS
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classe.selectMenuItem,
                            selected: classe.selectMenuItemSelected,
                          }}
                          value="DELIVERY ADDRESS"
                        >
                          DELIVERY ADDRESS
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classe.selectMenuItem,
                            selected: classe.selectMenuItemSelected,
                          }}
                          value="OTHER ADDRESS"
                        >
                          OTHER ADDRESS
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <span style={{ color: "red" }}>{simpleSelectTypeErr}</span>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={4}>
                    <label>Mobile Number</label>
                    <PhoneInput
                      style={mystyle}
                      inputStyle={{
                        width: "95%",
                        border: "none",
                        borderBottom: "1px solid #040000",
                        borderRadius: "0",
                      }}
                      country={"in"}
                      name="mobile"
                      value={mobile}
                      onChange={(mobile) => {
                        let country_code = document.body.querySelector(
                          "div.flag"
                        )?.className;
                        country_code =
                          country_code && country_code.replace("flag", "");
                        setCountryCode(country_code.trim());
                        // console.log(country_code);
                        handleMobileNumber(mobile);
                        let country_name = document.body.querySelector(
                          "div.selected-flag"
                        )?.title;
                        setCountry(country_name.slice(0, -6));
                      }}
                      // disabled={true}
                    />
                    <span style={{ color: "red", marginLeft: "12px" }}>
                      {mobileErr}
                    </span>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={4}>
                    <label>WhatsApp Number</label>
                    <PhoneInput
                      style={mystyle}
                      inputStyle={{
                        width: "95%",
                        border: "none",
                        borderBottom: "1px solid #040000 ",
                        borderRadius: "0",
                      }}
                      country={"in"}
                      name="whatsApp"
                      value={whatsApp}
                      onChange={(whatsApp) => {
                        setWhatApp(whatsApp);
                      }}
                      // required={true}
                    />
                    <span style={{ color: "red", marginLeft: "12px" }}>
                      {whatsAppErr}
                    </span>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={6} md={2}>
                    <CustomInput
                      labelText="First Name"
                      id="firstName"
                      inputProps={{
                        placeholder: "First Name ...",
                      }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      name="firstName"
                      value={firstName}
                      onChange={(e) => {
                        setFirstName(e.target.value);
                        setFirstNameErr("");
                      }}
                    />
                    <span style={{ color: "red", marginLeft: "12px" }}>
                      {firstNameErr}
                    </span>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={2}>
                    <CustomInput
                      labelText="Last Name"
                      id="lastName"
                      inputProps={{
                        placeholder: "Last Name ...",
                      }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      name="lastName"
                      value={lastName}
                      onChange={(e) => {
                        setLastName(e.target.value);
                        setLastNameErr("");
                      }}
                    />
                    <span style={{ color: "red", marginLeft: "12px" }}>
                      {lastNameErr}
                    </span>
                  </GridItem>
                  {simpleSelectType == "GSTIN ADDRESS" ||
                  simpleSelectType == "DELIVERY ADDRESS" ? (
                    ""
                  ) : (
                    <GridItem xs={12} sm={6} md={2}>
                      <CustomInput
                        labelText="Aadhar Number"
                        id="aadharNumber"
                        inputProps={{
                          placeholder: "Aadhar Number ...",
                        }}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        name="aadharNumber"
                        value={aadharNumber}
                        onChange={(e) => setAadharNumber(e.target.value)}
                      />
                    </GridItem>
                  )}
                  <GridItem xs={12} sm={6} md={2}>
                    <CustomInput
                      labelText="Pan Number"
                      id="panNumber"
                      inputProps={{
                        placeholder: "Pan Number ...",
                      }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      name="panNumber"
                      value={panNumber}
                      onChange={(e) => setPanNumber(e.target.value)}
                    />
                  </GridItem>
                  {simpleSelectType == "AADHAR ADDRESS" ? (
                    ""
                  ) : (
                    <GridItem xs={12} sm={6} md={2}>
                      <CustomInput
                        labelText="GSTIN Number"
                        id="gstIn"
                        inputProps={{
                          placeholder: "GSTIN Number ...",
                        }}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        name="gstIn"
                        value={gstIn}
                        onChange={handleGST}
                        // onChange={(e) => setGstIn(e.target.value)}
                      />
                      <span style={{ color: "red" }}>{gstInErr}</span>
                    </GridItem>
                  )}
                  <GridItem xs={12} sm={6} md={2}>
                    <CustomInput
                      labelText="Company"
                      id="company"
                      inputProps={{
                        placeholder: "Company ...",
                      }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      name="company"
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={2}>
                    <CustomInput
                      labelText="Alias Name"
                      id="aliasName"
                      inputProps={{
                        placeholder: "Alias Name ...",
                      }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      name="aliasName"
                      value={aliasName}
                      onChange={(e) => setAliasName(e.target.value)}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={2}>
                    <CustomInput
                      labelText="Plot House No."
                      id="plotHouseNo"
                      inputProps={{
                        placeholder: "Plot, House No., Building ...",
                      }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      name="plotHouseNo"
                      value={plotHouseNo}
                      onChange={(e) => setPlotHouseNo(e.target.value)}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={2}>
                    <CustomInput
                      labelText="Area Colony"
                      id="areaColony"
                      inputProps={{
                        placeholder: "Area Colony Street Vilage ...",
                      }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      name="areaColony"
                      value={areaColony}
                      onChange={(e) => {
                        setAreaColony(e.target.value);
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={2}>
                    <CustomInput
                      labelText="Land Mark"
                      id="landMark"
                      inputProps={{
                        placeholder: "LandMark e.g. Near In-Orbit Mall ...",
                      }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      name="landMark"
                      value={landMark}
                      onChange={(e) => setLandMark(e.target.value)}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={2}>
                    <CustomInput
                      labelText="Road"
                      id="road"
                      inputProps={{ placeholder: "Road ..." }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      name="road"
                      value={road}
                      onChange={(e) => setRoad(e.target.value)}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={2}>
                    <CustomInput
                      labelText="Zip Code"
                      id="zipCode"
                      inputProps={{ placeholder: "Zip Code ..." }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      name="zipCode"
                      type="number"
                      value={zipCode}
                      onChange={(e) => {
                        setZipCode(e.target.value);
                        setZipCodeErr("");
                      }}
                    />
                    <span style={{ color: "red", marginLeft: "12px" }}>
                      {zipCodeErr}
                    </span>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={2}>
                    <FormControl fullWidth className={classe.selectFormControl}>
                      <InputLabel
                        htmlFor="simple-select"
                        className={classe.selectLabel}
                      >
                        Select State...
                      </InputLabel>

                      <Select
                        MenuProps={{
                          className: classe.selectMenu,
                        }}
                        classes={{
                          select: classe.select,
                        }}
                        value={stateSelect}
                        onChange={handleState}
                        required={true}
                        inputProps={{
                          name: "stateSelect",
                          id: "simple-select",
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classe.selectMenuItem,
                          }}
                        >
                          Select State
                        </MenuItem>
                        {getStates(countryCode).map((item) => (
                          <MenuItem
                            key={item.name}
                            value={item.name}
                            onClick={() => {
                              setStateCode(item?.state_code);
                            }}
                            classes={{
                              root: classe.selectMenuItem,
                              selected: classe.selectMenuItemSelected,
                            }}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <span style={{ color: "red" }}>{stateSelectErr}</span>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={2}>
                    <FormControl fullWidth className={classe.selectFormControl}>
                      <InputLabel
                        htmlFor="simple-select"
                        className={classe.selectLabel}
                      >
                        Select City...
                      </InputLabel>

                      <Select
                        MenuProps={{
                          className: classe.selectMenu,
                        }}
                        classes={{
                          select: classe.select,
                        }}
                        value={citySelect}
                        onChange={handleCity}
                        required={true}
                        inputProps={{
                          name: "stateSelect",
                          id: "simple-select",
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classe.selectMenuItem,
                          }}
                        >
                          Select City
                        </MenuItem>
                        {getCities(countryCode, stateCode).map((item) => (
                          <MenuItem
                            key={item.name}
                            value={item.name}
                            classes={{
                              root: classe.selectMenuItem,
                              selected: classe.selectMenuItemSelected,
                            }}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <span style={{ color: "red" }}>{citySelectErr}</span>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={2}>
                    <CustomInput
                      labelText="Country"
                      id="country"
                      inputProps={{ placeholder: "Country ..." }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      name="country"
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={2}></GridItem>
                  {/* <FormControlLabel
                    classes={{
                      root: classCheck.checkboxLabelControl,
                      label: classCheck.checkboxLabel,
                    }}
                    control={
                      <Checkbox
                        id="active"
                        value={active}
                        // checked={active & (active === true) ? active : active}
                        onChange={(e) => {
                          setActive(e.target.checked);
                        }}
                        tabIndex={-1}
                        checkedIcon={
                          <Check className={classCheck.checkedIcon} />
                        }
                        icon={<Check className={classCheck.uncheckedIcon} />}
                        classes={{
                          checked: classCheck.checked,
                          root: classCheck.checkRoot,
                        }}
                      />
                    }
                    label={
                      <span style={{ color: "#9c27b0" }}>
                        I would like to recieve the latest news from the Company
                        by phone/whatsapp
                      </span>
                    }
                  /> */}
                </GridContainer>
                {/* <Button
                  // style={{ marginLeft: "110px" }}
                  color="rose"
                  onClick={SubmitAddress}
                  className={classes.updateProfileButton}
                >
                  Add Address
                </Button> */}
                {btn === true ? (
                  <Button className={classes.updateProfileButton}>
                    Add Address
                  </Button>
                ) : (
                  <Button
                    color="rose"
                    onClick={SubmitAddress}
                    className={classes.updateProfileButton}
                  >
                    Add Address
                  </Button>
                )}
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

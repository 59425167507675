import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { dataURLtoFile } from "utils/dataURLToFile";
import { headApi } from "utils/headApi";
import { imgApi } from "utils/imgApi";
import { localApi } from "utils/imgApi";
import { getStates, getCities } from "utils/util";
import { makeStyles } from "@material-ui/core/styles";
import ImageUpload from "components/CustomUpload/ImageUpload.js";
import { FaEdit } from "react-icons/fa";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardAvatar from "components/Card/CardAvatar.js";
import defaultAvatar from "assets/img/placeholder.jpg";
import defaultImage from "assets/img/image_placeholder.jpg";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import SweetAlert from "react-bootstrap-sweetalert";
import style from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import styleCheck from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import styDrop from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

import { credentials } from "utils/imgApi";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(styleCheck);
const useStyl = makeStyles(styDrop);
const useStylSweet = makeStyles(style);

export default function LogoAndSign(props) {
  const [signImg, setSignImg] = React.useState("");
  const [logoImg, setLogoImg] = React.useState("");
  const [firstname, setFristname] = React.useState("");
  const [lastname, setLastname] = React.useState("");

  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");
  const [submittedSign, setSubmittedSign] = React.useState(false);
  const [submittedDeleteSign, setSubmittedDeleteSign] = React.useState(false);
  const [submittedLogo, setSubmittedLogo] = React.useState(false);
  const [submittedDeleteLogo, setSubmittedDeleteLogo] = React.useState(false);

  const classes = useStyles();
  const classCheck = useStyle();
  const classe = useStyl();
  const classSweet = useStylSweet();

  let userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  const [file, setFile] = React.useState(null);
  const [fileLogo, setFileLogo] = React.useState(null);
  const [crop, setCrop] = React.useState({
    aspect: 1,
    unit: "px",
    width: 76, //280
    height: 76, //336
  });
  const [image, setImage] = React.useState("");
  const [result, setResult] = React.useState("");
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState(
    props.avatar ? defaultAvatar : defaultImage
  );
  const [imagePreviewUrlLogo, setImagePreviewUrlLogo] = React.useState(
    props.avatar ? defaultAvatar : defaultImage
  );
  let fileInput = React.createRef();
  let fileInputSign = React.createRef();
  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setFile(file);
      setImagePreviewUrl(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleImageChangeLogo = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setFileLogo(file);
      setImagePreviewUrlLogo(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const getCroppedImg = () => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    const base64Image = canvas.toDataURL("image/jpeg");
    setResult(base64Image);
  };

  const handleClick = () => {
    fileInput.current.click();
  };
  const handleClickLogo = () => {
    fileInputSign.current.click();
  };
  const handleRemove = () => {
    setFile(null);
    setImagePreviewUrl(props.avatar ? defaultAvatar : defaultImage);
    fileInput.current.value = null;
  };
  const handleRemoveLogo = () => {
    setFileLogo(null);
    setImagePreviewUrlLogo(props.avatar ? defaultAvatar : defaultImage);
    fileInputSign.current.value = null;
  };
  let { avatar, addButtonProps, changeButtonProps, removeButtonProps } = props;

  // eslint-disable-next-line
  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(file);
    const convertedUrlToBlob = dataURLtoFile(
      file ? result : null,
      `${file && file.name}`
    );
    // console.log(convertedUrlToBlob);
    let fData = new FormData();
    fData.append("signImage", convertedUrlToBlob || new File([""], ""));
    Axios.put(
      localApi + `/admin/update/signature/findById/${userObj.id}`,
      {
        headers: {
          Authorization: `Basic ${credentials}`,
        },
      },
      fData
    ).then((data) => {
      // console.log(data.data);
      if (data.data.message === "Data Updated Successfully !!!") {
        alertTypeOps("success");
        alertMsgOps("Succesfully !!!");
        setSubmittedSign(true);
      }
      // window.location.reload(false);
    });
    // file is the file/image uploaded
    // in this function you can save the image (file) on form submit
    // you have to call it yourself
  };

  const handleSubmitLogo = async (e) => {
    e.preventDefault();
    let fData = new FormData();
    fData.append("logoImage", fileLogo || new File([""], ""));
    Axios.put(
      localApi + `/admin/update/logo/findById/${userObj.id}`,
      {
        headers: {
          Authorization: `Basic ${credentials}`,
        },
      },
      fData
    ).then((data) => {
      // console.log(data.data);
      if (data.data.message === "Data Updated Successfully !!!") {
        alertTypeOps("success");
        alertMsgOps("Succesfully !!!");
        setSubmittedLogo(true);
      }
      // window.location.reload(false);
    });
  };

  async function getUsers() {
    const api = localApi + `/admin/byMobile/${userObj.mobile}`;
    const result = await fetch(api, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getResult = await result.json();
    // console.log(getResult);
    setFristname(getResult.firstname);
    setLastname(getResult.lastname);
    setSignImg(getResult.signatureUrl + "?id=" + getResult.id);
    setLogoImg(getResult.logoUrl + "?id=" + getResult.id);
  }

  useEffect(() => {
    getUsers();
  }, []);

  const SubmitDeleteLogo = () => {
    // setBtn(true);
    fetch(localApi + `/admin/update/deleteLogo/findById/${userObj.id}`, {
      method: "PUT",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        // console.log(res);
        if (res.message === "Data Updated Successfully !!!") {
          alertTypeOps("success");
          alertMsgOps("Succesfully !!!");
          setSubmittedDeleteLogo(true);
        }
      });
  };

  const SubmitDeleteSignature = () => {
    // setBtn(true);
    fetch(localApi + `/admin/update/deleteSignature/findById/${userObj.id}`, {
      method: "PUT",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        // console.log(res);
        if (res.message === "Data Updated Successfully !!!") {
          alertTypeOps("success");
          alertMsgOps("Succesfully !!!");
          setSubmittedDeleteSign(true);
        }
      });
  };

  const hideAlertSign = () => {
    setSubmittedSign(false);
    window.location.reload(false);
  };

  const hideAlertDeleteSign = () => {
    setSubmittedDeleteSign(false);
    window.location.reload(false);
  };

  const hideAlertLogo = () => {
    setSubmittedLogo(false);
    window.location.reload(false);
  };

  const hideAlertDeleteLogo = () => {
    setSubmittedDeleteLogo(false);
    window.location.reload(false);
  };

  const mystyle = {
    paddingLeft: 12,
    flex: 1,
  };

  return (
    <div>
      <GridContainer>
        {submittedSign ? (
          <SweetAlert
            type={alertType}
            style={{ display: "block", marginTop: "-100px" }}
            title={alertMsg}
            onConfirm={() => hideAlertSign()}
            confirmBtnCssClass={classSweet.button + " " + classSweet.success}
          >
            Updated Signature
          </SweetAlert>
        ) : null}
        {submittedDeleteSign ? (
          <SweetAlert
            type={alertType}
            style={{ display: "block", marginTop: "-100px" }}
            title={alertMsg}
            onConfirm={() => hideAlertDeleteSign()}
            confirmBtnCssClass={classSweet.button + " " + classSweet.success}
          >
            Delete Signature
          </SweetAlert>
        ) : null}
        {submittedLogo ? (
          <SweetAlert
            type={alertType}
            style={{ display: "block", marginTop: "-100px" }}
            title={alertMsg}
            onConfirm={() => hideAlertLogo()}
            confirmBtnCssClass={classSweet.button + " " + classSweet.success}
          >
            Updated Logo
          </SweetAlert>
        ) : null}
        {submittedDeleteLogo ? (
          <SweetAlert
            type={alertType}
            style={{ display: "block", marginTop: "-100px" }}
            title={alertMsg}
            onConfirm={() => hideAlertDeleteLogo()}
            confirmBtnCssClass={classSweet.button + " " + classSweet.success}
          >
            Delete Logo
          </SweetAlert>
        ) : null}
        <GridItem xs={12} sm={12} md={12}>
          <Card profile>
            <CardAvatar profile>
              <a href="#pablo" onClick={(e) => e.preventDefault()}>
                {/* <img src={image ? image : defaultAvatar} alt="..." /> */}
                <img src={signImg} alt="..." />
              </a>
            </CardAvatar>
            <CardBody profile>
              <h6 className={classes.cardCategory}>CEO / CO-FOUNDER</h6>
              <h4 className={classes.cardTitle}>
                {firstname + "  " + lastname}
              </h4>
              <p className={classes.description}>
                In this ever-changing society, the most powerful and enduring
                brands are built from the heart. They are real and sustainable.
                Their foundations are stronger because they are built with the
                strength of the human spirit. Don’t limit yourself. Many people
                limit themselves to what they think they can do. You can go as
                far as your mind lets you. What you believe, remember, you can
                achieve.
              </p>

              <legend>
                My Signature Image*
                <br /> Signature dimension Must be 76x76
              </legend>
              <div className="fileinput text-center">
                <input
                  type="file"
                  onChange={handleImageChange}
                  ref={fileInput}
                />

                {file && (
                  <div xs={12} sm={12} md={12}>
                    <ReactCrop
                      src={imagePreviewUrl}
                      onImageLoaded={setImage}
                      crop={crop}
                      onChange={setCrop}
                      minWidth={76 < 76 ? 76 : 76}
                      minHeight={76 < 76 ? 76 : 76}
                    />
                    <Button color="rose" round onClick={getCroppedImg}>
                      {" "}
                      Crop Image
                    </Button>
                  </div>
                )}

                <div className={"thumbnail" + (avatar ? " img-circle" : "")}>
                  {result && (
                    <img src={result} alt="..." style={{ height: "76px" }} />
                  )}
                </div>
                <div>
                  {file === null ? (
                    <Button
                      color="rose"
                      round
                      {...addButtonProps}
                      onClick={() => handleClick()}
                    >
                      {avatar ? "Select Sign" : "Select Sign"}
                    </Button>
                  ) : (
                    <span>
                      <Button
                        round
                        color="rose"
                        {...changeButtonProps}
                        onClick={() => handleClick()}
                      >
                        Change
                      </Button>
                      {avatar ? <br /> : null}
                      <Button
                        color="rose"
                        round
                        {...removeButtonProps}
                        onClick={() => handleRemove()}
                      >
                        <i className="fas fa-times" /> Remove
                      </Button>
                      {result ? (
                        <Button color="rose" round onClick={handleSubmit}>
                          upload Sign
                        </Button>
                      ) : (
                        ""
                      )}
                    </span>
                  )}
                  {file ? (
                    ""
                  ) : (
                    <Button color="rose" round onClick={SubmitDeleteSignature}>
                      Delete Sign
                    </Button>
                  )}
                </div>
              </div>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <Card profile>
            <CardAvatar profile>
              <a href="#pablo" onClick={(e) => e.preventDefault()}>
                <img src={logoImg} alt="..." />
              </a>
            </CardAvatar>
            <CardBody profile>
              <h6 className={classes.cardCategory}>CEO / CO-FOUNDER</h6>
              <h4 className={classes.cardTitle}>
                {firstname + "  " + lastname}
              </h4>
              <p className={classes.description}>
                In this ever-changing society, the most powerful and enduring
                brands are built from the heart. They are real and sustainable.
                Their foundations are stronger because they are built with the
                strength of the human spirit. Don’t limit yourself. Many people
                limit themselves to what they think they can do. You can go as
                far as your mind lets you. What you believe, remember, you can
                achieve.
              </p>

              <legend>
                My Logo Image* <br /> Logo dimension Must be 76x76
              </legend>
              <div className="fileinput text-center">
                <input
                  type="file"
                  onChange={handleImageChangeLogo}
                  ref={fileInputSign}
                />
                {fileLogo && (
                  <div className={"thumbnail" + (avatar ? " img-circle" : "")}>
                    <img src={imagePreviewUrlLogo} alt="..." />
                  </div>
                )}

                <div>
                  {fileLogo === null ? (
                    <Button
                      color="rose"
                      round
                      {...addButtonProps}
                      onClick={() => handleClickLogo()}
                    >
                      {avatar ? "Select Logo" : "Select Logo"}
                    </Button>
                  ) : (
                    <span>
                      <Button
                        color="rose"
                        round
                        {...changeButtonProps}
                        onClick={() => handleClickLogo()}
                      >
                        Change
                      </Button>
                      {avatar ? <br /> : null}
                      <Button
                        color="rose"
                        round
                        {...removeButtonProps}
                        onClick={() => handleRemoveLogo()}
                      >
                        <i className="fas fa-times" /> Remove
                      </Button>
                      <Button color="rose" round onClick={handleSubmitLogo}>
                        upload Logo
                      </Button>
                    </span>
                  )}
                  {fileLogo ? (
                    ""
                  ) : (
                    <Button color="rose" round onClick={SubmitDeleteLogo}>
                      Delete Logo
                    </Button>
                  )}
                </div>
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

LogoAndSign.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
};

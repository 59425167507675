import React, { useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { localApi, catApi } from "utils/imgApi";
import { BiFile } from "react-icons/bi";
import { makeStyles } from "@material-ui/core/styles";
import Selects from "react-select";
import InputLabel from "@material-ui/core/InputLabel";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import AddIcon from "@material-ui/icons/Add";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import { FaBox, FaRing, FaUsers } from "react-icons/fa";
import { GiWireCoil, GiBoxUnpacking } from "react-icons/gi";
import { BsImages } from "react-icons/bs";
import { AiOutlineCalculator } from "react-icons/ai";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import styRadio from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import styDrop from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import styCheckbox from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import styleSweet from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import { credentials } from "utils/imgApi";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(styRadio);
const useStyl = makeStyles(styDrop);
const useSty = makeStyles(styCheckbox);
const useStyleSweet = makeStyles(styleSweet);

export default function AddStockProduct(props) {
  const [itemProd, setItemProd] = React.useState([]);
  const [imgList, setImgList] = React.useState("");
  const [simpleSelectProd, setSimpleSelectProd] = React.useState("");
  const [prdId, setPrdID] = React.useState("");
  const [prdetails, setPrDetails] = React.useState("");

  const [submitted, setSubmitted] = React.useState(false);
  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");

  const [size1x8, setSize1x8] = React.useState(0);
  const [size1x9, setSize1x9] = React.useState(0);
  const [size1x10, setSize1x10] = React.useState(0);
  const [size1x11, setSize1x11] = React.useState(0);
  const [size1x12, setSize1x12] = React.useState(0);
  const [size1x13, setSize1x13] = React.useState(0);
  const [size1x14, setSize1x14] = React.useState(0);
  const [size1x15, setSize1x15] = React.useState(0);
  const [size2x0, setSize2x0] = React.useState(0);
  const [size2x1, setSize2x1] = React.useState(0);
  const [size2x2, setSize2x2] = React.useState(0);
  const [size2x3, setSize2x3] = React.useState(0);
  const [size2x4, setSize2x4] = React.useState(0);
  const [size2x5, setSize2x5] = React.useState(0);
  const [size2x6, setSize2x6] = React.useState(0);
  const [size2x7, setSize2x7] = React.useState(0);
  const [size2x8, setSize2x8] = React.useState(0);
  const [size2x9, setSize2x9] = React.useState(0);
  const [size2x10, setSize2x10] = React.useState(0);
  const [size2x11, setSize2x11] = React.useState(0);
  const [size2x12, setSize2x12] = React.useState(0);
  const [size2x13, setSize2x13] = React.useState(0);
  const [size2x14, setSize2x14] = React.useState(0);
  const [size2x15, setSize2x15] = React.useState(0);
  const [size3x0, setSize3x0] = React.useState(0);
  const [size3x1, setSize3x1] = React.useState(0);
  const [checkedCutSize, setCheckedCutSize] = React.useState(false);
  const [checkedFullSize, setCheckedFullSize] = React.useState(false);
  const [btn, setBtn] = React.useState(false);

  const classes = useStyles();
  const classesRadio = useStyle();
  const classe = useStyl();
  const classCheck = useSty();
  const classSweet = useStyleSweet();

  let { avatar } = props;

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  const totalPiece = "18";
  //   Math.ceil("864" / sets.pcs / box.box);

  async function getUsers() {
    const api = localApi + `/product/shortList/byAdminId/${userObj.id}`;
    const result = await fetch(api, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getResult = await result.json();
    setItemProd(getResult && getResult);
    // console.log(getResult);
  }

  useEffect(() => {
    getUsers();
  }, []);

  const handleSimpleProd = async (simpleSelectProd) => {
    setSimpleSelectProd(simpleSelectProd);
    if (simpleSelectProd.value) {
      let newSpli = simpleSelectProd.value.split("-");
      // console.log(newSpli[0]);
      const itemz = itemProd.find((f) => f.id == newSpli[0]);
      setPrdID(itemz.id);
      setPrDetails(itemz);

      setImgList(itemz.productimage);
    }
  };

  // const handleSimpleProd = async (event) => {
  //   // setSimpleSelect(event.target.value);
  //   const { value } = event.target;
  //   setSimpleSelectProd(value);
  //   const itemz = itemProd.find((item) => item.id == value);
  //   // console.log(itemz);
  //   setPrdID(itemz.id);

  //   setImgList(itemz.productImage);
  // };

  var fullSize =
    parseInt(size1x8) +
    parseInt(size1x10) +
    parseInt(size1x12) +
    parseInt(size1x14) +
    parseInt(size2x0) +
    parseInt(size2x2) +
    parseInt(size2x4) +
    parseInt(size2x6) +
    parseInt(size2x8) +
    parseInt(size2x10) +
    parseInt(size2x12) +
    parseInt(size2x14) +
    parseInt(size3x0);
  var cutSize =
    parseInt(size1x9) +
    parseInt(size1x11) +
    parseInt(size1x13) +
    parseInt(size1x15) +
    parseInt(size2x1) +
    parseInt(size2x3) +
    parseInt(size2x5) +
    parseInt(size2x7) +
    parseInt(size2x9) +
    parseInt(size2x11) +
    parseInt(size2x13) +
    parseInt(size2x15) +
    parseInt(size3x1);

  const SubmitAddStockProduct = () => {
    setBtn(true);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Basic ${credentials}`,
      },
      body: JSON.stringify({
        adminId: userObj.id,
        productId: prdId,
        stockCategoryId: prdetails.category.id,
        stockParentId: prdetails.parent.id,
        cutOrFullSize:
          checkedFullSize === true
            ? checkedCutSize === true
              ? "3"
              : checkedFullSize === true
              ? "1"
              : null
            : checkedCutSize === true
            ? "2"
            : null,
        s1x8: size1x8 ? size1x8 : 0,
        s1x9: size1x9 ? size1x9 : 0,
        s1x10: size1x10 ? size1x10 : 0,
        s1x11: size1x11 ? size1x11 : 0,
        s1x12: size1x12 ? size1x12 : 0,
        s1x13: size1x13 ? size1x13 : 0,
        s1x14: size1x14 ? size1x14 : 0,
        s1x15: size1x15 ? size1x15 : 0,
        s2x0: size2x0 ? size2x0 : 0,
        s2x1: size2x1 ? size2x1 : 0,
        s2x2: size2x2 ? size2x2 : 0,
        s2x3: size2x3 ? size2x3 : 0,
        s2x4: size2x4 ? size2x4 : 0,
        s2x5: size2x5 ? size2x5 : 0,
        s2x6: size2x6 ? size2x6 : 0,
        s2x7: size2x7 ? size2x7 : 0,
        s2x8: size2x8 ? size2x8 : 0,
        s2x9: size2x9 ? size2x9 : 0,
        s2x10: size2x10 ? size2x10 : 0,
        s2x11: size2x11 ? size2x11 : 0,
        s2x12: size2x12 ? size2x12 : 0,
        s2x13: size2x13 ? size2x13 : 0,
        s2x14: size2x14 ? size2x14 : 0,
        s2x15: size2x15 ? size2x15 : 0,
        s3x0: size3x0 ? size3x0 : 0,
        s3x1: size3x1 ? size3x1 : 0,
      }),
    };

    fetch(localApi + `/stockSize/add`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        if (data.message === "Data Inserted Successfully") {
          alertTypeOps("success");
          alertMsgOps("Succesfully !!!");
          setSubmitted(true);
        } else if (data.message === "Already Exist") {
          alertTypeOps("danger");
          alertMsgOps("Already Exits");
          setSubmitted(true);
        } else if (data.message === "Select Size") {
          alertTypeOps("danger");
          alertMsgOps("Select Product Size");
          setSubmitted(true);
        }
      });
  };

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  const select = {
    width: "100%",
    maxWidth: 600,
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      padding: 10,
    }),
    menu: (state) => ({
      color: "#d81b60",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  return (
    <div>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <AddIcon />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Add Stock Product</small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer justify="center">
                {submitted ? (
                  <SweetAlert
                    type={alertType}
                    style={{ display: "block", marginTop: "-100px" }}
                    title={alertMsg}
                    onConfirm={() => hideAlert()}
                    confirmBtnCssClass={
                      classSweet.button + " " + classSweet.success
                    }
                  >
                    Added Stock product
                  </SweetAlert>
                ) : null}
                <GridItem xs={12} sm={12} md={12}>
                  <label className={classe.selectLabel}>
                    Select Product Id
                  </label>
                  <Selects
                    styles={customStyles}
                    style={select}
                    required={true}
                    placeholder="Select Product Id..."
                    value={simpleSelectProd}
                    name="simpleSelectProd"
                    onChange={handleSimpleProd}
                    options={
                      itemProd &&
                      itemProd.map((c) => {
                        return {
                          label:
                            c.id +
                            " - " +
                            c.category.title +
                            " - " +
                            c.parent.title,
                          value:
                            c.id +
                            " - " +
                            c.category.title +
                            " - " +
                            c.parent.title,
                        };
                      })
                    }
                  />
                </GridItem>
                {/* <GridItem xs={12} sm={12} md={12}>
                  <FormControl fullWidth className={classe.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classe.selectLabel}
                    >
                      Select Product Id...
                    </InputLabel>

                    <Select
                      MenuProps={{
                        className: classe.selectMenu,
                      }}
                      classes={{
                        select: classe.select,
                      }}
                      value={simpleSelectProd}
                      onChange={handleSimpleProd}
                      inputProps={{
                        name: "simpleSelect",
                        id: "simple-select",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classe.selectMenuItem,
                        }}
                      >
                        Select Product Id...
                      </MenuItem>
                      {itemProd &&
                        itemProd.map((item) => (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                            classes={{
                              root: classe.selectMenuItem,
                              selected: classe.selectMenuItemSelected,
                            }}
                          >
                            {item.id}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>{" "} */}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <BiFile />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Product Size</small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={checkedFullSize}
                          onChange={(event) =>
                            setCheckedFullSize(event.target.checked)
                          }
                          value="checkedFullSize"
                          classes={{
                            switchBase: classe.switchBase,
                            checked: classes.switchChecked,
                            thumb: classe.switchIcon,
                            track: classe.switchBar,
                          }}
                        />
                      }
                      classes={{
                        label: classe.label,
                      }}
                      label={
                        checkedFullSize === false ? (
                          <span>Full Size Disable</span>
                        ) : (
                          <span
                            style={{
                              color: checkedFullSize === true ? "#d81b60" : "",
                            }}
                          >
                            Full Size Enable
                          </span>
                        )
                      }
                    />
                  </GridItem>
                  {checkedFullSize === true ? (
                    // <Card>
                    <GridContainer>
                      <GridItem xs={4} sm={4} md={4}>
                        <CustomInput
                          labelText="1x8"
                          id="1x8"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size1x8}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize1x8(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <CustomInput
                          labelText="1x10"
                          id="1x10"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size1x10}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize1x10(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>

                      <GridItem xs={4} sm={4} md={4}>
                        <CustomInput
                          labelText="1x12"
                          id="1x12"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size1x12}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize1x12(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>

                      <GridItem xs={4} sm={4} md={4}>
                        <CustomInput
                          labelText="1x14"
                          id="1x14"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size1x14}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize1x14(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>

                      <GridItem xs={4} sm={4} md={4}>
                        <CustomInput
                          labelText="2x0"
                          id="2x0"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size2x0}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize2x0(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>

                      <GridItem xs={4} sm={4} md={4}>
                        <CustomInput
                          labelText="2x2"
                          id="2x2"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size2x2}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize2x2(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <CustomInput
                          labelText="2x4"
                          id="2x4"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size2x4}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize2x4(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <CustomInput
                          labelText="2x6"
                          id="2x6"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size2x6}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize2x6(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <CustomInput
                          labelText="2x8"
                          id="2x8"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size2x8}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize2x8(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={3} sm={3} md={3}>
                        <CustomInput
                          labelText="2x10"
                          id="2x10"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size2x10}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize2x10(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={3} sm={3} md={3}>
                        <CustomInput
                          labelText="2x12"
                          id="2x12"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size2x12}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize2x12(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={3} sm={3} md={3}>
                        <CustomInput
                          labelText="2x14"
                          id="2x14"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size2x14}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize2x14(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={3} sm={3} md={3}>
                        <CustomInput
                          labelText="3x0"
                          id="3x0"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size3x0}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize3x0(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <h4>Full Size Total {fullSize}</h4>
                      </GridItem>
                    </GridContainer>
                  ) : (
                    // </Card>
                    ""
                  )}
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={checkedCutSize}
                          onChange={(event) =>
                            setCheckedCutSize(event.target.checked)
                          }
                          value="checkedCutSize"
                          classes={{
                            switchBase: classe.switchBase,
                            checked: classes.switchChecked,
                            thumb: classe.switchIcon,
                            track: classe.switchBar,
                          }}
                        />
                      }
                      classes={{
                        label: classe.label,
                      }}
                      label={
                        checkedCutSize === false ? (
                          <span>Cut Size Disable</span>
                        ) : (
                          <span
                            style={{
                              color: checkedCutSize === true ? "#d81b60" : "",
                            }}
                          >
                            Cut Size Enable
                          </span>
                        )
                      }
                    />
                  </GridItem>
                  {checkedCutSize === true ? (
                    <GridContainer>
                      <GridItem xs={4} sm={4} md={4}>
                        <CustomInput
                          labelText="1x9"
                          id="1x9"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          value={size1x9}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize1x9(value);
                          }}
                        />
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <CustomInput
                          labelText="1x11"
                          id="1x11"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size1x11}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize1x11(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <CustomInput
                          labelText="1x13"
                          id="1x13"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size1x13}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize1x13(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <CustomInput
                          labelText="1x15"
                          id="1x15"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size1x15}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize1x15(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <CustomInput
                          labelText="2x1"
                          id="2x1"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size2x1}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize2x1(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <CustomInput
                          labelText="2x3"
                          id="2x3"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size2x3}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize2x3(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <CustomInput
                          labelText="2x5"
                          id="2x5"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size2x5}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize2x5(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <CustomInput
                          labelText="2x7"
                          id="2x7"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size2x7}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize2x7(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <CustomInput
                          labelText="2x9"
                          id="2x9"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size2x9}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize2x9(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={3} sm={3} md={3}>
                        <CustomInput
                          labelText="2x11"
                          id="2x11"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size2x11}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize2x11(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={3} sm={3} md={3}>
                        <CustomInput
                          labelText="2x13"
                          id="2x13"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size2x13}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize2x13(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={3} sm={3} md={3}>
                        <CustomInput
                          labelText="2x15"
                          id="2x15"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size2x15}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize2x15(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={3} sm={3} md={3}>
                        <CustomInput
                          labelText="3x1"
                          id="3x1"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size3x1}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize3x1(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <h4>Cut Size Total {cutSize}</h4>
                      </GridItem>
                    </GridContainer>
                  ) : (
                    ""
                  )}
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h4>Full & Cut Size Total {fullSize + cutSize}</h4>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <BsImages />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Select Design Images</small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {imgList &&
                  imgList.map((i) => (
                    <GridItem xs={3} sm={2} md={2} key={i.id}>
                      <div className="fileinput text-center">
                        <div
                          className={
                            "thumbnail" + (avatar ? " img-circle" : "")
                          }
                        >
                          <img
                            src={i.cropImageUrl + "?id=" + i.imgId}
                            alt="..."
                          />
                        </div>
                      </div>
                    </GridItem>
                  ))}
              </GridContainer>
              {/* <Button
                color="rose"
                className={classes.updateProfileButton}
                onClick={SubmitAddStockProduct}
              >
                Save
              </Button> */}
              {btn === true ? (
                <Button className={classes.updateProfileButton}>Save</Button>
              ) : (
                <Button
                  color="rose"
                  onClick={SubmitAddStockProduct}
                  className={classes.updateProfileButton}
                >
                  Save
                </Button>
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

AddStockProduct.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
};

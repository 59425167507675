import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import PersonPinCircleIcon from "@material-ui/icons/PersonPinCircle";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { headApi } from "utils/headApi";
import { imgApi } from "utils/imgApi";
import axios from "axios";
import Selects from "react-select";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import SweetAlert from "react-bootstrap-sweetalert";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import style from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styl from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import styRadio from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import { credentials } from "utils/imgApi";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(style);
const useStyl = makeStyles(styl);
const useStyless = makeStyles(styRadio);

export default function UpdateShippingAddress() {
  const [user, setUser] = React.useState([]);
  const [items, setItems] = React.useState([]);
  const [simpleSelect, setSimpleSelect] = React.useState("");
  const [updateAdd, setUpdateAdd] = React.useState([]);
  const [checkOldAddress, setCheckOldAddress] = React.useState(false);
  const [shipadd, setShipadd] = React.useState("");
  const [shipadd1, setShipadd1] = React.useState("");
  const [shipadd2, setShipadd2] = React.useState("");
  const [shipadd3, setShipadd3] = React.useState("");
  const [shipzipcodes, setShipzipcodes] = React.useState("");
  const [shipcitys, setShipcitys] = React.useState("");
  const [shipstates, setShipstates] = React.useState("");
  const [shipcompanys, setShipCompanys] = React.useState("");
  const [countryNames, setCountryNames] = React.useState("");
  const [upId, setUpId] = React.useState("");
  const [firstNameU, setFirstNameU] = React.useState("");
  const [lastNameU, setLastNameU] = React.useState("");
  const [mobileNumberU, setMobileNumberU] = React.useState("");
  const [whatsAppNumberU, setWhatsAppNumberU] = React.useState("");
  const [alternateNumberU, setAlternateNumberU] = React.useState("");
  const [aadharNumberU, setAadharNumberU] = React.useState("");
  const [officeNumberU, setOfficeNumberU] = React.useState("");
  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);
  const [btn, setBtn] = React.useState(false);

  const classes = useStyles();
  const classe = useStyle();
  const classD = useStyl();
  const classesRadio = useStyless();

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  async function getUsers() {
    const api = headApi + `/user/clientlist?referenceid=1&type=client`;
    const results = await fetch(api, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getResults = await results.json();
    setItems(getResults.user);
    // console.log(getResults.user);
  }

  useEffect(() => {
    getUsers();
  }, []);

  // const handleSimple = async (event) => {
  //   // setSimpleSelect(event.target.value);
  //   const { value } = event.target;
  //   const item = items.find((item) => item.id == value);
  //   setSimpleSelect(value);
  //   // const api = headApi + `/user/${item.mobile}`;
  //   // const results = await fetch(api);
  //   // const getResults = await results.json();
  //   axios.get(headApi + `/user/shipAddress/${item.id}`)
  //     .then((res) => {
  //       setUpdateAdd(res.data.user);
  //     })

  // }

  const handleBoots = async (simpleSelect) => {
    if (simpleSelect.value) {
      let newSpli = simpleSelect.value.split("-");
      const ids = items.find((f) => f.company === newSpli[0]);
      // console.log(ids);
      axios.get(headApi + `/user/shipAddress/${ids.id}`, {
        headers: {
          Authorization: `Basic ${credentials}`,
        },
      }).then((res) => {
        setUpdateAdd(res.data.user);
      });
    }
    setSimpleSelect(simpleSelect);
  };

  const select = {
    width: "100%",
    maxWidth: 600,
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      padding: 10,
    }),
    menu: (state) => ({
      color: "#d81b60",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  const handleChange = (u) => {
    setCheckOldAddress(u);
    const getAdd = updateAdd.find((f) => f.id === u.id);
    setUpId(getAdd.id);
    setShipadd(getAdd.address);
    setShipadd1(getAdd.address1);
    setShipadd2(getAdd.address2);
    setShipadd3(getAdd.address3);
    setShipzipcodes(getAdd.zipcode);
    setShipcitys(getAdd.city);
    setShipstates(getAdd.state);
    setShipCompanys(getAdd.company);
    setCountryNames(getAdd.country);
    setFirstNameU(getAdd.firstname);
    setLastNameU(getAdd.lastname);
    setMobileNumberU(getAdd.mobile);
    setWhatsAppNumberU(getAdd.whatsapp);
    setAlternateNumberU(getAdd.alternate);
    setAadharNumberU(getAdd.aadharno);
    setOfficeNumberU(getAdd.office);
  };

  const shipUpdate = () => {
    setBtn(true);
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Basic ${credentials}`,
      },
      body: JSON.stringify({
        id: upId,
        company: shipcompanys,
        address: shipadd,
        address1: shipadd1,
        address2: shipadd2,
        address3: shipadd3,
        zipcode: shipzipcodes,
        city: shipcitys,
        state: shipstates,
        country: countryNames,
        firstname: firstNameU,
        lastname: lastNameU,
        mobile: mobileNumberU,
        whatsapp: whatsAppNumberU,
        alternate: alternateNumberU,
        aadharno: aadharNumberU,
        office: officeNumberU,
      }),
    };

    fetch(headApi + "/user/shipAddress", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        if (data.message === "Address Updated Successfully") {
          alertTypeOps("success");
          alertMsgOps("Address Added Successfully");
          setSubmitted(true);
        }
      });
  };

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  useEffect(() => {
    // setUpId("");
    // setShipadd("");
    // setShipadd1("");
    // setShipadd2("");
    // setShipadd3("");
    // setShipzipcodes("");
    // setShipcitys("");
    // setShipstates("");
    // setShipCompanys("");
    // setCountryNames("");
    // setFirstNameU("");
    // setLastNameU("");
    // setMobileNumberU("");
    // setWhatsAppNumberU("");
    // setAlternateNumberU("");
    // setAadharNumberU("");
    // setOfficeNumberU("");
    setCheckOldAddress("");
  }, [simpleSelect]);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <PersonPinCircleIcon />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Update Shipping Address</small>
              </h3>
            </CardHeader>
            <CardBody>
              <form>
                {submitted ? (
                  <SweetAlert
                    type={alertType}
                    style={{ display: "block", marginTop: "-100px" }}
                    title={alertMsg}
                    onConfirm={() => hideAlert()}
                    confirmBtnCssClass={classe.button + " " + classe.success}
                  />
                ) : null}
                <GridContainer>
                  {/* <GridItem xs={12} sm={12} md={6}>
                    <FormControl fullWidth className={classD.selectFormControl}>
                      <InputLabel
                        htmlFor="simple-select"
                        className={classD.selectLabel}
                      >
                        Select Client
                      </InputLabel>
                      <Select
                        MenuProps={{
                          className: classD.selectMenu,
                        }}
                        classes={{
                          select: classD.select,
                        }}
                        value={simpleSelect}
                        onChange={handleSimple}
                        inputProps={{
                          name: "simpleSelect",
                          id: "simple-select",
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classD.selectMenuItem,
                          }}
                        >
                          Select Client
                        </MenuItem>
                        {items && items.map((item) => (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                            classes={{
                              root: classD.selectMenuItem,
                              selected: classD.selectMenuItemSelected,
                            }}
                          >
                            {item.company}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </GridItem> */}
                  <GridItem xs={12} sm={12} md={6}>
                    <label className={classe.selectLabel}>Select Client</label>
                    <Selects
                      styles={customStyles}
                      style={select}
                      required={true}
                      placeholder="Select Client..."
                      value={simpleSelect}
                      name="simpleSelect"
                      onChange={handleBoots}
                      options={
                        items &&
                        items.map((c) => {
                          return {
                            label:
                              c && c.company
                                ? c.company + "- " + c.mobile
                                : c.firstname +
                                  " " +
                                  c.lastname +
                                  " " +
                                  c.mobile,
                            value:
                              c && c.company
                                ? c.company + "- " + c.mobile
                                : c.firstname +
                                  " " +
                                  c.lastname +
                                  " " +
                                  c.mobile,
                          };
                        })
                      }
                    />
                    {/* <span style={{ color: "red" }}>{simpleSelectErr}</span> */}
                  </GridItem>
                </GridContainer>
              </form>
            </CardBody>
          </Card>
        </GridItem>

        {updateAdd === undefined ? (
          ""
        ) : simpleSelect ? (
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardBody>
                <form>
                  <GridContainer>
                    {updateAdd &&
                      updateAdd.map((u) => (
                        <GridItem xs={12} sm={6} md={4} key={u.id}>
                          <FormControlLabel
                            key={u.id}
                            control={
                              <Radio
                                checked={
                                  checkOldAddress && checkOldAddress.id
                                    ? checkOldAddress?.id === u?.id
                                    : ""
                                }
                                onChange={() => handleChange(u)}
                                value={u.id}
                                name="radio button enabled"
                                aria-label="A"
                                icon={
                                  <FiberManualRecord
                                    className={classesRadio.radioUnchecked}
                                  />
                                }
                                checkedIcon={
                                  <FiberManualRecord
                                    className={classesRadio.radioChecked}
                                  />
                                }
                                classes={{
                                  checked: classesRadio.radio,
                                  root: classesRadio.radioRoot,
                                }}
                              />
                            }
                            classes={{
                              label: classesRadio.label,
                              root: classesRadio.labelRoot,
                            }}
                            label={
                              <h6 className={classes.description}>
                                <span className={classes.title}>
                                  Shipping Address
                                </span>{" "}
                                <br />{" "}
                                {u.address +
                                  "," +
                                  u.address1 +
                                  "," +
                                  u.address2 +
                                  "," +
                                  u.address3 +
                                  "," +
                                  u.zipcode +
                                  "," +
                                  u.city +
                                  "," +
                                  u.state +
                                  "," +
                                  u.country}
                              </h6>
                            }
                          />
                        </GridItem>
                      ))}
                  </GridContainer>
                </form>
              </CardBody>
            </Card>
          </GridItem>
        ) : (
          ""
        )}

        {/* {simpleSelect ?
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardBody>
                <form>
                  <GridContainer>
                    {updateAdd && updateAdd.map((u) => (
                      <GridItem xs={12} sm={6} md={4} key={u.id}>
                        <FormControlLabel
                          key={u.id}
                          control={
                            <Radio
                              checked={checkOldAddress && checkOldAddress.id ? checkOldAddress?.id === u?.id : ""}
                              onChange={() => handleChange(u)}
                              value={u.id}
                              name="radio button enabled"
                              aria-label="A"
                              icon={
                                <FiberManualRecord className={classesRadio.radioUnchecked} />
                              }
                              checkedIcon={
                                <FiberManualRecord className={classesRadio.radioChecked} />
                              }
                              classes={{
                                checked: classesRadio.radio,
                                root: classesRadio.radioRoot,
                              }}
                            />
                          }
                          classes={{
                            label: classesRadio.label,
                            root: classesRadio.labelRoot,
                          }}
                          label={<h6 className={classes.description}><span className={classes.title}>Shipping Address</span> <br /> {u.address + "," + u.address1 + "," + u.address2 + "," + u.address3 + "," + u.zipcode + "," + u.city + "," + u.state + "," + u.country}</h6>}
                        />
                      </GridItem>
                    ))}
                  </GridContainer>
                </form>
              </CardBody>
            </Card>
          </GridItem> : ""} */}

        {checkOldAddress ? (
          <>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="rose">
                    <LocalShippingIcon />
                  </CardIcon>
                  <h3 className={classes.cardIconTitle}>
                    <small>Shipping Address</small>
                  </h3>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    {" "}
                    <GridItem xs={12} sm={12} md={5}>
                      <CustomInput
                        labelText="Company Name"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          placeholder: "Company Name...",
                        }}
                        id="shipcompanys"
                        name="shipcompanys"
                        value={shipcompanys}
                        onChange={(e) => {
                          setShipCompanys(e.target.value);
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={5}>
                      <CustomInput
                        labelText="First Name"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          placeholder: "First Name...",
                        }}
                        id="firstNameU"
                        onChange={(e) => {
                          setFirstNameU(e.target.value);
                        }}
                        name="firstNameU"
                        value={firstNameU}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={5}>
                      <CustomInput
                        labelText="Last Name"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          placeholder: "Last Name...",
                        }}
                        id="lastNameU"
                        onChange={(e) => {
                          setLastNameU(e.target.value);
                        }}
                        name="lastNameU"
                        value={lastNameU}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={5}>
                      <CustomInput
                        labelText="Mobile Number"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          placeholder: "Mobile Number...",
                        }}
                        id="mobileNumberU"
                        onChange={(e) => {
                          setMobileNumberU(e.target.value);
                        }}
                        name="mobileNumberU"
                        value={mobileNumberU}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={5}>
                      <CustomInput
                        labelText="WhatsApp Number"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          placeholder: "WhatsApp Number...",
                        }}
                        id="whatsAppNumberU"
                        onChange={(e) => {
                          setWhatsAppNumberU(e.target.value);
                        }}
                        name="whatsAppNumberU"
                        value={whatsAppNumberU}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={5}>
                      <CustomInput
                        labelText="Alternate Number"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          placeholder: "Alternate Number...",
                        }}
                        id="alternateNumberU"
                        onChange={(e) => {
                          setAlternateNumberU(e.target.value);
                        }}
                        name="alternateNumberU"
                        value={alternateNumberU}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={5}>
                      <CustomInput
                        labelText="Office Number"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          placeholder: "Office Number...",
                        }}
                        id="officeNumberU"
                        onChange={(e) => {
                          setOfficeNumberU(e.target.value);
                        }}
                        name="officeNumberU"
                        value={officeNumberU}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={5}>
                      <CustomInput
                        labelText="Aadhar Number"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          placeholder: "Aadhar Number...",
                        }}
                        id="aadharNumberU"
                        onChange={(e) => {
                          setAadharNumberU(e.target.value);
                        }}
                        name="aadharNumberU"
                        value={aadharNumberU}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={5}>
                      <CustomInput
                        labelText="Shipping Shop no.,Building,Company"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          placeholder: "Shipping Address...",
                        }}
                        id="shipAdd"
                        onChange={(e) => {
                          setShipadd(e.target.value);
                        }}
                        name="shipadd"
                        value={shipadd}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={5}>
                      <CustomInput
                        labelText="Shipping Area,Colony,Street,Company"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          placeholder: "Shipping Address 1...",
                        }}
                        id="shipAdd1"
                        onChange={(e) => {
                          setShipadd1(e.target.value);
                        }}
                        name="shipadd1"
                        value={shipadd1}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={5}>
                      <CustomInput
                        labelText="Shipping LandMark e.g Near Inorbit"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          placeholder: "Shipping Address 2...",
                        }}
                        id="shipAdd2"
                        onChange={(e) => {
                          setShipadd2(e.target.value);
                        }}
                        name="shipadd2"
                        value={shipadd2}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={5}>
                      <CustomInput
                        labelText="Shipping Road"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          placeholder: "Shipping Address 3...",
                        }}
                        id="shipAdd3"
                        onChange={(e) => {
                          setShipadd3(e.target.value);
                        }}
                        name="shipadd3"
                        value={shipadd3}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={5}>
                      <CustomInput
                        labelText="Shipping Zip Code"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          placeholder: "Shipping Zip Code...",
                        }}
                        id="shipZipCodes"
                        onChange={(e) => {
                          setShipzipcodes(e.target.value);
                        }}
                        name="shipzipcodes"
                        value={shipzipcodes}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={5}>
                      <CustomInput
                        labelText="Shipping City"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          placeholder: "Shpping City...",
                        }}
                        id="shipCitys"
                        onChange={(e) => {
                          setShipcitys(e.target.value);
                        }}
                        name="shipcitys"
                        value={shipcitys}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={5}>
                      <CustomInput
                        labelText="Shipping State"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          placeholder: "Shpping State...",
                        }}
                        id="shipStates"
                        onChange={(e) => {
                          setShipstates(e.target.value);
                        }}
                        name="shipstates"
                        value={shipstates}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={5}>
                      <CustomInput
                        labelText="Country Name"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          placeholder: "Country Name...",
                        }}
                        id="countryNames"
                        onChange={(e) => {
                          setCountryNames(e.target.value);
                        }}
                        name="countryNames"
                        value={countryNames}
                      />
                    </GridItem>
                  </GridContainer>
                  {/* <Button
                  // style={{ marginLeft: "110px" }}
                  color="rose"
                  onClick={shipUpdate}
                  className={classes.updateProfileButton}
                >
                  Update
                </Button> */}
                  {btn === true ? (
                    <Button className={classes.updateProfileButton}>
                      Update
                    </Button>
                  ) : (
                    <Button
                      color="rose"
                      onClick={shipUpdate}
                      className={classes.updateProfileButton}
                    >
                      Update
                    </Button>
                  )}
                </CardBody>
              </Card>
            </GridItem>
          </>
        ) : (
          ""
        )}
      </GridContainer>
    </div>
  );
}

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import { headApi } from "utils/headApi";
import { imgApi } from "utils/imgApi";
import Check from "@material-ui/icons/Check";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import style from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import styleSweet from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import { localApi } from "utils/imgApi";

import { credentials } from "utils/imgApi";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(style);
const useStyleSweet = makeStyles(styleSweet);

export default function AddSet(props) {
  const [descName, setDescName] = React.useState("");
  const [pcs, setPcs] = React.useState("");
  const [descErr, setDescErr] = React.useState("");
  const [pcsErr, setPcsErr] = React.useState("");
  const [active, setActive] = React.useState(false);

  const [submitted, setSubmitted] = React.useState(false);
  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");

  const [newSets, setNewSets] = React.useState(false);
  const [btn, setBtn] = React.useState(false);

  const classes = useStyles();
  const classe = useStyle();
  const classSweet = useStyleSweet();

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  const SubmitAddSet = () => {
    setNewSets(true);
    let errorCount = 0;
    if (descName === "" || !descName) {
      errorCount++;
      setDescErr("Description Name is Required");
    }

    if (pcs === "" || !pcs) {
      errorCount++;
      setPcsErr("Piece/Sets is Required");
    }

    if (errorCount === 0) {
      setBtn(true);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Basic ${credentials}`,
        },
        body: JSON.stringify({
          setDesc: descName,
          pcs: pcs,
        }),
      };

      fetch(localApi + `/sets/add/${userObj && userObj.id}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          // console.log(data)
          if (data.message === "Data Inserted Successfully !!!") {
            alertTypeOps("success");
            alertMsgOps("Succesfully !!!");
            setSubmitted(true);
          }
        });
    }
  };

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  return (
    <div>
      <GridContainer justify="center" alignItems="center">
        <GridItem xs={12} sm={6} md={6}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <AddIcon />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Add Piece/Sets</small>
                {newSets === true ? (
                  <small
                    style={{
                      fontSize: "12px",
                      color: "red",
                      marginLeft: "10px",
                    }}
                  >
                    {descErr || pcsErr}
                  </small>
                ) : (
                  <small style={{ fontSize: "12px", marginLeft: "10px" }}>
                    Enter Sets Description
                  </small>
                )}
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {submitted ? (
                  <SweetAlert
                    type={alertType}
                    style={{ display: "block", marginTop: "-100px" }}
                    title={alertMsg}
                    onConfirm={() => hideAlert()}
                    confirmBtnCssClass={
                      classSweet.button + " " + classSweet.success
                    }
                  >
                    Added Sets
                  </SweetAlert>
                ) : null}{" "}
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Descriptions Name..."
                    id="descName"
                    inputProps={{
                      placeholder: "Eg: 2 Pcs in 1 Set...",
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="descName"
                    value={descName}
                    onChange={(e) => {
                      setDescName(e.target.value);
                      setDescErr("");
                    }}
                    required={true}
                  />
                  {/* <span style={{ color: "red" }}>{descErr}</span> */}
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Piece/Sets..."
                    id="pcs"
                    inputProps={{
                      placeholder: "Ex. 2...",
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="pcs"
                    value={pcs}
                    onChange={(e) => {
                      setPcs(e.target.value);
                      setPcsErr("");
                    }}
                    required={true}
                  />
                  {/* <span style={{ color: "red" }}>{pcsErr}</span> */}
                </GridItem>
              </GridContainer>
              {/* <Button
                color="rose"
                onClick={SubmitAddSet}
                className={classes.updateProfileButton}
              >
                Save
              </Button> */}
              {btn === true ? (
                <Button className={classes.updateProfileButton}>Save</Button>
              ) : (
                <Button
                  color="rose"
                  onClick={SubmitAddSet}
                  className={classes.updateProfileButton}
                >
                  Save
                </Button>
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

import React, { useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { localApi, catApi } from "utils/imgApi";
import { BiFile } from "react-icons/bi";
import { makeStyles } from "@material-ui/core/styles";
import Selects from "react-select";
import { useHistory, useLocation } from "react-router-dom";
import InputLabel from "@material-ui/core/InputLabel";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import AddIcon from "@material-ui/icons/Add";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import { FaBox, FaRing, FaUsers } from "react-icons/fa";
import { GiWireCoil, GiBoxUnpacking } from "react-icons/gi";
import { BsImages } from "react-icons/bs";
import { AiOutlineCalculator } from "react-icons/ai";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import styRadio from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import styDrop from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import styCheckbox from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import styleSweet from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import { credentials } from "utils/imgApi";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(styRadio);
const useStyl = makeStyles(styDrop);
const useSty = makeStyles(styCheckbox);
const useStyleSweet = makeStyles(styleSweet);

export default function UpdateStockProduct(props) {
  const [stockProd, setStockProd] = React.useState([]);
  const [itemProd, setItemProd] = React.useState([]);
  const [imgList, setImgList] = React.useState("");
  const [simpleSelectProd, setSimpleSelectProd] = React.useState("");
  const [simpleCartItem, setSimpleCartItem] = React.useState("");
  const [sProdId, setSProdId] = React.useState("");
  const [prdId, setPrdID] = React.useState("");

  const [submitted, setSubmitted] = React.useState(false);
  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");

  const [size1x8, setSize1x8] = React.useState(0);
  const [size1x9, setSize1x9] = React.useState(0);
  const [size1x10, setSize1x10] = React.useState(0);
  const [size1x11, setSize1x11] = React.useState(0);
  const [size1x12, setSize1x12] = React.useState(0);
  const [size1x13, setSize1x13] = React.useState(0);
  const [size1x14, setSize1x14] = React.useState(0);
  const [size1x15, setSize1x15] = React.useState(0);
  const [size2x0, setSize2x0] = React.useState(0);
  const [size2x1, setSize2x1] = React.useState(0);
  const [size2x2, setSize2x2] = React.useState(0);
  const [size2x3, setSize2x3] = React.useState(0);
  const [size2x4, setSize2x4] = React.useState(0);
  const [size2x5, setSize2x5] = React.useState(0);
  const [size2x6, setSize2x6] = React.useState(0);
  const [size2x7, setSize2x7] = React.useState(0);
  const [size2x8, setSize2x8] = React.useState(0);
  const [size2x9, setSize2x9] = React.useState(0);
  const [size2x10, setSize2x10] = React.useState(0);
  const [size2x11, setSize2x11] = React.useState(0);
  const [size2x12, setSize2x12] = React.useState(0);
  const [size2x13, setSize2x13] = React.useState(0);
  const [size2x14, setSize2x14] = React.useState(0);
  const [size2x15, setSize2x15] = React.useState(0);
  const [size3x0, setSize3x0] = React.useState(0);
  const [size3x1, setSize3x1] = React.useState(0);
  const [checkedCutSize, setCheckedCutSize] = React.useState(false);
  const [checkedFullSize, setCheckedFullSize] = React.useState(false);

  const [btn, setBtn] = React.useState(false);

  const [stockCategory, setStockCategory] = React.useState("");
  const [stockParent, setStockParent] = React.useState("");

  const classes = useStyles();
  const classesRadio = useStyle();
  const classe = useStyl();
  const classCheck = useSty();
  const classSweet = useStyleSweet();
  const history = useHistory();

  let { avatar } = props;

  const { state } = useLocation();

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  const totalPiece = "18";
  //   Math.ceil("864" / sets.pcs / box.box);

  async function getStockProduct() {
    const api = localApi + `/stockSize/list/byAdminId/${userObj && userObj.id}`;
    const result = await fetch(api, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getResult = await result.json();
    setStockProd(getResult && getResult);
    // console.log(getResult);
  }

  useEffect(() => {
    getStockProduct();
  }, []);

  useEffect(() => {
    // console.log(state);
    // const i = {
    //   label: state && state ? state.productId + "-" + state.id : "",
    //   value: state && state ? state.productId + "-" + state.id : "",
    // };
    // console.log(i);
    // setSimpleCartItem(i);
    setSProdId(state && state ? state.id : "");
    setSimpleSelectProd(state && state ? state.id : "");

    if (state && state ? state.cutOrFullSize == 3 : "") {
      setCheckedFullSize(true);
      setCheckedCutSize(true);
    }
    if (state && state ? state.cutOrFullSize == 1 : "") {
      setCheckedFullSize(true);
    }
    if (state && state ? state.cutOrFullSize == 2 : "") {
      setCheckedCutSize(true);
    }

    setSize1x8(state && state ? state.s1x8 : "");
    setSize1x9(state && state ? state.s1x9 : "");
    setSize1x10(state && state ? state.s1x10 : "");
    setSize1x11(state && state ? state.s1x11 : "");
    setSize1x12(state && state ? state.s1x12 : "");
    setSize1x13(state && state ? state.s1x13 : "");
    setSize1x14(state && state ? state.s1x14 : "");
    setSize1x15(state && state ? state.s1x15 : "");
    setSize2x0(state && state ? state.s2x0 : "");
    setSize2x1(state && state ? state.s2x1 : "");
    setSize2x2(state && state ? state.s2x2 : "");
    setSize2x3(state && state ? state.s2x3 : "");
    setSize2x4(state && state ? state.s2x4 : "");
    setSize2x5(state && state ? state.s2x5 : "");
    setSize2x6(state && state ? state.s2x6 : "");
    setSize2x7(state && state ? state.s2x7 : "");
    setSize2x8(state && state ? state.s2x8 : "");
    setSize2x9(state && state ? state.s2x9 : "");
    setSize2x10(state && state ? state.s2x10 : "");
    setSize2x11(state && state ? state.s2x11 : "");
    setSize2x12(state && state ? state.s2x12 : "");
    setSize2x13(state && state ? state.s2x13 : "");
    setSize2x14(state && state ? state.s2x14 : "");
    setSize2x15(state && state ? state.s2x15 : "");
    setSize3x0(state && state ? state.s3x0 : "");
    setSize3x1(state && state ? state.s3x1 : "");

    axios
      .get(
        localApi +
          `/product/shortList/byAdminId/${state && state ? state.adminId : ""}`, {
            headers: {
              Authorization: `Basic ${credentials}`,
            },
          }
      )
      .then((res) => {
        // console.log(res.data);
        const p = state && state ? state.productId : "";
        const im = res.data.filter((f) => f.id === p)[0];
        // console.log(im);
        setImgList(im.productimage);
        setStockCategory(im.category.id);
        setStockParent(im.parent.id);
      });
  }, []);

  // const handleCartItem = async (simpleCartItem) => {
  //   // console.log(simpleCartItem);
  //   setSimpleCartItem(simpleCartItem);
  //   if (simpleCartItem.value) {
  //     let newCartItem = simpleCartItem.value.split("-");
  //     const itCart = stockProd.find((itemz) => itemz.id == newCartItem[1]);
  //     //   console.log(itCart);
  //     setSProdId(itCart.id);
  //     setSimpleSelectProd(itCart.productId);

  //     if (itCart.cutOrFullSize == 3) {
  //       setCheckedFullSize(true);
  //       setCheckedCutSize(true);
  //     }
  //     if (itCart.cutOrFullSize == 1) {
  //       setCheckedFullSize(true);
  //     }
  //     if (itCart.cutOrFullSize == 2) {
  //       setCheckedCutSize(true);
  //     }

  //     setSize1x8(itCart.s1x8);
  //     setSize1x9(itCart.s1x9);
  //     setSize1x10(itCart.s1x10);
  //     setSize1x11(itCart.s1x11);
  //     setSize1x12(itCart.s1x12);
  //     setSize1x13(itCart.s1x13);
  //     setSize1x14(itCart.s1x14);
  //     setSize1x15(itCart.s1x15);
  //     setSize2x0(itCart.s2x0);
  //     setSize2x1(itCart.s2x1);
  //     setSize2x2(itCart.s2x2);
  //     setSize2x3(itCart.s2x3);
  //     setSize2x4(itCart.s2x4);
  //     setSize2x5(itCart.s2x5);
  //     setSize2x6(itCart.s2x6);
  //     setSize2x7(itCart.s2x7);
  //     setSize2x8(itCart.s2x8);
  //     setSize2x9(itCart.s2x9);
  //     setSize2x10(itCart.s2x10);
  //     setSize2x11(itCart.s2x11);
  //     setSize2x12(itCart.s2x12);
  //     setSize2x13(itCart.s2x13);
  //     setSize2x14(itCart.s2x14);
  //     setSize2x15(itCart.s2x15);
  //     setSize3x0(itCart.s3x0);
  //     setSize3x1(itCart.s3x1);

  //     const api = localApi + `/product/shortList/byAdminId/${itCart.adminId}`;
  //     const result = await fetch(api);
  //     const getResult = await result.json();
  //     const getProd = getResult.filter((f) => f.id === itCart.productId)[0];
  //     // console.log(getProd);
  //     setImgList(getProd.productImage);
  //   }
  // };

  async function getUsers() {
    const api = localApi + `/product/shortList/byAdminId/${userObj.id}`;
    const result = await fetch(api, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getResult = await result.json();
    setItemProd(getResult && getResult);
    // console.log(getResult);
  }

  useEffect(() => {
    getUsers();
  }, []);

  const handleSimpleProd = async (event) => {
    // setSimpleSelect(event.target.value);
    const { value } = event.target;
    setSimpleSelectProd(value);
    const itCart = stockProd.find((item) => item.id == value);
    // console.log(itCart);
    setSProdId(itCart.id);
      // setSimpleSelectProd(itCart.productId);

      if (itCart.cutOrFullSize == 3) {
        setCheckedFullSize(true);
        setCheckedCutSize(true);
      }
      if (itCart.cutOrFullSize == 1) {
        setCheckedFullSize(true);
      }
      if (itCart.cutOrFullSize == 2) {
        setCheckedCutSize(true);
      }

      setSize1x8(itCart.s1x8);
      setSize1x9(itCart.s1x9);
      setSize1x10(itCart.s1x10);
      setSize1x11(itCart.s1x11);
      setSize1x12(itCart.s1x12);
      setSize1x13(itCart.s1x13);
      setSize1x14(itCart.s1x14);
      setSize1x15(itCart.s1x15);
      setSize2x0(itCart.s2x0);
      setSize2x1(itCart.s2x1);
      setSize2x2(itCart.s2x2);
      setSize2x3(itCart.s2x3);
      setSize2x4(itCart.s2x4);
      setSize2x5(itCart.s2x5);
      setSize2x6(itCart.s2x6);
      setSize2x7(itCart.s2x7);
      setSize2x8(itCart.s2x8);
      setSize2x9(itCart.s2x9);
      setSize2x10(itCart.s2x10);
      setSize2x11(itCart.s2x11);
      setSize2x12(itCart.s2x12);
      setSize2x13(itCart.s2x13);
      setSize2x14(itCart.s2x14);
      setSize2x15(itCart.s2x15);
      setSize3x0(itCart.s3x0);
      setSize3x1(itCart.s3x1);

      const api = localApi + `/product/shortList/byAdminId/${itCart.adminId}`;
      const result = await fetch(api, {
        method: "GET",
        headers: {
          Authorization: `Basic ${credentials}`,
        },
      });
      const getResult = await result.json();
      const getProd = getResult.filter((f) => f.id === itCart.productId)[0];
      // console.log(getProd);
      setImgList(getProd.productimage);
  };

  var fullSize =
    parseInt(size1x8) +
    parseInt(size1x10) +
    parseInt(size1x12) +
    parseInt(size1x14) +
    parseInt(size2x0) +
    parseInt(size2x2) +
    parseInt(size2x4) +
    parseInt(size2x6) +
    parseInt(size2x8) +
    parseInt(size2x10) +
    parseInt(size2x12) +
    parseInt(size2x14) +
    parseInt(size3x0);
  var cutSize =
    parseInt(size1x9) +
    parseInt(size1x11) +
    parseInt(size1x13) +
    parseInt(size1x15) +
    parseInt(size2x1) +
    parseInt(size2x3) +
    parseInt(size2x5) +
    parseInt(size2x7) +
    parseInt(size2x9) +
    parseInt(size2x11) +
    parseInt(size2x13) +
    parseInt(size2x15) +
    parseInt(size3x1);

  const SubmitUpdateStockProduct = () => {
    setBtn(true);
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Basic ${credentials}`,
      },
      body: JSON.stringify({
        cutOrFullSize:
          checkedFullSize === true
            ? checkedCutSize === true
              ? "3"
              : checkedFullSize === true
              ? "1"
              : "0"
            : checkedCutSize === true
            ? "2"
            : "0",
        s1x8: size1x8 ? size1x8 : 0,
        s1x9: size1x9 ? size1x9 : 0,
        s1x10: size1x10 ? size1x10 : 0,
        s1x11: size1x11 ? size1x11 : 0,
        s1x12: size1x12 ? size1x12 : 0,
        s1x13: size1x13 ? size1x13 : 0,
        s1x14: size1x14 ? size1x14 : 0,
        s1x15: size1x15 ? size1x15 : 0,
        s2x0: size2x0 ? size2x0 : 0,
        s2x1: size2x1 ? size2x1 : 0,
        s2x2: size2x2 ? size2x2 : 0,
        s2x3: size2x3 ? size2x3 : 0,
        s2x4: size2x4 ? size2x4 : 0,
        s2x5: size2x5 ? size2x5 : 0,
        s2x6: size2x6 ? size2x6 : 0,
        s2x7: size2x7 ? size2x7 : 0,
        s2x8: size2x8 ? size2x8 : 0,
        s2x9: size2x9 ? size2x9 : 0,
        s2x10: size2x10 ? size2x10 : 0,
        s2x11: size2x11 ? size2x11 : 0,
        s2x12: size2x12 ? size2x12 : 0,
        s2x13: size2x13 ? size2x13 : 0,
        s2x14: size2x14 ? size2x14 : 0,
        s2x15: size2x15 ? size2x15 : 0,
        s3x0: size3x0 ? size3x0 : 0,
        s3x1: size3x1 ? size3x1 : 0,
      }),
    };

    fetch(localApi + `/stockSize/update/ById/${sProdId}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        if (data.message === "Data Updated Successfully !!!") {
          alertTypeOps("success");
          alertMsgOps("Succesfully !!!");
          setSubmitted(true);
        }
      });
  };

  const hideAlert = () => {
    setSubmitted(false);
    if (state) {
      history.push({
        pathname: `/admin/viewStockProduct`,
        state: stockCategory + "," + stockParent,
      });
    } else {
      window.location.reload(false);
    }
  };

  const select = {
    width: "100%",
    maxWidth: 600,
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      padding: 10,
    }),
    menu: (state) => ({
      color: "#d81b60",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  return (
    <div>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <AddIcon />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Update Stock Product</small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer justify="center">
                {submitted ? (
                  <SweetAlert
                    type={alertType}
                    style={{ display: "block", marginTop: "-100px" }}
                    title={alertMsg}
                    onConfirm={() => hideAlert()}
                    confirmBtnCssClass={
                      classSweet.button + " " + classSweet.success
                    }
                  >Updated Stock Product</SweetAlert>
                ) : null}
                {/* <GridItem xs={12} sm={12} md={12}>
                  <label className={classe.selectLabel}>
                    Select Stock Product Id
                  </label>
                  <Selects
                    styles={customStyles}
                    style={select}
                    required={true}
                    placeholder="Select Stock Product Id..."
                    value={simpleCartItem}
                    onChange={handleCartItem}
                    options={
                      stockProd &&
                      stockProd.map((c) => {
                        return {
                          label: c.productId + "-" + c.id,
                          value: c.productId + "-" + c.id,
                        };
                      })
                    }
                  />
                </GridItem> */}
                <GridItem xs={12} sm={12} md={12}>
                  <FormControl fullWidth className={classe.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classe.selectLabel}
                    >
                      Select Product Id...
                    </InputLabel>

                    <Select
                      MenuProps={{
                        className: classe.selectMenu,
                      }}
                      classes={{
                        select: classe.select,
                      }}
                      // disabled={true}
                      value={simpleSelectProd}
                      onChange={handleSimpleProd}
                      inputProps={{
                        name: "simpleSelect",
                        id: "simple-select",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classe.selectMenuItem,
                        }}
                      >
                        Select Product Id...
                      </MenuItem>
                      {stockProd &&
                        stockProd.map((item) => (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                            classes={{
                              root: classe.selectMenuItem,
                              selected: classe.selectMenuItemSelected,
                            }}
                          >
                            {item.id + "-" + item.productId}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>{" "}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <BiFile />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Product Size</small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={checkedFullSize}
                          onChange={(event) =>
                            setCheckedFullSize(event.target.checked)
                          }
                          value="checkedFullSize"
                          classes={{
                            switchBase: classe.switchBase,
                            checked: classes.switchChecked,
                            thumb: classe.switchIcon,
                            track: classe.switchBar,
                          }}
                        />
                      }
                      classes={{
                        label: classe.label,
                      }}
                      label={
                        checkedFullSize === false ? (
                          <span>Full Size Disable</span>
                        ) : (
                          <span
                            style={{
                              color: checkedFullSize === true ? "#d81b60" : "",
                            }}
                          >
                            Full Size Enable
                          </span>
                        )
                      }
                    />
                  </GridItem>
                  {checkedFullSize === true ? (
                    // <Card>
                    <GridContainer>
                      <GridItem xs={4} sm={4} md={4}>
                        <CustomInput
                          labelText="1x8"
                          id="1x8"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size1x8}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize1x8(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <CustomInput
                          labelText="1x10"
                          id="1x10"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size1x10}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize1x10(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>

                      <GridItem xs={4} sm={4} md={4}>
                        <CustomInput
                          labelText="1x12"
                          id="1x12"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size1x12}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize1x12(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>

                      <GridItem xs={4} sm={4} md={4}>
                        <CustomInput
                          labelText="1x14"
                          id="1x14"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size1x14}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize1x14(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>

                      <GridItem xs={4} sm={4} md={4}>
                        <CustomInput
                          labelText="2x0"
                          id="2x0"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size2x0}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize2x0(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>

                      <GridItem xs={4} sm={4} md={4}>
                        <CustomInput
                          labelText="2x2"
                          id="2x2"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size2x2}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize2x2(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <CustomInput
                          labelText="2x4"
                          id="2x4"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size2x4}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize2x4(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <CustomInput
                          labelText="2x6"
                          id="2x6"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size2x6}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize2x6(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <CustomInput
                          labelText="2x8"
                          id="2x8"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size2x8}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize2x8(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={3} sm={3} md={3}>
                        <CustomInput
                          labelText="2x10"
                          id="2x10"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size2x10}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize2x10(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={3} sm={3} md={3}>
                        <CustomInput
                          labelText="2x12"
                          id="2x12"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size2x12}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize2x12(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={3} sm={3} md={3}>
                        <CustomInput
                          labelText="2x14"
                          id="2x14"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size2x14}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize2x14(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={3} sm={3} md={3}>
                        <CustomInput
                          labelText="3x0"
                          id="3x0"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size3x0}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize3x0(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <h4>Full Size Total {fullSize}</h4>
                      </GridItem>
                    </GridContainer>
                  ) : (
                    // </Card>
                    ""
                  )}
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={checkedCutSize}
                          onChange={(event) =>
                            setCheckedCutSize(event.target.checked)
                          }
                          value="checkedCutSize"
                          classes={{
                            switchBase: classe.switchBase,
                            checked: classes.switchChecked,
                            thumb: classe.switchIcon,
                            track: classe.switchBar,
                          }}
                        />
                      }
                      classes={{
                        label: classe.label,
                      }}
                      label={
                        checkedCutSize === false ? (
                          <span>Cut Size Disable</span>
                        ) : (
                          <span
                            style={{
                              color: checkedCutSize === true ? "#d81b60" : "",
                            }}
                          >
                            Cut Size Enable
                          </span>
                        )
                      }
                    />
                  </GridItem>
                  {checkedCutSize === true ? (
                    <GridContainer>
                      <GridItem xs={4} sm={4} md={4}>
                        <CustomInput
                          labelText="1x9"
                          id="1x9"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          value={size1x9}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize1x9(value);
                          }}
                        />
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <CustomInput
                          labelText="1x11"
                          id="1x11"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size1x11}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize1x11(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <CustomInput
                          labelText="1x13"
                          id="1x13"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size1x13}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize1x13(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <CustomInput
                          labelText="1x15"
                          id="1x15"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size1x15}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize1x15(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <CustomInput
                          labelText="2x1"
                          id="2x1"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size2x1}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize2x1(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <CustomInput
                          labelText="2x3"
                          id="2x3"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size2x3}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize2x3(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <CustomInput
                          labelText="2x5"
                          id="2x5"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size2x5}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize2x5(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <CustomInput
                          labelText="2x7"
                          id="2x7"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size2x7}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize2x7(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <CustomInput
                          labelText="2x9"
                          id="2x9"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size2x9}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize2x9(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={3} sm={3} md={3}>
                        <CustomInput
                          labelText="2x11"
                          id="2x11"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size2x11}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize2x11(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={3} sm={3} md={3}>
                        <CustomInput
                          labelText="2x13"
                          id="2x13"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size2x13}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize2x13(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={3} sm={3} md={3}>
                        <CustomInput
                          labelText="2x15"
                          id="2x15"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size2x15}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize2x15(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={3} sm={3} md={3}>
                        <CustomInput
                          labelText="3x1"
                          id="3x1"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size3x1}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize3x1(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <h4>Cut Size Total {cutSize}</h4>
                      </GridItem>
                    </GridContainer>
                  ) : (
                    ""
                  )}
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h4>Full & Cut Size Total {fullSize + cutSize}</h4>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <BsImages />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Select Design Images</small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {imgList &&
                  imgList.map((i) => (
                    <GridItem xs={3} sm={2} md={2} key={i.id}>
                      <div className="fileinput text-center">
                        <div
                          className={
                            "thumbnail" + (avatar ? " img-circle" : "")
                          }
                        >
                          <img
                            src={i.cropImageUrl + "?id=" + i.imgId}
                            alt="..."
                          />
                        </div>
                      </div>
                    </GridItem>
                  ))}
              </GridContainer>
              {/* <Button
                color="rose"
                className={classes.updateProfileButton}
                onClick={SubmitUpdateStockProduct}
              >
                Update
              </Button> */}
              {btn === true ? (
                <Button className={classes.updateProfileButton}>Update</Button>
              ) : (
                <Button
                  color="rose"
                  onClick={SubmitUpdateStockProduct}
                  className={classes.updateProfileButton}
                >
                  Update
                </Button>
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

UpdateStockProduct.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
};

import React, { useState, useEffect } from "react";
import "react-phone-input-2/lib/bootstrap.css";
import { makeStyles } from "@material-ui/core/styles";
import { localApi } from "utils/imgApi";
import AddIcon from "@material-ui/icons/Add";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import styles from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import styleSweet from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";

import { credentials } from "utils/imgApi";

const useStyles = makeStyles(styles);
const useStyleSweet = makeStyles(styleSweet);

export default function AddTransport() {
  const [transportName, setTransportName] = React.useState("");
  const [transportNameErr, setTransportNameErr] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);
  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");
  const [btn, setBtn] = React.useState(false);

  const classes = useStyles();
  const classSweet = useStyleSweet();

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  const SubmitAddTransport = async () => {
    let errorCount = 0;
    if (transportName === "" || !transportName) {
      errorCount++;
      setTransportNameErr("Transport Name is Required");
    }
    if (errorCount === 0) {
      setBtn(true);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Basic ${credentials}`,
        },
        body: JSON.stringify({
          transportName: transportName,
        }),
      };

      fetch(localApi + `/transport/add/byAdminId/${userObj.id}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          if (data.error == true) {
            alertTypeOps("success");
            alertMsgOps("Succesfully !!!");
            setSubmitted(true);
          } else {
            alertTypeOps("danger");
            alertMsgOps("Already Exits");
            setSubmitted(true);
          }
        });
    }
  };

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  return (
    <div>
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={6}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <AddIcon />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Add Transport</small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer justify="center" alignItems="center">
                {submitted ? (
                  <SweetAlert
                    type={alertType}
                    style={{ display: "block", marginTop: "-100px" }}
                    title={alertMsg}
                    onConfirm={() => hideAlert()}
                    confirmBtnCssClass={
                      classSweet.button + " " + classSweet.success
                    }
                  >Added Transport</SweetAlert>
                ) : null}
                <GridItem xs={12} sm={8} md={8}>
                  <CustomInput
                    labelText="Transportation Name..."
                    id="transportName"
                    inputProps={{
                      placeholder: "Ex. Inland Wolrd Logistics...",
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="transportName"
                    value={transportName}
                    onChange={(e) => {
                      setTransportName(e.target.value);
                      setTransportNameErr("");
                    }}
                    required={true}
                  />
                  <span style={{ color: "red" }}>{transportNameErr}</span>
                </GridItem>
              </GridContainer>
              <br />
              <div className={classes.center}>
                {/* <Button
                  color="rose"
                  onClick={SubmitAddTransport}
                  className={classes.updateProfileButton}
                >
                  save
                </Button> */}
                {btn === true ? (
                  <Button className={classes.updateProfileButton}>Save</Button>
                ) : (
                  <Button
                    color="rose"
                    onClick={SubmitAddTransport}
                    className={classes.updateProfileButton}
                  >
                    Save
                  </Button>
                )}
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

import React, { useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import Selects from 'react-select'
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { dataURLtoFile } from "utils/dataURLToFile";
import { headApi } from "utils/headApi";
import { imgApi } from "utils/imgApi";
import { BiRupee, BiFile } from "react-icons/bi";
import { BiEdit } from "react-icons/bi";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import { FaBox, FaRing, FaUsers, FaFileInvoice } from "react-icons/fa";
import { GiWireCoil, GiBoxUnpacking } from "react-icons/gi";
import { FcTemplate } from "react-icons/fc";
import { BsImages, BsNewspaper } from "react-icons/bs";
import {
  AiOutlineCalculator,
  AiOutlineFundProjectionScreen,
} from "react-icons/ai";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import styRadio from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import styDrop from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import styCheckbox from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import avatar from "assets/img/faces/marc.jpg";
import defaultAvatar from "assets/img/placeholder.jpg";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(styRadio);
const useStyl = makeStyles(styDrop);
const useSty = makeStyles(styCheckbox);

export default function OnlineProductUpdate(props) {
  const [itemProd, setItemProd] = React.useState([]);
  const [items, setItems] = React.useState([]);
  const [items1, setItems1] = React.useState([]);
  const [items2, setItems2] = React.useState([]);
  const [itemSets, setItemSets] = React.useState([]);
  const [itemBox, setItemBox] = React.useState([]);
  const [metal, setMetal] = React.useState([]);
  const [plastic, setPlastic] = React.useState([]);
  const [crystal, setCrystal] = React.useState([]);
  const [ploythene, setPloythene] = React.useState([]);
  const [productId, setProductId] = React.useState("");
  const [productIdSepratedByComma, setProductIdSepratedByComma] =
    React.useState([]);
  const [simpleProductSelect, setSimpleProductSelect] = React.useState("");
  const [simpleSelect, setSimpleSelect] = React.useState("");
  const [simpleSelect1, setSimpleSelect1] = React.useState("");
  const [catSize, setCatSize] = React.useState("");
  const [selectedGstCal, setSelectedGstCal] = React.useState("");
  const [checkedA, setCheckedA] = React.useState("");
  const [selectedProfit, setSelectedProfit] = React.useState("");
  const [selectedPieceSets, setSelectedPieceSets] = React.useState(0);
  const [selectedBox, setSelectedBox] = React.useState(0);
  const [makingCheckboxMaking, setMakingCheckboxMaking] = React.useState("");
  const [makingCharge, setMakingCharge] = React.useState("");
  const [simpleSelectMetal, setSimpleSelectMetal] = React.useState("");
  const [readyGram, setReadyGram] = React.useState("");
  const [metalGram, setMetalGram] = React.useState(0);
  const [metalSGram, setMetalSGram] = React.useState("");
  const [golwireThaw, setGolwireThaw] = React.useState("");
  const [packingOption, setPackingOption] = React.useState("");
  const [simpleSelectPlastic, setSimpleSelectPlastic] = React.useState("");
  const [simpleSelectCrystal, setSimpleSelectCrystal] = React.useState("");
  const [simpleSelectPloythene, setSimpleSelectPloythene] = React.useState("");
  const [selectedPackingPhoto, setSelectedPackingPhoto] = React.useState("");
  const [plating, setPlating] = React.useState("");
  const [selectedPlating, setSelectedPlating] = React.useState(0);
  const [checkBoxMaking, setCheckBoxmaking] = React.useState([]);
  const [makingSingleId, setMakingSingleId] = React.useState("");
  const [includeGST, setIncludeGST] = React.useState(0);
  const [includeSets, setIncludeSets] = React.useState(0);
  const [totalCash, setTotalCash] = React.useState(0);
  const [sets, setSets] = React.useState(0);
  const [prodItemCode, setProdItemCode] = React.useState("");
  const [prodDescription, setProdDescription] = React.useState("");
  const [prodMfgDetails, setProdMfgDetails] = React.useState("");
  const [cartonDescription, setCartonDescription] = React.useState("");
  const [cartonQuantity, setCartonQuantity] = React.useState("");
  const [cartonPackingCharge, setCartonPackingCharge] = React.useState("");

  const [makingPrice, setMakingPrice] = React.useState(0);
  const [makingGst, setMakingGst] = React.useState(0);
  const [makIncludeGst, setMakIncludeGst] = React.useState(0);
  const [metalPrice, setMetalPrice] = React.useState(0);
  const [metalDiff, setMetalDiff] = React.useState(0);
  const [metalGst, setMetalGst] = React.useState(0);
  const [plasticPrice, setPlasticPrice] = React.useState(0);
  const [plasticGst, setPlasticGst] = React.useState(0);
  const [crystalPrice, setCrystalPrice] = React.useState(0);
  const [crystalGst, setCrystalGst] = React.useState(0);
  const [ployPrice, setPloyPrice] = React.useState(0);
  const [ployGst, setPloyGst] = React.useState(0);
  const [makChecked, setMakChecked] = React.useState("");
  const [boxVal, setBoxVal] = React.useState(0);
  const [platVal, setPlatVal] = React.useState(0);
  const [makVal, setMakVal] = React.useState(0);
  const [metGramVal, setMetGramVal] = React.useState(0);
  const [packVal, setPackVal] = React.useState(0);

  const [totalProfit, setTotalProfit] = React.useState(0);
  const [setProfit, setSetProfit] = React.useState(0);

  const [totalProfitSet, setTotalProfitSet] = React.useState(0);
  const [profitSet, setProfitSet] = React.useState(0);

  const [includeProfit, setIncludeProfit] = React.useState(0);
  const [includeProfitSet, setIncludeProfitSet] = React.useState(0);

  const [includeProfitGst, setIncludeProfitGst] = React.useState(0);
  const [includeProfitSetGst, setIncludeProfitSetGst] = React.useState(0);

  const [prodId, setProdId] = React.useState([]);
  const [mixlbrtotal, setmixlbrtotal] = React.useState("");
  const [mixmaktotal, setmixmaktotal] = React.useState("");

  const [singleMak, setSingleMak] = React.useState("");

  const [getMakingMulti, setGetMakingMulti] = React.useState([]);

  const [catDes, setCatDes] = React.useState("");
  const [sizeDes, setSizeDes] = React.useState("");
  const [prdId, setPrdID] = React.useState("");

  const classes = useStyles();
  const classesRadio = useStyle();
  const classe = useStyl();
  const classCheck = useSty();

  const [file, setFile] = React.useState(null);
  const [crop, setCrop] = React.useState({
    aspect: 1,
    unit: "px",
    width: 280,
    height: 336,
  });
  const [image, setImage] = React.useState("");

  const [photo, setPhoto] = React.useState([
    { id: 1, no: 1, img: "", radioValue: false },
    { id: 2, no: 2, img: "", radioValue: false },
    { id: 3, no: 3, img: "", radioValue: false },
    { id: 4, no: 4, img: "", radioValue: false },
    { id: 5, no: 5, img: "", radioValue: false },
    { id: 6, no: 6, img: "", radioValue: false },
  ]);
  const elementsRef = React.useRef(photo.map(() => React.createRef()));
  const handleImageChange = (e, id) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setFile(file);
      let tempphoto = photo.map((i) =>
        i.id === id ? { ...i, img: reader.result, file } : i
      );
      setPhoto(tempphoto);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const getCroppedImg = (id) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    const base64Image = canvas.toDataURL("image/jpeg");
    let convertFile = dataURLtoFile(base64Image, catDes.description + "~" + sizeDes.description + "~" + selectedPlating.description.slice(8) + "~" + file?.name);
    // console.log(convertFile);
    let tempphoto = photo.map((i) =>
      i.id === id ? { ...i, crop_img: base64Image, convertFile } : i
    );
    setPhoto(tempphoto);
  };

  const handleClick = (index) => {
    // console.log(elementsRef, index);
    elementsRef.current[index].current.click();
  };
  const handleRemove = (id) => {
    let tempphoto = photo.map((i) => (i.id === id ? { ...i, img: "" } : i));
    setPhoto(tempphoto);
  };
  let { avatar, addButtonProps, changeButtonProps, removeButtonProps } = props;

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));


  async function getUsers() {
    const api = headApi + `/products?userid=${userObj.id}&active=1`;
    const result = await fetch(api);
    const getResult = await result.json();
    setItemProd(getResult.ProductsData);
    // console.log(getResult.ProductsData);
  }

  useEffect(() => {
    getUsers();
  }, []);

  const handleProductSimple = (simpleProductSelect) => {
    setSimpleProductSelect(simpleProductSelect);
    if (simpleProductSelect.value) {
      let newP = simpleProductSelect.value.split("-");
      const itemz = itemProd.find((itemz) => itemz.id == newP[0]);
      // console.log(itemz);
      setPrdID(itemz.id);
      setCatDes(itemz.cat);
      // setSimpleProductSelect(value);
      setSimpleSelect(itemz.cat?.id);
      axios
        .get(
          headApi + `/category/size-mm?catid=${itemz.cat?.id}&userid=${userObj.id}`
        )
        .then((res) => setItems1(res.data.cat1SizemmData));
      setSizeDes(itemz.banglessizemm)
      setSimpleSelect1(itemz.banglessizemm?.id);
      if (simpleProductSelect) {
        const split = itemz.banglessizemm?.description.split("/");
        setCatSize(split.length >>> 1);
      }
      setProductId(itemz.mixmm);
      setSelectedPieceSets(itemz.sets);
      setSelectedBox(itemz.box);
      let boxVa = 0;
      let boxLoadGst = 0;
      if (itemz.box?.id) {
        boxVa = itemz.box?.price + itemz.box?.pkgcharge;
        boxLoadGst =
          (itemz.box?.price * itemz.box?.ligst) / 100 +
          (itemz.box?.price + itemz.box?.pkgcharge);
        setBoxVal(boxVa.toFixed(2));
      }
      // console.log(itemz.box?.box);
      axios
        .get(
          headApi + `/category/plating?cat1id=${itemz.banglessizemm?.id}&userid=${userObj.id}`
        )
        .then((res) => setPlating(res.data.cat2labourData));
      setSelectedPlating(itemz.plating);
      let platingPrice = 0;
      let platLoadGst = 0;
      if (itemz.plating?.id) {
        platingPrice = itemz.plating?.price * itemz.sets?.pcs * itemz.box?.box;
        platLoadGst =
          (itemz.plating?.price *
            itemz.sets?.pcs *
            itemz.box?.box *
            itemz.plating?.ligst) /
          100 +
          itemz.plating?.price * itemz.sets?.pcs * itemz.box?.box;

        setPlatVal(platingPrice.toFixed(2));
      }
      axios
        .get(
          headApi + `/category/no-plating?cat1id=${itemz.banglessizemm?.id}&userid=${userObj.id}`
        )
        .then((res) => setItems2(res.data.labourData));

      let tempPrice = 0;
      let tempMakVal = 0;
      let tempMakGstVal = 0;
      let tempMakGst = 0;

      setCheckBoxmaking(
        itemz.labourList.map((ltist) => {
          tempPrice += ltist.price;
          tempMakVal += ltist?.price * itemz.sets?.pcs * itemz.box?.box;
          tempMakGstVal += ltist.taxincl * itemz.sets?.pcs * itemz.box?.box;
          tempMakGst += ltist.taxincl;
          return ltist.id;
        })
      );
      setMakingPrice(tempPrice);
      setMakVal(tempMakVal);
      setMakIncludeGst(tempMakGst);
      setSingleMak(itemz.labourList[0].id);

      setSimpleSelectMetal(itemz.metal?.id);
      if (itemz.metal?.id) {
        setMetalPrice(itemz.metal?.price);
        setMetalDiff(itemz.metal?.different);
        setMetalGst(itemz.metal?.ligst);
      }
      setReadyGram(itemz.metalOption.readygram);
      setMetalGram(itemz.metalOption.metalgram);
      let newMet = 0;
      let metLoadGst = 0;
      if (itemz.metalOption.metalgram) {
        newMet =
          itemz.metalOption.metalgram *
          itemz.sets?.pcs *
          itemz.box?.box *
          itemz.metal?.metalamt;
        metLoadGst =
          (itemz.metalOption.metalgram *
            itemz.sets?.pcs *
            itemz.box?.box *
            (itemz.metal?.price + itemz.metal?.different) *
            itemz.metal?.ligst) /
          100 +
          itemz.metalOption.metalgram *
          itemz.sets?.pcs *
          itemz.box?.box *
          (itemz.metal?.price + itemz.metal?.different);

        setMetGramVal(newMet.toFixed(2));
      }
      setMetalSGram(itemz.metalOption.extragram);
      setGolwireThaw(itemz.metalOption.golwirethaw);

      setPackingOption(itemz.packing?.type);
      if (itemz.packing?.type === "Polythene PP") {
        axios
          .get(
            headApi + `/packing/type?userid=${userObj.id}&type=${itemz.packing?.type}`
          )
          .then((res) => setPloythene(res.data.PackingData));
        setSimpleSelectPloythene(itemz.packing?.id);
      }

      if (itemz.packing?.type === "Crystal Dibbi") {
        axios
          .get(
            headApi + `/packing/type?userid=${userObj.id}&type=${itemz.packing?.type}`
          )
          .then((res) => setCrystal(res.data.PackingData));
        setSimpleSelectCrystal(itemz.packing?.id);
      }

      if (itemz.packing?.type === "Plastic Dibbi") {
        axios
          .get(
            headApi + `/packing/type?userid=${userObj.id}&type=${itemz.packing?.type}`
          )
          .then((res) => setPlastic(res.data.PackingData));
        setSimpleSelectPlastic(itemz.packing?.id);
      }

      let ployTotal = 0;
      let packLoadGst = 0;
      if (itemz.packing?.id) {
        ployTotal = itemz.packing?.price * itemz.box?.box;
        packLoadGst =
          (itemz.packing?.price * itemz.box?.box * itemz.packing?.ligst) / 100 +
          itemz.packing?.price * itemz.box?.box;
        setPackVal(ployTotal.toFixed(2));
        setPloyPrice(itemz.packing?.price);
        setPloyGst(itemz.packing?.ligst);
        setPlasticPrice(itemz.packing?.price);
        setPlasticGst(itemz.packing?.ligst);
        setCrystalPrice(itemz.packing?.price);
        setCrystalGst(itemz.packing?.ligst);
      }
      // gst total start  // gst total start  // gst total start

      let boxPTotalCash = boxVa + platingPrice + tempMakVal + newMet + ployTotal;

      let boxPTotalGst =
        boxLoadGst + platLoadGst + tempMakGstVal + metLoadGst + packLoadGst;
      setIncludeGST(boxPTotalGst.toFixed(2));

      let setPGst = boxPTotalGst / itemz.box?.box;
      setIncludeSets(setPGst.toFixed(2));

      let prodGst = (boxPTotalCash * itemz.user?.profit) / 100 + boxPTotalGst;
      setIncludeProfit(prodGst.toFixed(2));

      let prodSet = prodGst / itemz.box?.box;
      setIncludeProfitSet(prodSet.toFixed(2));

      let prodGstSet = boxPTotalGst + itemz.user?.price * itemz.box?.box;
      setIncludeProfitGst(prodGstSet.toFixed(2));

      let prodSetGst = prodGstSet / itemz.box?.box;
      setIncludeProfitSetGst(prodSetGst.toFixed(2));
      // gst total End

      // cash total start   // cash total start    // cash total start
      let boxPrices = boxVa + platingPrice + tempMakVal + newMet + ployTotal;
      setTotalCash(boxPrices.toFixed(2));

      let setPrice = boxPrices / itemz.box?.box;
      setSets(setPrice.toFixed(2));

      let profit = (boxPrices * itemz.user.profit) / 100 + boxPrices;
      setTotalProfit(profit.toFixed(2));

      let setProfit = profit / itemz.box?.box;
      setSetProfit(setProfit.toFixed(2));

      let profSet = boxPrices + itemz.user.price * itemz.box?.box;
      setTotalProfitSet(profSet.toFixed(2));

      let profSets = profSet / itemz.box?.box;
      setProfitSet(profSets.toFixed(2));
      // cash total End

      if (itemz.taxCalculation.cash_gst === 1) {
        setSelectedGstCal("gst");
      } else {
        setSelectedGstCal("cash");
      }

      if (itemz.pageLoadCount.active === 0) {
        setCheckedA(false);
      } else {
        setCheckedA(true);
      }

      if (itemz.taxCalculation.profitactive === 1) {
        setSelectedProfit("profit");
      } else {
        setSelectedProfit("profit/sets");
      }

      setProdItemCode(itemz.itemcode);
      setProdMfgDetails(itemz.mfgdetails);
      setProdDescription(itemz.description);
      setCartonDescription(itemz.ctndescription);
      setCartonQuantity(itemz.ctnquantity);
      setCartonPackingCharge(itemz.cartooncharge);

      let tempImg = [];
      let val = 1;
      Object.keys(itemz).map((i) => {
        if (i.includes("design")) {
          // console.log(`image${val}`);
          tempImg.push({ crop_img: imgApi + "" + itemz[i][`image${val}`], orgImg: imgApi + "" + itemz[i][`originalimg${val}`] });
          val += 1;
        }
      });

      let tempRadio = [];
      let vals = 1;
      Object.keys(itemz).map((i) => {
        if (i.includes("design")) {
          tempRadio.push({ id: `${vals}`, active: itemz[i][`img${vals}active`] });
          vals += 1;
        }
      });

      // console.log(tempRadio);

      // setSelectedPackingPhoto(tempRadio);

      setPhoto((prev) =>
        prev.map((item, index) => {
          return {
            ...item,
            crop_img:
              tempImg[index]?.crop_img === null
                ? defaultAvatar
                : tempImg[index]?.crop_img ===
                  imgApi + "" + "/crop_img/null"
                  ? defaultAvatar
                  : tempImg[index]?.crop_img ===
                    imgApi + "" + "/crop_img/"
                    ? defaultAvatar
                    : tempImg[index]?.crop_img
                      ? tempImg[index]?.crop_img
                      : defaultAvatar,
            orgImg: tempImg[index]?.orgImg,
            radioValue:
              tempRadio[index].id == item.id
                ? tempRadio[index].active
                  ? true
                  : false
                : false,
          };
        })
      );

    }
  };

  const handleSimple = (event) => {
    // setSimpleSelect(event.target.value);
    const { value } = event.target;
    const item = items.find((item) => item.id == value);
    setSimpleSelect(value);
    if (value) {
      setCatDes(item);
    }
    // setGetCatId(item.id);
    axios
      .get(
        headApi + `/category/size-mm?catid=${item.id}&userid=${userObj.id}`
      )
      .then((res) => {
        // console.log(res.data.cat1SizemmData);
        setItems1(res.data.cat1SizemmData);
        // const catid = (res.data.cat1SizemmData.map((cat) => (cat.description)));
        // setGetCatId(catid);
      });
  };

  const handleSimple1 = async (event) => {
    // setSimpleSelect(event.target.value);
    const { value } = event.target;
    setSimpleSelect1(value);
    const itemm = items1.find((itemm) => itemm.id == value);
    if (value) {
      setSizeDes(itemm);
    }
    if (value) {
      setCartonDescription(`96 boxes of ${itemm.description} in 1 Carton`);
    }
    if (value) {
      let newSplit = itemm.description.split("/");
      setCatSize(newSplit.length >>> 1);
    }
    const apiCall = await fetch(
      headApi + `/category/no-plating?cat1id=${itemm.id}&userid=${userObj.id}`
    );
    const labour = await apiCall.json();
    setItems2(labour.labourData);
    const apiPlating = await fetch(
      headApi + `/category/plating?cat1id=${itemm.id}&userid=${userObj.id}`
    );
    const plating = await apiPlating.json();
    setPlating(plating.cat2labourData);
  };

  const handleProductId = async (e) => {
    const { value } = e.target;
    const ids = [];
    setProductId(value);
    let newstr = "";
    for (var i = 0; i < value.length; i++) {
      if (value[i] === ",") {
        if (!ids.includes(parseInt(newstr))) {
          ids.push(parseInt(newstr));
        }
        newstr = "";
      } else {
        newstr += value[i];
      }
    }
    if (ids?.toString() !== productIdSepratedByComma.toString()) {
      setProductIdSepratedByComma(ids);
    }
  };

  const handleProdId = () => {
    const tempData = [];
    Promise.all(
      productIdSepratedByComma.map(async (id) => {
        const response = await fetch(
          headApi + `/products/byid/${id}`
        );
        const data = await response.json();
        // console.log(data);
        tempData.push(data);
      })
    ).then(() => {
      // console.log(tempData);
      setProdId(tempData);
    });
    setmixlbrtotal(lbrmetprice.toFixed(2));
    setmixmaktotal(mixmetPrice.toFixed(2));
  };

  const fetchData = () => {
    const setsApi = headApi + `/sets/byuser/${userObj.id}`;
    const boxApi = headApi + `/box/byuser/${userObj.id}`;
    const metalApi = headApi + `/metal/byuser/${userObj.id}`;
    const catApi = headApi + `/category/catbyuser/${userObj.id}`;
    // const productApi = headApi + `/products/${userObj.id}`

    const getSetsApi = axios.get(setsApi);
    const getBoxApi = axios.get(boxApi);
    const getMetalApi = axios.get(metalApi);
    const getCatApi = axios.get(catApi);
    // const getProdApi = axios.get(productApi)
    axios.all([getSetsApi, getBoxApi, getMetalApi, getCatApi]).then(
      axios.spread((...allData) => {
        const allSetsApi = allData[0];
        const allBoxApi = allData[1];
        const allMetalApi = allData[2];
        const allCatApi = allData[3];
        // const allProdApi = allData[4]
        setItemSets(allSetsApi.data.SetData);
        // let newSets = allSetsApi.data.SetData.find((x) => {
        //   if (x.active === 1) {
        //     setSelectedPieceSets(x);
        //   }
        // });
        setItemBox(allBoxApi.data.BoxData);
        // let newSetss = allBoxApi.data.BoxData.find((x) => {
        //   // if (x.active === 1) {
        //   //   setSelectedBox(x);
        //   // }
        //   if (x.active === 1) {
        //     let boxVa = (x?.price + x?.pkgcharge);
        //     setBoxVal(boxVa);
        //   }
        // });
        setMetal(allMetalApi.data.MetalData);
        setItems(allCatApi.data.CategoriesData);
        // setItemProd(allProdApi.data.ProductsData);
      })
    );
    if (packingOption === "Polythene PP") {
      fetch(
        headApi + `/packing/type?userid=${userObj.id}&type=Polythene PP`
      )
        .then((res) => res.json())
        .then((data) => setPloythene(data.PackingData));
      // setPloythene(getResult.PackingData);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCheckBoxMaking = (event, making) => {
    // setCheckBoxmaking(e.target.checked);
    const { checked } = event.target;
    if (checked) {
      setMakingPrice(makingPrice + making?.price);
    } else {
      setMakingPrice(makingPrice - making?.price);
    }

    if (checked) {
      setMakVal(
        makVal + making?.price * selectedPieceSets?.pcs * selectedBox?.box
      );
    } else {
      setMakVal(
        makVal - making?.price * selectedPieceSets?.pcs * selectedBox?.box
      );
    }

    if (checked) {
      setMakingGst(making?.ligst);
      setMakIncludeGst(makIncludeGst + making?.taxincl);
    } else {
      setMakingGst("");
      setMakIncludeGst(makIncludeGst - making?.taxincl);
    }

    if (checked) {
      setMakingSingleId(making?.id);
    }

    setCheckBoxmaking((prev) => {
      if (prev.includes(making.id)) {
        return prev.filter((x) => x !== making.id);
      } else {
        return [...prev, making.id];
      }
    });

    // setMakChecked(prev => {
    //   if (prev.includes(making?.id)) {
    //     return prev.filter(x => x !== making?.id);
    //   } else {
    //     return [...prev, making?.id];
    //   }
    // });
  };

  const handleSimpleMetal = (event) => {
    // alert(event.target.value);
    const { value } = event.target;
    const metItem = metal.find((metItem) => metItem.id == value);
    setSimpleSelectMetal(value);
    setMetalPrice(metItem.price);
    setMetalDiff(metItem.different);
    setMetalGst(metItem.ligst);
    if (value) {
      let newMet = ((metalGram * selectedPieceSets?.pcs * selectedBox?.box) * (metItem.price + metItem.different));
      setMetGramVal(newMet.toFixed(2));
    }
  };

  const handlePackingOption = async (event) => {
    // setPackingOption(event.target.value);
    const { value } = event.target;
    setPackingOption(value);
    if (value === "Crystal Dibbi") {
      const api = headApi + `/packing/type?userid=${userObj.id}&type=${value}`;
      const result = await fetch(api);
      const getResult = await result.json();
      setCrystal(getResult.PackingData);
    } else if (value === "Plastic Dibbi") {
      const api = headApi + `/packing/type?userid=${userObj.id}&type=Plastic Dibbi`;
      const result = await fetch(api);
      const getResult = await result.json();
      setPlastic(getResult.PackingData);
    } else if (value === "Polythene PP") {
      const api = headApi + `/packing/type?userid=${userObj.id}&type=Polythene PP`;
      const result = await fetch(api);
      const getResult = await result.json();
      setPloythene(getResult.PackingData);
    }
    if (value === "Plastic Dibbi") {
      let packTotal = plasticPrice * selectedBox?.box;
      setPackVal(packTotal.toFixed(2));
    } else if (value === "Crystal Dibbi") {
      let crysTotal = crystalPrice * selectedBox?.box;
      setPackVal(crysTotal.toFixed(2));
    } else if (value === "Polythene PP") {
      let ployTotal = ployPrice * selectedBox?.box;
      setPackVal(ployTotal.toFixed(2));
    } else {
      setPackVal("")
    }
  };

  const handleSimplePlastic = (event) => {
    // setSimpleSelectPlastic(event.target.value);
    const { value } = event.target;
    setSimpleSelectPlastic(value);
    const plaItem = plastic.find((plaItem) => plaItem.id == value);
    setPlasticPrice(plaItem.price);
    setPlasticGst(plaItem.ligst);
    if (value) {
      let packTotal = plaItem.price * selectedBox?.box;
      setPackVal(packTotal.toFixed(2));
    } else {
      setPackVal("")
    }
    // if(value){
    //   console.log("plas : -", value);
    // }
  };

  const handleSimpleCrystal = (event) => {
    // setSimpleSelectCrystal(event.target.value);
    const { value } = event.target;
    setSimpleSelectCrystal(value);
    const crysItem = crystal.find((crysItem) => crysItem.id == value);
    setCrystalPrice(crysItem.price);
    setCrystalGst(crysItem.ligst);
    if (value) {
      let crysTotal = crysItem.price * selectedBox?.box;
      setPackVal(crysTotal.toFixed(2));
    } else {
      setPackVal("")
    }
    // if(value){
    //   console.log("cry : -", value);
    // }
  };

  const handleSimplePloythene = (event) => {
    // setSimpleSelectPloythene(event.target.value);
    const { value } = event.target;
    setSimpleSelectPloythene(value);
    const ployItem = ploythene.find((ployItem) => ployItem.id == value);
    setPloyPrice(ployItem.price);
    setPloyGst(ployItem.ligst);
    if (value) {
      let ployTotal = ployItem.price * selectedBox?.box;
      setPackVal(ployTotal.toFixed(2));
    } else {
      setPackVal("")
    }
    // if(value){
    //   console.log("ploy :- ", value);
    // }
  };

  const handleChangeGstCal = (event, id) => {
    // setSelectedGstCal(event.target.value);
    const { value } = event.target;
    setSelectedGstCal(value);
    if (value === "gst") {
      setSelectedGstCal(value);

      let boxPkgGst =
        (selectedBox?.price * selectedBox?.ligst) / 100 +
        (selectedBox?.price + selectedBox?.pkgcharge);
      let boxPkgCash = selectedBox?.price + selectedBox?.pkgcharge;

      let platPriceGst =
        (selectedPlating?.price *
          selectedPieceSets?.pcs *
          selectedBox?.box *
          selectedPlating?.ligst) /
        100 +
        selectedPlating?.price * selectedPieceSets?.pcs * selectedBox?.box ||
        0;
      let platPriceCash =
        selectedPlating?.price * selectedPieceSets?.pcs * selectedBox?.box || 0;

      let makPriceGst =
        makIncludeGst * selectedPieceSets?.pcs * selectedBox?.box || 0;
      let makPriceCash =
        makingPrice * selectedPieceSets?.pcs * selectedBox?.box || 0;

      let metPriceGst =
        (metalGram *
          selectedPieceSets?.pcs *
          selectedBox?.box *
          (metalPrice + metalDiff) *
          metalGst) /
        100 +
        metalGram *
        selectedPieceSets?.pcs *
        selectedBox?.box *
        (metalPrice + metalDiff) || 0;
      let metPriceCash =
        metalGram *
        selectedPieceSets?.pcs *
        selectedBox?.box *
        (metalPrice + metalDiff) || 0;

      let plasPriceGst =
        packingOption === "Plastic Dibbi"
          ? (plasticPrice * selectedBox?.box * plasticGst) / 100 +
          plasticPrice * selectedBox?.box
          : 0;
      let plasPriceCash =
        packingOption === "Plastic Dibbi" ? plasticPrice * selectedBox?.box : 0;

      let crysPriceGst =
        packingOption === "Crystal Dibbi"
          ? (crystalPrice * selectedBox?.box * crystalGst) / 100 +
          crystalPrice * selectedBox?.box
          : 0;
      let crysPriceCash =
        packingOption === "Crystal Dibbi" ? crystalPrice * selectedBox?.box : 0;

      let ployPriceGst =
        packingOption === "Polythene PP"
          ? (ployPrice * selectedBox?.box * ployGst) / 100 +
          ployPrice * selectedBox?.box
          : 0;
      let ployPriceCash =
        packingOption === "Polythene PP" ? ployPrice * selectedBox?.box : 0;

      let boxPriceGst =
        boxPkgGst +
        platPriceGst +
        makPriceGst +
        metPriceGst +
        plasPriceGst +
        crysPriceGst +
        ployPriceGst +
        mixplatPriceGst +
        mixmakPriceGst +
        mixmetPriceGst;
      setIncludeGST(boxPriceGst.toFixed(2));

      let setPriceGst = boxPriceGst / selectedBox?.box;
      setIncludeSets(setPriceGst.toFixed(2));

      let boxPriceCash =
        boxPkgCash +
        platPriceCash +
        makPriceCash +
        metPriceCash +
        plasPriceCash +
        crysPriceCash +
        ployPriceCash +
        mixplatingPrice +
        mixmakPrice +
        mixmetPrice;

      let profGst = (boxPriceCash * userObj.profit) / 100 + boxPriceGst;
      setIncludeProfit(profGst.toFixed(2));

      let profSet = profGst / selectedBox?.box;
      setIncludeProfitSet(profSet.toFixed(2));

      let profGstSet = boxPriceGst + userObj.price * selectedBox?.box;
      setIncludeProfitGst(profGstSet.toFixed(2));

      let profSetGst = profGstSet / selectedBox?.box;
      setIncludeProfitSetGst(profSetGst.toFixed(2));
    } else {
      let boxPkg = selectedBox?.price + selectedBox?.pkgcharge;
      let platingPrice =
        selectedPlating?.price * selectedPieceSets?.pcs * selectedBox?.box || 0;
      let makPrice =
        makingPrice * selectedPieceSets?.pcs * selectedBox?.box || 0;
      let metPrice =
        metalGram *
        selectedPieceSets?.pcs *
        selectedBox?.box *
        (metalPrice + metalDiff) || 0;
      let plasPrice =
        packingOption === "Plastic Dibbi" ? plasticPrice * selectedBox?.box : 0;
      let crysPrice =
        packingOption === "Crystal Dibbi" ? crystalPrice * selectedBox?.box : 0;
      let ployPrices =
        packingOption === "Polythene PP" ? ployPrice * selectedBox?.box : 0;

      let boxPrices =
        boxPkg +
        platingPrice +
        makPrice +
        metPrice +
        plasPrice +
        crysPrice +
        ployPrices +
        mixplatingPrice +
        mixmakPrice +
        mixmetPrice;
      setTotalCash(boxPrices.toFixed(2));

      let setPrice = boxPrices / selectedBox?.box;
      setSets(setPrice.toFixed(2));

      let profit = (boxPrices * userObj.profit) / 100 + boxPrices;
      setTotalProfit(profit.toFixed(2));

      let setProfit = profit / selectedBox?.box;
      setSetProfit(setProfit.toFixed(2));

      let profSet = boxPrices + userObj.price * selectedBox?.box;
      setTotalProfitSet(profSet.toFixed(2));

      let ProfSets = profSet / selectedBox?.box;
      setProfitSet(ProfSets.toFixed(2));
    }
  };

  const handleProfit = (e) => {
    setSelectedProfit(e.target.value);
  };

  const changeRadioValue = (value, id) => {
    const copyPho = Object.assign([], photo);
    for (const pho of copyPho) {
      if (pho.id === id) {
        pho.radioValue = value;
      } else {
        pho.radioValue = false;
      }
    }
    setPhoto(copyPho);
  };


  const SubmitUpdateProduct = () => {
    let fData = new FormData();
    fData.append("image1", photo[0].convertFile || new File([""], ''));
    fData.append("image2", photo[1].convertFile || new File([""], ''));
    fData.append("image3", photo[2].convertFile || new File([""], ''));
    fData.append("image4", photo[3].convertFile || new File([""], ''));
    fData.append("image5", photo[4].convertFile || new File([""], ''));
    fData.append("image6", photo[5].convertFile || new File([""], ''));
    fData.append("orgimage1", photo[0].file || new File([""], ''));
    fData.append("orgimage2", photo[1].file || new File([""], ''));
    fData.append("orgimage3", photo[2].file || new File([""], ''));
    fData.append("orgimage4", photo[3].file || new File([""], ''));
    fData.append("orgimage5", photo[4].file || new File([""], ''));
    fData.append("orgimage6", photo[5].file || new File([""], ''));
    fData.append(
      "product",
      new Blob([
        JSON.stringify({
          id: prdId,
          boxid: selectedBox?.id,
          cartooncharge: cartonPackingCharge,
          cat1id: simpleSelect1,
          cat2id: makingSingleId || singleMak,
          cat2multid: checkBoxMaking.toString(),
          catid: simpleSelect,
          ctndescription: cartonDescription || null,
          ctnquantity: cartonQuantity,
          description: prodDescription,
          extragram: metalSGram,
          golwirethaw: golwireThaw ? golwireThaw : null,
          img1active: photo[0].radioValue === true ? "1" : photo[0].radioValue === "1" ? "1" : "0",
          img2active: photo[1].radioValue === true ? "1" : photo[1].radioValue === "2" ? "1" : "0",
          img3active: photo[2].radioValue === true ? "1" : photo[2].radioValue === "3" ? "1" : "0",
          img4active: photo[3].radioValue === true ? "1" : photo[3].radioValue === "4" ? "1" : "0",
          img5active: photo[4].radioValue === true ? "1" : photo[4].radioValue === "5" ? "1" : "0",
          img6active: photo[5].radioValue === true ? "1" : photo[5].radioValue === "6" ? "1" : "0",
          itemcode: prodItemCode,
          metalgram: metalGram,
          metmultid: simpleSelectMetal,
          mfgdetails: prodMfgDetails,
          mfgrate: totalCash,
          pkgmultid: simpleSelectPlastic || simpleSelectCrystal || simpleSelectPloythene,
          pltmultid: selectedPlating?.id,
          profitactive: selectedProfit === "profit" ? "1" : "0",
          rate: selectedGstCal === "gst" ? selectedProfit === "profit" ? includeProfit : includeProfitGst : selectedProfit === "profit" ? totalProfit : totalProfitSet,
          readygram: readyGram,
          setsid: selectedPieceSets?.id,
          userid: userObj.id,
          mixmm: productId ? productId.slice(0, -1) : null,
          cash_gst: selectedGstCal === "gst" ? "1" : "0",
          active: checkedA === false ? "0" : "1"
        }),
      ])
    );
    axios.put(headApi + "/products", fData)
      .then((res) => {
        // console.log(res.data);
        if (res.data.message === "Data Updated Successfully !!!  & null & null & nul… & null & null & null & null & null & null & null") {
          window.location.reload(false);
        }
      })
  };

  useEffect(() => {
    if (selectedPieceSets) {
      let platingPrice =
        selectedPlating?.price *
        selectedPieceSets?.pcs *
        selectedBox?.box;
      setPlatVal(platingPrice.toFixed(2));
    }
    if (selectedBox) {
      let boxTotal = selectedBox?.price + selectedBox?.pkgcharge;
      setBoxVal(boxTotal);
    }
    if (metalGram) {
      let newMet =
        metalGram *
        selectedPieceSets?.pcs *
        selectedBox?.box *
        (metalPrice + metalDiff);
      setMetGramVal(newMet.toFixed(2));
    }
    if (simpleSelectMetal) {
      let newMet =
        metalGram *
        selectedPieceSets?.pcs *
        selectedBox?.box *
        (metalPrice + metalDiff);
      setMetGramVal(newMet.toFixed(2));
    }
    if (simpleSelectPloythene) {
      let ployTotal = ployPrice * selectedBox?.box;
      setPackVal(ployTotal.toFixed(2));
      // console.log("change", simpleSelectPloythene);
    }
    if (simpleSelectCrystal) {
      let crysTotal = crystalPrice * selectedBox?.box;
      setPackVal(crysTotal.toFixed(2));
    }
    if (simpleSelectPlastic) {
      let packTotal = plasticPrice * selectedBox?.box;
      setPackVal(packTotal.toFixed(2));
    }
    if (checkBoxMaking) {
      let makPrice = makingPrice * selectedPieceSets?.pcs * selectedBox?.box;
      setMakVal(makPrice);
    }
  }, [selectedPieceSets, selectedBox, metalGram, simpleSelectMetal, checkBoxMaking, simpleSelectPloythene, simpleSelectCrystal, simpleSelectPlastic])


  var mixmakPrice = 0;
  var mixmetPrice = 0;
  var mixplatingPrice = 0;
  var lbrmetprice = 0;
  var mixmakPriceGst = 0;
  var mixplatPriceGst = 0;
  var mixmetPriceGst = 0;

  const select = {
    width: "100%",
    maxWidth: 600
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      padding: 10,
    }),
    menu: (state) => ({
      color: '#d81b60',
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    }
  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <BiEdit />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Online Product Update</small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <label className={classe.selectLabel}>Select Product</label>
                  <Selects styles={customStyles} style={select} placeholder="Select Client Id..." value={simpleProductSelect} onChange={handleProductSimple} options={itemProd && itemProd.map((p) => {
                    return { label: p.id + "-> " + p.metalOption.readygram, value: p.id + "-> " + p.metalOption.readygram }
                  })} />
                </GridItem>

                <GridItem xs={12} sm={12} md={12}>
                  <FormControl fullWidth className={classe.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classe.selectLabel}
                    >
                      Select Category Name...
                    </InputLabel>

                    <Select
                      MenuProps={{
                        className: classe.selectMenu,
                      }}
                      classes={{
                        select: classe.select,
                      }}
                      value={simpleSelect}
                      onChange={handleSimple}
                      inputProps={{
                        name: "simpleSelect",
                        id: "simple-select",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classe.selectMenuItem,
                        }}
                      >
                        Select Category Name...
                      </MenuItem>
                      {items &&
                        items.map((item) => (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                            classes={{
                              root: classe.selectMenuItem,
                              selected: classe.selectMenuItemSelected,
                            }}
                          >
                            {item.description}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>{" "}
                <GridItem xs={12} sm={12} md={12}>
                  <FormControl fullWidth className={classe.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classe.selectLabel}
                    >
                      Select Bangles Size-MM...
                    </InputLabel>

                    <Select
                      MenuProps={{
                        className: classe.selectMenu,
                      }}
                      classes={{
                        select: classe.select,
                      }}
                      value={simpleSelect1}
                      onChange={handleSimple1}
                      inputProps={{
                        name: "simpleSelect1",
                        id: "simple-select-1",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classe.selectMenuItem,
                        }}
                      >
                        Select Bangles Size-MM...
                      </MenuItem>
                      {items1 &&
                        items1.map((item1) => (
                          <MenuItem
                            key={item1.id}
                            value={item1.id}
                            classes={{
                              root: classe.selectMenuItem,
                              selected: classe.selectMenuItemSelected,
                            }}
                          >
                            {item1.description}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>
                {catSize >= 1 ? (
                  <>
                    <GridItem xs={12} sm={12} md={5}>
                      <CustomInput
                        labelText="Select Product Id (ex.101,102,)"
                        id="productId"
                        inputProps={{
                          placeholder: "Product Id(ex.174,209) ...",
                        }}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        name="productId"
                        value={productId}
                        onChange={handleProductId}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <Button
                        color="rose"
                        className={classes.updateProfileButton}
                        onClick={() => handleProdId()}
                      >
                        Add /3MM
                      </Button>
                    </GridItem>
                  </>
                ) : (
                  ""
                )}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        {catSize >= 1 ? (
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <FaRing />
                </CardIcon>
                <h3 className={classes.cardIconTitle}>
                  <small>
                    Making & Plating Charge{" "}
                    <span style={{ color: "#d81b60" }}>₹ {mixlbrtotal}/-</span>{" "}
                    Metal Rate{" "}
                    <span style={{ color: "#d81b60" }}>₹ {mixmaktotal}/-</span>
                  </small>
                </h3>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={7}>
                    {prodId.map((i) => {
                      mixplatingPrice = mixplatingPrice + i.plating.price * i.sets.pcs * selectedBox?.box;
                      mixplatPriceGst = mixplatPriceGst + i.plating.taxamt * i.sets.pcs * selectedBox?.box;
                      mixmetPrice = mixmetPrice + i.metal.metalamt * i.metalOption.metalgram * i.sets.pcs * selectedBox?.box;
                      mixmetPriceGst = mixmetPriceGst + i.metal.taxincl * i.metalOption.metalgram * i.sets.pcs * selectedBox?.box;
                      return (
                        <p key={i.id} style={{ fontSize: "13px" }}>
                          <b>
                            {i.cat.description}&nbsp;
                            {i.banglessizemm.description}&nbsp;{i.sets.pcs}
                          </b>
                          <br />
                          <small>
                            {i.labourList.map((lbr) => {
                              mixmakPrice = mixmakPrice + lbr.price * i.sets.pcs * selectedBox?.box;
                              mixmakPriceGst = mixmakPriceGst + lbr.taxincl * i.sets.pcs * selectedBox?.box;
                              lbrmetprice = mixplatingPrice + mixmakPrice;
                              return (
                                <small
                                  key={lbr.id}
                                  style={{ fontSize: "14px" }}
                                >
                                  {lbr.description + " " + lbr.price + ", "}
                                </small>
                              );
                            })}
                            &nbsp;
                          </small>
                          <br />
                          <small style={{ fontSize: "14px" }}>
                            {i.plating.description.slice(8)} {i.plating.price}
                          </small>
                          <br />
                          <small style={{ fontSize: "14px" }}>
                            {i.metal.metdesc +
                              ", Price - " +
                              i.metal.price +
                              " + " +
                              i.metal.different +
                              " = " +
                              i.metal.metalamt +
                              "/-"}
                          </small>
                          <br />
                          {i.metalOption.golwirethaw +
                            ", Metal Gram - " +
                            i.metalOption.metalgram +
                            ", Ready Gram -  " +
                            i.metalOption.readygram +
                            ","}
                        </p>
                      );
                    })}
                    <h5>
                      Making{" "}<span style={{ color: "#d81b60" }}>{mixmakPrice.toFixed(2)}</span>{" "}+ Plating Charge{" "}<span style={{ color: "#d81b60" }}>{mixplatingPrice.toFixed(2)} = ₹{" "}{lbrmetprice.toFixed(2)}/-</span>
                    </h5>
                    <h5>
                      Metal Rate{" "}<span style={{ color: "#d81b60" }}>₹ {mixmetPrice.toFixed(2)}/-</span>
                    </h5>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        ) : (
          ""
        )}
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <FaRing />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Piece/Sets</small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {simpleProductSelect
                  ? itemSets &&
                  itemSets.map((sets) => (
                    <GridItem xs={12} sm={4} md={3} key={sets.id}>
                      <FormControlLabel
                        key={sets.id}
                        control={
                          <Radio
                            // checked={selectedPieceSets && selectedPieceSets
                            //     ? selectedPieceSets === sets.id
                            //     : sets.active === 1}
                            // checked={selectedPieceSets === sets}
                            checked={
                              selectedPieceSets && selectedPieceSets.id
                                ? selectedPieceSets?.id === sets?.id
                                : sets?.id === selectedPieceSets?.id
                            }
                            onChange={(e) => {
                              setSelectedPieceSets(sets);
                            }}
                            value={sets.id}
                            name="radioButton"
                            aria-label="A"
                            icon={
                              <FiberManualRecord
                                className={classesRadio.radioUnchecked}
                              />
                            }
                            checkedIcon={
                              <FiberManualRecord
                                className={classesRadio.radioChecked}
                              />
                            }
                            classes={{
                              checked: classesRadio.radioRose,
                              root: classesRadio.radioRoot,
                            }}
                          />
                        }
                        classes={{
                          label: classesRadio.label,
                          root: classesRadio.labelRoot,
                        }}
                      // label={sets.setdesc}
                      />
                      <label
                        style={{
                          color:
                            selectedPieceSets?.id === sets?.id
                              ? "#d81b60"
                              : "",
                        }}
                      >
                        {sets.setdesc}
                      </label>
                    </GridItem>
                  ))
                  : ""}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <FaBox />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Box + Labour ₹ {boxVal}/-</small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {simpleProductSelect
                  ? itemBox &&
                  itemBox.map((box) => (
                    <GridItem xs={12} sm={6} md={3} key={box.id}>
                      <FormControlLabel
                        key={box.id}
                        control={
                          <Radio
                            // checked={
                            //     selectedBox &&
                            //         selectedBox ? selectedBox === box.id
                            //         : box.active === 1
                            // }
                            // checked={selectedBox === box}
                            checked={
                              selectedBox && selectedBox.id
                                ? selectedBox?.id === box?.id
                                : box?.id === selectedBox?.id
                            }
                            onChange={(e) => {
                              setSelectedBox(box);
                              // console.log(selectedBox);
                              if (box?.id) {
                                let boxTotal = box?.price + box?.pkgcharge;
                                setBoxVal(boxTotal);
                              }
                            }}
                            value={box.id}
                            name="radio button demo"
                            aria-label="A"
                            icon={
                              <FiberManualRecord
                                className={classesRadio.radioUnchecked}
                              />
                            }
                            checkedIcon={
                              <FiberManualRecord
                                className={classesRadio.radioChecked}
                              />
                            }
                            classes={{
                              checked: classesRadio.radioRose,
                              root: classesRadio.radioRoot,
                            }}
                          />
                        }
                        classes={{
                          label: classesRadio.label,
                          root: classesRadio.labelRoot,
                        }}
                      // label={box.box + " " + box.brandname}
                      />
                      <label
                        style={{
                          color: box?.id === selectedBox?.id ? "#d81b60" : "",
                        }}
                      >
                        {box.box + " " + box.brandname}
                      </label>
                    </GridItem>
                  ))
                  : ""}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <FcTemplate />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Plating Option (Box ₹ {platVal})</small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {plating &&
                  plating.map((plat) => (
                    <GridItem xs={12} sm={6} md={4} key={plat.id}>
                      <FormControlLabel
                        key={plat.id}
                        control={
                          <Radio
                            // checked={
                            //     selectedPlating &&
                            //         selectedPlating ? selectedPlating === plat.id
                            //         : plat.active === 1
                            // }
                            // // checked={selectedPlating === plat.id}
                            // onChange={(e) => {
                            //     setSelectedPlating(plat.id);
                            //     // console.log(plat.id);
                            // }}
                            checked={
                              selectedPlating && selectedPlating.id
                                ? selectedPlating?.id === plat?.id
                                : plat?.id === selectedPlating?.id
                            }
                            onChange={(e) => {
                              setSelectedPlating(plat);
                              if (plat?.id) {
                                let platingPrice =
                                  plat?.price *
                                  selectedPieceSets?.pcs *
                                  selectedBox?.box;
                                setPlatVal(platingPrice.toFixed(2));
                              }
                            }}
                            value={plat.id}
                            name="radio button demo"
                            aria-label="A"
                            icon={
                              <FiberManualRecord
                                className={classesRadio.radioUnchecked}
                              />
                            }
                            checkedIcon={
                              <FiberManualRecord
                                className={classesRadio.radioChecked}
                              />
                            }
                            classes={{
                              checked: classesRadio.radioRose,
                              root: classesRadio.radioRoot,
                            }}
                          />
                        }
                        classes={{
                          label: classesRadio.label,
                          root: classesRadio.labelRoot,
                        }}
                      // label={plat.description + "> " + plat.price}
                      />
                      <label
                        style={{
                          color:
                            plat?.id === selectedPlating?.id ? "#d81b60" : "",
                        }}
                      >
                        {plat.description + "> " + plat.price}
                      </label>
                    </GridItem>
                  ))}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <FaUsers />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Making Charge Per (Box : ₹ {makVal.toFixed(2)}) </small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {items2 &&
                  items2.map((making) => (
                    <GridItem xs={12} sm={6} md={4} key={making.id}>
                      <FormControlLabel
                        key={making.id}
                        control={
                          <Checkbox
                            tabIndex={-1}
                            checked={checkBoxMaking.includes(making.id)}
                            // value={making}
                            name={making.description}
                            // onChange={handleMakingMulti}
                            onChange={(e) => handleCheckBoxMaking(e, making)}
                            checkedIcon={
                              <Check className={classCheck.checkedIcon} />
                            }
                            icon={
                              <Check className={classCheck.uncheckedIcon} />
                            }
                            classes={{
                              checked: classCheck.checkedRose,
                              root: classCheck.checkRoot,
                            }}
                          />
                        }
                        classes={{
                          label:
                            classCheck.label +
                            (makingCheckboxMaking === "error"
                              ? " " + classCheck.labelError
                              : ""),
                        }}
                      // label={making.description + "> " + making.price}
                      />
                      <label
                        style={{
                          color: checkBoxMaking.includes(making.id)
                            ? "#d81b60"
                            : "",
                        }}
                      >
                        {making.description + "> " + making.price}
                      </label>
                    </GridItem>
                  ))}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <GiWireCoil />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>
                  {" "}
                  Metal & Ready Gram Per Piece (Box ₹ {metGramVal})
                </small>
              </h3>
            </CardHeader>
            <CardBody>
              <h5 className={classes.cardIconTitle}>
                Metal Price ₹{" "}
                {simpleSelectMetal ? metalPrice + " + " + metalDiff : ""}
              </h5>
              <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                  <FormControl fullWidth className={classe.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classe.selectLabel}
                    >
                      Select Metal
                    </InputLabel>

                    <Select
                      MenuProps={{
                        className: classe.selectMenu,
                      }}
                      classes={{
                        select: classe.select,
                      }}
                      value={simpleSelectMetal}
                      onChange={handleSimpleMetal}
                      inputProps={{
                        name: "simpleSelect",
                        id: "simple-select",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classe.selectMenuItem,
                        }}
                      >
                        Select Metal
                      </MenuItem>
                      {metal &&
                        metal.map((item) => (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                            classes={{
                              root: classe.selectMenuItem,
                              selected: classe.selectMenuItemSelected,
                            }}
                          >
                            {item.metdesc +
                              "> " +
                              item.price +
                              " + " +
                              item.different}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <CustomInput
                    labelText="Ready Gram"
                    id="readyGram"
                    // inputProps={{
                    //   placeholder: "Ready Gram",
                    // }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="readyGram"
                    value={readyGram}
                    onChange={(e) => setReadyGram(e.target.value)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <CustomInput
                    labelText="Metal Gram"
                    id="metalGram"
                    // inputProps={{
                    //   placeholder: "Metal Gram",
                    // }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="metalGram"
                    value={metalGram}
                    onChange={(e) => {
                      const { value } = e.target;
                      setMetalGram(value);
                      if (value) {
                        let newMet =
                          value *
                          selectedPieceSets?.pcs *
                          selectedBox?.box *
                          (metalPrice + metalDiff);
                        setMetGramVal(newMet.toFixed(2));
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <CustomInput
                    labelText="Extra S Gram"
                    id="metalSGram"
                    // inputProps={{
                    //   placeholder: "Metal S Gram",
                    // }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="metalSGram"
                    value={metalSGram}
                    onChange={(e) => setMetalSGram(e.target.value)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <CustomInput
                    labelText="Thaw Golwire"
                    id="golwireThaw"
                    // inputProps={{
                    //   placeholder: "Golwire Thaw",
                    // }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="golwireThaw"
                    value={golwireThaw}
                    onChange={(e) => setGolwireThaw(e.target.value)}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <GiBoxUnpacking />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small> Packing Option (Box ₹ {packVal})</small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={packingOption === "Plastic Dibbi"}
                          onChange={handlePackingOption}
                          value="Plastic Dibbi"
                          name="Plastic Dibbi"
                          aria-label="Plastic Dibbi"
                          icon={
                            <FiberManualRecord
                              className={classesRadio.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classesRadio.radioChecked}
                            />
                          }
                          classes={{
                            checked: classesRadio.radioRose,
                            root: classesRadio.radioRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classesRadio.label,
                        root: classesRadio.labelRoot,
                      }}
                    // label="Plastic Dibbi"
                    />
                    <label
                      style={{
                        color:
                          packingOption === "Plastic Dibbi" ? "#d81b60" : "",
                      }}
                    >
                      Plastic Dibbi
                    </label>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControl fullWidth className={classe.selectFormControl}>
                      <InputLabel
                        htmlFor="simple-select"
                        className={classe.selectLabel}
                      >
                        Select Plastic
                      </InputLabel>

                      <Select
                        MenuProps={{
                          className: classe.selectMenu,
                        }}
                        classes={{
                          select: classe.select,
                        }}
                        value={simpleSelectPlastic}
                        onChange={handleSimplePlastic}
                        inputProps={{
                          name: "simpleSelect",
                          id: "simple-select",
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classe.selectMenuItem,
                          }}
                        >
                          Select Plastic
                        </MenuItem>
                        {plastic &&
                          plastic.map((plas) => (
                            <MenuItem
                              key={plas.id}
                              value={plas.id}
                              classes={{
                                root: classe.selectMenuItem,
                                selected: classe.selectMenuItemSelected,
                              }}
                            >
                              {plas.pkgdesc + "> " + plas.price}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={packingOption === "Crystal Dibbi"}
                          onChange={handlePackingOption}
                          value="Crystal Dibbi"
                          name="Crystal Dibbi"
                          aria-label="Crystal Dibbi"
                          icon={
                            <FiberManualRecord
                              className={classesRadio.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classesRadio.radioChecked}
                            />
                          }
                          classes={{
                            checked: classesRadio.radioRose,
                            root: classesRadio.radioRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classesRadio.label,
                        root: classesRadio.labelRoot,
                      }}
                    // label="Crystal Dibbi"
                    />
                    <label
                      style={{
                        color:
                          packingOption === "Crystal Dibbi" ? "#d81b60" : "",
                      }}
                    >
                      Crystal Dibbi
                    </label>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControl fullWidth className={classe.selectFormControl}>
                      <InputLabel
                        htmlFor="simple-select"
                        className={classe.selectLabel}
                      >
                        Select Crystal
                      </InputLabel>

                      <Select
                        MenuProps={{
                          className: classe.selectMenu,
                        }}
                        classes={{
                          select: classe.select,
                        }}
                        value={simpleSelectCrystal}
                        onChange={handleSimpleCrystal}
                        inputProps={{
                          name: "simpleSelect",
                          id: "simple-select",
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classe.selectMenuItem,
                          }}
                        >
                          Select Crystal
                        </MenuItem>
                        {crystal &&
                          crystal.map((crys) => (
                            <MenuItem
                              key={crys.id}
                              value={crys.id}
                              classes={{
                                root: classe.selectMenuItem,
                                selected: classe.selectMenuItemSelected,
                              }}
                            >
                              {crys.pkgdesc + "> " + crys.price}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={packingOption === "Polythene PP"}
                          onChange={handlePackingOption}
                          value="Polythene PP"
                          name="Polythene PP"
                          aria-label="Polythene PP"
                          icon={
                            <FiberManualRecord
                              className={classesRadio.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classesRadio.radioChecked}
                            />
                          }
                          classes={{
                            checked: classesRadio.radioRose,
                            root: classesRadio.radioRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classesRadio.label,
                        root: classesRadio.labelRoot,
                      }}
                    // label="Polythene PP"
                    />
                    <label
                      style={{
                        color:
                          packingOption === "Polythene PP" ? "#d81b60" : "",
                      }}
                    >
                      Polythene PP
                    </label>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControl fullWidth className={classe.selectFormControl}>
                      <InputLabel
                        htmlFor="simple-select"
                        className={classe.selectLabel}
                      >
                        Select Ploythene
                      </InputLabel>

                      <Select
                        MenuProps={{
                          className: classe.selectMenu,
                        }}
                        classes={{
                          select: classe.select,
                        }}
                        value={simpleSelectPloythene}
                        onChange={handleSimplePloythene}
                        inputProps={{
                          name: "simpleSelect",
                          id: "simple-select",
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classe.selectMenuItem,
                          }}
                        >
                          Select Ploythene
                        </MenuItem>
                        {ploythene &&
                          ploythene.map((ploy) => (
                            <MenuItem
                              key={ploy.id}
                              value={ploy.id}
                              classes={{
                                root: classe.selectMenuItem,
                                selected: classe.selectMenuItemSelected,
                              }}
                            >
                              {ploy.pkgdesc + "> " + ploy.price}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <AiOutlineCalculator />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Price Calculation</small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={6} sm={6} md={4}>
                  {/* <GridItem xs={12} sm={12} md={12}> */}
                  <FormControlLabel
                    control={
                      <Radio
                        checked={selectedGstCal === "gst"}
                        onChange={handleChangeGstCal}
                        value="gst"
                        name="gst"
                        aria-label="GST"
                        icon={
                          <FiberManualRecord
                            className={classesRadio.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord
                            className={classesRadio.radioChecked}
                          />
                        }
                        classes={{
                          checked: classesRadio.radioRose,
                          root: classesRadio.radioRoot,
                        }}
                      />
                    }
                    classes={{
                      label: classesRadio.label,
                      root: classesRadio.labelRoot,
                    }}
                  // label="GST"
                  />
                  <label
                    style={{ color: selectedGstCal === "gst" ? "#d81b60" : "" }}
                  >
                    GST
                  </label>
                </GridItem>
                <GridItem xs={6} sm={6} md={4}>
                  {/* <GridItem xs={12} sm={12} md={12}> */}
                  <FormControlLabel
                    control={
                      <Radio
                        checked={selectedGstCal === "cash"}
                        onClick={handleChangeGstCal}
                        value="cash"
                        name="cash"
                        aria-label="Cash"
                        icon={
                          <FiberManualRecord
                            className={classesRadio.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord
                            className={classesRadio.radioChecked}
                          />
                        }
                        classes={{
                          checked: classesRadio.radioRose,
                          root: classesRadio.radioRoot,
                        }}
                      />
                    }
                    classes={{
                      label: classesRadio.label,
                      root: classesRadio.labelRoot,
                    }}
                  // label="CASH"
                  />
                  <label
                    style={{
                      color: selectedGstCal === "cash" ? "#d81b60" : "",
                    }}
                  >
                    CASH
                  </label>
                </GridItem>
                <GridItem xs={6} sm={6} md={4}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={checkedA}
                        onChange={(event) => setCheckedA(event.target.checked)}
                        value="checkedA"
                        classes={{
                          switchBase: classe.switchBase,
                          checked: classes.switchChecked,
                          thumb: classe.switchIcon,
                          track: classe.switchBar,
                        }}
                      />
                    }
                    classes={{
                      label: classe.label,
                    }}
                    label={
                      checkedA === false ? (
                        "Offline"
                      ) : (
                        <span
                          style={{ color: checkedA === true ? "#d81b60" : "" }}
                        >
                          Online
                        </span>
                      )
                    }
                  />
                </GridItem>
              </GridContainer>
              <GridContainer style={{ border: "1px solid #eeeeee" }}>
                <GridItem xs={12} sm={4} ms={4}>
                  <h5 className={classes.cardIconTitle}>
                    <small>Making Charge</small>
                  </h5>
                </GridItem>
                <GridItem xs={6} sm={4} ms={4}>
                  {" "}
                  <h5 className={classes.cardIconTitle}>
                    <small>
                      ₹{" "}
                      {selectedGstCal && selectedGstCal === "gst"
                        ? includeGST
                        : totalCash}
                      /-
                    </small>
                  </h5>
                </GridItem>
                <GridItem xs={6} sm={4} ms={4}>
                  <h5 className={classes.cardIconTitle}>
                    <small>
                      Sets ₹{" "}
                      {selectedGstCal && selectedGstCal === "gst"
                        ? includeSets
                        : sets}
                      /-
                    </small>
                  </h5>
                </GridItem>
              </GridContainer>
              <GridContainer style={{ border: "1px solid #eeeeee" }}>
                <GridItem xs={12} sm={4} ms={4}>
                  <FormControlLabel
                    control={
                      <Radio
                        checked={selectedProfit === "profit"}
                        onClick={handleProfit}
                        value="profit"
                        name="profit"
                        aria-label="profit"
                        icon={
                          <FiberManualRecord
                            className={classesRadio.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord
                            className={classesRadio.radioChecked}
                          />
                        }
                        classes={{
                          checked: classesRadio.radioRose,
                          root: classesRadio.radioRoot,
                        }}
                      />
                    }
                    classes={{
                      label: classesRadio.label,
                      root: classesRadio.labelRoot,
                    }}
                    label="Profit 6.0%"
                  />
                </GridItem>
                <GridItem xs={6} sm={4} ms={4}>
                  <h5 className={classes.cardIconTitle}>
                    <small>
                      ₹{" "}
                      {selectedGstCal && selectedGstCal === "gst"
                        ? includeProfit
                        : totalProfit}
                      /-
                    </small>
                  </h5>
                </GridItem>
                <GridItem xs={6} sm={4} ms={4}>
                  <h5 className={classes.cardIconTitle}>
                    <small>
                      {" "}
                      Sets ₹{" "}
                      {selectedGstCal && selectedGstCal === "gst"
                        ? includeProfitSet
                        : setProfit}
                      /-
                    </small>
                  </h5>
                </GridItem>
              </GridContainer>
              <GridContainer style={{ border: "1px solid #eeeeee" }}>
                <GridItem xs={12} sm={4} ms={4}>
                  <FormControlLabel
                    control={
                      <Radio
                        checked={selectedProfit === "profit/sets"}
                        onClick={handleProfit}
                        value="profit/sets"
                        name="profit/sets"
                        aria-label="profit/sets"
                        icon={
                          <FiberManualRecord
                            className={classesRadio.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord
                            className={classesRadio.radioChecked}
                          />
                        }
                        classes={{
                          checked: classesRadio.radioRose,
                          root: classesRadio.radioRoot,
                        }}
                      />
                    }
                    classes={{
                      label: classesRadio.label,
                      root: classesRadio.labelRoot,
                    }}
                    label="Profit/Sets"
                  />
                </GridItem>
                <GridItem xs={6} sm={4} md={4}>
                  <h5 className={classes.cardIconTitle}>
                    <small>
                      ₹{" "}
                      {selectedGstCal && selectedGstCal === "gst"
                        ? includeProfitGst
                        : totalProfitSet}
                      /-
                    </small>
                  </h5>
                </GridItem>
                <GridItem xs={6} sm={4} md={4}>
                  <h5 className={classes.cardIconTitle}>
                    <small>
                      Sets ₹{" "}
                      {selectedGstCal && selectedGstCal === "gst"
                        ? includeProfitSetGst
                        : profitSet}
                      /-
                    </small>
                  </h5>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <BiFile />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Product Detail</small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Item Code"
                    id="prodItemCode"
                    // inputProps={{
                    //   placeholder: "Golwire Thaw",
                    // }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="prodItemCode"
                    value={prodItemCode}
                    onChange={(e) => setProdItemCode(e.target.value)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Product Manufacture Details"
                    id="prodMfgDetails"
                    // inputProps={{
                    //   placeholder: "Golwire Thaw",
                    // }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="prodMfgDetails"
                    value={prodMfgDetails}
                    onChange={(e) => setProdMfgDetails(e.target.value)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Product Description"
                    id="prodDescription"
                    // inputProps={{
                    //   placeholder: "Golwire Thaw",
                    // }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="prodDescription"
                    value={prodDescription}
                    onChange={(e) => setProdDescription(e.target.value)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Carton Description"
                    id="cartonDescription"
                    // inputProps={{
                    //   placeholder: "Golwire Thaw",
                    // }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="cartonDescription"
                    value={cartonDescription}
                    onChange={(e) => setCartonDescription(e.target.value)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Carton Quantity"
                    id="cartonQuantity"
                    // inputProps={{
                    //   placeholder: "Golwire Thaw",
                    // }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="cartonQuantity"
                    value={cartonQuantity}
                    onChange={(e) => setCartonQuantity(e.target.value)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Carton Packing Charge"
                    id="cartonPackingCharge"
                    // inputProps={{
                    //   placeholder: "Golwire Thaw",
                    // }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="cartonPackingCharge"
                    value={cartonPackingCharge}
                    onChange={(e) => setCartonPackingCharge(e.target.value)}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <BsImages />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Select Design Images</small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {photo &&
                  photo.map((pic, index) => pic.img ? (
                    <GridItem xs={12} sm={12} md={12} key={pic.id}>
                      <GridItem xs={12} sm={12} md={12}>
                        <div className="fileinput text-center">
                          <h4>Design No.{pic.no}</h4>
                          {/* <div
                            className={
                              "thumbnail" + (avatar ? " img-circle" : "")
                            }
                          >
                            {pic?.crop_im && (
                              <img src={pic.crop_im} alt="..." />
                            )}
                          </div>
                          <br /> */}
                          <input
                            type="file"
                            onChange={(e) => handleImageChange(e, pic.id)}
                            ref={elementsRef.current[index]}
                          />

                          {pic && pic.img && (
                            <div xs={12} sm={12} md={12}>
                              <ReactCrop
                                src={pic.img}
                                onImageLoaded={setImage}
                                crop={crop}
                                onChange={setCrop}
                              />
                              <Button
                                color="rose"
                                round
                                onClick={() => getCroppedImg(pic.id)}
                              >
                                {" "}
                                Crop Image
                              </Button>
                            </div>
                          )}


                          <div
                            className={
                              "thumbnail" + (avatar ? " img-circle" : "")
                            }
                          >
                            {pic?.crop_img && (
                              <img src={pic.crop_img} alt="..." />
                            )}
                          </div>

                          <div>
                            {pic && !pic.img ? (
                              <Button
                                color="rose"
                                round
                                {...addButtonProps}
                                onClick={() => handleClick(index)}
                              >
                                {avatar ? "Add Photo" : "Add Photo"}
                              </Button>
                            ) : (
                              <span>
                                <Button
                                  color="rose"
                                  round
                                  {...changeButtonProps}
                                  onClick={() => handleClick(index)}
                                >
                                  Change
                                </Button>
                                {avatar ? <br /> : null}
                                <Button
                                  color="rose"
                                  round
                                  {...removeButtonProps}
                                  onClick={() => handleRemove(pic.id)}
                                >
                                  <i className="fas fa-times" /> Remove
                                </Button>
                              </span>
                            )}
                          </div>
                        </div>
                        {/* </GridItem>
                    <GridItem xs={12} sm={12} md={12}> */}
                        <FormControlLabel
                          control={
                            <Radio
                              // checked={selectedPackingPhoto === pic.id}
                              checked={pic.radioValue}
                              onChange={(e) => {
                                changeRadioValue(e.target.value, pic.id)
                                // setSelectedPackingPhoto(pic.id)
                              }}
                              value={pic.id}
                              name="radio button demo"
                              aria-label="A"
                              icon={
                                <FiberManualRecord
                                  className={classesRadio.radioUnchecked}
                                />
                              }
                              checkedIcon={
                                <FiberManualRecord
                                  className={classesRadio.radioChecked}
                                />
                              }
                              classes={{
                                checked: classesRadio.radioRose,
                                root: classesRadio.radioRoot,
                              }}
                            />
                          }
                          classes={{
                            label: classesRadio.label,
                            root: classesRadio.labelRoot,
                          }}
                          label={<span style={{ color: pic.radioValue ? "#d81b60" : "" }}>Select Photo</span>}
                        />
                        {/* <label style={{color: pic.radioValue ? "#d81b60" : ""}}>Select Photo</label> */}
                      </GridItem>
                    </GridItem>
                  ) : (<GridItem xs={12} sm={12} md={2} key={pic.id}>
                    <GridItem xs={12} sm={12} md={12}>
                      <div className="fileinput text-center">
                        <h4>Design No.{pic.no}</h4>
                        {/* <div
                          className={
                            "thumbnail" + (avatar ? " img-circle" : "")
                          }
                        >
                          {pic?.crop_im && (
                            <img src={pic.crop_im} alt="..." />
                          )}
                        </div>
                        <br /> */}
                        <input
                          type="file"
                          onChange={(e) => handleImageChange(e, pic.id)}
                          ref={elementsRef.current[index]}
                        />

                        {pic && pic.img && (
                          <div xs={12} sm={12} md={12}>
                            <ReactCrop
                              src={pic.img}
                              onImageLoaded={setImage}
                              crop={crop}
                              onChange={setCrop}
                            />
                            <Button
                              color="rose"
                              round
                              onClick={() => getCroppedImg(pic.id)}
                            >
                              {" "}
                              Crop Image
                            </Button>
                          </div>
                        )}


                        <div
                          className={
                            "thumbnail" + (avatar ? " img-circle" : "")
                          }
                        >
                          {pic?.crop_img && (
                            <img src={pic.crop_img} alt="..." />
                          )}
                        </div>

                        <div>
                          {pic && !pic.img ? (
                            <Button
                              color="rose"
                              round
                              {...addButtonProps}
                              onClick={() => handleClick(index)}
                            >
                              {avatar ? "Add Photo" : "Add Photo"}
                            </Button>
                          ) : (
                            <span>
                              <Button
                                color="rose"
                                round
                                {...changeButtonProps}
                                onClick={() => handleClick(index)}
                              >
                                Change
                              </Button>
                              {avatar ? <br /> : null}
                              <Button
                                color="rose"
                                round
                                {...removeButtonProps}
                                onClick={() => handleRemove(pic.id)}
                              >
                                <i className="fas fa-times" /> Remove
                              </Button>
                            </span>
                          )}
                        </div>
                      </div>
                      {/* </GridItem>
                  <GridItem xs={12} sm={12} md={12}> */}
                      <FormControlLabel
                        control={
                          <Radio
                            // checked={selectedPackingPhoto === pic.id}
                            checked={pic.radioValue}
                            onChange={(e) => {
                              changeRadioValue(e.target.value, pic.id)
                              // setSelectedPackingPhoto(pic.id)
                            }}
                            value={pic.id}
                            name="radio button demo"
                            aria-label="A"
                            icon={
                              <FiberManualRecord
                                className={classesRadio.radioUnchecked}
                              />
                            }
                            checkedIcon={
                              <FiberManualRecord
                                className={classesRadio.radioChecked}
                              />
                            }
                            classes={{
                              checked: classesRadio.radioRose,
                              root: classesRadio.radioRoot,
                            }}
                          />
                        }
                        classes={{
                          label: classesRadio.label,
                          root: classesRadio.labelRoot,
                        }}
                        label={<span style={{ color: pic.radioValue ? "#d81b60" : "" }}>Select Photo</span>}
                      />
                      {/* <label style={{color: pic.radioValue ? "#d81b60" : ""}}>Select Photo</label> */}
                    </GridItem>
                  </GridItem>))}
              </GridContainer>
              <Button
                color="rose"
                className={classes.updateProfileButton}
                onClick={SubmitUpdateProduct}
              >
                Save
              </Button>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

OnlineProductUpdate.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
};

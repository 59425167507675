import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import { headApi } from "utils/headApi";
import { localApi } from "utils/imgApi";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import styleSweet from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";

import { credentials } from "utils/imgApi";

const useStyles = makeStyles(styles);
const useStyleSweet = makeStyles(styleSweet);

export default function AddUnit(props) {
  // const headApi = `https://api.bangles.org.in/api/rest`;

  const [title, setTitle] = React.useState("");
  const [subUnit, setSubUnit] = React.useState("");
  const [factor, setFactor] = React.useState("");

  const [titleErr, setTitleErr] = React.useState("");
  const [subUnitErr, setSubUnitErr] = React.useState("");
  const [factorErr, setFactorErr] = React.useState("");

  const [submitted, setSubmitted] = React.useState(false);
  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");
  const [btn, setBtn] = React.useState(false);

  const classes = useStyles();
  const classSweet = useStyleSweet();

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  const SubmitAddUnit = () => {
    let errorCount = 0;
    if (title === "" || !title) {
      errorCount++;
      setTitleErr("Title is Required");
    }

    if (subUnit === "" || !subUnit) {
      errorCount++;
      setSubUnitErr("SunUnit is Required");
    }

    if (factor === "" || !factor) {
      errorCount++;
      setFactorErr("Factor is Required");
    }

    if (errorCount === 0) {
      setBtn(true);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Basic ${credentials}`,
        },
        body: JSON.stringify({
          title: title,
          subunit: subUnit,
          con_factor: factor,
        }),
      };

      fetch(localApi + `/unit/add/${userObj.id}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          if (data.error == true) {
            alertTypeOps("success");
            alertMsgOps("Succesfully !!!");
            setSubmitted(true);
          } else {
            alertTypeOps("danger");
            alertMsgOps("Already Exits");
            setSubmitted(true);
          }
        });
    }
  };

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  return (
    <div>
      <GridContainer justify="center" alignItems="center">
        <GridItem xs={12} sm={6} md={6}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <AddIcon />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Add Unit</small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {submitted ? (
                  <SweetAlert
                    type={alertType}
                    style={{ display: "block", marginTop: "-100px" }}
                    title={alertMsg}
                    onConfirm={() => hideAlert()}
                    confirmBtnCssClass={
                      classSweet.button + " " + classSweet.success
                    }
                  >Added Unit</SweetAlert>
                ) : null}{" "}
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Title..."
                    id="title"
                    inputProps={{
                      placeholder: "Title ...",
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="title"
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value);
                      setTitleErr("");
                    }}
                    required={true}
                  />
                  <span style={{ color: "red" }}>{titleErr}</span>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="SubUnit..."
                    id="subUnit"
                    inputProps={{
                      placeholder: "Sub Unit...",
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="subUnit"
                    value={subUnit}
                    onChange={(e) => {
                      setSubUnit(e.target.value);
                      setSubUnitErr("");
                    }}
                    required={true}
                  />
                  <span style={{ color: "red" }}>{subUnitErr}</span>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Con Factor..."
                    id="factor"
                    inputProps={{
                      placeholder: "Con Factor...",
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="factor"
                    value={factor}
                    onChange={(e) => {
                      setFactor(e.target.value);
                      setFactorErr("");
                    }}
                    required={true}
                  />
                  <span style={{ color: "red" }}>{factorErr}</span>
                </GridItem>
              </GridContainer>
              {/* <Button
                color="rose"
                onClick={SubmitAddUnit}
                className={classes.updateProfileButton}
              >
                Save
              </Button> */}
              {btn === true ? (
                <Button className={classes.updateProfileButton}>Save</Button>
              ) : (
                <Button
                  color="rose"
                  onClick={SubmitAddUnit}
                  className={classes.updateProfileButton}
                >
                  Save
                </Button>
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

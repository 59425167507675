import React, { useEffect } from "react";
import { produce } from "immer";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { headApi } from "utils/headApi";
import { BiEdit } from "react-icons/bi";
import Badge from "components/Badge/Badge.js";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Tooltip from "@material-ui/core/Tooltip";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import SweetAlert from "react-bootstrap-sweetalert";
import styleSweet from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import style from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import { localApi } from "utils/imgApi";
import styRadio from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import styHoever from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

import { credentials } from "utils/imgApi";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(style);
const useStyless = makeStyles(styRadio);
const useStyleSweet = makeStyles(styleSweet);
const useHoever = makeStyles(styHoever);

export default function ViewAddress(props) {
  const [addressList, setAddressList] = React.useState([]);
  const [checkOldAddress, setCheckOldAddress] = React.useState(false);
  const [checkedFullSize, setCheckedFullSize] = React.useState(false);
  const [cartId, setCartId] = React.useState();

  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);

  const [submitteds, setSubmitteds] = React.useState(false);
  const [submittedConfirm, setSubmittedConfirm] = React.useState(false);

  const classes = useStyles();
  const classe = useStyle();
  const classesRadio = useStyless();
  const classSweet = useStyleSweet();
  const classeHover = useHoever();

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  async function getUsers() {
    const api = localApi + `/adminAddress/list/byAdminId/${userObj && userObj.id}`;
    const result = await fetch(api, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getResult = await result.json();
    setAddressList(getResult && getResult);
    // console.log(getResult.AddressList);
  }

  useEffect(() => {
    getUsers();
  }, []);

  const handleRemove = (a) => {
    setSubmitteds(true);
    setCartId(a);
  };

  const handleConfirm = () => {
    fetch(localApi + `/adminAddress/delete/byId/${cartId.id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        if (res.message === "Deleted Successfully !!!") {
          alertTypeOps("success");
          alertMsgOps("Deleted Succesfully !!!");
          setSubmittedConfirm(true);
        }
      });
  };

  const handleActive = (a) => {
    if (checkedFullSize === false) {
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Basic ${credentials}`,
        },
        body: JSON.stringify({
          country: "India",
        }),
      };

      fetch(
        localApi + `/adminAddress/update/byAdminId/${userObj.id}/${a.id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          if (data.message === "Data Updated Successfully !!!") {
            alertTypeOps("success");
            alertMsgOps("Your delivery Updated Succesfully");
            setSubmitted(true);
          }
        });
    }
  };

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  const hideAlerts = () => {
    setSubmitteds(false);
    setSubmittedConfirm(false);
    window.location.reload(false);
  };

  return (
    <div>
      <GridContainer justify="center" alignItems="center">
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <BiEdit />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Select a delivery address</small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer justify="center" alignItems="center">
                {" "}
                {/* <h5>hello</h5> */}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridContainer>
          {submitted ? (
            <SweetAlert
              type={alertType}
              style={{ display: "block", marginTop: "-100px" }}
              title={alertMsg}
              onConfirm={() => hideAlert()}
              confirmBtnCssClass={classSweet.button + " " + classSweet.success}
            />
          ) : null}
          {submitteds ? (
            <SweetAlert
              warning
              style={{ display: "block", marginTop: "100px" }}
              title="Are you sure?"
              onConfirm={handleConfirm}
              onCancel={() => hideAlerts()}
              confirmBtnCssClass={classSweet.button + " " + classSweet.success}
              cancelBtnCssClass={classSweet.button + " " + classSweet.danger}
              confirmBtnText="Yes, delete it!"
              cancelBtnText="Cancel"
              showCancel
            />
          ) : null}

          {submittedConfirm ? (
            <SweetAlert
              success
              style={{ display: "block", marginTop: "100px" }}
              title="Deleted!"
              onConfirm={() => hideAlerts()}
              onCancel={() => hideAlerts()}
              confirmBtnCssClass={classSweet.button + " " + classSweet.success}
            >
              Your imaginary file has been deleted.
            </SweetAlert>
          ) : null}
          {addressList &&
            addressList.map((a) => (
              <GridItem xs={12} sm={12} md={4} key={a.id}>
                <Card>
                  <CardBody>
                    <p className={classes.cardDescription}>
                      {a && a.company
                        ? a.company
                        : a.firstName + " " + a.lastName}

                      <small style={{ float: "right", marginRight: "5px" }}>
                        <Tooltip
                          id="tooltip-top"
                          title="Deliver to this address"
                          placement="left"
                          classes={{ tooltip: classeHover.tooltip }}
                        >
                          <Switch
                            onChange={(e) => {
                              setCheckedFullSize(e.target.checked);
                              handleActive(a);
                            }}
                            key={a.id}
                            value={a.id}
                            checked={
                              checkedFullSize && checkedFullSize
                                ? checkedFullSize === a.id
                                : a.active === true
                            }
                            id={a.id}
                            name="checkedFullSize"
                            inputProps={{
                              "aria-label": "secondary checkbox",
                            }}
                          />
                        </Tooltip>
                      </small>
                      <span
                        style={{
                          marginTop: "0px",
                          float: "right",
                          marginRight: "5px",
                        }}
                      >
                        <Tooltip
                          id="tooltip-top"
                          title="Remove"
                          placement="right"
                          classes={{ tooltip: classeHover.tooltip }}
                        >
                          <Button
                            color="danger"
                            simple
                            justIcon
                            onClick={() => handleRemove(a)}
                            size="lg"
                            style={{ marginTop: "0px" }}
                          >
                            <DeleteForeverIcon
                              className={classeHover.underChartIcons}
                            />
                          </Button>
                        </Tooltip>
                      </span>
                    </p>
                    <p className={classes.cardDescription}>
                      {a.active === true ? (
                        <Badge color="success">{a.addressType}</Badge>
                      ) : (
                        a.addressType
                      )}
                    </p>
                    <p className={classes.cardDescription}>
                      {a.plotHouseNo + " " + a.areaColony}
                      <br />
                      {a.landMark + " " + a.road + " - " + a.zipCode}
                      <br />
                      {a.city + ", " + a.state + ", " + a.country}
                    </p>
                  </CardBody>
                </Card>
              </GridItem>
            ))}
        </GridContainer>
      </GridContainer>
    </div>
  );
}

import React, { useEffect } from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { localApi } from "utils/imgApi";
import { useHistory, useLocation } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import { IoLogoWhatsapp } from "react-icons/io";
import { ImMobile } from "react-icons/im";
import { MdEmail } from "react-icons/md";
import styles from "assets/jss/material-dashboard-pro-react/views/labourAllBillViewStyle";
import logo from "assets/img/apple-admin-icon.png";

const useStyles = makeStyles(styles);

export default function LabourAllBillViewPrint() {
  const [billView, setBillView] = React.useState([]);
  const [adminAddress, setAdminAddress] = React.useState([]);
  const [regAddress1, setRegAddress1] = React.useState([]);
  const [regAddress2, setRegAddress2] = React.useState([]);

  const classTable = useStyles();

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  const { state } = useLocation();
  // console.log(state);

  async function getProd() {
    const get = localApi + `/adminAddress/list/byAdminId/${userObj && userObj.id}`;
    const results = await fetch(get);
    const res = await results.json();
    setAdminAddress(res && res.filter((a) => a.addressType === "GSTIN ADDRESS" && a.active === true));
  }

  async function getClient() {
    const api =
      localApi +
      `/regAddress/list/byAdminAndRegisterId/${userObj && userObj.id}/${state[0] && state[0].reggid}`;
    const result = await fetch(api);
    const getResult = await result.json();
    setRegAddress1(getResult[0] && getResult[0]);
    setRegAddress2(getResult[1] && getResult[1]);
  }

  async function getList() {
    const getList = await fetch(
      localApi + `/metal/list/byAdminId/${userObj && userObj.id}`
    );
    const res = await getList.json();
    setBillView(res && res);
  }

  useEffect(() => {
    getProd();
    getClient();
    getList();
  }, []);

  

  return (
    <div className={classTable.productPage}>
      <GridContainer justify="center" alignItems="center">
        <div className={classTable.container}>
          <GridContainer className={classTable.borderLine + "" + classTable.headStamp}>
            <GridItem md={2} sm={2} xs={2}>
              <img src={logo} style={{ marginTop: "10px", height: "100px" }} />
            </GridItem>
            <GridItem
              md={8}
              sm={8}
              xs={8}
              className={
                classTable.mlAuto + " " + classTable.mrAuto + " " + classTable.textCenter
              }
            >
              <span className={classTable.invoiceName}>TAX INVOICE</span>
              <br />
              <span className={classTable.companyName}>
                {adminAddress.map((a) => a.company)}
              </span>
              <br />
              <div className={classTable.space}>
                <span className={classTable.address}>
                  {adminAddress.map((a) => a.plotHouseNo)}{" "}
                  {adminAddress.map((a) => a.areaColony)}{" "}
                  {adminAddress.map((a) => a.landMark)}{" "}
                  {adminAddress.map((a) => a.road)}
                  <br />
                  {adminAddress.map((a) => a.city)} {"-"}{" "}
                  {adminAddress.map((a) => a.zipCode)},{" "}
                  {adminAddress.map((a) => a.state)}, INDIA.
                  <br />{" "}
                  <strong className={classTable.gst}>
                    GSTIN : {adminAddress.map((a) => a.gstIn)}
                  </strong>{" "}
                </span>
                <span className={classTable.mobileTel}>
                  <ImMobile /> {adminAddress.map((a) => a.mobile)}{" "}
                  <IoLogoWhatsapp /> {adminAddress.map((a) => a.whatsApp)}{" "}
                  <MdEmail /> {userObj.email}
                  {/* {adminAddress.map((a) => a.email)} */}
                </span>
              </div>
            </GridItem>
            <GridItem md={2} sm={2} xs={2} className={classTable.originalCopy}>
              <span>Original Copy</span>
            </GridItem>
          </GridContainer>
          <Table
            hover
            tableHead={[
              <span className={classTable.tableHead} key="sr">
                Sr.No.
              </span>,
              <span className={classTable.tableHead} key="bn">
                Bill.No.
              </span>,
              <span className={classTable.tableHead} key="dt">
                Date
              </span>,
              <span className={classTable.tableHead} key="pn">
                Party Name
              </span>,
              <span className={classTable.tableHead} key="des">
                Description of Goods
              </span>,
              <span
                className={classTable.tableHead + " " + classTable.headRight}
                key="qty"
              >
                Quantity
              </span>,
              <span
                className={classTable.tableHead + " " + classTable.headRight}
                key="ut"
              >
                Unit
              </span>,
              <span
                className={classTable.tableHead + " " + classTable.headRight}
                key="pri"
              >
                Price
              </span>,
              <span
                className={classTable.tableHead + " " + classTable.headRight}
                key="amt"
              >
                Amount
              </span>,
            ]}
            tableData={[
              ...state.map((c, index) => ({
                color: c.taxApply === true ? "success" : "",
                data: [
                  <span className={classTable.listNumber} key={c.id}>
                    {index + 1}
                  </span>,
                  <span className={classTable.listNumber} key={c.id}>
                    {c.id}
                  </span>,
                  <span className={classTable.listNumber} key={c.id}>
                    {moment(c.pickerdate).format('MM-DD-YYYY')}
                  </span>,
                  <span className={classTable.listNumber} key={c.id}>
                    {c.firstName + " " + c.lastName}
                  </span>,
                  <span key={c.id}>
                    <small className={classTable.listNumber}>
                      {c.title} - {c.description}
                    </small>
                    <br />
                    <GridContainer className={classTable.sizePadding}>
                      {c.productSize.s1x8 != 0 ? (
                        <span
                          className={
                            classTable.textCenter +
                            " " +
                            classTable.sizeSpan +
                            " " +
                            classTable.listDescriptionMM
                          }
                        >
                          {c.productSize.s1x8 == null
                            ? ""
                            : c.productSize.s1x8 == 0
                            ? ""
                            : "1x8"}
                          <br />
                          {c.productSize.s1x8 == null
                            ? ""
                            : c.productSize.s1x8 == 0
                            ? ""
                            : c.productSize.s1x8}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s1x10 != 0 ? (
                        <span
                          className={
                            classTable.textCenter +
                            " " +
                            classTable.sizeSpan +
                            " " +
                            classTable.listDescriptionMM
                          }
                        >
                          {c.productSize.s1x10 == null
                            ? ""
                            : c.productSize.s1x10 == 0
                            ? ""
                            : "1x10"}
                          <br />
                          {c.productSize.s1x10 == null
                            ? ""
                            : c.productSize.s1x10 == 0
                            ? ""
                            : c.productSize.s1x10}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s1x12 != 0 ? (
                        <span
                          className={
                            classTable.textCenter +
                            " " +
                            classTable.sizeSpan +
                            " " +
                            classTable.listDescriptionMM
                          }
                        >
                          {c.productSize.s1x12 == null
                            ? ""
                            : c.productSize.s1x12 == 0
                            ? ""
                            : "1x12"}
                          <br />
                          {c.productSize.s1x12 == null
                            ? ""
                            : c.productSize.s1x12 == 0
                            ? ""
                            : c.productSize.s1x12}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s1x14 != 0 ? (
                        <span
                          className={
                            classTable.textCenter +
                            " " +
                            classTable.sizeSpan +
                            " " +
                            classTable.listDescriptionMM
                          }
                        >
                          {c.productSize.s1x14 == null
                            ? ""
                            : c.productSize.s1x14 == 0
                            ? ""
                            : "1x14"}
                          <br />
                          {c.productSize.s1x14 == null
                            ? ""
                            : c.productSize.s1x14 == 0
                            ? ""
                            : c.productSize.s1x14}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s2x0 != 0 ? (
                        <span
                          className={
                            classTable.textCenter +
                            " " +
                            classTable.sizeSpan +
                            " " +
                            classTable.listDescriptionMM
                          }
                        >
                          {c.productSize.s2x0 == null
                            ? ""
                            : c.productSize.s2x0 == 0
                            ? ""
                            : "2x0"}
                          <br />
                          {c.productSize.s2x0 == null
                            ? ""
                            : c.productSize.s2x0 == 0
                            ? ""
                            : c.productSize.s2x0}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s2x2 != 0 ? (
                        <span
                          className={
                            classTable.textCenter +
                            " " +
                            classTable.sizeSpan +
                            " " +
                            classTable.listDescriptionMM
                          }
                        >
                          {c.productSize.s2x2 == null
                            ? ""
                            : c.productSize.s2x2 == 0
                            ? ""
                            : "2x2"}
                          <br />
                          {c.productSize.s2x2 == null
                            ? ""
                            : c.productSize.s2x2 == 0
                            ? ""
                            : c.productSize.s2x2}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s2x4 != 0 ? (
                        <span
                          className={
                            classTable.textCenter +
                            " " +
                            classTable.sizeSpan +
                            " " +
                            classTable.listDescriptionMM
                          }
                        >
                          {c.productSize.s2x4 == null
                            ? ""
                            : c.productSize.s2x4 == 0
                            ? ""
                            : "2x4"}
                          <br />
                          {c.productSize.s2x4 == null
                            ? ""
                            : c.productSize.s2x4 == 0
                            ? ""
                            : c.productSize.s2x4}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s2x6 != 0 ? (
                        <span
                          className={
                            classTable.textCenter +
                            " " +
                            classTable.sizeSpan +
                            " " +
                            classTable.listDescriptionMM
                          }
                        >
                          {c.productSize.s2x6 == null
                            ? ""
                            : c.productSize.s2x6 == 0
                            ? ""
                            : "2x6"}
                          <br />
                          {c.productSize.s2x6 == null
                            ? ""
                            : c.productSize.s2x6 == 0
                            ? ""
                            : c.productSize.s2x6}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s2x8 != 0 ? (
                        <span
                          className={
                            classTable.textCenter +
                            " " +
                            classTable.sizeSpan +
                            " " +
                            classTable.listDescriptionMM
                          }
                        >
                          {c.productSize.s2x8 == null
                            ? ""
                            : c.productSize.s2x8 == 0
                            ? ""
                            : "2x8"}
                          <br />
                          {c.productSize.s2x8 == null
                            ? ""
                            : c.productSize.s2x8 == 0
                            ? ""
                            : c.productSize.s2x8}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s2x10 != 0 ? (
                        <span
                          className={
                            classTable.textCenter +
                            " " +
                            classTable.sizeSpan +
                            " " +
                            classTable.listDescriptionMM
                          }
                        >
                          {c.productSize.s2x10 == null
                            ? ""
                            : c.productSize.s2x10 == 0
                            ? ""
                            : "2x10"}
                          <br />
                          {c.productSize.s2x10 == null
                            ? ""
                            : c.productSize.s2x10 == 0
                            ? ""
                            : c.productSize.s2x10}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s2x12 != 0 ? (
                        <span
                          className={
                            classTable.textCenter +
                            " " +
                            classTable.sizeSpan +
                            " " +
                            classTable.listDescriptionMM
                          }
                        >
                          {c.productSize.s2x12 == null
                            ? ""
                            : c.productSize.s2x12 == 0
                            ? ""
                            : "2x12"}
                          <br />
                          {c.productSize.s2x12 == null
                            ? ""
                            : c.productSize.s2x12 == 0
                            ? ""
                            : c.productSize.s2x12}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s2x14 != 0 ? (
                        <span
                          className={
                            classTable.textCenter +
                            " " +
                            classTable.sizeSpan +
                            " " +
                            classTable.listDescriptionMM
                          }
                        >
                          {c.productSize.s2x14 == null
                            ? ""
                            : c.productSize.s2x14 == 0
                            ? ""
                            : "2x14"}
                          <br />
                          {c.productSize.s2x14 == null
                            ? ""
                            : c.productSize.s2x14 == 0
                            ? ""
                            : c.productSize.s2x14}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s3x0 != 0 ? (
                        <span
                          className={
                            classTable.textCenter +
                            " " +
                            classTable.sizeSpan +
                            " " +
                            classTable.listDescriptionMM
                          }
                        >
                          {c.productSize.s3x0 == null
                            ? ""
                            : c.productSize.s3x0 == 0
                            ? ""
                            : "3x0"}
                          <br />
                          {c.productSize.s3x0 == null
                            ? ""
                            : c.productSize.s3x0 == 0
                            ? ""
                            : c.productSize.s3x0}
                        </span>
                      ) : (
                        ""
                      )}
                    </GridContainer>
                    <GridContainer className={classTable.sizePadding}>
                      {c.productSize.s1x9 != 0 ? (
                        <span
                          className={
                            classTable.textCenter +
                            " " +
                            classTable.sizeSpan +
                            " " +
                            classTable.listDescriptionMM
                          }
                        >
                          {c.productSize.s1x9 == null
                            ? ""
                            : c.productSize.s1x9 == 0
                            ? ""
                            : "1x9"}
                          <br />
                          {c.productSize.s1x9 == null
                            ? ""
                            : c.productSize.s1x9 == 0
                            ? ""
                            : c.productSize.s1x9}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s1x11 != 0 ? (
                        <span
                          className={
                            classTable.textCenter +
                            " " +
                            classTable.sizeSpan +
                            " " +
                            classTable.listDescriptionMM
                          }
                        >
                          {c.productSize.s1x11 == null
                            ? ""
                            : c.productSize.s1x11 == 0
                            ? ""
                            : "1x11"}
                          <br />
                          {c.productSize.s1x11 == null
                            ? ""
                            : c.productSize.s1x11 == 0
                            ? ""
                            : c.productSize.s1x11}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s1x13 != 0 ? (
                        <span
                          className={
                            classTable.textCenter +
                            " " +
                            classTable.sizeSpan +
                            " " +
                            classTable.listDescriptionMM
                          }
                        >
                          {c.productSize.s1x13 == null
                            ? ""
                            : c.productSize.s1x13 == 0
                            ? ""
                            : "1x13"}
                          <br />
                          {c.productSize.s1x13 == null
                            ? ""
                            : c.productSize.s1x13 == 0
                            ? ""
                            : c.productSize.s1x13}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s1x15 != 0 ? (
                        <span
                          className={
                            classTable.textCenter +
                            " " +
                            classTable.sizeSpan +
                            " " +
                            classTable.listDescriptionMM
                          }
                        >
                          {c.productSize.s1x15 == null
                            ? ""
                            : c.productSize.s1x15 == 0
                            ? ""
                            : "1x15"}
                          <br />
                          {c.productSize.s1x15 == null
                            ? ""
                            : c.productSize.s1x15 == 0
                            ? ""
                            : c.productSize.s1x15}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s2x1 != 0 ? (
                        <span
                          className={
                            classTable.textCenter +
                            " " +
                            classTable.sizeSpan +
                            " " +
                            classTable.listDescriptionMM
                          }
                        >
                          {c.productSize.s2x1 == null
                            ? ""
                            : c.productSize.s2x1 == 0
                            ? ""
                            : "2x1"}
                          <br />
                          {c.productSize.s2x1 == null
                            ? ""
                            : c.productSize.s2x1 == 0
                            ? ""
                            : c.productSize.s2x1}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s2x3 != 0 ? (
                        <span
                          className={
                            classTable.textCenter +
                            " " +
                            classTable.sizeSpan +
                            " " +
                            classTable.listDescriptionMM
                          }
                        >
                          {c.productSize.s2x3 == null
                            ? ""
                            : c.productSize.s2x3 == 0
                            ? ""
                            : "2x3"}
                          <br />
                          {c.productSize.s2x3 == null
                            ? ""
                            : c.productSize.s2x3 == 0
                            ? ""
                            : c.productSize.s2x3}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s2x5 != 0 ? (
                        <span
                          className={
                            classTable.textCenter +
                            " " +
                            classTable.sizeSpan +
                            " " +
                            classTable.listDescriptionMM
                          }
                        >
                          {c.productSize.s2x5 == null
                            ? ""
                            : c.productSize.s2x5 == 0
                            ? ""
                            : "2x5"}
                          <br />
                          {c.productSize.s2x5 == null
                            ? ""
                            : c.productSize.s2x5 == 0
                            ? ""
                            : c.productSize.s2x5}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s2x7 != 0 ? (
                        <span
                          className={
                            classTable.textCenter +
                            " " +
                            classTable.sizeSpan +
                            " " +
                            classTable.listDescriptionMM
                          }
                        >
                          {c.productSize.s2x7 == null
                            ? ""
                            : c.productSize.s2x7 == 0
                            ? ""
                            : "2x7"}
                          <br />
                          {c.productSize.s2x7 == null
                            ? ""
                            : c.productSize.s2x7 == 0
                            ? ""
                            : c.productSize.s2x7}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s2x9 != 0 ? (
                        <span
                          className={
                            classTable.textCenter +
                            " " +
                            classTable.sizeSpan +
                            " " +
                            classTable.listDescriptionMM
                          }
                        >
                          {c.productSize.s2x9 == null
                            ? ""
                            : c.productSize.s2x9 == 0
                            ? ""
                            : "2x9"}
                          <br />
                          {c.productSize.s2x9 == null
                            ? ""
                            : c.productSize.s2x9 == 0
                            ? ""
                            : c.productSize.s2x9}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s2x11 != 0 ? (
                        <span
                          className={
                            classTable.textCenter +
                            " " +
                            classTable.sizeSpan +
                            " " +
                            classTable.listDescriptionMM
                          }
                        >
                          {c.productSize.s2x11 == null
                            ? ""
                            : c.productSize.s2x11 == 0
                            ? ""
                            : "2x11"}
                          <br />
                          {c.productSize.s2x11 == null
                            ? ""
                            : c.productSize.s2x11 == 0
                            ? ""
                            : c.productSize.s2x11}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s2x13 != 0 ? (
                        <span
                          className={
                            classTable.textCenter +
                            " " +
                            classTable.sizeSpan +
                            " " +
                            classTable.listDescriptionMM
                          }
                        >
                          {c.productSize.s2x13 == null
                            ? ""
                            : c.productSize.s2x13 == 0
                            ? ""
                            : "2x13"}
                          <br />
                          {c.productSize.s2x13 == null
                            ? ""
                            : c.productSize.s2x13 == 0
                            ? ""
                            : c.productSize.s2x13}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s2x15 != 0 ? (
                        <span
                          className={
                            classTable.textCenter +
                            " " +
                            classTable.sizeSpan +
                            " " +
                            classTable.listDescriptionMM
                          }
                        >
                          {c.productSize.s2x15 == null
                            ? ""
                            : c.productSize.s2x15 == 0
                            ? ""
                            : "2x15"}
                          <br />
                          {c.productSize.s2x15 == null
                            ? ""
                            : c.productSize.s2x15 == 0
                            ? ""
                            : c.productSize.s2x15}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s3x1 != 0 ? (
                        <span
                          className={
                            classTable.textCenter +
                            " " +
                            classTable.sizeSpan +
                            " " +
                            classTable.listDescriptionMM
                          }
                        >
                          {c.productSize.s3x1 == null
                            ? ""
                            : c.productSize.s3x1 == 0
                            ? ""
                            : "3x1"}
                          <br />
                          {c.productSize.s3x1 == null
                            ? ""
                            : c.productSize.s3x1 == 0
                            ? ""
                            : c.productSize.s3x1}
                        </span>
                      ) : (
                        ""
                      )}
                    </GridContainer>
                  </span>,
                  <span
                    className={
                      classTable.headRight + " " + classTable.listNumber
                    }
                    key={c.id}
                  >
                    {c.quantity}
                  </span>,
                  <span
                    className={
                      classTable.headRight + " " + classTable.listNumber
                    }
                    key={c.id}
                  >
                    {c.unit}
                  </span>,
                  <span
                    className={
                      classTable.headRight + " " + classTable.listNumber
                    }
                    key={c.id}
                  >
                    ₹{c.price}
                  </span>,
                  <span
                    className={
                      classTable.headRight + " " + classTable.listNumber
                    }
                    key={c.id}
                  >
                    ₹{c.amount.toFixed(2)}
                  </span>,
                  // <Button
                  //   color="rose"
                  //   block
                  //   simple
                  //   onClick={() => {
                  //     handleDeleteMetal(c);
                  //   }}
                  // >
                  //   <DeleteForeverIcon />
                  // </Button>,
                ],
              })),
              [
                "",
                "",
                "",
                "",
                <span
                  className={classTable.headRight + " " + classTable.listNumber}
                  key={1}
                >
                  Grand Total
                </span>,
                <span key={3}>
                  <span
                    className={
                      classTable.headRight + " " + classTable.listNumber
                    }
                  >
                    {state
                      .reduce(
                        (a, b) => parseFloat(a) + parseFloat(b.quantity),
                        0
                      )
                      .toFixed(2)}
                  </span>
                </span>,
                "",
                "",
                <span key={3}>
                  <span
                    className={
                      classTable.headRight + " " + classTable.listNumber
                    }
                  >
                    ₹
                    {state
                      .reduce((a, b) => parseFloat(a) + parseFloat(b.amount), 0)
                      .toFixed(2)}
                  </span>
                </span>,
              ],
              [
                "",
                "",
                "",
                "",
                <span key={3}>
                  <span
                    className={
                      classTable.headRight + " " + classTable.listNumber
                    }
                  >
                    Closing Balance
                  </span>
                </span>,
                "",
                "",
                "",
                <span key={3}>
                  <span
                    className={
                      classTable.headRight + " " + classTable.listNumber
                    }
                  >
                    ₹
                    {state
                      .reduce((a, b) => parseFloat(a) + parseFloat(b.amount), 0)
                      .toFixed(2)}
                  </span>
                </span>,
              ],
            ]}
          />
          <div className={classTable.brand}>
            <Button
              className={classTable.print}
              color="rose"
              round
              onClick={() => window.print()}
            >
              Print
            </Button>
            {/* <Button color="rose"
            className={classTable.print}
              round href="/admin/labourAllViewMetal">Back</Button> */}
          </div>
        </div>
      </GridContainer>
    </div>
  );
}

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { dataURLtoFile } from "utils/dataURLToFile";
import { headApi } from "utils/headApi";
import { imgApi } from "utils/imgApi";
import { localApi } from "utils/imgApi";
import { getStates, getCities } from "utils/util";
import { makeStyles } from "@material-ui/core/styles";
import ImageUpload from "components/CustomUpload/ImageUpload.js";
import { FaEdit } from "react-icons/fa";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardAvatar from "components/Card/CardAvatar.js";
import defaultAvatar from "assets/img/placeholder.jpg";
import defaultImage from "assets/img/image_placeholder.jpg";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import SweetAlert from "react-bootstrap-sweetalert";
import style from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import styleCheck from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import styDrop from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

import { credentials } from "utils/imgApi";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(styleCheck);
const useStyl = makeStyles(styDrop);
const useStylSweet = makeStyles(style);

export default function EditProfile(props) {
  const [userEdit, setUserEdit] = React.useState("");
  const [firstname, setFristname] = React.useState("");
  const [lastname, setLastname] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [whatsapp, setWhatsapp] = React.useState("");
  const [proImg, setProImg] = React.useState("");
  const [company, setCompany] = React.useState("");
  const [active, setActive] = React.useState(false);
  const [countryCode, setCountryCode] = React.useState("in");
  const [countryName, setCountryName] = React.useState("India");
  const [stateSelect, setStateSelect] = React.useState("");
  const [citySelect, setCitySelect] = React.useState("");
  const [stateCode, setStateCode] = React.useState("");
  const [cityErr, setCityErr] = React.useState("");
  const [stateNameErr, setStateNameErr] = React.useState("");
  const [country, setCountry] = React.useState("India");
  const [countryErr, setCountryErr] = React.useState("");
  const [sname, setSname] = React.useState("");
  const [cname, setCname] = React.useState("");

  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);
  const [submittedSign, setSubmittedSign] = React.useState(false);
  const [btn, setBtn] = React.useState(false);

  const classes = useStyles();
  const classCheck = useStyle();
  const classe = useStyl();
  const classSweet = useStylSweet();

  let userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  const [file, setFile] = React.useState(null);
  const [crop, setCrop] = React.useState({
    aspect: 0.833,
    unit: "px",
    width: 280, //280
    height: 336, //336
  });
  const [image, setImage] = React.useState("");
  const [result, setResult] = React.useState("");
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState(
    props.avatar ? defaultAvatar : defaultImage
  );
  let fileInput = React.createRef();

  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setFile(file);
      setImagePreviewUrl(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const getCroppedImg = () => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    const base64Image = canvas.toDataURL("image/jpeg");
    setResult(base64Image);
  };

  const handleClick = () => {
    fileInput.current.click();
  };
  const handleRemove = () => {
    setFile(null);
    setImagePreviewUrl(props.avatar ? defaultAvatar : defaultImage);
    fileInput.current.value = null;
  };
  let { avatar, addButtonProps, changeButtonProps, removeButtonProps } = props;

  // eslint-disable-next-line
  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(file);
    const convertedUrlToBlob = dataURLtoFile(
      file ? result : null,
      `${file && file.name}`
    );
    // console.log(convertedUrlToBlob);
    let fData = new FormData();
    fData.append("profileImage", convertedUrlToBlob || new File([""], ""));
    Axios.put(
      localApi + `/admin/update/profile/findById/${userObj.id}`, {
        headers: {
            Authorization: `Basic ${credentials}`,
        }
    },
      fData
    ).then((data) => {
      // console.log(data.data);
      if (data.data.message === "Data Updated Successfully !!!") {
        alertTypeOps("success");
        alertMsgOps("Succesfully !!!");
        setSubmittedSign(true);
      }
      // window.location.reload(false);
    });
    // file is the file/image uploaded
    // in this function you can save the image (file) on form submit
    // you have to call it yourself
  };

  const handleState = (event) => {
    setStateSelect(event.target.value);
    setStateNameErr("");
  };

  const handleCity = (event) => {
    setCitySelect(event.target.value);
    setCityErr("");
  };

  const handleMobileNumber = (mobile) => {
    setMobile(mobile);
  };

  async function getUsers() {
    const api = localApi + `/admin/byMobile/${userObj.mobile}`;
    const result = await fetch(api, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getResult = await result.json();
    // console.log(getResult.state);
    // setUserEdit(getResult);
    setFristname(getResult.firstname);
    setLastname(getResult.lastname);
    setEmail(getResult.email);
    setMobile(getResult.mobile);
    setWhatsapp(getResult.whatsapp);
    setCompany(getResult.company);
    setActive(getResult.smsMarketing);
    setCountry(getResult.country);

    const countryCodeApi = localApi + `/countrylistcode/allList`;
    const countryCodeResult = await fetch(countryCodeApi, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getCountryCodeResult = await countryCodeResult.json();
    const cCode = getCountryCodeResult.filter(
      (f) => f.countryName === getResult.country
    )[0];
    setCountryCode(cCode.countryCode);
    setStateSelect(getResult.state);
    const sc = getStates(cCode.countryCode).filter(
      (f) => f.name === getResult.state
    )[0];
    setStateCode(sc.state_code);
    setCitySelect(getResult.city);
    setSname(getResult.state);
    setCname(getResult.city);
    setProImg(getResult.profileUrl + "?id=" + getResult.id);
  }

  useEffect(() => {
    getUsers();
  }, []);

  const SubmitProfile = (event) => {
    let errorCount = 0;
    if (stateSelect === "" || !stateSelect) {
      errorCount++;
      setStateNameErr("State is Required");
    }
    if (citySelect === "" || !citySelect) {
      errorCount++;
      setCityErr("City is Required");
    }
    if (errorCount === 0) {
      setBtn(true);
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Basic ${credentials}`,
        },
        body: JSON.stringify({
          firstname: firstname,
          lastname: lastname,
          whatsapp: whatsapp,
          company: company,
          smsMarketing: active,
          country: country,
          state: stateSelect,
          city: citySelect,
        }),
      };

      fetch(
        localApi + `/admin/update/findByMobile&Email/${userObj.id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          if (data.message === "Data Updated Successfully !!!") {
            alertTypeOps("success");
            alertMsgOps("Succesfully !!!");
            setSubmitted(true);
          }
        })

        .catch((err) => {
          console.log(err);
        });
    }
  };

  const hideAlert = () => {
    setSubmitted(false);
    setSubmittedSign(false);
    window.location.reload(false);
  };

  const mystyle = {
    paddingLeft: 12,
    flex: 1,
  };

  return (
    <div>
      {file ? (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <FaEdit />
                </CardIcon>
                <h3 className={classes.cardIconTitle}>
                  <small>Edit Profile</small>
                </h3>
              </CardHeader>
              <CardBody>
                <form>
                  {submitted ? (
                    <SweetAlert
                      type={alertType}
                      style={{ display: "block", marginTop: "-100px" }}
                      title={alertMsg}
                      onConfirm={() => hideAlert()}
                      confirmBtnCssClass={
                        classSweet.button + " " + classSweet.success
                      }
                    >
                      Updated Edit Profile
                    </SweetAlert>
                  ) : null}
                  {submittedSign ? (
                    <SweetAlert
                      type={alertType}
                      style={{ display: "block", marginTop: "-100px" }}
                      title={alertMsg}
                      onConfirm={() => hideAlert()}
                      confirmBtnCssClass={
                        classSweet.button + " " + classSweet.success
                      }
                    >
                      Updated Profile Image
                    </SweetAlert>
                  ) : null}
                  <GridContainer>
                    {" "}
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="First Name"
                        id="firstname"
                        inputProps={{
                          placeholder: "First Name ...",
                        }}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        name="firstname"
                        value={firstname}
                        onChange={(e) => {
                          setFristname(e.target.value);
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Last Name"
                        id="lastname"
                        inputProps={{
                          placeholder: "Last Name ...",
                        }}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        name="lastname"
                        value={lastname}
                        onChange={(e) => {
                          setLastname(e.target.value);
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Email"
                        id="email"
                        inputProps={{
                          placeholder: "Email ...",
                        }}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        name="email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        disabled={true}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <PhoneInput
                        style={mystyle}
                        inputStyle={{
                          width: "95%",
                          border: "none",
                          borderBottom: "1px solid #040000",
                          borderRadius: "0",
                        }}
                        country={"in"}
                        name="mobile"
                        value={mobile}
                        onChange={(mobile) => {
                          let country_code = document.body.querySelector(
                            "div.flag"
                          )?.className;
                          country_code =
                            country_code && country_code.replace("flag", "");
                          setCountryCode(country_code.trim());
                          // console.log(country_code);
                          handleMobileNumber(mobile);
                          let country_name = document.body.querySelector(
                            "div.selected-flag"
                          )?.title;
                          setCountryName(country_name.slice(0, -6));
                        }}
                        disabled={true}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <PhoneInput
                        style={mystyle}
                        inputStyle={{
                          width: "95%",
                          border: "none",
                          borderBottom: "1px solid #040000 ",
                          borderRadius: "0",
                        }}
                        country={"in"}
                        name="whatsapp"
                        value={whatsapp}
                        onChange={(whatsapp) => {
                          setWhatsapp(whatsapp);
                        }}
                        // required={true}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Company Name"
                        id="company"
                        inputProps={{
                          placeholder: "Company Name ...",
                        }}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        name="company"
                        value={company}
                        onChange={(e) => {
                          setCompany(e.target.value);
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <FormControl
                        fullWidth
                        className={classe.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classe.selectLabel}
                        >
                          Select State...{sname}
                        </InputLabel>

                        <Select
                          MenuProps={{
                            className: classe.selectMenu,
                          }}
                          classes={{
                            select: classe.select,
                          }}
                          value={stateSelect}
                          onChange={handleState}
                          required={true}
                          inputProps={{
                            name: "stateSelect",
                            id: "simple-select",
                          }}
                        >
                          <MenuItem
                            disabled
                            classes={{
                              root: classe.selectMenuItem,
                            }}
                          >
                            Select State
                          </MenuItem>
                          {getStates(countryCode).map((item) => (
                            <MenuItem
                              key={item.name}
                              value={item.name}
                              onClick={() => {
                                setStateCode(item?.state_code);
                              }}
                              classes={{
                                root: classe.selectMenuItem,
                                selected: classe.selectMenuItemSelected,
                              }}
                            >
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <span style={{ color: "red" }}>{stateNameErr}</span>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <FormControl
                        fullWidth
                        className={classe.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classe.selectLabel}
                        >
                          Select City...{cname}
                        </InputLabel>

                        <Select
                          MenuProps={{
                            className: classe.selectMenu,
                          }}
                          classes={{
                            select: classe.select,
                          }}
                          value={citySelect}
                          onChange={handleCity}
                          required={true}
                          inputProps={{
                            name: "stateSelect",
                            id: "simple-select",
                          }}
                        >
                          <MenuItem
                            disabled
                            classes={{
                              root: classe.selectMenuItem,
                            }}
                          >
                            Select City
                          </MenuItem>
                          {getCities(countryCode, stateCode).map((item) => (
                            <MenuItem
                              key={item.name}
                              value={item.name}
                              classes={{
                                root: classe.selectMenuItem,
                                selected: classe.selectMenuItemSelected,
                              }}
                            >
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <span style={{ color: "red" }}>{cityErr}</span>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Country..."
                        id="country"
                        inputProps={{
                          placeholder: "Country...",
                        }}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        name="country"
                        value={country}
                        onChange={(e) => {
                          setCountry(e.target.value);
                          setCountryErr("");
                        }}
                        required={true}
                        disabled={true}
                      />
                      <span style={{ color: "red" }}>{countryErr}</span>
                    </GridItem>
                    <FormControlLabel
                      classes={{
                        root: classCheck.checkboxLabelControl,
                        label: classCheck.checkboxLabel,
                      }}
                      control={
                        <Checkbox
                          id="active"
                          value={active}
                          checked={active & (active === true) ? active : active}
                          onChange={(e) => {
                            setActive(e.target.checked);
                          }}
                          tabIndex={-1}
                          checkedIcon={
                            <Check className={classCheck.checkedIcon} />
                          }
                          icon={<Check className={classCheck.uncheckedIcon} />}
                          classes={{
                            checked: classCheck.checked,
                            root: classCheck.checkRoot,
                          }}
                        />
                      }
                      label={
                        <span style={{ color: "#9c27b0" }}>
                          I would like to recieve the latest news from the
                          Company by phone/whatsapp
                        </span>
                      }
                    />
                  </GridContainer>
                  {/* <Button
                    color="rose"
                    onClick={SubmitProfile}
                    className={classes.updateProfileButton}
                  >
                    Update
                  </Button> */}
                  {btn === true ? (
                    <Button className={classes.updateProfileButton}>
                      Update
                    </Button>
                  ) : (
                    <Button
                      color="rose"
                      onClick={SubmitProfile}
                      className={classes.updateProfileButton}
                    >
                      Update
                    </Button>
                  )}
                </form>
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={12}>
            <Card profile>
              <CardAvatar profile>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  {/* <img src={image ? image : defaultAvatar} alt="..." /> */}
                  <img src={proImg} alt="..." />
                </a>
              </CardAvatar>
              <CardBody profile>
                <h6 className={classes.cardCategory}>CEO / CO-FOUNDER</h6>
                <h4 className={classes.cardTitle}>
                  {firstname + "  " + lastname}
                </h4>
                <p className={classes.description}>
                  In this ever-changing society, the most powerful and enduring
                  brands are built from the heart. They are real and
                  sustainable. Their foundations are stronger because they are
                  built with the strength of the human spirit. Don’t limit
                  yourself. Many people limit themselves to what they think they
                  can do. You can go as far as your mind lets you. What you
                  believe, remember, you can achieve.
                </p>

                <legend>My Profile Image*</legend>
                <div className="fileinput text-center">
                  <input
                    type="file"
                    onChange={handleImageChange}
                    ref={fileInput}
                  />

                  {file && (
                    <div xs={12} sm={12} md={12}>
                      <ReactCrop
                        src={imagePreviewUrl}
                        onImageLoaded={setImage}
                        crop={crop}
                        onChange={setCrop}
                        // minWidth={280 < 280 ? 280 : 280}
                        // minHeight={336 < 336 ? 336 : 336}
                      />
                      <Button color="rose" round onClick={getCroppedImg}>
                        {" "}
                        Crop Image
                      </Button>
                    </div>
                  )}

                  <div className={"thumbnail" + (avatar ? " img-circle" : "")}>
                    {result && <img src={result} alt="..." />}
                  </div>
                  <div>
                    {file === null ? (
                      <Button
                        color="rose"
                        {...addButtonProps}
                        onClick={() => handleClick()}
                      >
                        {avatar ? "Select Sign" : "Select Sign"}
                      </Button>
                    ) : (
                      <span>
                        <Button
                          color="rose"
                          {...changeButtonProps}
                          onClick={() => handleClick()}
                        >
                          Change
                        </Button>
                        {avatar ? <br /> : null}
                        <Button
                          color="rose"
                          {...removeButtonProps}
                          onClick={() => handleRemove()}
                        >
                          <i className="fas fa-times" /> Remove
                        </Button>
                        <Button color="rose" round onClick={handleSubmit}>
                          upload Sign
                        </Button>
                      </span>
                    )}
                  </div>
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      ) : (
        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <FaEdit />
                </CardIcon>
                <h3 className={classes.cardIconTitle}>
                  <small>Edit Profile</small>
                </h3>
              </CardHeader>
              <CardBody>
                <form>
                  {submitted ? (
                    <SweetAlert
                      type={alertType}
                      style={{ display: "block", marginTop: "-100px" }}
                      title={alertMsg}
                      onConfirm={() => hideAlert()}
                      confirmBtnCssClass={
                        classSweet.button + " " + classSweet.success
                      }
                    />
                  ) : null}
                  <GridContainer>
                    {" "}
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="First Name"
                        id="firstname"
                        inputProps={{
                          placeholder: "First Name ...",
                        }}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        name="firstname"
                        value={firstname}
                        onChange={(e) => {
                          setFristname(e.target.value);
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Last Name"
                        id="lastname"
                        inputProps={{
                          placeholder: "Last Name ...",
                        }}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        name="lastname"
                        value={lastname}
                        onChange={(e) => {
                          setLastname(e.target.value);
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Email"
                        id="email"
                        inputProps={{
                          placeholder: "Email ...",
                        }}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        name="email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        disabled={true}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <PhoneInput
                        style={mystyle}
                        inputStyle={{
                          width: "95%",
                          border: "none",
                          borderBottom: "1px solid #040000",
                          borderRadius: "0",
                        }}
                        country={"in"}
                        name="mobile"
                        value={mobile}
                        onChange={(mobile) => {
                          let country_code = document.body.querySelector(
                            "div.flag"
                          )?.className;
                          country_code =
                            country_code && country_code.replace("flag", "");
                          setCountryCode(country_code.trim());
                          // console.log(country_code);
                          handleMobileNumber(mobile);
                          let country_name = document.body.querySelector(
                            "div.selected-flag"
                          )?.title;
                          setCountryName(country_name.slice(0, -6));
                        }}
                        disabled={true}
                      />
                    </GridItem>
                    {/* <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Mobile Number"
                        id="mobile"
                        inputProps={{
                          placeholder: "Mobile Number ...",
                        }}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        name="mobile"
                        value={mobile}
                        onChange={(e) => {
                          setMobile(e.target.value);
                        }}
                        disabled={true}
                      />
                    </GridItem> */}
                    <GridItem xs={12} sm={12} md={6}>
                      <PhoneInput
                        style={mystyle}
                        inputStyle={{
                          width: "95%",
                          border: "none",
                          borderBottom: "1px solid #040000 ",
                          borderRadius: "0",
                        }}
                        country={"in"}
                        name="whatsapp"
                        value={whatsapp}
                        onChange={(whatsapp) => {
                          setWhatsapp(whatsapp);
                        }}
                        // required={true}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Company Name"
                        id="company"
                        inputProps={{
                          placeholder: "Company Name ...",
                        }}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        name="company"
                        value={company}
                        onChange={(e) => {
                          setCompany(e.target.value);
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <FormControl
                        fullWidth
                        className={classe.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classe.selectLabel}
                        >
                          Select State...{sname}
                        </InputLabel>

                        <Select
                          MenuProps={{
                            className: classe.selectMenu,
                          }}
                          classes={{
                            select: classe.select,
                          }}
                          value={stateSelect}
                          onChange={handleState}
                          required={true}
                          inputProps={{
                            name: "stateSelect",
                            id: "simple-select",
                          }}
                        >
                          <MenuItem
                            disabled
                            classes={{
                              root: classe.selectMenuItem,
                            }}
                          >
                            Select State
                          </MenuItem>
                          {getStates(countryCode).map((item) => (
                            <MenuItem
                              key={item.name}
                              value={item.name}
                              onClick={() => {
                                setStateCode(item?.state_code);
                              }}
                              classes={{
                                root: classe.selectMenuItem,
                                selected: classe.selectMenuItemSelected,
                              }}
                            >
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <span style={{ color: "red" }}>{stateNameErr}</span>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <FormControl
                        fullWidth
                        className={classe.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classe.selectLabel}
                        >
                          Select City...{cname}
                        </InputLabel>

                        <Select
                          MenuProps={{
                            className: classe.selectMenu,
                          }}
                          classes={{
                            select: classe.select,
                          }}
                          value={citySelect}
                          onChange={handleCity}
                          required={true}
                          inputProps={{
                            name: "stateSelect",
                            id: "simple-select",
                          }}
                        >
                          <MenuItem
                            disabled
                            classes={{
                              root: classe.selectMenuItem,
                            }}
                          >
                            Select City
                          </MenuItem>
                          {getCities(countryCode, stateCode).map((item) => (
                            <MenuItem
                              key={item.name}
                              value={item.name}
                              classes={{
                                root: classe.selectMenuItem,
                                selected: classe.selectMenuItemSelected,
                              }}
                            >
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <span style={{ color: "red" }}>{cityErr}</span>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Country..."
                        id="country"
                        inputProps={{
                          placeholder: "Country...",
                        }}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        name="country"
                        value={country}
                        onChange={(e) => {
                          setCountry(e.target.value);
                          setCountryErr("");
                        }}
                        required={true}
                      />
                      <span style={{ color: "red" }}>{countryErr}</span>
                    </GridItem>
                    <FormControlLabel
                      classes={{
                        root: classCheck.checkboxLabelControl,
                        label: classCheck.checkboxLabel,
                      }}
                      control={
                        <Checkbox
                          id="active"
                          value={active}
                          checked={active & (active === true) ? active : active}
                          onChange={(e) => {
                            setActive(e.target.checked);
                          }}
                          tabIndex={-1}
                          checkedIcon={
                            <Check className={classCheck.checkedIcon} />
                          }
                          icon={<Check className={classCheck.uncheckedIcon} />}
                          classes={{
                            checked: classCheck.checked,
                            root: classCheck.checkRoot,
                          }}
                        />
                      }
                      label={
                        <span style={{ color: "#9c27b0" }}>
                          I would like to recieve the latest news from the
                          Company by phone/whatsapp
                        </span>
                      }
                    />
                  </GridContainer>
                  {/* <Button
                    color="rose"
                    onClick={SubmitProfile}
                    className={classes.updateProfileButton}
                  >
                    Update
                  </Button> */}
                  {btn === true ? (
                    <Button className={classes.updateProfileButton}>
                      Update
                    </Button>
                  ) : (
                    <Button
                      color="rose"
                      onClick={SubmitProfile}
                      className={classes.updateProfileButton}
                    >
                      Update
                    </Button>
                  )}
                </form>
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={4}>
            <Card profile>
              <CardAvatar profile>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  {/* <img src={image ? image : defaultAvatar} alt="..." /> */}
                  <img src={proImg} alt="..." />
                </a>
              </CardAvatar>
              <CardBody profile>
                <h6 className={classes.cardCategory}>CEO / CO-FOUNDER</h6>
                <h4 className={classes.cardTitle}>
                  {firstname + "  " + lastname}
                </h4>
                <p className={classes.description}>
                  In this ever-changing society, the most powerful and enduring
                  brands are built from the heart. They are real and
                  sustainable. Their foundations are stronger because they are
                  built with the strength of the human spirit. Don’t limit
                  yourself. Many people limit themselves to what they think they
                  can do. You can go as far as your mind lets you. What you
                  believe, remember, you can achieve.
                </p>

                <legend>My Profile Image*</legend>

                <div className="fileinput text-center">
                  <input
                    type="file"
                    onChange={handleImageChange}
                    ref={fileInput}
                  />

                  {file && (
                    <div xs={12} sm={12} md={12}>
                      <ReactCrop
                        src={imagePreviewUrl}
                        onImageLoaded={setImage}
                        crop={crop}
                        onChange={setCrop}
                      />
                      <Button color="rose" round onClick={getCroppedImg}>
                        {" "}
                        Crop Image
                      </Button>
                    </div>
                  )}

                  <div className={"thumbnail" + (avatar ? " img-circle" : "")}>
                    {result && <img src={result} alt="..." />}
                  </div>

                  <div>
                    {file === null ? (
                      <Button
                        color="rose"
                        {...addButtonProps}
                        onClick={() => handleClick()}
                      >
                        {avatar ? "Select Sign" : "Select Sign"}
                      </Button>
                    ) : (
                      <span>
                        <Button
                          color="rose"
                          {...changeButtonProps}
                          onClick={() => handleClick()}
                        >
                          Change
                        </Button>
                        {avatar ? <br /> : null}
                        <Button
                          color="rose"
                          {...removeButtonProps}
                          onClick={() => handleRemove()}
                        >
                          <i className="fas fa-times" /> Remove
                        </Button>
                        <Button color="rose" round onClick={handleSubmit}>
                          upload Sign
                        </Button>
                      </span>
                    )}
                  </div>
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      )}
    </div>
  );
}

EditProfile.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
};

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { localApi, catApi } from "utils/imgApi";
import { BiFile } from "react-icons/bi";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import AddIcon from "@material-ui/icons/Add";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import { FaBox, FaRing, FaUsers } from "react-icons/fa";
import { GiWireCoil, GiBoxUnpacking } from "react-icons/gi";
import { BsImages } from "react-icons/bs";
import { AiOutlineCalculator } from "react-icons/ai";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import styRadio from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import styDrop from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import styCheckbox from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import styleSweet from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import axios from "axios";

import { credentials } from "utils/imgApi";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(styRadio);
const useStyl = makeStyles(styDrop);
const useSty = makeStyles(styCheckbox);
const useStyleSweet = makeStyles(styleSweet);

export default function AddProduct(props) {
  const [items, setItems] = React.useState([]);
  const [items1, setItems1] = React.useState([]);
  const [items2, setItems2] = React.useState([]);
  const [itemSets, setItemSets] = React.useState([]);
  const [itemBox, setItemBox] = React.useState([]);
  const [metal, setMetal] = React.useState([]);
  const [plastic, setPlastic] = React.useState([]);
  const [crystal, setCrystal] = React.useState([]);
  const [ploythene, setPloythene] = React.useState([]);
  const [imgList, setImgList] = React.useState("");
  const [simpleSelect, setSimpleSelect] = React.useState("");
  const [simpleSelect1, setSimpleSelect1] = React.useState("");
  const [selectedGstCal, setSelectedGstCal] = React.useState("");
  const [checkedA, setCheckedA] = React.useState(false);
  const [selectedProfit, setSelectedProfit] = React.useState("profit");
  const [selectedPieceSets, setSelectedPieceSets] = React.useState(0);
  const [selectedBox, setSelectedBox] = React.useState(0);
  const [makingCheckboxMaking, setMakingCheckboxMaking] = React.useState("");
  const [simpleSelectMetal, setSimpleSelectMetal] = React.useState("");
  const [readyGram, setReadyGram] = React.useState("0.0075");
  const [metalGram, setMetalGram] = React.useState("0.0100");
  const [metalSGram, setMetalSGram] = React.useState("0.0005");
  const [golwireThaw, setGolwireThaw] = React.useState("105 Thaw Golwire");
  const [packingOption, setPackingOption] = React.useState("");
  const [simpleSelectPlastic, setSimpleSelectPlastic] = React.useState("");
  const [simpleSelectCrystal, setSimpleSelectCrystal] = React.useState("");
  const [simpleSelectPloythene, setSimpleSelectPloythene] = React.useState("");
  const [checkBoxMaking, setCheckBoxmaking] = React.useState([]);
  const [makingSingleId, setMakingSingleId] = React.useState("");
  const [includeGST, setIncludeGST] = React.useState(0);
  const [includeSets, setIncludeSets] = React.useState(0);
  const [totalCash, setTotalCash] = React.useState(0);
  const [sets, setSets] = React.useState(0);
  const [prodItemCode, setProdItemCode] = React.useState("");
  const [prodDescription, setProdDescription] = React.useState("");
  const [prodMfgDetails, setProdMfgDetails] = React.useState("");
  const [cartonDescription, setCartonDescription] = React.useState(
    "96 boxes of in 1 Carton"
  );
  const [cartonQuantity, setCartonQuantity] = React.useState("1");
  const [cartonPackingCharge, setCartonPackingCharge] = React.useState(
    "500.00"
  );
  const [makingPrice, setMakingPrice] = React.useState(0);
  const [makingGst, setMakingGst] = React.useState(0);
  const [makIncludeGst, setMakIncludeGst] = React.useState(0);
  const [metalPrice, setMetalPrice] = React.useState(0);
  const [metalDiff, setMetalDiff] = React.useState(0);
  const [metalGst, setMetalGst] = React.useState(0);
  const [plasticPrice, setPlasticPrice] = React.useState(0);
  const [plasticGst, setPlasticGst] = React.useState(0);
  const [crystalPrice, setCrystalPrice] = React.useState(0);
  const [crystalGst, setCrystalGst] = React.useState(0);
  const [ployPrice, setPloyPrice] = React.useState(0);
  const [ployGst, setPloyGst] = React.useState(0);
  const [makChecked, setMakChecked] = React.useState("");
  const [boxVal, setBoxVal] = React.useState(0);
  const [platVal, setPlatVal] = React.useState(0);
  const [makVal, setMakVal] = React.useState(0);
  const [metGramVal, setMetGramVal] = React.useState(0);
  const [packVal, setPackVal] = React.useState(0);

  const [totalProfit, setTotalProfit] = React.useState(0);
  const [setProfit, setSetProfit] = React.useState(0);

  const [includeProfit, setIncludeProfit] = React.useState(0);
  const [includeProfitSet, setIncludeProfitSet] = React.useState(0);

  const [catDes, setCatDes] = React.useState("");

  const [tax, setTax] = React.useState("0");

  const [igst, setIgst] = React.useState("3.0");
  const [cgst, setCgst] = React.useState("1.5");
  const [sgst, setSgst] = React.useState("1.5");

  const [cat, setCat] = React.useState("");
  const [catSize, setCatSize] = React.useState("");
  const [checkBoxImage, setCheckBoxImage] = React.useState([]);
  const [makingCheckboxImage, setMakingCheckboxImage] = React.useState("");
  const [imgChecked, setImgChecked] = React.useState("");
  const [moqkg, setMoqkg] = React.useState("100");

  const [metalDetails, setMetalDetails] = React.useState("");
  const [imageId, setImageId] = React.useState(0);

  const [submitted, setSubmitted] = React.useState(false);
  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");

  const [setsErr, setSetsErr] = React.useState("");
  const [boxErr, setBoxErr] = React.useState("");
  const [childErr, setChildErr] = React.useState("");
  const [metalErr, setMetalErr] = React.useState("");
  const [packOptionErr, setPackOptionErr] = React.useState("");
  const [packListErr, setPackListErr] = React.useState("");
  const [imageErr, setImageErr] = React.useState("");
  const [calculationErr, setCalculationErr] = React.useState("");
  const [btn, setBtn] = React.useState(false);

  const [hsnCode, setHsnCode] = React.useState("");
  const [hsnCodeErr, setHsnCodeErr] = React.useState("");
  const [discount, setDiscount] = React.useState("0");

  const classes = useStyles();
  const classesRadio = useStyle();
  const classe = useStyl();
  const classCheck = useSty();
  const classSweet = useStyleSweet();

  let { avatar } = props;

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  const fetchCat = async () => {
    const catApi =
      localApi + `/category/list/byAdminId/${userObj && userObj.id}`;
    const catRresult = await fetch(catApi, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getCat = await catRresult.json();
    // console.log(getSet);
    setItems(getCat && getCat);
  };

  const fetchSet = async () => {
    const setsApi = localApi + `/sets/list/byAdminId/${userObj && userObj.id}`;
    const setRresult = await fetch(setsApi, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getSet = await setRresult.json();
    // console.log(getSet);
    setItemSets(getSet && getSet);
  };

  const fetchBox = async () => {
    const boxApi = localApi + `/box/list/byAdminId/${userObj && userObj.id}`;
    const boxRresult = await fetch(boxApi, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getBox = await boxRresult.json();
    // console.log(getSet);
    setItemBox(getBox && getBox);
  };

  const fetchMetal = async () => {
    const metalApi =
      localApi + `/rawprice/list/byAdminId/${userObj && userObj.id}`;
    const metalRresult = await fetch(metalApi, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getMetal = await metalRresult.json();
    // console.log(getChild);
    setMetal(getMetal && getMetal);
  };

  useEffect(() => {
    // catApi().then((res) => setItems(res));
    fetchCat();
    fetchSet();
    fetchBox();
    fetchMetal();
  }, []);

  const handleSimple = async (event) => {
    // setSimpleSelect(event.target.value);
    const { value } = event.target;
    const item = items.find((item) => item.id == value);
    setSimpleSelect(value);
    if (value) {
      setCatDes(item.title);
    }
    // setGetId(item.id);
    const response = await axios.get(
      localApi + `/parent/list/byCategoryId/${item.id}`,
      {
        headers: {
          Authorization: `Basic ${credentials}`,
        },
      }
    );
    setItems1(response.data);
    setCat(item);
  };

  const handleSimple1 = async (event) => {
    // setSimpleSelect(event.target.value);
    var tempData = {};
    const { value } = event.target;
    setSimpleSelect1(value);
    const itemm = items1.find((itemm) => itemm.id == value);
    setCatSize(itemm);
    const childApi = localApi + `/child/list/byParentId/${itemm.id}`;
    const childRresult = await fetch(childApi, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getChild = await childRresult.json();
    // console.log(getChild);
    setItems2(getChild && getChild);
    const imageApi =
      localApi +
      `/image/list/byAdminAndCategoryAndParentId/${userObj && userObj.id}/${
        cat.id
      }/${itemm.id}`;
    const imageRresult = await fetch(imageApi, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getImage = await imageRresult.json();
    // console.log(getImage);
    setImgList(getImage && getImage);
    // const userUsed = getImage.map((d) => d?.id);
    // Promise.all(
    //   userUsed.map(async (id) => {
    //     const usedApi = await fetch(
    //       localApi +
    //       `/productimage/find/byCountAdminAndImageId/${userObj.id}/${id}`
    //     );
    //     const data = await usedApi.json();
    //     // console.log(data);
    //     tempData = { ...tempData, [id]: data };
    //   })
    // ).then(() => {
    //   // console.log(tempData);
    //   const tempItems2 = getImage.map((i) => {
    //     return { ...i, usedCount: tempData[i.id] || 0 };
    //   });
    //   setImgList(tempItems2);
    //   // console.log(tempItems2);
    // });
  };

  const handleChangeGstCal = (event, id) => {
    // setSelectedGstCal(event.target.value);
    const { value } = event.target;
    setSelectedGstCal(value);
    setCalculationErr("");
    if (value === "gst") {
      setSelectedGstCal(value);

      let boxPkgGst = (selectedBox?.price * selectedBox?.tax_percentage) / 100;
      let boxPkgCash = selectedBox?.price + selectedBox?.pkgCharge;

      let makPriceGst =
        makIncludeGst * selectedPieceSets?.pcs * selectedBox?.box || 0;
      let makPriceCash =
        makingPrice * selectedPieceSets?.pcs * selectedBox?.box || 0;

      let metPriceGst =
        (metalGram *
          selectedPieceSets?.pcs *
          selectedBox?.box *
          (metalPrice + metalDiff) *
          metalGst) /
          100 || 0;
      let metPriceCash =
        metalGram *
          selectedPieceSets?.pcs *
          selectedBox?.box *
          (metalPrice + metalDiff) || 0;

      let plasPriceGst =
        packingOption === "Plastic Dibbi"
          ? (plasticPrice * selectedBox?.box * plasticGst) / 100
          : 0;
      let plasPriceCash =
        packingOption === "Plastic Dibbi" ? plasticPrice * selectedBox?.box : 0;

      let crysPriceGst =
        packingOption === "Crystal Dibbi"
          ? (crystalPrice * selectedBox?.box * crystalGst) / 100
          : 0;
      let crysPriceCash =
        packingOption === "Crystal Dibbi" ? crystalPrice * selectedBox?.box : 0;

      let ployPriceGst =
        packingOption === "Polythene PP"
          ? (ployPrice * selectedBox?.box * ployGst) / 100
          : 0;
      let ployPriceCash =
        packingOption === "Polythene PP" ? ployPrice * selectedBox?.box : 0;

      let boxPriceGst =
        boxPkgGst +
        makPriceGst +
        metPriceGst +
        plasPriceGst +
        crysPriceGst +
        ployPriceGst +
        mixplatPriceGst +
        mixmakPriceGst +
        mixmetPriceGst;

      let boxPriceCash =
        boxPkgCash +
        makPriceCash +
        metPriceCash +
        plasPriceCash +
        crysPriceCash +
        ployPriceCash +
        mixplatingPrice +
        mixmakPrice +
        mixmetPrice;

      setTax(boxPriceGst.toFixed(2));

      setIncludeGST((boxPriceCash + boxPriceGst).toFixed(2));

      let setPriceGst = (boxPriceGst + boxPriceCash) / selectedBox?.box;
      setIncludeSets(setPriceGst.toFixed(2));

      // console.log(userObj.profit);
      let profGst =
        (boxPriceCash * userObj.profit) / 100 + boxPriceGst + boxPriceCash;
      setIncludeProfit(profGst.toFixed(2));

      let profSet = profGst / selectedBox?.box;
      setIncludeProfitSet(profSet.toFixed(2));
    } else {
      let boxPkg = selectedBox?.price + selectedBox?.pkgCharge;
      // console.log(boxPkg);
      let makPrice =
        makingPrice * selectedPieceSets?.pcs * selectedBox?.box || 0;
      // console.log(makPrice);
      let metPrice =
        metalGram *
          selectedPieceSets?.pcs *
          selectedBox?.box *
          (metalPrice + metalDiff) || 0;
      // console.log(metPrice);
      let plasPrice =
        packingOption === "Plastic Dibbi" ? plasticPrice * selectedBox?.box : 0;
      // console.log(plasPrice);
      let crysPrice =
        packingOption === "Crystal Dibbi" ? crystalPrice * selectedBox?.box : 0;
      // console.log(crysPrice);
      let ployPrices =
        packingOption === "Polythene PP" ? ployPrice * selectedBox?.box : 0;
      // console.log(ployPrices);

      /// Gst Part ////
      let boxPkgGst = (selectedBox?.price * selectedBox?.tax_percentage) / 100;

      let makPriceGst =
        makIncludeGst * selectedPieceSets?.pcs * selectedBox?.box || 0;

      let metPriceGst =
        (metalGram *
          selectedPieceSets?.pcs *
          selectedBox?.box *
          (metalPrice + metalDiff) *
          metalGst) /
          100 || 0;

      let plasPriceGst =
        packingOption === "Plastic Dibbi"
          ? (plasticPrice * selectedBox?.box * plasticGst) / 100
          : 0;

      let crysPriceGst =
        packingOption === "Crystal Dibbi"
          ? (crystalPrice * selectedBox?.box * crystalGst) / 100
          : 0;

      let ployPriceGst =
        packingOption === "Polythene PP"
          ? (ployPrice * selectedBox?.box * ployGst) / 100
          : 0;

      let boxPriceGst =
        boxPkgGst +
        makPriceGst +
        metPriceGst +
        plasPriceGst +
        crysPriceGst +
        ployPriceGst +
        mixplatPriceGst +
        mixmakPriceGst +
        mixmetPriceGst;
      //// Gst part End ///////

      let boxPrices =
        boxPkg +
        makPrice +
        metPrice +
        plasPrice +
        crysPrice +
        ployPrices +
        mixplatingPrice +
        mixmakPrice +
        mixmetPrice;
      setTotalCash(boxPrices.toFixed(2));
      // console.log("making", boxPrices);

      let setPrice = boxPrices / selectedBox?.box;
      setSets(setPrice.toFixed(2));

      let profit =
        (boxPrices * userObj.profit) / 100 + boxPrices + boxPriceGst / 2;
      // console.log("profit", profit);

      setTotalProfit(profit.toFixed(2));

      let setProfit = profit / selectedBox?.box;
      setSetProfit(setProfit.toFixed(2));
    }
  };

  const handleProfit = (e) => {
    setSelectedProfit(e.target.value);
  };

  const handleCheckBoxMaking = (event, making) => {
    // setCheckBoxmaking(e.target.checked);
    setChildErr("");
    const { checked } = event.target;
    if (checked) {
      setMakingPrice(makingPrice + making?.price);
    } else {
      setMakingPrice(makingPrice - making?.price);
    }

    if (checked) {
      setMakVal(
        makVal + making?.price * selectedPieceSets?.pcs * selectedBox?.box
      );
    } else {
      setMakVal(
        makVal - making?.price * selectedPieceSets?.pcs * selectedBox?.box
      );
    }

    if (checked) {
      setMakingGst(making?.taxPercentage);
      setMakIncludeGst(makIncludeGst + making?.tax);
    } else {
      setMakingGst("");
      setMakIncludeGst(makIncludeGst - making?.tax);
    }

    if (checked) {
      setMakingSingleId(making?.id);
    }

    setCheckBoxmaking((prev) => {
      if (prev.includes(making)) {
        return prev.filter((x) => x !== making);
      } else {
        return [...prev, making];
      }
    });

    setMakChecked((prev) => {
      if (prev.includes(making?.id)) {
        return prev.filter((x) => x !== making?.id);
      } else {
        return [...prev, making?.id];
      }
    });
  };

  const handleSimpleMetal = (event) => {
    // alert(event.target.value);
    const { value } = event.target;
    const metItem = metal.find((metItem) => metItem.id == value);
    setSimpleSelectMetal(value);
    // console.log(metItem);
    setMetalDetails(metItem);
    setMetalPrice(metItem.price);
    setMetalDiff(metItem.different);
    setMetalGst(metItem.taxPercentage);
    setMetalErr("");
    if (value) {
      let newMet =
        metalGram *
        selectedPieceSets?.pcs *
        selectedBox?.box *
        (metItem.price + metItem.different);
      setMetGramVal(newMet.toFixed(2));
    }
  };

  const handlePackingOption = async (event) => {
    // setPackingOption(event.target.value);
    setPackOptionErr("");
    const { value } = event.target;
    setPackingOption(value);
    if (value === "Crystal Dibbi") {
      const api =
        localApi +
        `/packingmaterial/list/byAdminIdAndType/${
          userObj && userObj.id
        }/Crystal Dibbi`;
      const result = await fetch(api, {
        method: "GET",
        headers: {
          Authorization: `Basic ${credentials}`,
        },
      });
      const getResult = await result.json();
      setCrystal(getResult && getResult);
    } else if (value === "Plastic Dibbi") {
      const api =
        localApi +
        `/packingmaterial/list/byAdminIdAndType/${
          userObj && userObj.id
        }/Plastic Dibbi`;
      const result = await fetch(api, {
        method: "GET",
        headers: {
          Authorization: `Basic ${credentials}`,
        },
      });
      const getResult = await result.json();
      setPlastic(getResult && getResult);
    } else if (value === "Polythene PP") {
      const api =
        localApi +
        `/packingmaterial/list/byAdminIdAndType/${
          userObj && userObj.id
        }/Polythene PP`;
      const result = await fetch(api, {
        method: "GET",
        headers: {
          Authorization: `Basic ${credentials}`,
        },
      });
      const getResult = await result.json();
      setPloythene(getResult && getResult);
    }
    if (value === "Plastic Dibbi") {
      let packTotal = plasticPrice * selectedBox?.box;
      // console.log("plas", packTotal);
      setPackVal(packTotal.toFixed(2));
    } else if (value === "Crystal Dibbi") {
      let crysTotal = crystalPrice * selectedBox?.box;
      setPackVal(crysTotal.toFixed(2));
    } else if (value === "Polythene PP") {
      let ployTotal = ployPrice * selectedBox?.box;
      setPackVal(ployTotal.toFixed(2));
    }
  };

  const handleSimplePlastic = (event) => {
    // setSimpleSelectPlastic(event.target.value);
    const { value } = event.target;
    setSimpleSelectPlastic(value);
    const plaItem = plastic.find((plaItem) => plaItem.id == value);
    setPlasticPrice(plaItem.price);
    setPlasticGst(plaItem.ligst);
    setPackListErr("");
    if (packingOption === "Plastic Dibbi" && value) {
      let packTotal = plaItem.price * selectedBox?.box;
      setPackVal(packTotal.toFixed(2));
    }
  };

  const handleSimpleCrystal = (event) => {
    // setSimpleSelectCrystal(event.target.value);
    const { value } = event.target;
    setSimpleSelectCrystal(value);
    const crysItem = crystal.find((crysItem) => crysItem.id == value);
    setCrystalPrice(crysItem.price);
    setCrystalGst(crysItem.ligst);
    setPackListErr("");
    if (packingOption === "Crystal Dibbi" && value) {
      let crysTotal = crysItem.price * selectedBox?.box;
      setPackVal(crysTotal.toFixed(2));
    }
  };

  const handleSimplePloythene = (event) => {
    // setSimpleSelectPloythene(event.target.value);
    const { value } = event.target;
    setSimpleSelectPloythene(value);
    const ployItem = ploythene.find((ployItem) => ployItem.id == value);
    setPloyPrice(ployItem.price);
    setPloyGst(ployItem.ligst);
    setPackListErr("");
    if (packingOption === "Polythene PP" && value) {
      let ployTotal = ployItem.price * selectedBox?.box;
      setPackVal(ployTotal.toFixed(2));
    }
  };

  const handleCheckBoxImage = (e, i) => {
    // setCheckBoxmaking(e.target.checked);
    const { checked } = e.target;
    setImageErr("");
    if (checked) {
      setImageId(parseInt(imageId) + parseInt(i.size));
    } else {
      setImageId(parseInt(imageId) - parseInt(i.size));
    }

    setCheckBoxImage((prev) => {
      if (prev.includes(i)) {
        return prev.filter((x) => x !== i);
      } else {
        return [...prev, i];
      }
    });

    setImgChecked((prev) => {
      if (prev.includes(i?.id)) {
        return prev.filter((x) => x !== i?.id);
      } else {
        return [...prev, i?.id];
      }
    });
  };

  const SubmitAddProduct = () => {
    let errorCount = 0;
    if (selectedPieceSets === "" || !selectedPieceSets) {
      errorCount++;
      setSetsErr("Select Any One");
    }
    if (selectedBox === "" || !selectedBox) {
      errorCount++;
      setBoxErr("Select Any One");
    }
    if (makVal === "" || !makVal) {
      errorCount++;
      setChildErr("Select Atleast From 1 to 5");
    }
    if (simpleSelectMetal === "" || !simpleSelectMetal) {
      errorCount++;
      setMetalErr("Select Atleast One");
    }
    if (packingOption === "" || !packingOption) {
      errorCount++;
      setPackOptionErr("Select Atleast One");
    }
    if (
      packingOption === "Plastic Dibbi"
        ? simpleSelectPlastic === ""
        : packingOption === "Crystal Dibbi"
        ? simpleSelectCrystal === ""
        : packingOption === "Polythene PP"
        ? simpleSelectPloythene === ""
        : "" || packingOption === "Plastic Dibbi"
        ? !simpleSelectPlastic
        : packingOption === "Crystal Dibbi"
        ? !simpleSelectCrystal
        : packingOption === "Polythene PP"
        ? !simpleSelectPloythene
        : ""
    ) {
      errorCount++;
      setPackListErr("Select Atleast One");
    }
    if (imageId === "" || !imageId) {
      errorCount++;
      setImageErr("Select Atleast From 1 to 5");
    }
    if (selectedGstCal === "" || !selectedGstCal) {
      errorCount++;
      setCalculationErr("Select Any One & Check Again");
    }
    if (errorCount === 0) {
      setBtn(true);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Basic ${credentials}`,
        },
        body: JSON.stringify({
          metalGram: parseFloat(metalGram),
          moqkg: parseFloat(moqkg),
          taxApply: selectedGstCal === "gst" ? true : false,
          cgst: selectedGstCal === "gst" ? parseFloat(cgst) : parseFloat(cgst),
          sgst: selectedGstCal === "gst" ? parseFloat(sgst) : parseFloat(sgst),
          igst: selectedGstCal === "gst" ? parseFloat(igst) : parseFloat(igst),
          extraGram: parseFloat(metalSGram),
          readyGram: parseFloat(readyGram),
          golwireThaw: golwireThaw,
          hsnSacCode: hsnCode ? hsnCode : null,
          itemCodeNumber: prodItemCode ? prodItemCode : null,
          prodManfDetails: prodMfgDetails ? prodMfgDetails : null,
          prodDescription: prodDescription ? prodDescription : null,
          ctnDescription: cartonDescription,
          ctnQuantity: parseFloat(cartonQuantity),
          ctnPackCharge: parseFloat(cartonPackingCharge),
          active: checkedA,
          discount: discount,
          gstCash: selectedGstCal === "gst" ? true : false,
          productchild: checkBoxMaking.map((c) => {
            return {
              lbrId: c.id,
              price: c.price,
              taxPercentage: c.taxPercentage,
              hsncode: c.hsncode,
              title: c.title,
              tax: c.tax,
            };
          }),
          productimage: checkBoxImage.map((item) => {
            return {
              imgId: item.id,
              categoryId: item.categoryId,
              parentId: item.parentId,
              cropImageUrl: null,
              cropName: item.cropName,
            };
          }),
        }),
      };
      fetch(
        localApi +
          `/product/add/byAdminAndCategoryAndParentAndBoxAndSetsAndRawPriceAndPackingMaterialId/${
            userObj.id
          }/${simpleSelect}/${simpleSelect1}/${selectedBox.id}/${
            selectedPieceSets.id
          }/${metalDetails.id}/${
            packingOption === "Plastic Dibbi"
              ? simpleSelectPlastic
              : packingOption === "Crystal Dibbi"
              ? simpleSelectCrystal
              : packingOption === "Polythene PP"
              ? simpleSelectPloythene
              : ""
          }`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          if (data.message === "Data Inserted Successfully !!!") {
            alertTypeOps("success");
            alertMsgOps("Succesfully !!!");
            setSubmitted(true);
          }
        });
    }
  };

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  useEffect(() => {
    if (selectedPieceSets) {
      let platingPrice = selectedPieceSets?.pcs * selectedBox?.box;
      setPlatVal(platingPrice ? platingPrice.toFixed(2) : 0);
    }
    if (selectedBox) {
      let boxTotal = selectedBox?.price + selectedBox?.pkgCharge;
      setBoxVal(boxTotal);
    }
    if (metalGram) {
      let newMet =
        metalGram *
        selectedPieceSets?.pcs *
        selectedBox?.box *
        (metalPrice + metalDiff);
      setMetGramVal(newMet.toFixed(2));
    }
    if (simpleSelectMetal) {
      let newMet =
        metalGram *
        selectedPieceSets?.pcs *
        selectedBox?.box *
        (metalPrice + metalDiff);
      setMetGramVal(newMet.toFixed(2));
    }
    if (packingOption === "Plastic Dibbi") {
      const ployTotals = plasticPrice * selectedBox?.box;
      setPackVal(ployTotals.toFixed(2));
    }
    if (packingOption === "Crystal Dibbi") {
      const crysTotals = crystalPrice * selectedBox?.box;
      setPackVal(crysTotals.toFixed(2));
    }
    if (packingOption === "Polythene PP") {
      const ployTotals = ployPrice * selectedBox?.box;
      setPackVal(ployTotals.toFixed(2));
    }
    // if (simpleSelectPloythene) {
    //   let ployTotal = ployPrice * selectedBox?.box;
    //   setPackVal(ployTotal.toFixed(2));
    //   // console.log("change", simpleSelectPloythene);
    // }
    // if (simpleSelectCrystal) {
    //   let crysTotal = crystalPrice * selectedBox?.box;
    //   setPackVal(crysTotal.toFixed(2));
    // }
    // if (simpleSelectPlastic) {
    //   let packTotal = plasticPrice * selectedBox?.box;
    //   setPackVal(packTotal.toFixed(2));
    // }
    if (checkBoxMaking) {
      let makPrice = makingPrice * selectedPieceSets?.pcs * selectedBox?.box;
      setMakVal(makPrice);
    }
  }, [
    selectedPieceSets,
    selectedBox,
    metalGram,
    simpleSelectMetal,
    checkBoxMaking,
    // simpleSelectPloythene,
    // simpleSelectCrystal,
    // simpleSelectPlastic,
    packingOption === "Plastic Dibbi",
    packingOption === "Crystal Dibbi",
    packingOption === "Polythene PP",
  ]);

  var mixmakPrice = 0;
  var mixmetPrice = 0;
  var mixplatingPrice = 0;
  var lbrmetprice = 0;
  var mixmakPriceGst = 0;
  var mixplatPriceGst = 0;
  var mixmetPriceGst = 0;

  // useEffect(() => {
  // setSelectedPieceSets("");
  // setBoxVal("");
  // setSelectedBox("");
  // setItems2("");
  // setCheckBoxmaking("");
  // setMakVal("");
  // setMakingPrice("");
  // setMakingGst("");
  // setMakIncludeGst("");
  // setMakingSingleId("");
  // setMakChecked("");
  // setMetGramVal("");
  // setSimpleSelectMetal("");
  // setMetalPrice("");
  // setMetalDiff("");
  // setPackVal("");
  // setPackingOption("");
  // setSimpleSelectPlastic("");
  // setSimpleSelectCrystal("");
  // setSimpleSelectPloythene("");
  // setSelectedGstCal("");
  // setCheckedA("");
  // setIncludeGST("");
  // setTotalCash("");
  // setIncludeSets("");
  // setSets("");
  // setIncludeProfit("");
  // setTotalProfit("");
  // setIncludeProfitSet("");
  // setSetProfit("");
  // setImgList("");
  // }, [simpleSelect1]);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <AddIcon />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Add Product</small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {submitted ? (
                  <SweetAlert
                    type={alertType}
                    style={{ display: "block", marginTop: "-100px" }}
                    title={alertMsg}
                    onConfirm={() => hideAlert()}
                    confirmBtnCssClass={
                      classSweet.button + " " + classSweet.success
                    }
                  >
                    Added Product
                  </SweetAlert>
                ) : null}
                <GridItem xs={12} sm={12} md={12}>
                  <FormControl fullWidth className={classe.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classe.selectLabel}
                    >
                      Select Category Name...
                    </InputLabel>

                    <Select
                      MenuProps={{
                        className: classe.selectMenu,
                      }}
                      classes={{
                        select: classe.select,
                      }}
                      value={simpleSelect}
                      onChange={handleSimple}
                      inputProps={{
                        name: "simpleSelect",
                        id: "simple-select",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classe.selectMenuItem,
                        }}
                      >
                        Select Category Name...
                      </MenuItem>
                      {items &&
                        items.map((item) => (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                            classes={{
                              root: classe.selectMenuItem,
                              selected: classe.selectMenuItemSelected,
                            }}
                          >
                            {item.title}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>{" "}
                <GridItem xs={12} sm={12} md={12}>
                  <FormControl fullWidth className={classe.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classe.selectLabel}
                    >
                      Select Bangles Size-MM...
                    </InputLabel>

                    <Select
                      MenuProps={{
                        className: classe.selectMenu,
                      }}
                      classes={{
                        select: classe.select,
                      }}
                      value={simpleSelect1}
                      onChange={handleSimple1}
                      inputProps={{
                        name: "simpleSelect",
                        id: "simple-select",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classe.selectMenuItem,
                        }}
                      >
                        Select Bangles Size-MM...
                      </MenuItem>
                      {items1 &&
                        items1.map((item1) => (
                          <MenuItem
                            key={item1.id}
                            value={item1.id}
                            classes={{
                              root: classe.selectMenuItem,
                              selected: classe.selectMenuItemSelected,
                            }}
                          >
                            {item1.title}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <FaRing />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Piece/Sets</small>
                <small
                  style={{ fontSize: "15px", color: "red", marginLeft: "12px" }}
                >
                  {setsErr}
                </small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {itemSets &&
                  itemSets.map((sets) => (
                    <GridItem xs={12} sm={4} md={3} key={sets.id}>
                      <FormControlLabel
                        key={sets.id}
                        control={
                          <Radio
                            // checked={selectedPieceSets && selectedPieceSets
                            //     ? selectedPieceSets === sets.id
                            //     : sets.active === 1}
                            checked={
                              selectedPieceSets && selectedPieceSets.id
                                ? selectedPieceSets?.id === sets?.id
                                : sets?.active === 1
                            }
                            onChange={(e) => {
                              setSelectedPieceSets(sets);
                              setSetsErr("");
                              // console.log(sets);
                            }}
                            value={sets.id}
                            name="radioButton"
                            aria-label="A"
                            icon={
                              <FiberManualRecord
                                className={classesRadio.radioUnchecked}
                              />
                            }
                            checkedIcon={
                              <FiberManualRecord
                                className={classesRadio.radioChecked}
                              />
                            }
                            classes={{
                              checked: classesRadio.radioRose,
                              root: classesRadio.radioRoot,
                            }}
                          />
                        }
                        classes={{
                          label: classesRadio.label,
                          root: classesRadio.labelRoot,
                        }}
                        // label={sets.setdesc}
                      />
                      <label
                        style={{
                          color:
                            sets?.id === selectedPieceSets?.id ? "#d81b60" : "",
                        }}
                      >
                        {sets.setDesc}
                      </label>
                    </GridItem>
                  ))}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <FaBox />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Box + Labour ₹ {boxVal}/-</small>
                <small
                  style={{ fontSize: "15px", color: "red", marginLeft: "12px" }}
                >
                  {boxErr}
                </small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {itemBox &&
                  itemBox.map((box) => (
                    <GridItem xs={12} sm={6} md={3} key={box.id}>
                      <FormControlLabel
                        key={box.id}
                        control={
                          <Radio
                            // checked={
                            //     selectedBox &&
                            //         selectedBox ? selectedBox === box.id
                            //         : box.active === 1
                            // }
                            checked={
                              selectedBox && selectedBox.id
                                ? selectedBox?.id === box?.id
                                : box?.active === 1
                            }
                            onChange={(e) => {
                              setSelectedBox(box);
                              setBoxErr("");
                              // console.log(box);
                              if (box?.id) {
                                let boxTotal = box?.price + box?.pkgCharge;
                                setBoxVal(boxTotal);
                              }
                            }}
                            value={box.id}
                            name="radio button demo"
                            aria-label="A"
                            icon={
                              <FiberManualRecord
                                className={classesRadio.radioUnchecked}
                              />
                            }
                            checkedIcon={
                              <FiberManualRecord
                                className={classesRadio.radioChecked}
                              />
                            }
                            classes={{
                              checked: classesRadio.radioRose,
                              root: classesRadio.radioRoot,
                            }}
                          />
                        }
                        classes={{
                          label: classesRadio.label,
                          root: classesRadio.labelRoot,
                        }}
                        // label={box.box + " " + box.brandname}
                      />
                      <label
                        style={{
                          color: box?.id === selectedBox?.id ? "#d81b60" : "",
                        }}
                      >
                        {box.box + " " + box.brandName}
                      </label>
                    </GridItem>
                  ))}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <FaUsers />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Making Charge Per (Box : ₹ {makVal.toFixed(2)}) </small>
                <small
                  style={{ fontSize: "15px", color: "red", marginLeft: "12px" }}
                >
                  {childErr}
                </small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {items2 &&
                  items2.map((making) => (
                    <GridItem xs={12} sm={6} md={4} key={making.id}>
                      <FormControlLabel
                        key={making.id}
                        control={
                          <Checkbox
                            tabIndex={-1}
                            checked={checkBoxMaking.includes(making)}
                            name={making.description}
                            onChange={(e) => {
                              handleCheckBoxMaking(e, making);
                              // console.log(making);
                            }}
                            checkedIcon={
                              <Check className={classCheck.checkedIcon} />
                            }
                            icon={
                              <Check className={classCheck.uncheckedIcon} />
                            }
                            classes={{
                              checked: classCheck.checkedRose,
                              root: classCheck.checkRoot,
                            }}
                          />
                        }
                        classes={{
                          label:
                            classCheck.label +
                            (makingCheckboxMaking === "error"
                              ? " " + classCheck.labelError
                              : ""),
                        }}
                        // label={making.description + "> " + making.price}
                      />
                      <label
                        style={{
                          color: checkBoxMaking.includes(making)
                            ? "#d81b60"
                            : "",
                        }}
                      >
                        {making.title + "-" + making.price}
                      </label>
                    </GridItem>
                  ))}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <GiWireCoil />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>
                  {" "}
                  Metal & Ready Gram Per Piece (Box ₹ {metGramVal})
                </small>
                <small
                  style={{ fontSize: "15px", color: "red", marginLeft: "12px" }}
                >
                  {metalErr}
                </small>
              </h3>
            </CardHeader>
            <CardBody>
              <h5 className={classes.cardIconTitle}>
                Metal Price ₹{" "}
                {simpleSelectMetal ? metalPrice + " + " + metalDiff : ""}
              </h5>
              <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                  <FormControl fullWidth className={classe.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classe.selectLabel}
                    >
                      Select Metal
                    </InputLabel>

                    <Select
                      MenuProps={{
                        className: classe.selectMenu,
                      }}
                      classes={{
                        select: classe.select,
                      }}
                      value={simpleSelectMetal}
                      onChange={handleSimpleMetal}
                      inputProps={{
                        name: "simpleSelect",
                        id: "simple-select",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classe.selectMenuItem,
                        }}
                      >
                        Select Metal
                      </MenuItem>
                      {metal &&
                        metal.map((item) => (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                            classes={{
                              root: classe.selectMenuItem,
                              selected: classe.selectMenuItemSelected,
                            }}
                          >
                            {item.title +
                              "> " +
                              item.price +
                              " + " +
                              item.different}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <CustomInput
                    labelText="Ready Gram"
                    id="readyGram"
                    // inputProps={{
                    //   placeholder: "Ready Gram",
                    // }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="readyGram"
                    value={readyGram}
                    onChange={(e) => setReadyGram(e.target.value)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <CustomInput
                    labelText="Metal Gram"
                    id="metalGram"
                    // inputProps={{
                    //   placeholder: "Metal Gram",
                    // }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="metalGram"
                    value={metalGram}
                    onChange={(e) => {
                      const { value } = e.target;
                      setMetalGram(value);
                      if (value) {
                        let newMet =
                          value *
                          selectedPieceSets?.pcs *
                          selectedBox?.box *
                          (metalPrice + metalDiff);
                        setMetGramVal(newMet.toFixed(2));
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <CustomInput
                    labelText="Extra S Gram"
                    id="metalSGram"
                    // inputProps={{
                    //   placeholder: "Metal S Gram",
                    // }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="metalSGram"
                    value={metalSGram}
                    onChange={(e) => setMetalSGram(e.target.value)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <CustomInput
                    labelText="Thaw Golwire"
                    id="golwireThaw"
                    // inputProps={{
                    //   placeholder: "Golwire Thaw",
                    // }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="golwireThaw"
                    value={golwireThaw}
                    onChange={(e) => setGolwireThaw(e.target.value)}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <GiBoxUnpacking />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small> Packing Option (Box ₹ {packVal})</small>
                <small
                  style={{ fontSize: "15px", color: "red", marginLeft: "12px" }}
                >
                  {packOptionErr}
                  {packListErr}
                </small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={packingOption === "Plastic Dibbi"}
                          onChange={handlePackingOption}
                          value="Plastic Dibbi"
                          name="Plastic Dibbi"
                          aria-label="Plastic Dibbi"
                          icon={
                            <FiberManualRecord
                              className={classesRadio.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classesRadio.radioChecked}
                            />
                          }
                          classes={{
                            checked: classesRadio.radioRose,
                            root: classesRadio.radioRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classesRadio.label,
                        root: classesRadio.labelRoot,
                      }}
                      // label="Plastic Dibbi"
                    />
                    <label
                      style={{
                        color:
                          packingOption === "Plastic Dibbi" ? "#d81b60" : "",
                      }}
                    >
                      Plastic Dibbi
                    </label>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControl fullWidth className={classe.selectFormControl}>
                      <InputLabel
                        htmlFor="simple-select"
                        className={classe.selectLabel}
                      >
                        Select Plastic
                      </InputLabel>

                      <Select
                        MenuProps={{
                          className: classe.selectMenu,
                        }}
                        classes={{
                          select: classe.select,
                        }}
                        value={simpleSelectPlastic}
                        onChange={handleSimplePlastic}
                        inputProps={{
                          name: "simpleSelect",
                          id: "simple-select",
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classe.selectMenuItem,
                          }}
                        >
                          Select Plastic
                        </MenuItem>
                        {plastic &&
                          plastic.map((plas) => (
                            <MenuItem
                              key={plas.id}
                              value={plas.id}
                              classes={{
                                root: classe.selectMenuItem,
                                selected: classe.selectMenuItemSelected,
                              }}
                            >
                              {plas.pkgdesc + "> " + plas.price}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={packingOption === "Crystal Dibbi"}
                          onChange={handlePackingOption}
                          value="Crystal Dibbi"
                          name="Crystal Dibbi"
                          aria-label="Crystal Dibbi"
                          icon={
                            <FiberManualRecord
                              className={classesRadio.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classesRadio.radioChecked}
                            />
                          }
                          classes={{
                            checked: classesRadio.radioRose,
                            root: classesRadio.radioRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classesRadio.label,
                        root: classesRadio.labelRoot,
                      }}
                      // label="Crystal Dibbi"
                    />
                    <label
                      style={{
                        color:
                          packingOption === "Crystal Dibbi" ? "#d81b60" : "",
                      }}
                    >
                      Crystal Dibbi
                    </label>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControl fullWidth className={classe.selectFormControl}>
                      <InputLabel
                        htmlFor="simple-select"
                        className={classe.selectLabel}
                      >
                        Select Crystal
                      </InputLabel>

                      <Select
                        MenuProps={{
                          className: classe.selectMenu,
                        }}
                        classes={{
                          select: classe.select,
                        }}
                        value={simpleSelectCrystal}
                        onChange={handleSimpleCrystal}
                        inputProps={{
                          name: "simpleSelect",
                          id: "simple-select",
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classe.selectMenuItem,
                          }}
                        >
                          Select Crystal
                        </MenuItem>
                        {crystal &&
                          crystal.map((crys) => (
                            <MenuItem
                              key={crys.id}
                              value={crys.id}
                              classes={{
                                root: classe.selectMenuItem,
                                selected: classe.selectMenuItemSelected,
                              }}
                            >
                              {crys.pkgdesc + "> " + crys.price}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={packingOption === "Polythene PP"}
                          onChange={handlePackingOption}
                          value="Polythene PP"
                          name="Polythene PP"
                          aria-label="Polythene PP"
                          icon={
                            <FiberManualRecord
                              className={classesRadio.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classesRadio.radioChecked}
                            />
                          }
                          classes={{
                            checked: classesRadio.radioRose,
                            root: classesRadio.radioRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classesRadio.label,
                        root: classesRadio.labelRoot,
                      }}
                      // label="Polythene PP"
                    />
                    <label
                      style={{
                        color:
                          packingOption === "Polythene PP" ? "#d81b60" : "",
                      }}
                    >
                      Polythene PP
                    </label>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControl fullWidth className={classe.selectFormControl}>
                      <InputLabel
                        htmlFor="simple-select"
                        className={classe.selectLabel}
                      >
                        Select Polythene
                      </InputLabel>

                      <Select
                        MenuProps={{
                          className: classe.selectMenu,
                        }}
                        classes={{
                          select: classe.select,
                        }}
                        value={simpleSelectPloythene}
                        onChange={handleSimplePloythene}
                        inputProps={{
                          name: "simpleSelect",
                          id: "simple-select",
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classe.selectMenuItem,
                          }}
                        >
                          Select Polythene
                        </MenuItem>
                        {ploythene &&
                          ploythene.map((ploy) => (
                            <MenuItem
                              key={ploy.id}
                              value={ploy.id}
                              classes={{
                                root: classe.selectMenuItem,
                                selected: classe.selectMenuItemSelected,
                              }}
                            >
                              {ploy.pkgdesc + "> " + ploy.price}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <AiOutlineCalculator />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Price Calculation</small>
                <small
                  style={{ fontSize: "15px", color: "red", marginLeft: "12px" }}
                >
                  {calculationErr}
                </small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={6} sm={6} md={4}>
                  {/* <GridItem xs={12} sm={12} md={12}> */}
                  <FormControlLabel
                    control={
                      <Radio
                        checked={selectedGstCal === "gst"}
                        onChange={handleChangeGstCal}
                        value="gst"
                        name="gst"
                        aria-label="GST"
                        icon={
                          <FiberManualRecord
                            className={classesRadio.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord
                            className={classesRadio.radioChecked}
                          />
                        }
                        classes={{
                          checked: classesRadio.radioRose,
                          root: classesRadio.radioRoot,
                        }}
                      />
                    }
                    classes={{
                      label: classesRadio.label,
                      root: classesRadio.labelRoot,
                    }}
                    // label="GST"
                  />
                  <label
                    style={{ color: selectedGstCal === "gst" ? "#d81b60" : "" }}
                  >
                    TaxInclude ({tax})
                  </label>
                </GridItem>
                <GridItem xs={6} sm={6} md={4}>
                  {/* <GridItem xs={12} sm={12} md={12}> */}
                  <FormControlLabel
                    control={
                      <Radio
                        checked={selectedGstCal === "cash"}
                        onClick={handleChangeGstCal}
                        value="cash"
                        name="cash"
                        aria-label="Cash"
                        icon={
                          <FiberManualRecord
                            className={classesRadio.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord
                            className={classesRadio.radioChecked}
                          />
                        }
                        classes={{
                          checked: classesRadio.radioRose,
                          root: classesRadio.radioRoot,
                        }}
                      />
                    }
                    classes={{
                      label: classesRadio.label,
                      root: classesRadio.labelRoot,
                    }}
                    // label="CASH"
                  />
                  <label
                    style={{
                      color: selectedGstCal === "cash" ? "#d81b60" : "",
                    }}
                  >
                    CASH
                  </label>
                </GridItem>
                <GridItem xs={6} sm={6} md={4}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={checkedA}
                        onChange={(event) => setCheckedA(event.target.checked)}
                        value="checkedA"
                        classes={{
                          switchBase: classe.switchBase,
                          checked: classes.switchChecked,
                          thumb: classe.switchIcon,
                          track: classe.switchBar,
                        }}
                      />
                    }
                    classes={{
                      label: classe.label,
                    }}
                    label={
                      checkedA === false ? (
                        "Offline"
                      ) : (
                        <span
                          style={{ color: checkedA === true ? "#d81b60" : "" }}
                        >
                          Online
                        </span>
                      )
                    }
                  />
                </GridItem>
              </GridContainer>
              <GridContainer style={{ border: "1px solid #eeeeee" }}>
                <GridItem xs={12} sm={4} ms={4}>
                  <h5 className={classes.cardIconTitle}>
                    <small>Making Charge</small>
                  </h5>
                </GridItem>
                <GridItem xs={6} sm={4} ms={4}>
                  {" "}
                  <h5 className={classes.cardIconTitle}>
                    <small>
                      ₹{" "}
                      {selectedGstCal && selectedGstCal === "gst"
                        ? includeGST
                        : totalCash}
                      /-
                    </small>
                  </h5>
                </GridItem>
                <GridItem xs={6} sm={4} ms={4}>
                  <h5 className={classes.cardIconTitle}>
                    <small>
                      Sets ₹{" "}
                      {selectedGstCal && selectedGstCal === "gst"
                        ? includeSets
                        : sets}
                      /-
                    </small>
                  </h5>
                </GridItem>
              </GridContainer>
              <GridContainer style={{ border: "1px solid #eeeeee" }}>
                <GridItem xs={12} sm={4} ms={4}>
                  <FormControlLabel
                    control={
                      <Radio
                        checked={selectedProfit === "profit"}
                        onClick={handleProfit}
                        value="profit"
                        name="profit"
                        aria-label="profit"
                        icon={
                          <FiberManualRecord
                            className={classesRadio.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord
                            className={classesRadio.radioChecked}
                          />
                        }
                        classes={{
                          checked: classesRadio.radioRose,
                          root: classesRadio.radioRoot,
                        }}
                      />
                    }
                    classes={{
                      label: classesRadio.label,
                      root: classesRadio.labelRoot,
                    }}
                    label={`Profit ${userObj.profit}%`}
                  />
                </GridItem>
                <GridItem xs={6} sm={4} ms={4}>
                  <h5 className={classes.cardIconTitle}>
                    <small>
                      ₹{" "}
                      {selectedGstCal && selectedGstCal === "gst"
                        ? includeProfit
                        : totalProfit}
                      /-
                    </small>
                  </h5>
                </GridItem>
                <GridItem xs={6} sm={4} ms={4}>
                  <h5 className={classes.cardIconTitle}>
                    <small>
                      {" "}
                      Sets ₹{" "}
                      {selectedGstCal && selectedGstCal === "gst"
                        ? includeProfitSet
                        : setProfit}
                      /-
                    </small>
                  </h5>
                </GridItem>
              </GridContainer>
              {selectedGstCal === "gst" ? (
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="CGST @9%..."
                      id="cgst"
                      inputProps={{
                        placeholder: "CGST @9%...",
                      }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      name="cgst"
                      value={cgst}
                      onChange={(e) => {
                        setCgst(e.target.value);
                      }}
                      required={true}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="SGST @9%..."
                      id="sgst"
                      inputProps={{
                        placeholder: "SGST @9%...",
                      }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      name="sgst"
                      value={sgst}
                      onChange={(e) => {
                        setSgst(e.target.value);
                      }}
                      required={true}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="IGST @18%..."
                      id="igst"
                      inputProps={{
                        placeholder: "IGST @18%...",
                      }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      name="igst"
                      value={igst}
                      onChange={(e) => {
                        setIgst(e.target.value);
                      }}
                      required={true}
                    />
                  </GridItem>
                </GridContainer>
              ) : (
                ""
              )}
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Discount..."
                    id="discount"
                    inputProps={{
                      placeholder: "Discount...",
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="discount"
                    value={discount}
                    onChange={(e) => {
                      setDiscount(e.target.value);
                    }}
                    required={true}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <BiFile />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Product Detail</small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={2}>
                  <CustomInput
                    labelText="Item Code"
                    id="prodItemCode"
                    // inputProps={{
                    //   placeholder: "Golwire Thaw",
                    // }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="prodItemCode"
                    value={prodItemCode}
                    onChange={(e) => setProdItemCode(e.target.value)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <CustomInput
                    labelText="Moq kg"
                    id="moqkg"
                    // inputProps={{
                    //   placeholder: "Golwire Thaw",
                    // }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="moqkg"
                    value={moqkg}
                    onChange={(e) => setMoqkg(e.target.value)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Product Manufacture Details"
                    id="prodMfgDetails"
                    // inputProps={{
                    //   placeholder: "Golwire Thaw",
                    // }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="prodMfgDetails"
                    value={prodMfgDetails}
                    onChange={(e) => setProdMfgDetails(e.target.value)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Product Description Min(100) Max(150)"
                    // labelText={prodDescription.length <= 150 ? "ok" : "no"}
                    id="prodDescription"
                    inputProps={{
                      placeholder:
                        "Ex. The design may change, choose with your range",
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="prodDescription"
                    value={prodDescription}
                    onChange={(e) => {
                      const { value } = e.target;
                      // console.log(value.length <= 150 ? value.slice(0, 150) : value.slice(0, 150));
                      setProdDescription(
                        value.length <= 150
                          ? value.slice(0, 150)
                          : value.slice(0, 150)
                      );
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Carton Description"
                    id="cartonDescription"
                    // inputProps={{
                    //   placeholder: "Golwire Thaw",
                    // }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="cartonDescription"
                    value={cartonDescription}
                    onChange={(e) => setCartonDescription(e.target.value)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Carton Quantity"
                    id="cartonQuantity"
                    // inputProps={{
                    //   placeholder: "Golwire Thaw",
                    // }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="cartonQuantity"
                    value={cartonQuantity}
                    onChange={(e) => setCartonQuantity(e.target.value)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Carton Packing Charge"
                    id="cartonPackingCharge"
                    // inputProps={{
                    //   placeholder: "Golwire Thaw",
                    // }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="cartonPackingCharge"
                    value={cartonPackingCharge}
                    onChange={(e) => setCartonPackingCharge(e.target.value)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="HSN/SAC CODE"
                    id="hsnsaccode"
                    // inputProps={{
                    //   placeholder: "Golwire Thaw",
                    // }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="hsnCode"
                    value={hsnCode}
                    onChange={(e) => setHsnCode(e.target.value)}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <BsImages />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Select Design Images</small>
                <small
                  style={{ fontSize: "15px", color: "red", marginLeft: "12px" }}
                >
                  {imageErr}
                </small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {imgList &&
                  imgList.map((i) => (
                    <GridItem xs={3} sm={2} md={2} key={i.id}>
                      <div className="fileinput text-center">
                        <div
                          className={
                            "thumbnail" + (avatar ? " img-circle" : "")
                          }
                        >
                          <img src={i.cropImageUrl + "?id=" + i.id} alt="..." />
                        </div>
                      </div>
                      <br />
                      <div>
                        <span style={{ marginRight: "5px" }}>{i.id}</span>
                        <FormControlLabel
                          key={i.id}
                          control={
                            <Checkbox
                              tabIndex={-1}
                              checked={checkBoxImage.includes(i)}
                              name={i.cropName}
                              onChange={(e) => {
                                handleCheckBoxImage(e, i);
                              }}
                              checkedIcon={
                                <Check className={classCheck.checkedIcon} />
                              }
                              icon={
                                <Check className={classCheck.uncheckedIcon} />
                              }
                              classes={{
                                checked: classCheck.checkedRose,
                                root: classCheck.checkRoot,
                              }}
                            />
                          }
                          classes={{
                            label:
                              classCheck.label +
                              (makingCheckboxImage === "error"
                                ? " " + classCheck.labelError
                                : ""),
                          }}
                          // label={i.id}
                        />
                        {/* {"using - " + i.usedCount} */}
                      </div>
                    </GridItem>
                  ))}
              </GridContainer>
              {/* <Button
                color="rose"
                className={classes.updateProfileButton}
                onClick={SubmitAddProduct}
              >
                Save
              </Button> */}
              {btn === true ? (
                <Button className={classes.updateProfileButton}>Save</Button>
              ) : (
                <Button
                  color="rose"
                  onClick={SubmitAddProduct}
                  className={classes.updateProfileButton}
                >
                  Save
                </Button>
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

AddProduct.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
};

import React, { useEffect } from "react";
import { produce } from "immer";
import { makeStyles } from "@material-ui/core/styles";
import { BiEdit } from "react-icons/bi";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import SweetAlert from "react-bootstrap-sweetalert";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import styleSweet from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import style from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import { localApi } from "utils/imgApi";
import styRadio from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import styleTable from "assets/jss/material-dashboard-pro-react/views/viewCartStyle";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(style);
const useStyless = makeStyles(styRadio);
const useStyleSweet = makeStyles(styleSweet);
const useStyleTable = makeStyles(styleTable);

export default function LabourReceiveDeletePlating(props) {
  const [billView, setBillView] = React.useState([]);
  const [checkOldAddress, setCheckOldAddress] = React.useState(false);
  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);

  const classes = useStyles();
  const classe = useStyle();
  const classesRadio = useStyless();
  const classSweet = useStyleSweet();
  const classTable = useStyleTable();

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  async function getList() {
    const getList = await fetch(
      localApi + `/metal/list/byAdminId/${userObj && userObj.id}`
    );
    const res = await getList.json();
    setBillView(res && res);
  }

  useEffect(() => {
    getList();
  }, []);

  const handleDeletePlating = (c) => {
    fetch(
      localApi + `/metal/delete/byAdminAndMetalId/${userObj && userObj.id}/${c.metalId}`,
      {
        method: "DELETE",
      }
    )
      .then((res) => res.json())
      .then((res) => {
        // console.log(res);
        if (res.message === "Deleted Successfully !!!") {
          alertTypeOps("success");
          alertMsgOps("Deleted Successfully !!!");
          setSubmitted(true);
        }
      });
  };

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  return (
    <div>
      <GridContainer justify="center" alignItems="center">
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <BiEdit />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Delete Receieve Plating</small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer justify="center" alignItems="center">
                {submitted ? (
                  <SweetAlert
                    type={alertType}
                    style={{ display: "block", marginTop: "-100px" }}
                    title={alertMsg}
                    onConfirm={() => hideAlert()}
                    confirmBtnCssClass={
                      classSweet.button + " " + classSweet.success
                    }
                  />
                ) : null}
                <Table
                  hover
                  tableHead={[
                    <span className={classTable.tableHead} key="sr">
                      Sr.No.
                    </span>,
                    <span className={classTable.tableHead} key="bn">
                      Bill.No.
                    </span>,
                    <span className={classTable.tableHead} key="dt">
                      Date
                    </span>,
                    <span className={classTable.tableHead} key="pn">
                      Party Name
                    </span>,
                    <span className={classTable.tableHead} key="des">
                      Description of Goods
                    </span>,
                    <span
                      className={
                        classTable.tableHead + " " + classTable.headRight
                      }
                      key="qty"
                    >
                      Quantity
                    </span>,
                    <span
                      className={
                        classTable.tableHead + " " + classTable.headRight
                      }
                      key="ut"
                    >
                      Unit
                    </span>,
                    <span
                      className={
                        classTable.tableHead + " " + classTable.headRight
                      }
                      key="pri"
                    >
                      Price
                    </span>,
                    <span
                      className={
                        classTable.tableHead + " " + classTable.headRight
                      }
                      key="amt"
                    >
                      Amount
                    </span>,
                    "",
                  ]}
                  tableData={[
                    ...billView.map((c, index) => ({
                      color: c.taxApply === true ? "success" : "",
                      data: [
                        <span className={classTable.listNumber} key={c.id}>
                          {index + 1}
                        </span>,
                        <span className={classTable.listNumber} key={c.id}>
                          {c.id}
                        </span>,
                        <span className={classTable.listNumber} key={c.id}>
                          {c.pickerdate}
                        </span>,
                        <span className={classTable.listNumber} key={c.id}>
                          {c.firstName + " " + c.lastName}
                        </span>,
                        <span key={c.id}>
                          <small className={classTable.listNumber}>
                            {c.title} - {c.description}
                          </small>
                        </span>,
                        <span
                          className={
                            classTable.headRight + " " + classTable.listNumber
                          }
                          key={c.id}
                        >
                          {c.quantity}
                        </span>,
                        <span
                          className={
                            classTable.headRight + " " + classTable.listNumber
                          }
                          key={c.id}
                        >
                          {c.unit}
                        </span>,
                        <span
                          className={
                            classTable.headRight + " " + classTable.listNumber
                          }
                          key={c.id}
                        >
                          ₹{c.price}
                        </span>,
                        <span
                          className={
                            classTable.headRight + " " + classTable.listNumber
                          }
                          key={c.id}
                        >
                          ₹{c.amount.toFixed(2)}
                        </span>,
                        <Button
                          color="rose"
                          block
                          simple
                          onClick={() => {
                            handleDeletePlating(c);
                          }}
                        >
                          <DeleteForeverIcon />
                        </Button>,
                      ],
                    })),
                    [
                      "",
                      "",
                      "",
                      "",
                      <span
                        className={
                          classTable.headRight + " " + classTable.listNumber
                        }
                        key={1}
                      >
                        Grand Total
                      </span>,
                      <span key={3}>
                        <span
                          className={
                            classTable.headRight + " " + classTable.listNumber
                          }
                        >
                          {billView
                            .reduce(
                              (a, b) => parseFloat(a) + parseFloat(b.quantity),
                              0
                            )
                            .toFixed(2)}
                        </span>
                      </span>,
                      "",
                      "",
                      <span key={3}>
                        <span
                          className={
                            classTable.headRight + " " + classTable.listNumber
                          }
                        >
                          ₹
                          {billView
                            .reduce(
                              (a, b) => parseFloat(a) + parseFloat(b.amount),
                              0
                            )
                            .toFixed(2)}
                        </span>
                      </span>,
                      "",
                    ],
                    [
                      "",
                      "",
                      "",
                      "",
                      <span key={3}>
                        <span
                          className={
                            classTable.headRight + " " + classTable.listNumber
                          }
                        >
                          Closing Balance
                        </span>
                      </span>,
                      "",
                      "",
                      "",
                      <span key={3}>
                        <span
                          className={
                            classTable.headRight + " " + classTable.listNumber
                          }
                        >
                          ₹
                          {billView
                            .reduce(
                              (a, b) => parseFloat(a) + parseFloat(b.amount),
                              0
                            )
                            .toFixed(2)}
                        </span>
                      </span>,
                      "",
                    ],
                  ]}
                />
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

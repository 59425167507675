import React, { useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { localApi, catApi } from "utils/imgApi";
import { BiFile } from "react-icons/bi";
import { makeStyles } from "@material-ui/core/styles";
import Selects from "react-select";
import InputLabel from "@material-ui/core/InputLabel";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import AddIcon from "@material-ui/icons/Add";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import { FaBox, FaRing, FaUsers } from "react-icons/fa";
import { GiWireCoil, GiBoxUnpacking } from "react-icons/gi";
import { BsImages } from "react-icons/bs";
import { AiOutlineCalculator } from "react-icons/ai";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import styRadio from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import styDrop from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import styCheckbox from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import styleSweet from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import { credentials } from "utils/imgApi";

const useStyles = makeStyles(styles);
const useStyl = makeStyles(styDrop);
const useStyleSweet = makeStyles(styleSweet);

export default function DeleteStockProduct(props) {
  const [stockProd, setStockProd] = React.useState([]);
  const [itemProd, setItemProd] = React.useState([]);
  const [imgList, setImgList] = React.useState("");
  const [simpleSelectProd, setSimpleSelectProd] = React.useState("");
  const [simpleCartItem, setSimpleCartItem] = React.useState("");
  const [sProdId, setSProdId] = React.useState("");
  const [prdId, setPrdID] = React.useState("");

  const [submitted, setSubmitted] = React.useState(false);
  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");
  const [submitteds, setSubmitteds] = React.useState(false);
  const [submittedConfirm, setSubmittedConfirm] = React.useState(false);

  const [size1x8, setSize1x8] = React.useState(0);
  const [size1x9, setSize1x9] = React.useState(0);
  const [size1x10, setSize1x10] = React.useState(0);
  const [size1x11, setSize1x11] = React.useState(0);
  const [size1x12, setSize1x12] = React.useState(0);
  const [size1x13, setSize1x13] = React.useState(0);
  const [size1x14, setSize1x14] = React.useState(0);
  const [size1x15, setSize1x15] = React.useState(0);
  const [size2x0, setSize2x0] = React.useState(0);
  const [size2x1, setSize2x1] = React.useState(0);
  const [size2x2, setSize2x2] = React.useState(0);
  const [size2x3, setSize2x3] = React.useState(0);
  const [size2x4, setSize2x4] = React.useState(0);
  const [size2x5, setSize2x5] = React.useState(0);
  const [size2x6, setSize2x6] = React.useState(0);
  const [size2x7, setSize2x7] = React.useState(0);
  const [size2x8, setSize2x8] = React.useState(0);
  const [size2x9, setSize2x9] = React.useState(0);
  const [size2x10, setSize2x10] = React.useState(0);
  const [size2x11, setSize2x11] = React.useState(0);
  const [size2x12, setSize2x12] = React.useState(0);
  const [size2x13, setSize2x13] = React.useState(0);
  const [size2x14, setSize2x14] = React.useState(0);
  const [size2x15, setSize2x15] = React.useState(0);
  const [size3x0, setSize3x0] = React.useState(0);
  const [size3x1, setSize3x1] = React.useState(0);
  const [fullCutSize, setFullCutSize] = React.useState("");
  const [btn, setBtn] = React.useState(false);

  const classes = useStyles();
  const classe = useStyl();
  const classSweet = useStyleSweet();

  let { avatar } = props;

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  const totalPiece = "18";
  //   Math.ceil("864" / sets.pcs / box.box);

  async function getStockProduct() {
    const api = localApi + `/stockSize/list/byAdminId/${userObj && userObj.id}`;
    const result = await fetch(api, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getResult = await result.json();
    setStockProd(getResult && getResult);
    // console.log(getResult);
  }

  useEffect(() => {
    getStockProduct();
  }, []);

  const handleCartItem = async (simpleCartItem) => {
    setSimpleCartItem(simpleCartItem);
    if (simpleCartItem.value) {
      let newCartItem = simpleCartItem.value.split("-");
      const itCart = stockProd.find((itemz) => itemz.id == newCartItem[1]);
      //   console.log(itCart);
      setSProdId(itCart.id);
      setSimpleSelectProd(itCart.productId);
      setFullCutSize(itCart.cutOrFullSize);

      setSize1x8(itCart.s1x8);
      setSize1x9(itCart.s1x9);
      setSize1x10(itCart.s1x10);
      setSize1x11(itCart.s1x11);
      setSize1x12(itCart.s1x12);
      setSize1x13(itCart.s1x13);
      setSize1x14(itCart.s1x14);
      setSize1x15(itCart.s1x15);
      setSize2x0(itCart.s2x0);
      setSize2x1(itCart.s2x1);
      setSize2x2(itCart.s2x2);
      setSize2x3(itCart.s2x3);
      setSize2x4(itCart.s2x4);
      setSize2x5(itCart.s2x5);
      setSize2x6(itCart.s2x6);
      setSize2x7(itCart.s2x7);
      setSize2x8(itCart.s2x8);
      setSize2x9(itCart.s2x9);
      setSize2x10(itCart.s2x10);
      setSize2x11(itCart.s2x11);
      setSize2x12(itCart.s2x12);
      setSize2x13(itCart.s2x13);
      setSize2x14(itCart.s2x14);
      setSize2x15(itCart.s2x15);
      setSize3x0(itCart.s3x0);
      setSize3x1(itCart.s3x1);

      const api = localApi + `/product/shortList/byAdminId/${itCart.adminId}`;
      const result = await fetch(api, {
        method: "GET",
        headers: {
          Authorization: `Basic ${credentials}`,
        },
      });
      const getResult = await result.json();
      const getProd = getResult.filter((f) => f.id === itCart.productId)[0];
      setImgList(getProd.productimage);
    }
  };

  async function getUsers() {
    const api = localApi + `/product/shortList/byAdminId/${userObj.id}`;
    const result = await fetch(api, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getResult = await result.json();
    setItemProd(getResult && getResult);
    // console.log(getResult);
  }

  useEffect(() => {
    getUsers();
  }, []);

  const handleSimpleProd = async (event) => {
    // setSimpleSelect(event.target.value);
    const { value } = event.target;
    setSimpleSelectProd(value);
    const itemz = itemProd.find((item) => item.id == value);
    // console.log(itemz);
    setPrdID(itemz.id);

    setImgList(itemz.productimage);
  };

  var fullSize =
    parseInt(size1x8) +
    parseInt(size1x10) +
    parseInt(size1x12) +
    parseInt(size1x14) +
    parseInt(size2x0) +
    parseInt(size2x2) +
    parseInt(size2x4) +
    parseInt(size2x6) +
    parseInt(size2x8) +
    parseInt(size2x10) +
    parseInt(size2x12) +
    parseInt(size2x14) +
    parseInt(size3x0);
  var cutSize =
    parseInt(size1x9) +
    parseInt(size1x11) +
    parseInt(size1x13) +
    parseInt(size1x15) +
    parseInt(size2x1) +
    parseInt(size2x3) +
    parseInt(size2x5) +
    parseInt(size2x7) +
    parseInt(size2x9) +
    parseInt(size2x11) +
    parseInt(size2x13) +
    parseInt(size2x15) +
    parseInt(size3x1);

  const handleRemove = () => {
    setSubmitteds(true);
    setBtn(true);
  };

  const handleConfirm = () => {
    fetch(localApi + `/stockSize/delete/byId/${sProdId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        // console.log(res);
        if (res.message === "Deleted Successfully !!!") {
          alertTypeOps("success");
          alertMsgOps("Deleted Succesfully");
          setSubmittedConfirm(true);
        }
      });
  };

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  const hideAlerts = () => {
    setSubmitteds(false);
    setSubmittedConfirm(false);
    window.location.reload(false);
  };

  const select = {
    width: "100%",
    maxWidth: 600,
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      padding: 10,
    }),
    menu: (state) => ({
      color: "#d81b60",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  return (
    <div>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <AddIcon />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Delete Stock Product</small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer justify="center">
                {submitted ? (
                  <SweetAlert
                    type={alertType}
                    style={{ display: "block", marginTop: "-100px" }}
                    title={alertMsg}
                    onConfirm={() => hideAlert()}
                    confirmBtnCssClass={
                      classSweet.button + " " + classSweet.success
                    }
                  />
                ) : null}
                {submitteds ? (
                  <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "100px" }}
                    title="Are you sure?"
                    onConfirm={handleConfirm}
                    onCancel={() => hideAlerts()}
                    confirmBtnCssClass={
                      classSweet.button + " " + classSweet.success
                    }
                    cancelBtnCssClass={
                      classSweet.button + " " + classSweet.danger
                    }
                    confirmBtnText="Yes, delete it!"
                    cancelBtnText="Cancel"
                    showCancel
                  />
                ) : null}

                {submittedConfirm ? (
                  <SweetAlert
                    success
                    style={{ display: "block", marginTop: "100px" }}
                    title="Deleted!"
                    onConfirm={() => hideAlerts()}
                    onCancel={() => hideAlerts()}
                    confirmBtnCssClass={
                      classSweet.button + " " + classSweet.success
                    }
                  >
                    Your imaginary cart has been deleted.
                  </SweetAlert>
                ) : null}
                <GridItem xs={12} sm={12} md={12}>
                  <label className={classe.selectLabel}>
                    Select Stock Product Id
                  </label>
                  <Selects
                    styles={customStyles}
                    style={select}
                    placeholder="Select Stock Product Id..."
                    value={simpleCartItem}
                    onChange={handleCartItem}
                    options={
                      stockProd &&
                      stockProd.map((c) => {
                        return {
                          label: c.productId + "-" + c.id,
                          value: c.productId + "-" + c.id,
                        };
                      })
                    }
                  />
                </GridItem>
                {/* <GridItem xs={12} sm={12} md={12}>
                  <FormControl fullWidth className={classe.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classe.selectLabel}
                    >
                      Select Product Id...
                    </InputLabel>

                    <Select
                      MenuProps={{
                        className: classe.selectMenu,
                      }}
                      classes={{
                        select: classe.select,
                      }}
                      disabled={true}
                      value={simpleSelectProd}
                      onChange={handleSimpleProd}
                      inputProps={{
                        name: "simpleSelect",
                        id: "simple-select",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classe.selectMenuItem,
                        }}
                      >
                        Select Product Id...
                      </MenuItem>
                      {itemProd &&
                        itemProd.map((item) => (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                            classes={{
                              root: classe.selectMenuItem,
                              selected: classe.selectMenuItemSelected,
                            }}
                          >
                            {item.id}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>{" "} */}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <BiFile />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Product Size</small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h4>Full & Cut Size: {fullCutSize}</h4>
                  <GridContainer className={classes.sizePadding}>
                    {size1x8 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {size1x8 == null ? "" : size1x8 == 0 ? "" : "1x8"}
                        <br />
                        {size1x8 == null ? "" : size1x8 == 0 ? "" : size1x8}
                      </span>
                    ) : (
                      ""
                    )}
                    {size1x10 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {size1x10 == null ? "" : size1x10 == 0 ? "" : "1x10"}
                        <br />
                        {size1x10 == null ? "" : size1x10 == 0 ? "" : size1x10}
                      </span>
                    ) : (
                      ""
                    )}
                    {size1x12 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {size1x12 == null ? "" : size1x12 == 0 ? "" : "1x12"}
                        <br />
                        {size1x12 == null ? "" : size1x12 == 0 ? "" : size1x12}
                      </span>
                    ) : (
                      ""
                    )}
                    {size1x14 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {size1x14 == null ? "" : size1x14 == 0 ? "" : "1x14"}
                        <br />
                        {size1x14 == null ? "" : size1x14 == 0 ? "" : size1x14}
                      </span>
                    ) : (
                      ""
                    )}
                    {size2x0 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {size2x0 == null ? "" : size2x0 == 0 ? "" : "2x0"}
                        <br />
                        {size2x0 == null ? "" : size2x0 == 0 ? "" : size2x0}
                      </span>
                    ) : (
                      ""
                    )}
                    {size2x2 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {size2x2 == null ? "" : size2x2 == 0 ? "" : "2x2"}
                        <br />
                        {size2x2 == null ? "" : size2x2 == 0 ? "" : size2x2}
                      </span>
                    ) : (
                      ""
                    )}
                    {size2x4 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {size2x4 == null ? "" : size2x4 == 0 ? "" : "2x4"}
                        <br />
                        {size2x4 == null ? "" : size2x4 == 0 ? "" : size2x4}
                      </span>
                    ) : (
                      ""
                    )}
                    {size2x6 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {size2x6 == null ? "" : size2x6 == 0 ? "" : "2x6"}
                        <br />
                        {size2x6 == null ? "" : size2x6 == 0 ? "" : size2x6}
                      </span>
                    ) : (
                      ""
                    )}
                    {size2x8 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {size2x8 == null ? "" : size2x8 == 0 ? "" : "2x8"}
                        <br />
                        {size2x8 == null ? "" : size2x8 == 0 ? "" : size2x8}
                      </span>
                    ) : (
                      ""
                    )}
                    {size2x10 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {size2x10 == null ? "" : size2x10 == 0 ? "" : "2x10"}
                        <br />
                        {size2x10 == null ? "" : size2x10 == 0 ? "" : size2x10}
                      </span>
                    ) : (
                      ""
                    )}
                    {size2x12 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {size2x12 == null ? "" : size2x12 == 0 ? "" : "2x12"}
                        <br />
                        {size2x12 == null ? "" : size2x12 == 0 ? "" : size2x12}
                      </span>
                    ) : (
                      ""
                    )}
                    {size2x14 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {size2x14 == null ? "" : size2x14 == 0 ? "" : "2x14"}
                        <br />
                        {size2x14 == null ? "" : size2x14 == 0 ? "" : size2x14}
                      </span>
                    ) : (
                      ""
                    )}
                    {size3x0 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {size3x0 == null ? "" : size3x0 == 0 ? "" : "3x0"}
                        <br />
                        {size3x0 == null ? "" : size3x0 == 0 ? "" : size3x0}
                      </span>
                    ) : (
                      ""
                    )}
                  </GridContainer>
                  <GridContainer className={classes.sizePadding}>
                    {size1x9 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {size1x9 == null ? "" : size1x9 == 0 ? "" : "1x9"}
                        <br />
                        {size1x9 == null ? "" : size1x9 == 0 ? "" : size1x9}
                      </span>
                    ) : (
                      ""
                    )}
                    {size1x11 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {size1x11 == null ? "" : size1x11 == 0 ? "" : "1x11"}
                        <br />
                        {size1x11 == null ? "" : size1x11 == 0 ? "" : size1x11}
                      </span>
                    ) : (
                      ""
                    )}
                    {size1x13 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {size1x13 == null ? "" : size1x13 == 0 ? "" : "1x13"}
                        <br />
                        {size1x13 == null ? "" : size1x13 == 0 ? "" : size1x13}
                      </span>
                    ) : (
                      ""
                    )}
                    {size1x15 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {size1x15 == null ? "" : size1x15 == 0 ? "" : "1x15"}
                        <br />
                        {size1x15 == null ? "" : size1x15 == 0 ? "" : size1x15}
                      </span>
                    ) : (
                      ""
                    )}
                    {size2x1 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {size2x1 == null ? "" : size2x1 == 0 ? "" : "2x1"}
                        <br />
                        {size2x1 == null ? "" : size2x1 == 0 ? "" : size2x1}
                      </span>
                    ) : (
                      ""
                    )}
                    {size2x3 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {size2x3 == null ? "" : size2x3 == 0 ? "" : "2x3"}
                        <br />
                        {size2x3 == null ? "" : size2x3 == 0 ? "" : size2x3}
                      </span>
                    ) : (
                      ""
                    )}
                    {size2x5 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {size2x5 == null ? "" : size2x5 == 0 ? "" : "2x5"}
                        <br />
                        {size2x5 == null ? "" : size2x5 == 0 ? "" : size2x5}
                      </span>
                    ) : (
                      ""
                    )}
                    {size2x7 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {size2x7 == null ? "" : size2x7 == 0 ? "" : "2x7"}
                        <br />
                        {size2x7 == null ? "" : size2x7 == 0 ? "" : size2x7}
                      </span>
                    ) : (
                      ""
                    )}
                    {size2x9 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {size2x9 == null ? "" : size2x9 == 0 ? "" : "2x9"}
                        <br />
                        {size2x9 == null ? "" : size2x9 == 0 ? "" : size2x9}
                      </span>
                    ) : (
                      ""
                    )}
                    {size2x11 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {size2x11 == null ? "" : size2x11 == 0 ? "" : "2x11"}
                        <br />
                        {size2x11 == null ? "" : size2x11 == 0 ? "" : size2x11}
                      </span>
                    ) : (
                      ""
                    )}
                    {size2x13 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {size2x13 == null ? "" : size2x13 == 0 ? "" : "2x13"}
                        <br />
                        {size2x13 == null ? "" : size2x13 == 0 ? "" : size2x13}
                      </span>
                    ) : (
                      ""
                    )}
                    {size2x15 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {size2x15 == null ? "" : size2x15 == 0 ? "" : "2x15"}
                        <br />
                        {size2x15 == null ? "" : size2x15 == 0 ? "" : size2x15}
                      </span>
                    ) : (
                      ""
                    )}
                    {size3x1 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {size3x1 == null ? "" : size3x1 == 0 ? "" : "3x1"}
                        <br />
                        {size3x1 == null ? "" : size3x1 == 0 ? "" : size3x1}
                      </span>
                    ) : (
                      ""
                    )}
                  </GridContainer>
                  <h4>Total Box & Packet: {fullSize + cutSize}</h4>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <BsImages />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Select Design Images</small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {imgList &&
                  imgList.map((i) => (
                    <GridItem xs={3} sm={2} md={2} key={i.id}>
                      <div className="fileinput text-center">
                        <div
                          className={
                            "thumbnail" + (avatar ? " img-circle" : "")
                          }
                        >
                          <img
                            src={i.cropImageUrl + "?id=" + i.imgId}
                            alt="..."
                          />
                        </div>
                      </div>
                    </GridItem>
                  ))}
              </GridContainer>
              {/* <Button
                color="rose"
                className={classes.updateProfileButton}
                onClick={handleRemove}
              >
                Delete
              </Button> */}
              {btn === true ? (
                <Button className={classes.updateProfileButton}>Delete</Button>
              ) : (
                <Button
                  color="rose"
                  onClick={handleRemove}
                  className={classes.updateProfileButton}
                >
                  Delete
                </Button>
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

DeleteStockProduct.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
};

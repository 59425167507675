import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { headApi } from "utils/headApi";
import { imgApi } from "utils/imgApi";
import { catApi } from "utils/imgApi";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import style from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import { localApi } from "utils/imgApi";
import SweetAlert from "react-bootstrap-sweetalert";
import styleSweet from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import { credentials } from "utils/imgApi";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(style);
const useStyleSweet = makeStyles(styleSweet);

export default function DeleteLabourRate(props) {
  const [items, setItems] = React.useState([]);
  const [items1, setItems1] = React.useState([]);
  const [items2, setItems2] = React.useState([]);
  const [simpleSelect, setSimpleSelect] = React.useState("");
  const [simpleSelect1, setSimpleSelect1] = React.useState("");
  const [simpleSelect2, setSimpleSelect2] = React.useState("");
  const [labourDetails, setLabourDetails] = React.useState("");
  const [hsnCode, setHsnCode] = React.useState("");
  const [price, setPrice] = React.useState("");
  const [gst, setGst] = React.useState("");
  const [getId, setGetId] = React.useState("");
  const [getcatId, setGetcatId] = React.useState("");
  const [getlabId, setGetlabId] = React.useState("");
  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);
  const [btn, setBtn] = React.useState(false);

  const classes = useStyles();
  const classe = useStyle();
  const classSweet = useStyleSweet();

  const adminObj = JSON.parse(sessionStorage.getItem("sessionData"));

  async function getCat() {
    const api = localApi + `/category/list/byAdminId/${adminObj.id}`;
    const result = await fetch(api, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getResult = await result.json();
    setItems(getResult);
  }

  useEffect(() => {
    getCat();
    // catApi().then((res) => setItems(res));
  }, []);

  const handleSimple = async (event) => {
    // setSimpleSelect(event.target.value);
    const { value } = event.target;
    const item = items.find((item) => item.id == value);
    setSimpleSelect(value);
    setGetId(item.id);
    const response = await axios.get(
      localApi + `/parent/list/byCategoryId/${item.id}`,
      {
        headers: {
          Authorization: `Basic ${credentials}`,
        },
      }
    );
    setItems1(response.data);
  };

  const handleSimple1 = async (event) => {
    // setSimpleSelect(event.target.value);
    const { value } = event.target;
    const itemm = items1.find((itemm) => itemm.id == value);
    setSimpleSelect1(value);
    setGetcatId(itemm.id);
    const response = await axios.get(
      headApi + `/child/list/byParentId/${itemm.id}`,
      {
        headers: {
          Authorization: `Basic ${credentials}`,
        },
      }
    );
    setItems2(response.data);
  };

  const handleSimple2 = (event) => {
    // setSimpleSelect(event.target.value);
    const { value } = event.target;
    const itemms = items2.find((itemms) => itemms.id == value);
    setSimpleSelect2(value);
    setLabourDetails(itemms.title);
    setHsnCode(itemms.hsncode);
    setPrice(itemms.price);
    setGst(itemms.taxPercentage);
    setGetlabId(itemms.id);
  };

  const SubmitDeleteLabourRate = (e) => {
    setBtn(true);
    fetch(
      localApi + `/child/delete/byParentAndChildId/${getcatId}/${getlabId}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Basic ${credentials}`,
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        // console.log(res);
        if (res.error == true) {
          alertTypeOps("success");
          alertMsgOps("Deleted Successfully !!!");
          setSubmitted(true);
        }
      });
  };

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  useEffect(() => {
    // console.log("Value Changed")
    setSimpleSelect1("");
    setSimpleSelect2("");
    setLabourDetails("");
    setHsnCode("");
    setPrice("");
    setGst("");
    setGetlabId("");
  }, [simpleSelect]);

  return (
    <div>
      <GridContainer justify="center" alignItems="center">
        <GridItem xs={12} sm={6} md={6}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <DeleteForeverIcon />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Delete Labour & Rate</small>
              </h3>
            </CardHeader>
            <CardBody>
              <form>
                <GridContainer justify="center" alignItems="center">
                  {submitted ? (
                    <SweetAlert
                      type={alertType}
                      style={{ display: "block", marginTop: "-100px" }}
                      title={alertMsg}
                      onConfirm={() => hideAlert()}
                      confirmBtnCssClass={
                        classSweet.button + " " + classSweet.success
                      }
                    />
                  ) : null}{" "}
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControl fullWidth className={classe.selectFormControl}>
                      <InputLabel
                        htmlFor="simple-select"
                        className={classe.selectLabel}
                      >
                        Select Category Title...
                      </InputLabel>

                      <Select
                        MenuProps={{
                          className: classe.selectMenu,
                        }}
                        classes={{
                          select: classe.select,
                        }}
                        value={simpleSelect}
                        onChange={handleSimple}
                        inputProps={{
                          name: "simpleSelect",
                          id: "simple-select",
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classe.selectMenuItem,
                          }}
                        >
                          Select Category Title..
                        </MenuItem>
                        {items &&
                          items.map((item) => (
                            <MenuItem
                              key={item.id}
                              value={item.id}
                              classes={{
                                root: classe.selectMenuItem,
                                selected: classe.selectMenuItemSelected,
                              }}
                            >
                              {item.title}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </GridItem>{" "}
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControl fullWidth className={classe.selectFormControl}>
                      <InputLabel
                        htmlFor="simple-select"
                        className={classe.selectLabel}
                      >
                        Select Bangles Size-MM...
                      </InputLabel>

                      <Select
                        MenuProps={{
                          className: classe.selectMenu,
                        }}
                        classes={{
                          select: classe.select,
                        }}
                        value={simpleSelect1}
                        onChange={handleSimple1}
                        inputProps={{
                          name: "simpleSelect",
                          id: "simple-select",
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classe.selectMenuItem,
                          }}
                        >
                          Select Bangles Size-MM...
                        </MenuItem>
                        {items1 &&
                          items1.map((item1) => (
                            <MenuItem
                              key={item1.id}
                              value={item1.id}
                              classes={{
                                root: classe.selectMenuItem,
                                selected: classe.selectMenuItemSelected,
                              }}
                            >
                              {item1.title}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </GridItem>{" "}
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControl fullWidth className={classe.selectFormControl}>
                      <InputLabel
                        htmlFor="simple-select"
                        className={classe.selectLabel}
                      >
                        Select Labour & Rate...
                      </InputLabel>

                      <Select
                        MenuProps={{
                          className: classe.selectMenu,
                        }}
                        classes={{
                          select: classe.select,
                        }}
                        value={simpleSelect2}
                        onChange={handleSimple2}
                        inputProps={{
                          name: "simpleSelect",
                          id: "simple-select",
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classe.selectMenuItem,
                          }}
                        >
                          Select Labour & Rate...
                        </MenuItem>
                        {items2 &&
                          items2.map((item2) => (
                            <MenuItem
                              key={item2.id}
                              value={item2.id}
                              classes={{
                                root: classe.selectMenuItem,
                                selected: classe.selectMenuItemSelected,
                              }}
                            >
                              {item2.title}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                  {simpleSelect2 ? (
                    <GridItem xs={12} sm={12} md={12}>
                      <h5 className={classes.cardIconTitle}>
                        <small>
                          Labour Title :-{" "}
                          <span style={{ color: "black" }}>
                            {labourDetails}
                          </span>
                        </small>
                        <br />
                        <small>
                          HSN Code :-{" "}
                          <span style={{ color: "black" }}>{hsnCode}</span>
                        </small>
                        <br />
                        <small>
                          Price :-{" "}
                          <span style={{ color: "black" }}>{price}</span>
                        </small>
                        <br />
                        <small>
                          Tax Rate :-{" "}
                          <span style={{ color: "black" }}>{gst}%</span>
                        </small>
                      </h5>
                    </GridItem>
                  ) : (
                    ""
                  )}
                </GridContainer>
                {/* <Button
                  color="rose"
                  onClick={SubmitDeleteLabourRate}
                  className={classes.updateProfileButton}
                >
                  Delete
                </Button> */}
                {btn === true ? (
                  <Button className={classes.updateProfileButton}>
                    Delete
                  </Button>
                ) : (
                  <Button
                    color="rose"
                    onClick={SubmitDeleteLabourRate}
                    className={classes.updateProfileButton}
                  >
                    Delete
                  </Button>
                )}
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router";
import { localApi } from "utils/imgApi";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Table from "components/Table/Table.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Radio from "@material-ui/core/Radio";
import SweetAlert from "react-bootstrap-sweetalert";
import Tooltip from "@material-ui/core/Tooltip";
import Close from "@material-ui/icons/Close";

import invoiceStyle from "assets/jss/material-dashboard-pro-react/views/viewCartStyle";
import style from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import basicsCheck from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import styles from "assets/jss/material-dashboard-pro-react/views/orderViewPrintStyle";

import Razor from "assets/img/razorpay.png";
import PayTM from "assets/img/paytm.png";

import { credentials } from "utils/imgApi";

const useStylesTable = makeStyles(invoiceStyle);
const useStyleAlert = makeStyles(style);
const useStyl = makeStyles(basicsCheck);
const useStyles = makeStyles(styles);

export default function PaymentRecieved() {
  const [addCart, setAddCart] = React.useState([]);
  const [amountRecieved, setAmountRecieved] = React.useState("");
  const [amountRecievedErr, setAmountRecievedErr] = React.useState("");
  const [bal, setBal] = React.useState("");
  const [mainAmount, setMainAmount] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [number, setNumber] = React.useState("");
  const [selectedEnabled, setSelectedEnabled] = React.useState("");
  const [submitPayment, setSubmitPayment] = React.useState(false);
  // const [alertMsg, alertMsgOps] = React.useState("");
  // const [alertType, alertTypeOps] = React.useState("");
  const [company, setCompany] = React.useState("");

  const [billAmountErr, setBillAmountErr] = React.useState("");
  const [btn, setBtn] = React.useState(false);
  const [arrSortList, setArrSortList] = React.useState("");

  const [cartId, setCartId] = React.useState();
  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);
  const [submitteds, setSubmitteds] = React.useState(false);
  const [submittedConfirm, setSubmittedConfirm] = React.useState(false);

  const history = useHistory();
  const classesTable = useStylesTable();
  const classeAlert = useStyleAlert();
  const classe = useStyl();
  const classTable = useStyles();

  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  const client = new URLSearchParams(search).get("client");
  const order = new URLSearchParams(search).get("order");

  const activeSession = sessionStorage.getItem("sessionData");
  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  async function getProd() {
    const get =
      localApi +
      `/clientGroup/list/byAdminAndClientId/${userObj && userObj.id}/${client}`;
    const results = await fetch(get, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const res = await results.json();
    // console.log(res);
    setFirstName(res.firstname);
    setLastName(res.lastname);
    setEmail(res.email);
    setNumber(res.mobile);
    setCompany(res.company);
  }

  useEffect(() => {
    getProd();
  }, []);

  function arrSorts(arr) {
    arr.sort((a, b) => a - b);
    arr.reverse();
    return arr;
  }

  async function getList() {
    const getPay = await fetch(
      localApi + `/payment/byOrdersAndClientId/${order}/${client}`,
      {
        method: "GET",
        headers: {
          Authorization: `Basic ${credentials}`,
        },
      }
    );
    const res = await getPay.json();
    // console.log(res);

    setAddCart(res && res);

    // const sortList = arrSorts(res);
    setArrSortList(res.length - 1);
    // console.log(res.length - 1);

    const getOrder = await fetch(
      localApi +
        `/orders/list/byAdminAndOrdersId/${userObj && userObj.id}/${order}`,
      {
        method: "GET",
        headers: {
          Authorization: `Basic ${credentials}`,
        },
      }
    );
    const result = await getOrder.json();
    setMainAmount(result && result);
    setAmountRecieved(
      res && res
        ? Math.round(
            result.amount -
              res.reduce((a, b) => a + b.received, 0) -
              amountRecieved
          )
        : Math.round(result.amount)
    );
    setBal(
      res && res
        ? Math.round(
            result.amount -
              res.reduce((a, b) => a + b.received, 0) -
              amountRecieved
          )
        : Math.round(result.amount)
    );
    // console.log(result);
  }

  useEffect(() => {
    getList();
  }, []);

  const handleSubmit = () => {
    setBtn(true);
    const getRecieved =
      addCart && addCart ? addCart.reduce((a, b) => a + b.received, 0) : 0;
    const payStatus = Math.round(
      ((getRecieved + parseInt(amountRecieved)) / mainAmount.amount) * 100
    );

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Basic ${credentials}`,
      },
      body: JSON.stringify({
        ordersId: order,
        clientId: client,
        name: firstName + " " + lastName,
        amount: mainAmount.amount,
        received: Math.floor(amountRecieved),
      }),
    };

    fetch(localApi + `/payment/add`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        if (data.message === "Data Inserted Successfully !!!") {
          window.location.reload(false);
        } else if (data.message === "Bill Amount High") {
          setBillAmountErr("Bill Amount is High");
        }
        // if (data.message === "Datauser Inserted Successfully !!!") {
        //     const requestOptions = {
        //         method: "PUT",
        //         headers: {
        //             "Content-Type": "application/json",
        //             Accept: "application/json",
        //         },
        //         body: JSON.stringify({
        //             id: state.id,
        //             clientid: state.clientid,
        //             paymentstatus: payStatus,
        //             status: mainAmount.status,
        //             deliverystatus: mainAmount.deliverystatus
        //         }),
        //     };

        //     fetch(
        //         headApi + "/order/status",
        //         requestOptions
        //     )
        //         .then((response) => response.json())
        //         .then((res) => {
        //             // console.log(res);
        //         })
        // }
      });

    // let errorCount = 0;
    // if (amountRecieved === "" || !amountRecieved) {
    //   errorCount++;
    //   setAmountRecievedErr("Please Enter Amount");
    // }
    // if (amountRecieved > bal) {
    //   errorCount++;
    //   setAmountRecievedErr("Filled Amount is High compared to Balance Amount");
    // }
    // if (errorCount === 0) {
    //   var options = {
    //     key: "rzp_live_SsKrYvXeVM5elD",
    //     amount: amountRecieved * 100,
    //     currency: "INR",
    //     name: "Sharif H M",
    //     description: "Insha Bangles",
    //     handler: function (response) {
    //       // alert(response.razorpay_payment_id);
    //       if (response.razorpay_payment_id) {
    //         const requestOptions = {
    //           method: "POST",
    //           headers: {
    //             "Content-Type": "application/json",
    //             Accept: "application/json",
    //           },
    //           body: JSON.stringify({
    //             ordersId: order,
    //             clientId: client,
    //             name: firstName + " " + lastName,
    //             amount: mainAmount.amount,
    //             received: Math.floor(amountRecieved),
    //           }),
    //         };

    //         fetch(localApi + `/payment/add/${userObj.id}`, requestOptions)
    //           .then((response) => response.json())
    //           .then((data) => {
    //             console.log(data);
    //             // if (data.message === "Data Inserted Successfully !!!") {
    //             //     const requestOptions = {
    //             //         method: "PUT",
    //             //         headers: {
    //             //             "Content-Type": "application/json",
    //             //             Accept: "application/json",
    //             //         },
    //             //         body: JSON.stringify({
    //             //             id: state.id,
    //             //             clientid: state.clientid,
    //             //             paymentstatus: payStatus,
    //             //             status: mainAmount.status,
    //             //             deliverystatus: mainAmount.deliverystatus
    //             //         }),
    //             //     };

    //             //     fetch(
    //             //         headApi + "/order/status",
    //             //         requestOptions
    //             //     )
    //             //         .then((response) => response.json())
    //             //         .then((res) => {
    //             //             // console.log(res);
    //             //         })
    //             // }
    //           });
    //         setSubmitPayment(true);
    //         alertTypeOps("success");
    //         alertMsgOps(
    //           `₹ ${
    //             amountRecieved + "/- "
    //           } Congratulation your Transaction is Successfully`
    //         );
    //       }
    //     },
    //     prefill: {
    //       name: firstName + "" + lastName,
    //       email: email,
    //       contact: number,
    //     },
    //     notes: {
    //       address: "Razorpay Corporate office",
    //     },
    //     theme: {
    //       color: "#528FF0",
    //     },
    //   };
    //   var pay = new window.Razorpay(options);
    //   pay.open();
    // }
  };

  const handleRemove = (c) => {
    setSubmitteds(true);
    setCartId(c);
  };

  const handleConfirm = () => {
    // console.log("oooo", cartId);

    fetch(
      localApi + `/payment/delete/byAdminAndPaymentId/${userObj.id}/${cartId}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Basic ${credentials}`,
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        // console.log(res);
        if (res.message === "Deleted Successfully !!!") {
          alertTypeOps("success");
          alertMsgOps("Deleted Succesfully !!!");
          setSubmittedConfirm(true);
        }
      });
  };

  const hideAlertPayment = () => {
    setSubmitPayment(false);
    window.location.reload(false);
  };

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  const hideAlerts = () => {
    setSubmitteds(false);
    setSubmittedConfirm(false);
    window.location.reload(false);
  };

  return (
    <div>
      {addCart.length !== 0 ? (
        // <div className={classNames(classeCart.main, classeCart.mainRaised)}>
        <div className={classesTable.container}>
          <Card>
            <CardBody>
              <h3 className={classesTable.cardTitle}>Payment Details</h3>
              {submitted ? (
                <SweetAlert
                  type={alertType}
                  style={{ display: "block", marginTop: "-100px" }}
                  title={alertMsg}
                  onConfirm={() => hideAlert()}
                  confirmBtnCssClass={
                    classeAlert.button + " " + classeAlert.success
                  }
                />
              ) : null}
              {submitteds ? (
                <SweetAlert
                  warning
                  style={{ display: "block", marginTop: "100px" }}
                  title="Are you sure?"
                  onConfirm={handleConfirm}
                  onCancel={() => hideAlerts()}
                  confirmBtnCssClass={
                    classeAlert.button + " " + classeAlert.success
                  }
                  cancelBtnCssClass={
                    classeAlert.button + " " + classeAlert.danger
                  }
                  confirmBtnText="Yes, delete it!"
                  cancelBtnText="Cancel"
                  showCancel
                />
              ) : null}

              {submittedConfirm ? (
                <SweetAlert
                  success
                  style={{ display: "block", marginTop: "100px" }}
                  title="Deleted!"
                  onConfirm={() => hideAlerts()}
                  onCancel={() => hideAlerts()}
                  confirmBtnCssClass={
                    classeAlert.button + " " + classeAlert.success
                  }
                >
                  Your item has been deleted.
                </SweetAlert>
              ) : null}
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12}>
                  <div style={{ textAlign: "center" }}>
                    <h4 style={{ fontWeight: "bold" }}>
                      {company} <br />
                      Order No.: {order}
                    </h4>
                  </div>
                </GridItem>
              </GridContainer>
              <Table
                tableHead={[
                  <span className={classesTable.tableHead}>Sr.No.</span>,
                  <span className={classesTable.tableHead}>Name</span>,
                  <span
                    className={
                      classesTable.headRight + " " + classesTable.tableHead
                    }
                  >
                    Amount
                  </span>,
                  <span
                    className={
                      classesTable.headRight + " " + classesTable.tableHead
                    }
                  >
                    Received
                  </span>,
                  <span
                    className={
                      classesTable.headRight + " " + classesTable.tableHead
                    }
                  >
                    Balance
                  </span>,
                  "",
                  // <span className={classesTable.tableHead + " " + classesTable.headRight}>""</span>,
                  // ""
                  // "Remove"
                ]}
                tableData={[
                  ...addCart.map((c, index) => [
                    <span className={classesTable.listNumber}>
                      {index + 1}
                    </span>,
                    <div>
                      <span className={classesTable.listNumber}>{c.name}</span>
                    </div>,
                    <div>
                      <span
                        className={
                          classesTable.headRight + " " + classesTable.listNumber
                        }
                      >
                        ₹{c.balAmount + "/-"}
                      </span>
                    </div>,
                    <span
                      className={
                        classesTable.headRight + " " + classesTable.listNumber
                      }
                    >
                      ₹{c.received + "/-"}
                    </span>,
                    <span
                      className={
                        classesTable.headRight + " " + classesTable.listNumber
                      }
                    >
                      ₹{c.balance + "/-"}
                    </span>,
                    <span>
                      {index === arrSortList ? (
                        <Tooltip
                          id="close1"
                          title="Payment Remove"
                          placement="left"
                          classes={{ tooltip: classTable.tooltip }}
                          key={c.id}
                        >
                          <Button
                            link
                            className={classTable.actionButton}
                            onClick={() => handleRemove(c.id)}
                          >
                            <Close style={{ color: "red" }} />
                          </Button>
                        </Tooltip>
                      ) : (
                        ""
                      )}
                    </span>,
                  ]),
                ]}
              />
              <hr />
            </CardBody>
          </Card>
        </div>
      ) : (
        // </div>
        ""
      )}

      {
        bal == "0" ? (
          ""
        ) : (
          // <div className={classNames(classeCart.main, classeCart.mainRaised)} style={{ marginTop: "5%" }}>
          <div className={classesTable.container}>
            {submitPayment ? (
              <SweetAlert
                type={alertType}
                style={{ display: "block", marginTop: "100px" }}
                title={alertMsg}
                onConfirm={() => hideAlertPayment()}
                confirmBtnCssClass={
                  classeAlert.button + " " + classeAlert.success
                }
              />
            ) : null}
            <Card>
              <CardBody>
                <GridContainer justify="center" alignItems="center">
                  <GridItem md={6} sm={6}>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={selectedEnabled === "a"}
                          onChange={() => setSelectedEnabled("a")}
                          value="a"
                          name="radio button enabled"
                          aria-label="A"
                          icon={
                            <FiberManualRecord
                              className={classe.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classe.radioChecked}
                            />
                          }
                          classes={{
                            checked: classe.radio,
                            root: classe.radioRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classe.label,
                        root: classe.labelRoot,
                      }}
                      label={<img src={Razor} />}
                    />
                  </GridItem>
                  <GridItem md={6} sm={6}>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={selectedEnabled === "b"}
                          onChange={() => setSelectedEnabled("b")}
                          value="b"
                          name="radio button enabled"
                          aria-label="B"
                          icon={
                            <FiberManualRecord
                              className={classe.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classe.radioChecked}
                            />
                          }
                          classes={{
                            checked: classe.radio,
                            root: classe.radioRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classe.label,
                        root: classe.labelRoot,
                      }}
                      label={<img src={PayTM} />}
                    />
                  </GridItem>
                </GridContainer>
                {selectedEnabled === "a" ? (
                  <>
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={12} md={12}>
                        <div style={{ textAlign: "center" }}>
                          <h4 style={{ fontWeight: "bold" }}>
                            Payment <br /> {company + ", "} Order No.: {order}
                          </h4>
                        </div>
                      </GridItem>
                    </GridContainer>
                    <GridContainer justify="center">
                      <GridItem sm={6} md={6}>
                        <CustomInput
                          labelText="Amount Recieved"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            placeholder: "Amount Recieved...",
                          }}
                          onChange={(e) => {
                            setAmountRecieved(e.target.value);
                            setAmountRecievedErr("");
                          }}
                          name="amountRecieved"
                          value={amountRecieved}
                          id="amountRecieved"
                          required={true}
                          type="number"
                        />
                        <span style={{ color: "red" }}>
                          {amountRecievedErr}
                        </span>
                        <span style={{ color: "red" }}>{billAmountErr}</span>
                      </GridItem>
                      <GridItem sm={6} md={6}>
                        <CustomInput
                          labelText="Email"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            placeholder: "Email...",
                          }}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                          name="email"
                          value={email}
                          disabled={true}
                          id="email"
                        />
                      </GridItem>
                      <GridItem sm={6} md={6}>
                        <CustomInput
                          labelText="FirstName"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            placeholder: "FirstName...",
                          }}
                          onChange={(e) => {
                            setFirstName(e.target.value);
                          }}
                          name="firstName"
                          value={firstName}
                          disabled={true}
                          id="firstName"
                        />
                      </GridItem>
                      <GridItem sm={6} md={6}>
                        <CustomInput
                          labelText="LastName"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            placeholder: "LastName...",
                          }}
                          onChange={(e) => {
                            setLastName(e.target.value);
                          }}
                          name="lastName"
                          value={lastName}
                          disabled={true}
                          id="lastName"
                        />
                      </GridItem>
                      <GridItem sm={6} md={6}>
                        <CustomInput
                          labelText="Number"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            placeholder: "Number...",
                          }}
                          onChange={(e) => {
                            setNumber(e.target.value);
                          }}
                          name="number"
                          value={number}
                          disabled={true}
                          id="number"
                        />
                      </GridItem>
                      <GridItem md={6} sm={6}></GridItem>
                    </GridContainer>
                    <GridContainer justify="center">
                      {/* <Button color="rose" round onClick={handleSubmit}>
                        Pay Now
                      </Button> */}
                      {btn === true ? (
                        <Button>Pay Now</Button>
                      ) : (
                        <Button color="rose" round onClick={handleSubmit}>
                          Pay Now
                        </Button>
                      )}
                    </GridContainer>
                  </>
                ) : (
                  ""
                )}
              </CardBody>
            </Card>
          </div>
        )
        // </div>
      }
    </div>
  );
}

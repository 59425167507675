import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { localApi } from "utils/imgApi";
import { useHistory, useLocation } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import { IoLogoWhatsapp } from "react-icons/io";
import { ImMobile } from "react-icons/im";
import { MdEmail } from "react-icons/md";
import { TiPhoneOutline } from "react-icons/ti";
import styles from "assets/jss/material-dashboard-pro-react/views/viewCartStyle";
import logo from "assets/img/apple-admin-icon.png";

const useStyles = makeStyles(styles);

export default function ViewCart() {
  const [adminAddress, setAdminAddress] = React.useState([]);
  const [regAddress1, setRegAddress1] = React.useState([]);
  const [regAddress2, setRegAddress2] = React.useState([]);
  const [metalList, setMetalList] = React.useState([]);

  const classes = useStyles();

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  const { state } = useLocation();

  async function getProd() {
    const get = localApi + `/adminAddress/list/byAdminId/${userObj && userObj.id}`;
    const results = await fetch(get);
    const res = await results.json();
    setAdminAddress(res && res.filter((a) => a.addressType === "GSTIN ADDRESS"));
  }

  async function getClient() {
    const api =
      localApi +
      `/regAddress/list/byAdminAndRegisterId/${userObj && userObj.id}/${state && state.clientId}`;
    const result = await fetch(api);
    const getResult = await result.json();
    setRegAddress1(getResult[0] && getResult[0]);
    setRegAddress2(getResult[1] && getResult[1]);
  }

  useEffect(() => {
    getProd();
    getClient();
    var tempArray = [];
    tempArray.push(state);
    setMetalList(tempArray);
  }, []);

  return (
    <div className={classes.productPage}>
      <GridContainer justify="center" alignItems="center">
        <div className={classes.borders + " " + classes.container}>
          <GridContainer className={classes.borderLine}>
            <GridItem md={2} sm={2} xs={2}>
              <img src={logo} style={{ marginTop: "10px", height: "100px" }} />
            </GridItem>
            <GridItem
              md={8}
              sm={8}
              xs={8}
              className={
                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
              }
            >
              <span className={classes.invoiceName}>TAX INVOICE</span>
              <br />
              <span className={classes.companyName}>
                {adminAddress.map((a) => a.company)}
              </span>
              <br />
              <div className={classes.space}>
                <span className={classes.address}>
                  {adminAddress.map((a) => a.plotHouseNo)}{" "}
                  {adminAddress.map((a) => a.areaColony)}{" "}
                  {adminAddress.map((a) => a.landMark)}{" "}
                  {adminAddress.map((a) => a.road)}
                  <br />
                  {adminAddress.map((a) => a.city)} {"-"}{" "}
                  {adminAddress.map((a) => a.zipCode)},{" "}
                  {adminAddress.map((a) => a.state)}, INDIA.
                  <br />{" "}
                  <strong className={classes.gst}>
                    GSTIN : {adminAddress.map((a) => a.gstIn)}
                  </strong>{" "}
                </span>
                <span className={classes.mobileTel}>
                  <ImMobile /> {adminAddress.map((a) => a.mobile)}{" "}
                  <IoLogoWhatsapp /> {adminAddress.map((a) => a.whatsApp)}{" "}
                  <MdEmail /> {adminAddress.map((a) => a.email)}
                </span>
              </div>
            </GridItem>
            <GridItem md={2} sm={2} xs={2} className={classes.originalCopy}>
              <span>Original Copy</span>
            </GridItem>
          </GridContainer>
          <GridContainer className={classes.borderLine}>
            <GridItem
              md={6}
              sm={6}
              xs={6}
              className={classes.space + " " + classes.verticalLine}
            >
              <span className={classes.invoiceDetailsHead}>Billed to :-</span>
              <br />
              <span className={classes.invoicedetailCom}>
                {regAddress1 && regAddress1.company
                  ? regAddress1.company
                  : regAddress1 &&
                    regAddress1.firstName + " " + regAddress1 &&
                    regAddress1.lastName}
              </span>
              <br />
              <span className={classes.invoicedetail}>
                {regAddress1 && regAddress1.plotHouseNo}{" "}
                {regAddress1 && regAddress1.areaColony}
              </span>
              <br />
              <span className={classes.invoicedetail}>
                {regAddress1 && regAddress1.landMark}{" "}
                {regAddress1 && regAddress1.road}{" "}
                {regAddress1 && regAddress1.zipCode}
              </span>{" "}
              <br />
              <span className={classes.invoicedetail}>
                {regAddress1 && regAddress1.city}{" "}
                {regAddress1 && regAddress1.state}
              </span>
              <br />
              <span className={classes.invoicedetail}>
                {regAddress1 && regAddress1.mobile ? <ImMobile /> : ""}{" "}
                {regAddress1 && regAddress1.mobile ? regAddress1.mobile : ""}{" "}
                {regAddress1 && regAddress1.whatsApp ? <IoLogoWhatsapp /> : ""}{" "}
                {regAddress1 && regAddress1.whatsApp
                  ? regAddress1.whatsApp
                  : ""}
              </span>
              <br />
              <span className={classes.invoicedetail + " " + classes.gstIn}>
                {regAddress1 && regAddress1.gstIn
                  ? "GSTIN / UIN"
                  : "Aadhar No."}{" "}
                &nbsp;&nbsp;:{" "}
                {regAddress1 && regAddress1.gstIn
                  ? regAddress1.gstIn
                  : regAddress1 && regAddress1.aadharNumber}
              </span>
            </GridItem>
            <GridItem md={6} sm={6} xs={6} className={classes.space}>
              <span className={classes.invoiceDetailsHead}>Shipped to :-</span>
              <br />
              <span className={classes.invoicedetailCom}>
                {regAddress2 && regAddress2.company
                  ? regAddress2.company
                  : regAddress2 &&
                    regAddress2.firstName + " " + regAddress2 &&
                    regAddress2.lastName}
              </span>
              <br />
              <span className={classes.invoicedetail}>
                {regAddress2 && regAddress2.plotHouseNo}{" "}
                {regAddress2 && regAddress2.areaColony}
              </span>{" "}
              <br />
              <span className={classes.invoicedetail}>
                {regAddress2 && regAddress2.landMark}{" "}
                {regAddress2 && regAddress2.road}{" "}
                {regAddress2 && regAddress2.zipCode}{" "}
              </span>{" "}
              <br />
              <span className={classes.invoicedetail}>
                {regAddress2 && regAddress2.city}{" "}
                {regAddress2 && regAddress2.state}
              </span>
              <br />
              <span className={classes.invoicedetail}>
                {regAddress2 && regAddress2.mobile ? <ImMobile /> : ""}{" "}
                {regAddress2 && regAddress2.mobile ? regAddress2.mobile : ""}{" "}
                {regAddress2 && regAddress2.whatsApp ? <TiPhoneOutline /> : ""}{" "}
                {regAddress2 && regAddress2.whatsApp
                  ? regAddress2.whatsApp
                  : ""}
              </span>
              <br />
              <span className={classes.invoicedetail + " " + classes.gstIn}>
                {regAddress2 && regAddress2.gstIn
                  ? "GSTIN / UIN :"
                  : "Aadhar No. :"}{" "}
                {regAddress2 && regAddress2.gstIn
                  ? regAddress2.gstIn
                  : regAddress2 && regAddress2.aadharNumber}
              </span>
            </GridItem>
          </GridContainer>
          <Table
            tableHead={[
              <span className={classes.tableHead} key="sr">
                Sr.No.
              </span>,
              <span className={classes.tableHead} key="des">
                Description of Goods
              </span>,
              <span
                className={classes.tableHead + " " + classes.headRight}
                key="qty"
              >
                Quantity
              </span>,
              <span
                className={classes.tableHead + " " + classes.headRight}
                key="ut"
              >
                Unit
              </span>,
              <span
                className={classes.tableHead + " " + classes.headRight}
                key="pri"
              >
                Price
              </span>,
              <span
                className={classes.tableHead + " " + classes.headRight}
                key="amt"
              >
                Amount
              </span>,
            ]}
            tableData={[
              ...metalList.map((c, index) => ({
                color: c.taxApply === true ? "success" : "",
                data: [
                  <span className={classes.listNumber} key={c.id}>
                    {index + 1}
                  </span>,
                  <span key={c.id}>
                    <small className={classes.listNumber}>
                      {c.title} - {c.description}
                    </small>
                    <br />
                    <GridContainer className={classes.sizePadding}>
                      {c.productSize.s1x8 != 0 ? (
                        <span
                          className={
                            classes.textCenter +
                            " " +
                            classes.sizeSpan +
                            " " +
                            classes.listDescriptionMM
                          }
                        >
                          {c.productSize.s1x8 == null
                            ? ""
                            : c.productSize.s1x8 == 0
                            ? ""
                            : "1x8"}
                          <br />
                          {c.productSize.s1x8 == null
                            ? ""
                            : c.productSize.s1x8 == 0
                            ? ""
                            : c.productSize.s1x8}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s1x10 != 0 ? (
                        <span
                          className={
                            classes.textCenter +
                            " " +
                            classes.sizeSpan +
                            " " +
                            classes.listDescriptionMM
                          }
                        >
                          {c.productSize.s1x10 == null
                            ? ""
                            : c.productSize.s1x10 == 0
                            ? ""
                            : "1x10"}
                          <br />
                          {c.productSize.s1x10 == null
                            ? ""
                            : c.productSize.s1x10 == 0
                            ? ""
                            : c.productSize.s1x10}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s1x12 != 0 ? (
                        <span
                          className={
                            classes.textCenter +
                            " " +
                            classes.sizeSpan +
                            " " +
                            classes.listDescriptionMM
                          }
                        >
                          {c.productSize.s1x12 == null ? (
                            ""
                          ) : c.productSize.s1x12 == 0 ? (
                            ""
                          ) : "1x12"}
                          <br />
                          {c.productSize.s1x12 == null ? (
                            ""
                          ) : c.productSize.s1x12 == 0 ? (
                            ""
                          ) : c.productSize.s1x12}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s1x14 != 0 ? (
                        <span
                          className={
                            classes.textCenter +
                            " " +
                            classes.sizeSpan +
                            " " +
                            classes.listDescriptionMM
                          }
                        >
                          {c.productSize.s1x14 == null ? (
                            ""
                          ) : c.productSize.s1x14 == 0 ? (
                            ""
                          ) : "1x14"}
                          <br />
                          {c.productSize.s1x14 == null ? (
                            ""
                          ) : c.productSize.s1x14 == 0 ? (
                            ""
                          ) : c.productSize.s1x14}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s2x0 != 0 ? (
                        <span
                          className={
                            classes.textCenter +
                            " " +
                            classes.sizeSpan +
                            " " +
                            classes.listDescriptionMM
                          }
                        >
                          {c.productSize.s2x0 == null ? (
                            ""
                          ) : c.productSize.s2x0 == 0 ? (
                            ""
                          ) : "2x0"}
                          <br />
                          {c.productSize.s2x0 == null ? (
                            ""
                          ) : c.productSize.s2x0 == 0 ? (
                            ""
                          ) : c.productSize.s2x0}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s2x2 != 0 ? (
                        <span
                          className={
                            classes.textCenter +
                            " " +
                            classes.sizeSpan +
                            " " +
                            classes.listDescriptionMM
                          }
                        >
                          {c.productSize.s2x2 == null ? (
                            ""
                          ) : c.productSize.s2x2 == 0 ? (
                            ""
                          ) : "2x2"}
                          <br />
                          {c.productSize.s2x2 == null ? (
                            ""
                          ) : c.productSize.s2x2 == 0 ? (
                            ""
                          ) : c.productSize.s2x2}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s2x4 != 0 ? (
                        <span
                          className={
                            classes.textCenter +
                            " " +
                            classes.sizeSpan +
                            " " +
                            classes.listDescriptionMM
                          }
                        >
                          {c.productSize.s2x4 == null ? (
                            ""
                          ) : c.productSize.s2x4 == 0 ? (
                            ""
                          ) : "2x4"}
                          <br />
                          {c.productSize.s2x4 == null ? (
                            ""
                          ) : c.productSize.s2x4 == 0 ? (
                            ""
                          ) : c.productSize.s2x4}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s2x6 != 0 ? (
                        <span
                          className={
                            classes.textCenter +
                            " " +
                            classes.sizeSpan +
                            " " +
                            classes.listDescriptionMM
                          }
                        >
                          {c.productSize.s2x6 == null
                            ? ""
                            : c.productSize.s2x6 == 0
                            ? ""
                            : "2x6"}
                          <br />
                          {c.productSize.s2x6 == null
                            ? ""
                            : c.productSize.s2x6 == 0
                            ? ""
                            : c.productSize.s2x6}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s2x8 != 0 ? (
                        <span
                          className={
                            classes.textCenter +
                            " " +
                            classes.sizeSpan +
                            " " +
                            classes.listDescriptionMM
                          }
                        >
                          {c.productSize.s2x8 == null ? (
                            ""
                          ) : c.productSize.s2x8 == 0 ? (
                            ""
                          ) : "2x8"}
                          <br />
                          {c.productSize.s2x8 == null ? (
                            ""
                          ) : c.productSize.s2x8 == 0 ? (
                            ""
                          ) : c.productSize.s2x8}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s2x10 != 0 ? (
                        <span
                          className={
                            classes.textCenter +
                            " " +
                            classes.sizeSpan +
                            " " +
                            classes.listDescriptionMM
                          }
                        >
                          {c.productSize.s2x10 == null ? (
                            ""
                          ) : c.productSize.s2x10 == 0 ? (
                            ""
                          ) : "2x10"}
                          <br />
                          {c.productSize.s2x10 == null ? (
                            ""
                          ) : c.productSize.s2x10 == 0 ? (
                            ""
                          ) : c.productSize.s2x10}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s2x12 != 0 ? (
                        <span
                          className={
                            classes.textCenter +
                            " " +
                            classes.sizeSpan +
                            " " +
                            classes.listDescriptionMM
                          }
                        >
                          {c.productSize.s2x12 == null ? (
                            ""
                          ) : c.productSize.s2x12 == 0 ? (
                            ""
                          ) : "2x10"}
                          <br />
                          {c.productSize.s2x12 == null ? (
                            ""
                          ) : c.productSize.s2x12 == 0 ? (
                            ""
                          ) : c.productSize.s2x12}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s2x14 != 0 ? (
                        <span
                          className={
                            classes.textCenter +
                            " " +
                            classes.sizeSpan +
                            " " +
                            classes.listDescriptionMM
                          }
                        >
                          {c.productSize.s2x14 == null ? (
                            ""
                          ) : c.productSize.s2x14 == 0 ? (
                            ""
                          ) : "2x14"}
                          <br />
                          {c.productSize.s2x14 == null ? (
                            ""
                          ) : c.productSize.s2x14 == 0 ? (
                            ""
                          ) : c.productSize.s2x14}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s3x0 != 0 ? (
                        <span
                          className={
                            classes.textCenter +
                            " " +
                            classes.sizeSpan +
                            " " +
                            classes.listDescriptionMM
                          }
                        >
                          {c.productSize.s3x0 == null ? (
                            ""
                          ) : c.productSize.s3x0 == 0 ? (
                            ""
                          ) : "3x0"}
                          <br />
                          {c.productSize.s3x0 == null ? (
                            ""
                          ) : c.productSize.s3x0 == 0 ? (
                            ""
                          ) : c.productSize.s3x0}
                        </span>
                      ) : (
                        ""
                      )}
                    </GridContainer>
                    <GridContainer className={classes.sizePadding}>
                      {c.productSize.s1x9 != 0 ? (
                        <span
                          className={
                            classes.textCenter +
                            " " +
                            classes.sizeSpan +
                            " " +
                            classes.listDescriptionMM
                          }
                        >
                          {c.productSize.s1x9 == null
                            ? ""
                            : c.productSize.s1x9 == 0
                            ? ""
                            : "1x9"}
                          <br />
                          {c.productSize.s1x9 == null
                            ? ""
                            : c.productSize.s1x9 == 0
                            ? ""
                            : c.productSize.s1x9}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s1x11 != 0 ? (
                        <span
                          className={
                            classes.textCenter +
                            " " +
                            classes.sizeSpan +
                            " " +
                            classes.listDescriptionMM
                          }
                        >
                          {c.productSize.s1x11 == null
                            ? ""
                            : c.productSize.s1x11 == 0
                            ? ""
                            : "1x11"}
                          <br />
                          {c.productSize.s1x11 == null
                            ? ""
                            : c.productSize.s1x11 == 0
                            ? ""
                            : c.productSize.s1x11}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s1x13 != 0 ? (
                        <span
                          className={
                            classes.textCenter +
                            " " +
                            classes.sizeSpan +
                            " " +
                            classes.listDescriptionMM
                          }
                        >
                          {c.productSize.s1x13 == null
                            ? ""
                            : c.productSize.s1x13 == 0
                            ? ""
                            : "1x13"}
                          <br />
                          {c.productSize.s1x13 == null
                            ? ""
                            : c.productSize.s1x13 == 0
                            ? ""
                            : c.productSize.s1x13}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s1x15 != 0 ? (
                        <span
                          className={
                            classes.textCenter +
                            " " +
                            classes.sizeSpan +
                            " " +
                            classes.listDescriptionMM
                          }
                        >
                          {c.productSize.s1x15 == null
                            ? ""
                            : c.productSize.s1x15 == 0
                            ? ""
                            : "1x15"}
                          <br />
                          {c.productSize.s1x15 == null
                            ? ""
                            : c.productSize.s1x15 == 0
                            ? ""
                            : c.productSize.s1x15}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s2x1 != 0 ? (
                        <span
                          className={
                            classes.textCenter +
                            " " +
                            classes.sizeSpan +
                            " " +
                            classes.listDescriptionMM
                          }
                        >
                          {c.productSize.s2x1 == null
                            ? ""
                            : c.productSize.s2x1 == 0
                            ? ""
                            : "2x1"}
                          <br />
                          {c.productSize.s2x1 == null
                            ? ""
                            : c.productSize.s2x1 == 0
                            ? ""
                            : c.productSize.s2x1}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s2x3 != 0 ? (
                        <span
                          className={
                            classes.textCenter +
                            " " +
                            classes.sizeSpan +
                            " " +
                            classes.listDescriptionMM
                          }
                        >
                          {c.productSize.s2x3 == null
                            ? ""
                            : c.productSize.s2x3 == 0
                            ? ""
                            : "2x3"}
                          <br />
                          {c.productSize.s2x3 == null
                            ? ""
                            : c.productSize.s2x3 == 0
                            ? ""
                            : c.productSize.s2x3}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s2x5 != 0 ? (
                        <span
                          className={
                            classes.textCenter +
                            " " +
                            classes.sizeSpan +
                            " " +
                            classes.listDescriptionMM
                          }
                        >
                          {c.productSize.s2x5 == null
                            ? ""
                            : c.productSize.s2x5 == 0
                            ? ""
                            : "2x5"}
                          <br />
                          {c.productSize.s2x5 == null
                            ? ""
                            : c.productSize.s2x5 == 0
                            ? ""
                            : c.productSize.s2x5}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s2x7 != 0 ? (
                        <span
                          className={
                            classes.textCenter +
                            " " +
                            classes.sizeSpan +
                            " " +
                            classes.listDescriptionMM
                          }
                        >
                          {c.productSize.s2x7 == null
                            ? ""
                            : c.productSize.s2x7 == 0
                            ? ""
                            : "2x7"}
                          <br />
                          {c.productSize.s2x7 == null
                            ? ""
                            : c.productSize.s2x7 == 0
                            ? ""
                            : c.productSize.s2x7}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s2x9 != 0 ? (
                        <span
                          className={
                            classes.textCenter +
                            " " +
                            classes.sizeSpan +
                            " " +
                            classes.listDescriptionMM
                          }
                        >
                          {c.productSize.s2x9 == null
                            ? ""
                            : c.productSize.s2x9 == 0
                            ? ""
                            : "2x9"}
                          <br />
                          {c.productSize.s2x9 == null
                            ? ""
                            : c.productSize.s2x9 == 0
                            ? ""
                            : c.productSize.s2x9}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s2x11 != 0 ? (
                        <span
                          className={
                            classes.textCenter +
                            " " +
                            classes.sizeSpan +
                            " " +
                            classes.listDescriptionMM
                          }
                        >
                          {c.productSize.s2x11 == null
                            ? ""
                            : c.productSize.s2x11 == 0
                            ? ""
                            : "2x11"}
                          <br />
                          {c.productSize.s2x11 == null
                            ? ""
                            : c.productSize.s2x11 == 0
                            ? ""
                            : c.productSize.s2x11}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s2x13 != 0 ? (
                        <span
                          className={
                            classes.textCenter +
                            " " +
                            classes.sizeSpan +
                            " " +
                            classes.listDescriptionMM
                          }
                        >
                          {c.productSize.s2x13 == null
                            ? ""
                            : c.productSize.s2x13 == 0
                            ? ""
                            : "2x13"}
                          <br />
                          {c.productSize.s2x13 == null
                            ? ""
                            : c.productSize.s2x13 == 0
                            ? ""
                            : c.productSize.s2x13}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s2x15 != 0 ? (
                        <span
                          className={
                            classes.textCenter +
                            " " +
                            classes.sizeSpan +
                            " " +
                            classes.listDescriptionMM
                          }
                        >
                          {c.productSize.s2x15 == null
                            ? ""
                            : c.productSize.s2x15 == 0
                            ? ""
                            : "2x15"}
                          <br />
                          {c.productSize.s2x15 == null
                            ? ""
                            : c.productSize.s2x15 == 0
                            ? ""
                            : c.productSize.s2x15}
                        </span>
                      ) : (
                        ""
                      )}
                      {c.productSize.s3x1 != 0 ? (
                        <span
                          className={
                            classes.textCenter +
                            " " +
                            classes.sizeSpan +
                            " " +
                            classes.listDescriptionMM
                          }
                        >
                          {c.productSize.s3x1 == null
                            ? ""
                            : c.productSize.s3x1 == 0
                            ? ""
                            : "3x1"}
                          <br />
                          {c.productSize.s3x1 == null
                            ? ""
                            : c.productSize.s3x1 == 0
                            ? ""
                            : c.productSize.s3x1}
                        </span>
                      ) : (
                        ""
                      )}
                    </GridContainer>
                  </span>,
                  <span
                    className={classes.headRight + " " + classes.listNumber}
                    key={c.id}
                  >
                    {c.quantity}
                  </span>,
                  <span
                    className={classes.headRight + " " + classes.listNumber}
                    key={c.id}
                  >
                    {c.unit}
                  </span>,
                  <span
                    className={classes.headRight + " " + classes.listNumber}
                    key={c.id}
                  >
                    ₹{c.price}
                  </span>,
                  <span
                    className={classes.headRight + " " + classes.listNumber}
                    key={c.id}
                  >
                    ₹{c.amount.toFixed(2)}
                  </span>,
                ],
              })),
              [
                "",
                <span
                  className={classes.headRight + " " + classes.listNumber}
                  key={1}
                >
                  Grand Total
                </span>,
                <span key={3}>
                  <span
                    className={classes.headRight + " " + classes.listNumber}
                  >
                    {metalList.reduce(
                      (a, b) => parseFloat(a) + parseFloat(b.quantity),
                      0
                    )}
                  </span>
                </span>,
                "",
                "",
                <span key={3}>
                  <span
                    className={classes.headRight + " " + classes.listNumber}
                  >
                    ₹
                    {metalList.reduce(
                      (a, b) => parseFloat(a) + parseFloat(b.amount.toFixed(2)),
                      0
                    )}
                  </span>
                </span>,
              ],
            ]}
          />
          <div className={classes.brand}>
            <Button
              className={classes.print}
              color="rose"
              round
              onClick={() => window.print()}
            >
              Print
            </Button>
          </div>
        </div>
      </GridContainer>
    </div>
  );
}

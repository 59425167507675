import React, { useEffect } from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { localApi } from "utils/imgApi";
import { useHistory, useLocation } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import { IoLogoWhatsapp } from "react-icons/io";
import { ImMobile } from "react-icons/im";
import { MdEmail } from "react-icons/md";
import { TiPhoneOutline } from "react-icons/ti";
import styles from "assets/jss/material-dashboard-pro-react/views/labourAllBillViewStyle";
import logo from "assets/img/apple-admin-icon.png";

const useStyles = makeStyles(styles);

export default function LabourClientBillViewPrintWithoutSize() {
  const [adminAddress, setAdminAddress] = React.useState([]);
  const [regAddress1, setRegAddress1] = React.useState([]);
  const [regAddress2, setRegAddress2] = React.useState([]);

  const classTable = useStyles();

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  const { state } = useLocation();
  // console.log(state);

  async function getProd() {
    const get = localApi + `/adminAddress/list/byAdminId/${userObj && userObj.id}`;
    const results = await fetch(get);
    const res = await results.json();
    setAdminAddress(res && res.filter((a) => a.addressType === "GSTIN ADDRESS" && a.active === true));
  }

  async function getClient() {
    const api =
      localApi +
      `/regAddress/list/byAdminAndRegisterId/${userObj && userObj.id}/${state[0] && state[0].reggid}`;
    const result = await fetch(api);
    const getResult = await result.json();
    const gstAddress = getResult.filter((f) =>
      f && f.addressType === "GSTIN ADDRESS"
        ? f.addressType === "GSTIN ADDRESS"
        : f.addressType === "AADHAR ADDRESS"
        ? f.addressType === "AADHAR ADDRESS"
        : ""
    );
    // console.log(gstAddress);
    const shippingAddress = getResult.filter(
      (f) => f.addressType != "GSTIN ADDRESS"
    );
    setRegAddress1(gstAddress[0] && gstAddress[0]);
    setRegAddress2(shippingAddress[0] && shippingAddress[0]);
  }

  useEffect(() => {
    getProd();
    getClient();
  }, []);

  

  return (
    <div className={classTable.productPage}>
      <GridContainer justify="center" alignItems="center">
        <div className={classTable.container}>
          <GridContainer  className={classTable.borderLine}>
            <GridItem md={2} sm={2} xs={2}>
              <img src={logo} style={{ marginTop: "10px", height: "100px" }} />
            </GridItem>
            <GridItem
              md={8}
              sm={8}
              xs={8}
              className={
                classTable.mlAuto + " " + classTable.mrAuto + " " + classTable.textCenter
              }
            >
              <span className={classTable.invoiceName}>TAX INVOICE</span>
              <br />
              <span className={classTable.companyName}>
                {adminAddress.map((a) => a.company)}
              </span>
              <br />
              <div className={classTable.space}>
                <span className={classTable.address}>
                  {adminAddress.map((a) => a.plotHouseNo)}{" "}
                  {adminAddress.map((a) => a.areaColony)}{" "}
                  {adminAddress.map((a) => a.landMark)}{" "}
                  {adminAddress.map((a) => a.road)}
                  <br />
                  {adminAddress.map((a) => a.city)} {"-"}{" "}
                  {adminAddress.map((a) => a.zipCode)},{" "}
                  {adminAddress.map((a) => a.state)}, INDIA.
                  <br />{" "}
                  <strong className={classTable.gst}>
                    GSTIN : {adminAddress.map((a) => a.gstIn)}
                  </strong>{" "}
                </span>
                <span className={classTable.mobileTel}>
                  <ImMobile /> {adminAddress.map((a) => a.mobile)}{" "}
                  <IoLogoWhatsapp /> {adminAddress.map((a) => a.whatsApp)}{" "}
                  <MdEmail /> {userObj.email}
                  {/* {adminAddress.map((a) => a.email)} */}
                </span>
              </div>
            </GridItem>
            <GridItem md={2} sm={2} xs={2} className={classTable.originalCopy}>
              <span>Original Copy</span>
            </GridItem>
          </GridContainer>
          <GridContainer className={classTable.borderLine}>
            <GridItem
              md={6}
              sm={6}
              xs={6}
              className={classTable.space + " " + classTable.verticalLine}
            >
              <span className={classTable.invoiceDetailsHead}>Billed to :-</span>
              <br />
              <span className={classTable.invoicedetailCom}>
                {regAddress1 && regAddress1.company
                  ? regAddress1.company
                  : regAddress1 &&
                    regAddress1.firstName + " " + regAddress1 &&
                    regAddress1.lastName}
              </span>
              <br />
              <span className={classTable.invoicedetail}>
                {regAddress1 && regAddress1.plotHouseNo}{" "}
                {regAddress1 && regAddress1.areaColony}
              </span>
              <br />
              <span className={classTable.invoicedetail}>
                {regAddress1 && regAddress1.landMark}{" "}
                {regAddress1 && regAddress1.road}{" "}
                {regAddress1 && regAddress1.zipCode}
              </span>{" "}
              <br />
              <span className={classTable.invoicedetail}>
                {regAddress1 && regAddress1.city}{" "}
                {regAddress1 && regAddress1.state}
              </span>
              <br />
              <span className={classTable.invoicedetail}>
                {regAddress1 && regAddress1.mobile ? <ImMobile /> : ""}{" "}
                {regAddress1 && regAddress1.mobile ? regAddress1.mobile : ""}{" "}
                {regAddress1 && regAddress1.whatsApp ? <IoLogoWhatsapp /> : ""}{" "}
                {regAddress1 && regAddress1.whatsApp
                  ? regAddress1.whatsApp
                  : ""}
              </span>
              <br />
              <span className={classTable.invoicedetail + " " + classTable.gstIn}>
                {regAddress1 && regAddress1.gstIn
                  ? "GSTIN / UIN"
                  : "Aadhar No."}{" "}
                &nbsp;&nbsp;:{" "}
                {regAddress1 && regAddress1.gstIn
                  ? regAddress1.gstIn
                  : regAddress1 && regAddress1.aadharNumber}
              </span>
            </GridItem>
            <GridItem md={6} sm={6} xs={6} className={classTable.space}>
              <span className={classTable.invoiceDetailsHead}>Shipped to :-</span>
              <br />
              <span className={classTable.invoicedetailCom}>
              {regAddress2 && regAddress2.company
                    ? regAddress2.company
                      ? regAddress2.company
                      : regAddress2 &&
                        regAddress2.firstName + " " + regAddress2 &&
                        regAddress2.lastName
                    : regAddress1.company
                    ? regAddress1.company
                    : regAddress1 &&
                      regAddress1.firstName + " " + regAddress1 &&
                      regAddress1.lastName}
              </span>
              <br />
              <span className={classTable.invoicedetail}>
              {regAddress2 && regAddress2.plotHouseNo
                    ? regAddress2.plotHouseNo
                    : regAddress1 && regAddress1.plotHouseNo}{" "}
                  {regAddress2 && regAddress2.areaColony
                    ? regAddress2.areaColony
                    : regAddress1 && regAddress1.areaColony}
              </span>{" "}
              <br />
              <span className={classTable.invoicedetail}>
              {regAddress2 && regAddress2.landMark
                    ? regAddress2.landMark
                    : regAddress1 && regAddress1.landMark}{" "}
                  {regAddress2 && regAddress2.road
                    ? regAddress2.road
                    : regAddress1 && regAddress1.road}{" "}
                  {regAddress2 && regAddress2.zipCode
                    ? regAddress2.zipCode
                    : regAddress1 && regAddress1.zipCode}{" "}
              </span>{" "}
              <br />
              <span className={classTable.invoicedetail}>
              {regAddress2 && regAddress2.city
                    ? regAddress2.city
                    : regAddress1 && regAddress1.city}{" "}
                  {regAddress2 && regAddress2.state
                    ? regAddress2.state
                    : regAddress1 && regAddress1.state}
              </span>
              <br />
              <span className={classTable.invoicedetail}>
              {regAddress2 && regAddress2.mobile ? (
                    <ImMobile />
                  ) : regAddress1 && regAddress1.mobile ? (
                    <ImMobile />
                  ) : (
                    ""
                  )}{" "}
                  {regAddress2 && regAddress2.mobile
                    ? regAddress2.mobile
                    : regAddress1 && regAddress1.mobile
                    ? regAddress1.mobile
                    : ""}{" "}
                  {regAddress2 && regAddress2.whatsApp ? (
                    <TiPhoneOutline />
                  ) : regAddress1 && regAddress1.whatsApp ? (
                    <TiPhoneOutline />
                  ) : (
                    ""
                  )}{" "}
                  {regAddress2 && regAddress2.whatsApp
                    ? regAddress2.whatsApp
                    : regAddress1 && regAddress1.whatsApp
                    ? regAddress1.whatsApp
                    : ""}
              </span>
              <br />
              <span className={classTable.invoicedetail + " " + classTable.gstIn}>
              {regAddress2 && regAddress2.gstIn
                    ? regAddress2.gstIn
                      ? "GSTIN / UIN :"
                      : "Aadhar No. :"
                    : regAddress1.gstIn
                    ? "GSTIN / UIN :"
                    : "Aadhar No. :"}
                  {regAddress2 && regAddress2.gstIn
                    ? regAddress2.gstIn
                      ? regAddress2.gstIn
                      : regAddress2 && regAddress2.aadharNumber
                    : regAddress1.gstIn
                    ? regAddress1.gstIn
                    : regAddress1 && regAddress1.aadharNumber}
              </span>
            </GridItem>
          </GridContainer>
          <Table
            hover
            tableHead={[
              <span className={classTable.tableHead} key="sr">
                Sr.No.
              </span>,
              <span className={classTable.tableHead} key="bn">
                Bill.No.
              </span>,
              <span className={classTable.tableHead} key="dt">
                Date
              </span>,
              <span className={classTable.tableHead} key="pn">
                Party Name
              </span>,
              <span className={classTable.tableHead} key="des">
                Description of Goods
              </span>,
              <span
                className={classTable.tableHead + " " + classTable.headRight}
                key="qty"
              >
                Quantity
              </span>,
              <span
                className={classTable.tableHead + " " + classTable.headRight}
                key="ut"
              >
                Unit
              </span>,
              <span
                className={classTable.tableHead + " " + classTable.headRight}
                key="pri"
              >
                Price
              </span>,
              <span
                className={classTable.tableHead + " " + classTable.headRight}
                key="amt"
              >
                Amount
              </span>,
            ]}
            tableData={[
              ...state.map((c, index) => ({
                color: c.taxApply === true ? "success" : "",
                data: [
                  <span className={classTable.listNumber} key={c.id}>
                    {index + 1}
                  </span>,
                  <span className={classTable.listNumber} key={c.id}>
                    {c.id}
                  </span>,
                  <span className={classTable.listNumber} key={c.id}>
                    {moment(c.pickerdate).format('MM-DD-YYYY')}
                  </span>,
                  <span className={classTable.listNumber} key={c.id}>
                    {c.firstName + " " + c.lastName}
                  </span>,
                  <span key={c.id}>
                    <small className={classTable.listNumber}>
                      {c.title} - {c.description}
                    </small>
                  </span>,
                  <span
                    className={
                      classTable.headRight + " " + classTable.listNumber
                    }
                    key={c.id}
                  >
                    {c.quantity}
                  </span>,
                  <span
                    className={
                      classTable.headRight + " " + classTable.listNumber
                    }
                    key={c.id}
                  >
                    {c.unit}
                  </span>,
                  <span
                    className={
                      classTable.headRight + " " + classTable.listNumber
                    }
                    key={c.id}
                  >
                    ₹{c.price}
                  </span>,
                  <span
                    className={
                      classTable.headRight + " " + classTable.listNumber
                    }
                    key={c.id}
                  >
                    ₹{c.amount.toFixed(2)}
                  </span>,
                  // <Button
                  //   color="rose"
                  //   block
                  //   simple
                  //   onClick={() => {
                  //     handleDeleteMetal(c);
                  //   }}
                  // >
                  //   <DeleteForeverIcon />
                  // </Button>,
                ],
              })),
              [
                "",
                "",
                "",
                "",
                <span
                  className={classTable.headRight + " " + classTable.listNumber}
                  key={1}
                >
                  Grand Total
                </span>,
                <span key={3}>
                  <span
                    className={
                      classTable.headRight + " " + classTable.listNumber
                    }
                  >
                    {state
                      .reduce(
                        (a, b) => parseFloat(a) + parseFloat(b.quantity),
                        0
                      )
                      .toFixed(2)}
                  </span>
                </span>,
                "",
                "",
                <span key={3}>
                  <span
                    className={
                      classTable.headRight + " " + classTable.listNumber
                    }
                  >
                    ₹
                    {state
                      .reduce((a, b) => parseFloat(a) + parseFloat(b.amount), 0)
                      .toFixed(2)}
                  </span>
                </span>,
              ],
              [
                "",
                "",
                "",
                "",
                <span key={3}>
                  <span
                    className={
                      classTable.headRight + " " + classTable.listNumber
                    }
                  >
                    Closing Balance
                  </span>
                </span>,
                "",
                "",
                "",
                <span key={3}>
                  <span
                    className={
                      classTable.headRight + " " + classTable.listNumber
                    }
                  >
                    ₹
                    {state
                      .reduce((a, b) => parseFloat(a) + parseFloat(b.amount), 0)
                      .toFixed(2)}
                  </span>
                </span>,
              ],
            ]}
          />
          <div className={classTable.brand}>
            <Button
              className={classTable.print}
              color="rose"
              round
              onClick={() => {
                window.print()
              }}
            >
              Print
            </Button>
          </div>
        </div>
      </GridContainer>
    </div>
  );
}

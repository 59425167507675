import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import axios from "axios";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Compressor from "compressorjs";
import { useHistory, useLocation } from "react-router-dom";
import { dataURLtoFile } from "utils/dataURLToFile";
import { catApi } from "utils/imgApi";
import { headApi } from "utils/headApi";
import { imgApi } from "utils/imgApi";
import { BiEdit } from "react-icons/bi";
import { localApi } from "utils/imgApi";
import MenuItem from "@material-ui/core/MenuItem";
import Selects from "react-select";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import defaultImage from "assets/img/image_placeholder.jpg";
import defaultAvatar from "assets/img/placeholder.jpg";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import style from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import styleSweet from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import { credentials } from "utils/imgApi";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(style);
const useStyleSweet = makeStyles(styleSweet);

export default function UpdateCropImage(props) {
  const [itemsImage, setItemsImage] = React.useState([]);
  const [items, setItems] = React.useState([]);
  const [items1, setItems1] = React.useState([]);
  const [simpleSelectImage, setSimpleSelectImage] = React.useState("");
  const [simpleSelectImageErr, setSimpleSelectImageErr] = React.useState("");
  const [simpleSelect, setSimpleSelect] = React.useState("");
  const [simpleSelect1, setSimpleSelect1] = React.useState("");
  const [simpleImage, setSimpleImage] = React.useState("");
  const [images, setImages] = React.useState("");
  const [getId, setGetId] = React.useState("");
  const [getcatId, setGetcatId] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);
  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");
  const [newImage, setNewImage] = React.useState(false);
  const [btn, setBtn] = React.useState(false);

  const [orgCompFile, setorgCompFile] = React.useState("");

  const classes = useStyles();
  const classe = useStyle();
  const classSweet = useStyleSweet();
  const history = useHistory();

  const adminObj = JSON.parse(sessionStorage.getItem("sessionData"));

  const [file, setFile] = React.useState(null);
  const [fileErr, setFileErr] = React.useState("");
  const [crop, setCrop] = React.useState({
    aspect: 0.833,
    unit: "px",
    width: 280, //280
    height: 336, //336
  });
  const [image, setImage] = React.useState("");
  const [result, setResult] = React.useState("");
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState(
    props.avatar ? defaultAvatar : defaultImage
  );
  let fileInput = React.createRef();

  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setFile(file);
      setImagePreviewUrl(reader.result);

      new Compressor(file, {
        quality: 0.8,
        success: (res) => {
          // console.log("After", res);
          const orgCompress = new File([res], `${file?.name}`, {
            type: res.type,
          });
          setorgCompFile(orgCompress);
        },
      });
    };
    if (file) {
      reader.readAsDataURL(file);
    }
    setFileErr("");
  };

  const getCroppedImg = () => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    const base64Image = canvas.toDataURL("image/jpeg");
    setResult(base64Image);
  };

  const handleClick = () => {
    fileInput.current.click();
  };

  const handleRemove = () => {
    setFile(null);
    setImagePreviewUrl(props.avatar ? defaultAvatar : defaultImage);
    fileInput.current.value = null;
  };

  let { avatar, addButtonProps, changeButtonProps, removeButtonProps } = props;

  const { state } = useLocation();
  // console.log(state);

  async function getUsers() {
    const api = localApi + `/image/list/byAdminId/${adminObj && adminObj.id}`;
    const result = await fetch(api, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getResult = await result.json();
    setItemsImage(
      getResult && getResult.filter((a) => a.imageTypeCrop === "crop")
    );
    // console.log(getResult);
  }

  async function getCat() {
    const api = localApi + `/category/list/byAdminId/${adminObj.id}`;
    const result = await fetch(api, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getResult = await result.json();
    setItems(getResult);
  }

  useEffect(() => {
    getUsers();
    getCat();
    // catApi().then((res) => setItems(res && res));
    setSimpleSelectImage(state && state ? state.id : "");
    setSimpleSelectImageErr("");
    setSimpleSelect(state && state ? state.categoryId : "");
    axios
      .get(
        localApi +
          `/parent/list/byCategoryId/${state && state ? state.categoryId : ""}`,
        {
          headers: {
            Authorization: `Basic ${credentials}`,
          },
        }
      )
      .then((res) => setItems1(res && res.data));
    setSimpleSelect1(state && state ? state.parentId : "");
    setImages(state && state ? state.cropImageUrl + "?id=" + state.id : "");
  }, []);

  const handleSimpleImage = async (simpleSelectImage) => {
    setSimpleSelectImage(simpleSelectImage);
    if (simpleSelectImage.value) {
      let newSpli = simpleSelectImage.value;
      // console.log(newSpli[0]);
      const item = itemsImage.find((f) => f.id == newSpli);
      setSimpleImage(item);
      // console.log(item);
      setSimpleSelect(item.categoryId);
      axios
        .get(localApi + `/parent/list/byCategoryId/${item.categoryId}`, {
          headers: {
            Authorization: `Basic ${credentials}`,
          },
        })
        .then((res) => setItems1(res && res.data));
      setSimpleSelect1(item.parentId);
      setImages(item.cropImageUrl + "?id=" + item.id);
    }
  };

  // const handleSimpleImage = (event) => {
  //   const { value } = event.target;
  //   setSimpleSelectImage(value);
  //   setSimpleSelectImageErr("");
  //   const item = itemsImage.find((item) => item.id === value);
  //   setSimpleImage(item);
  //   setSimpleSelect(item.categoryId);
  //   axios
  //     .get(
  //       localApi +
  //         `/parent/list/byAdminIdAndCategoryId/${adminObj && adminObj.id}/${
  //           item.categoryId
  //         }`
  //     )
  //     .then((res) => setItems1(res && res.data));
  //   setSimpleSelect1(item.parentId);
  //   setImages(item.cropImageUrl + "?id=" + item.id);
  // };

  const handleSimple = async (event) => {
    // setSimpleSelect(event.target.value);
    const { value } = event.target;
    const item = items.find((item) => item.id === value);
    setSimpleSelect(value);
    setGetId(item.id);
    const response = await axios.get(
      localApi + `/parent/list/byCategoryId/${item.id}`,
      {
        headers: {
          Authorization: `Basic ${credentials}`,
        },
      }
    );

    setItems1(response.data);
  };

  const handleSimple1 = (event) => {
    // setSimpleSelect(event.target.value);
    const { value } = event.target;
    const itemm = items1.find((itemm) => itemm.id === value);
    setSimpleSelect1(value);
    setGetcatId(itemm.id);
  };

  const SubmitUpdateImage = (e) => {
    setNewImage(true);
    let errorCount = 0;
    if (simpleSelect === "" || !simpleSelect) {
      errorCount++;
      setSimpleSelectImageErr("Select Image");
    }
    if (file === "" || !file) {
      errorCount++;
      setFileErr("of fix size (atleast width-280, height-336 pixel)");
    }
    if (errorCount === 0) {
      const convertedUrlToFile = dataURLtoFile(
        file ? result : null,
        `${file?.name}`
      );

      setBtn(true);

      new Compressor(convertedUrlToFile, {
        quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
          const compressFile = new File([compressedResult], `${file?.name}`, {
            type: compressedResult.type,
          });
          // console.log("Crop", compressFile);
          // console.log("Org", orgCompFile);

          let fData = new FormData();
          fData.append("originalImage", orgCompFile || new File([""], ""));
          fData.append("cropImage", compressFile || file);
          fData.append(
            "data",
            new Blob(
              [
                JSON.stringify({
                  adminId: adminObj.id,
                  categoryId: getId || simpleImage.categoryId || simpleSelect,
                  parentId: getcatId || simpleImage.parentId || simpleSelect1,
                }),
              ],
              {
                type: "application/json",
              }
            )
          );

          // for (var value of fData.values()) {
          //   console.log(value);
          // }

          axios
            .put(
              localApi +
                `/image/UpdateImageById/${adminObj.id}/${simpleImage.id}`,
              {
                headers: {
                  Authorization: `Basic ${credentials}`,
                },
              },
              fData
            )
            .then((res) => {
              // console.log(res.data);
              if (res.data.message === "Image Updated Successfully !!!") {
                alertTypeOps("success");
                alertMsgOps("Succesfully !!!");
                setSubmitted(true);
              }
            });
        },
      });
    }
  };

  const hideAlert = () => {
    setSubmitted(false);
    if (state) {
      history.push({
        pathname: `/admin/viewImage`,
        state: simpleSelect + "," + simpleSelect1,
      });
    } else {
      window.location.reload(false);
    }
    // window.history.replaceState({state: null}, document.title);
  };

  const select = {
    width: "100%",
    maxWidth: 600,
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      padding: 10,
    }),
    menu: (state) => ({
      color: "#d81b60",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  return (
    <div>
      <GridContainer justify="center" alignItems="center">
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <BiEdit />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Update Image</small>
                {newImage === true ? (
                  <small
                    style={{
                      fontSize: "12px",
                      color: "red",
                      marginLeft: "10px",
                    }}
                  >
                    {simpleSelectImageErr || fileErr}
                  </small>
                ) : (
                  <small style={{ fontSize: "12px", marginLeft: "10px" }}>
                    Select Image Number
                  </small>
                )}
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {submitted ? (
                  <SweetAlert
                    type={alertType}
                    style={{ display: "block", marginTop: "-100px" }}
                    title={alertMsg}
                    onConfirm={() => hideAlert()}
                    confirmBtnCssClass={
                      classSweet.button + " " + classSweet.success
                    }
                  >
                    Updated Image
                  </SweetAlert>
                ) : null}{" "}
                <GridItem xs={12} sm={12} md={12}>
                  <label className={classe.selectLabel}>
                    Select Image Number
                  </label>
                  <Selects
                    styles={customStyles}
                    style={select}
                    required={true}
                    placeholder="Select Image Number..."
                    value={simpleSelectImage}
                    name="simpleSelectImage"
                    onChange={handleSimpleImage}
                    options={
                      itemsImage &&
                      itemsImage.map((c) => {
                        return {
                          label: c.id,
                          value: c.id,
                        };
                      })
                    }
                  />
                </GridItem>
                {/* <GridItem xs={12} sm={12} md={12}>
                  <FormControl fullWidth className={classe.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classe.selectLabel}
                    >
                      Select Image...
                    </InputLabel>

                    <Select
                      MenuProps={{
                        className: classe.selectMenu,
                      }}
                      classes={{
                        select: classe.select,
                      }}
                      value={simpleSelectImage}
                      onChange={handleSimpleImage}
                      inputProps={{
                        name: "simpleSelectImage",
                        id: "simple-select-image",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classe.selectMenuItem,
                        }}
                      >
                        Select Image...
                      </MenuItem>
                      {itemsImage &&
                        itemsImage.map((item) => (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                            classes={{
                              root: classe.selectMenuItem,
                              selected: classe.selectMenuItemSelected,
                            }}
                          >
                            {item.id}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem> */}
                <GridItem xs={12} sm={6} md={6}>
                  <FormControl fullWidth className={classe.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classe.selectLabel}
                    >
                      Select Category Title...
                    </InputLabel>

                    <Select
                      MenuProps={{
                        className: classe.selectMenu,
                      }}
                      classes={{
                        select: classe.select,
                      }}
                      value={simpleSelect}
                      onChange={handleSimple}
                      inputProps={{
                        name: "simpleSelect",
                        id: "simple-select",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classe.selectMenuItem,
                        }}
                      >
                        Select Category title...
                      </MenuItem>
                      {items &&
                        items.map((item) => (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                            classes={{
                              root: classe.selectMenuItem,
                              selected: classe.selectMenuItemSelected,
                            }}
                          >
                            {item.title}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <FormControl fullWidth className={classe.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classe.selectLabel}
                    >
                      Select Bangles Size-MM...
                    </InputLabel>

                    <Select
                      MenuProps={{
                        className: classe.selectMenu,
                      }}
                      classes={{
                        select: classe.select,
                      }}
                      value={simpleSelect1}
                      onChange={handleSimple1}
                      inputProps={{
                        name: "simpleSelect1",
                        id: "simple-select1",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classe.selectMenuItem,
                        }}
                      >
                        Select Bangles Size-MM...
                      </MenuItem>
                      {items1 &&
                        items1.map((item1) => (
                          <MenuItem
                            key={item1.id}
                            value={item1.id}
                            classes={{
                              root: classe.selectMenuItem,
                              selected: classe.selectMenuItemSelected,
                            }}
                          >
                            {item1.title}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      paddingTop: "30px",
                      marginBottom: "30px",
                    }}
                  >
                    <img
                      src={images ? images : defaultAvatar}
                      alt="..."
                      width={200}
                    />
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <div className="fileinput text-center">
                    <input
                      type="file"
                      onChange={handleImageChange}
                      ref={fileInput}
                    />

                    {file && (
                      <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={8}>
                          <ReactCrop
                            src={imagePreviewUrl}
                            onImageLoaded={setImage}
                            crop={crop}
                            onChange={setCrop}
                            minWidth={280 < 280 ? 280 : 280}
                            minHeight={336 < 336 ? 336 : 336}
                            // style={{ width: "500px" }}
                          />
                          <Button color="rose" round onClick={getCroppedImg}>
                            {" "}
                            Crop Image
                          </Button>
                        </GridItem>
                      </GridContainer>
                    )}

                    <div
                      className={"thumbnail" + (avatar ? " img-circle" : "")}
                    >
                      {result && <img src={result} alt="..." />}
                    </div>

                    <div>
                      {file === null ? (
                        <Button
                          color="rose"
                          round
                          {...addButtonProps}
                          onClick={() => handleClick()}
                        >
                          {avatar ? "Update Photo" : "Update image"}
                        </Button>
                      ) : (
                        <span>
                          <Button
                            color="rose"
                            round
                            {...changeButtonProps}
                            onClick={() => handleClick()}
                          >
                            Change
                          </Button>
                          {avatar ? <br /> : null}
                          <Button
                            color="rose"
                            round
                            {...removeButtonProps}
                            onClick={() => handleRemove()}
                          >
                            <i className="fas fa-times" /> Remove
                          </Button>
                        </span>
                      )}
                    </div>
                  </div>
                </GridItem>
              </GridContainer>
              {/* <Button
                color="rose"
                onClick={SubmitUpdateImage}
                className={classes.updateProfileButton}
              >
                Update
              </Button> */}
              {btn === true ? (
                <Button className={classes.updateProfileButton}>Update</Button>
              ) : (
                <Button
                  color="rose"
                  onClick={SubmitUpdateImage}
                  className={classes.updateProfileButton}
                >
                  Update
                </Button>
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

UpdateCropImage.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
};

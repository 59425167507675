import React, { useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { localApi, catApi } from "utils/imgApi";
import { BiFile } from "react-icons/bi";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useLocation } from "react-router-dom";
import Selects from "react-select";
import InputLabel from "@material-ui/core/InputLabel";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import AddIcon from "@material-ui/icons/Add";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import { BsImages } from "react-icons/bs";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import styRadio from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import styDrop from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import styCheckbox from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import styleSweet from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import { credentials } from "utils/imgApi";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(styRadio);
const useStyl = makeStyles(styDrop);
const useSty = makeStyles(styCheckbox);
const useStyleSweet = makeStyles(styleSweet);

export default function ProductContent(props) {
  const [itemProd, setItemProd] = React.useState([]);
  const [items, setItems] = React.useState([]);
  const [items1, setItems1] = React.useState([]);
  const [items2, setItems2] = React.useState([]);
  const [imgList, setImgList] = React.useState("");
  const [simpleSelectProd, setSimpleSelectProd] = React.useState("");
  const [simpleSelectProdErr, setSimpleSelectProdErr] = React.useState("");
  const [newUpdateProduct, setNewUpdateProduct] = React.useState(false);
  const [simpleSelect, setSimpleSelect] = React.useState("");
  const [simpleSelect1, setSimpleSelect1] = React.useState("");
  const [prodDescription, setProdDescription] = React.useState("");
  const [prodDescriptionLength, setProdDescriptionLength] = React.useState("");

  const [catDes, setCatDes] = React.useState("");
  const [prdId, setPrdID] = React.useState("");

  const [cat, setCat] = React.useState("");
  const [catSize, setCatSize] = React.useState("");
  const [checkBoxImage, setCheckBoxImage] = React.useState([]);
  const [makingCheckboxImage, setMakingCheckboxImage] = React.useState("");

  const [submitted, setSubmitted] = React.useState(false);
  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");
  const [btn, setBtn] = React.useState(false);

  const classes = useStyles();
  const classesRadio = useStyle();
  const classe = useStyl();
  const classCheck = useSty();
  const classSweet = useStyleSweet();
  const history = useHistory();

  let { avatar } = props;

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  const regex = new RegExp("^[a-zA-Z]*$");

  var totalWord = "150";

  async function getUsers() {
    // const api = localApi + `/product/shortList/byAdminId/${userObj.id}`;
    const api = localApi + `/product/list/byAdminId/${userObj.id}`;
    const result = await fetch(api, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getResult = await result.json();
    setItemProd(getResult && getResult);
    // console.log(getResult);
  }

  useEffect(() => {
    getUsers();
  }, []);

  const handleSimpleProd = async (simpleSelectProd) => {
    setSimpleSelectProd(simpleSelectProd);
    if (simpleSelectProd.value) {
      let newSpli = simpleSelectProd.value.split("-");
      // console.log(newSpli[0]);
      const itemz = itemProd.find((f) => f.id == newSpli[0]);
      setPrdID(itemz.id);
      setSimpleSelect(itemz.category?.id);
      axios
        .get(localApi + `/parent/list/byCategoryId/${itemz.category?.id}`, {
          headers: {
            Authorization: `Basic ${credentials}`,
          },
        })
        .then((res) => setItems1(res && res.data));
      setSimpleSelect1(itemz.parent?.id);
      setImgList(itemz.productimage);
      setProdDescription(itemz.prodDescription);
      setProdDescriptionLength(itemz.prodDescription.length);

      const cApi = localApi + `/child/list/byParentId/${itemz.parent.id}`;
      const cRresult = await fetch(cApi, {
        method: "GET",
        headers: {
          Authorization: `Basic ${credentials}`,
        },
      });
      const getC = await cRresult.json();
      // console.log(getC);
      setItems2(getC);
    }
  };

  // const handleSimpleProd = async (event) => {
  //   // setSimpleSelect(event.target.value);
  //   const { value } = event.target;
  //   setSimpleSelectProd(value);
  //   setSimpleSelectProdErr("");
  //   const itemz = itemProd.find((item) => item.id == value);
  //   // console.log(itemz);
  //   setPrdID(itemz.id);
  //   setSimpleSelect(itemz.productCategory?.categoryId);
  //   axios
  //     .get(
  //       localApi +
  //         `/parent/list/byAdminIdAndCategoryId/${userObj && userObj.id}/${
  //           itemz.productCategory?.categoryId
  //         }`
  //     )
  //     .then((res) => setItems1(res && res.data));
  //   setSimpleSelect1(itemz.productParent?.parentId);
  //   setImgList(itemz.productImage);
  //   setProdDescription(itemz.prodDescription);

  //   const cApi =
  //     localApi +
  //     `/child/list/byAdminIdAndParentId/${userObj.id}/${itemz.productParent.parentId}`;
  //   const cRresult = await fetch(cApi);
  //   const getC = await cRresult.json();
  //   // console.log(getC);
  //   setItems2(getC);
  // };

  const fetchCat = async () => {
    const catApi =
      localApi + `/category/list/byAdminId/${userObj && userObj.id}`;
    const catRresult = await fetch(catApi, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getCat = await catRresult.json();
    // console.log(getSet);
    setItems(getCat && getCat);
  };

  useEffect(() => {
    // catApi().then((res) => setItems(res));
    fetchCat();
  }, []);

  const handleSimple = async (event) => {
    // setSimpleSelect(event.target.value);
    const { value } = event.target;
    const item = items.find((item) => item.id == value);
    setSimpleSelect(value);
    if (value) {
      setCatDes(item.title);
    }
    // setGetId(item.id);
    const response = await axios.get(
      `${localApi}/parent/list/byCategoryId/${item.id}`,
      {
        headers: {
          Authorization: `Basic ${credentials}`,
        },
      }
    );
    setItems1(response.data);
    setCat(item);
  };

  const handleSimple1 = async (event) => {
    // setSimpleSelect(event.target.value);
    var tempData = {};
    const { value } = event.target;
    setSimpleSelect1(value);
    const itemm = items1.find((itemm) => itemm.id == value);
    setCatSize(itemm);
    const childApi = localApi + `/child/list/byParentId/${itemm.id}`;
    const childRresult = await fetch(childApi, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getChild = await childRresult.json();
    // console.log(getChild);
    setItems2(getChild);
    const imageApi =
      localApi +
      `/image/list/byAdminAndCategoryAndParentId/${userObj.id}/${cat.id}/${itemm.id}`;
    const imageRresult = await fetch(imageApi, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getImage = await imageRresult.json();
    // console.log(getImage);
    setImgList(getImage);
    // const userUsed = getImage.map((d) => d?.id);
    // Promise.all(
    //   userUsed.map(async (id) => {
    //     const usedApi = await fetch(
    //       localApi +
    //         `/productimage/find/byCountAdminAndImageId/${userObj.id}/${id}`
    //     );
    //     const data = await usedApi.json();
    //     // console.log(data);
    //     tempData = { ...tempData, [id]: data };
    //   })
    // ).then(() => {
    //   // console.log(tempData);
    //   const tempItems2 = getImage.map((i) => {
    //     return { ...i, usedCount: tempData[i.id] || 0 };
    //   });
    //   setImgList(tempItems2);
    //   // console.log(tempItems2);
    // });
  };

  const handleCheckBoxImage = (e, i) => {
    // setCheckBoxmaking(e.target.checked);
    const { checked } = e.target;

    setCheckBoxImage((prev) => {
      if (prev.includes(i.id)) {
        return prev.filter((x) => x !== i.id);
      } else {
        return [...prev, i.id];
      }
    });

    // setImgChecked((prev) => {
    //   if (prev.includes(i?.id)) {
    //     return prev.filter((x) => x !== i?.id);
    //   } else {
    //     return [...prev, i?.id];
    //   }
    // });
  };

  const mystyle = {
    position: "fixed",
    bottom: "10px",
    right: "30px",
  };

  // const textFilter = !prodDescription
  //   ? itemProd
  //   : itemProd.filter((person) =>
  //       prodDescription
  //         .includes(person.prodDescription)
  //     );

  const SubmitUpdateProduct = () => {
    setNewUpdateProduct(true);
    let errorCount = 0;
    if (simpleSelectProd === "" || !simpleSelectProd) {
      errorCount++;
      setSimpleSelectProdErr("Select Product Id");
    }
    if (errorCount === 0) {
      setBtn(true);
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Basic ${credentials}`,
        },
        body: JSON.stringify({
          prodDescription: prodDescription,
        }),
      };

      fetch(
        localApi + `/product/updateContent/byProductId/${prdId}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          if (data.message === "Data Updated Successfully !!!") {
            alertTypeOps("success");
            alertMsgOps("Updated Succesfully !!!");
            setSubmitted(true);
          }
        });
    }
  };

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);

    // window.history.replaceState({state: null}, document.title);
  };

  const select = {
    width: "100%",
    maxWidth: 600,
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      padding: 10,
    }),
    menu: (state) => ({
      color: "#d81b60",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <AddIcon />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Product Content</small>
                {newUpdateProduct === true ? (
                  <small
                    style={{
                      fontSize: "12px",
                      color: "red",
                      marginLeft: "10px",
                    }}
                  >
                    {simpleSelectProdErr}
                  </small>
                ) : (
                  <small style={{ fontSize: "12px", marginLeft: "10px" }}>
                    Select Product Id
                  </small>
                )}
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {submitted ? (
                  <SweetAlert
                    type={alertType}
                    style={{ display: "block", marginTop: "-100px" }}
                    title={alertMsg}
                    onConfirm={() => hideAlert()}
                    confirmBtnCssClass={
                      classSweet.button + " " + classSweet.success
                    }
                  />
                ) : null}
                <GridItem xs={12} sm={12} md={12}>
                  <label className={classe.selectLabel}>
                    Select Product Id
                  </label>
                  <Selects
                    styles={customStyles}
                    style={select}
                    required={true}
                    placeholder="Select Product Id..."
                    value={simpleSelectProd}
                    name="simpleSelectProd"
                    onChange={handleSimpleProd}
                    options={
                      itemProd &&
                      itemProd.map((c) => {
                        return {
                          label:
                            c.id +
                            " - " +
                            c.category.title +
                            " - " +
                            c.parent.title,
                          value:
                            c.id +
                            " - " +
                            c.category.title +
                            " - " +
                            c.parent.title,
                        };
                      })
                    }
                  />
                </GridItem>
                {/* <GridItem xs={12} sm={12} md={12}>
                  <FormControl fullWidth className={classe.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classe.selectLabel}
                    >
                      Select Product Id...
                    </InputLabel>

                    <Select
                      MenuProps={{
                        className: classe.selectMenu,
                      }}
                      classes={{
                        select: classe.select,
                      }}
                      value={simpleSelectProd}
                      onChange={handleSimpleProd}
                      inputProps={{
                        name: "simpleSelect",
                        id: "simple-select",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classe.selectMenuItem,
                        }}
                      >
                        Select Product Id...
                      </MenuItem>
                      {itemProd &&
                        itemProd.map((item) => (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                            classes={{
                              root: classe.selectMenuItem,
                              selected: classe.selectMenuItemSelected,
                            }}
                          >
                            {item.id}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>{" "} */}
                <GridItem xs={12} sm={12} md={12}>
                  <FormControl fullWidth className={classe.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classe.selectLabel}
                    >
                      Select Category Name...
                    </InputLabel>

                    <Select
                      MenuProps={{
                        className: classe.selectMenu,
                      }}
                      classes={{
                        select: classe.select,
                      }}
                      value={simpleSelect}
                      onChange={handleSimple}
                      inputProps={{
                        name: "simpleSelect",
                        id: "simple-select",
                      }}
                      disabled={true}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classe.selectMenuItem,
                        }}
                      >
                        Select Category Name...
                      </MenuItem>
                      {items &&
                        items.map((item) => (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                            classes={{
                              root: classe.selectMenuItem,
                              selected: classe.selectMenuItemSelected,
                            }}
                          >
                            {item.title}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>{" "}
                <GridItem xs={12} sm={12} md={12}>
                  <FormControl fullWidth className={classe.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classe.selectLabel}
                    >
                      Select Bangles Size-MM...
                    </InputLabel>

                    <Select
                      MenuProps={{
                        className: classe.selectMenu,
                      }}
                      classes={{
                        select: classe.select,
                      }}
                      value={simpleSelect1}
                      onChange={handleSimple1}
                      inputProps={{
                        name: "simpleSelect",
                        id: "simple-select",
                      }}
                      disabled={true}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classe.selectMenuItem,
                        }}
                      >
                        Select Bangles Size-MM...
                      </MenuItem>
                      {items1 &&
                        items1.map((item1) => (
                          <MenuItem
                            key={item1.id}
                            value={item1.id}
                            classes={{
                              root: classe.selectMenuItem,
                              selected: classe.selectMenuItemSelected,
                            }}
                          >
                            {item1.title}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <BiFile />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>
                  Product Detail Left words {prodDescriptionLength - totalWord}
                </small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Product Description Min(100) Max(150)"
                    id="prodDescription"
                    inputProps={{
                      //   placeholder: "Golwire Thaw",
                      multiline: true,
                      rows: 5,
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="prodDescription"
                    value={prodDescription}
                    onChange={(e) => {
                      const { value } = e.target;
                      // setProdDescription(value)
                      setProdDescription(
                        value.length <= 150
                          ? value.slice(0, 150)
                          : value.slice(0, 150)
                      );
                      setProdDescriptionLength(value.length);

                      // const test = itemProd.filter((team) => {
                      //   return team.prodDescription.includes(value);
                      // });
                      // setItemProd(test);
                    }}
                  />
                </GridItem>
                {/* {itemProd.map((team) => (
                  <p key={team.id}>{team.prodDescription}</p>
                ))} */}
                {/* {textFilter.map((person) => {
                  return <p key={person.id}>{person.prodDescription}</p>;
                })} */}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <BsImages />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Select Design Images</small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {imgList &&
                  imgList.map((i) => (
                    <GridItem xs={3} sm={2} md={2} key={i.id}>
                      <div className="fileinput text-center">
                        <div
                          className={
                            "thumbnail" + (avatar ? " img-circle" : "")
                          }
                        >
                          <img
                            src={i.cropImageUrl + "?id=" + i.imgId}
                            alt="..."
                          />
                        </div>
                      </div>
                      <br />
                    </GridItem>
                  ))}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <div style={mystyle}>
        {/* <Button
          color="rose"
          className={classes.updateProfileButton}
          onClick={SubmitUpdateProduct}
        >
          Save
        </Button> */}
        {btn === true ? (
          <Button className={classes.updateProfileButton}>Save</Button>
        ) : (
          <Button
            color="rose"
            onClick={SubmitUpdateProduct}
            className={classes.updateProfileButton}
          >
            Save
          </Button>
        )}
      </div>
    </div>
  );
}

ProductContent.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
};

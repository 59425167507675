import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import { headApi } from "utils/headApi";
import { localApi } from "utils/imgApi";
import { BiEdit } from "react-icons/bi";
import { imgApi } from "utils/imgApi";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import axios from "axios";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import styleDrop from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import styleSweet from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import { credentials } from "utils/imgApi";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

const style = {
  cardTitle,
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center",
  },
  cardCategory: {
    margin: "0",
    color: "#999999",
  },
};

const useStyles = makeStyles(styles);
const useStyle = makeStyles(style);
const useStyleDrop = makeStyles(styleDrop);
const useStyleSweet = makeStyles(styleSweet);

export default function LoginPage() {
  // const headApi = `https://api.bangles.org.in/api/rest`;

  const [items, setItems] = React.useState([]);
  const [items1, setItems1] = React.useState([]);
  const [items2, setItems2] = React.useState([]);

  const [simpleSelect, setSimpleSelect] = React.useState("");
  const [simpleSelect1, setSimpleSelect1] = React.useState("");
  const [simpleSelect2, setSimpleSelect2] = React.useState("");
  const [labourDetails, setLabourDetails] = React.useState("");
  const [labourErr, setLabourErr] = React.useState("");
  const [hsnCode, setHsnCode] = React.useState("");
  const [hsnCodeErr, setHsnCodeErr] = React.useState("");
  const [price, setPrice] = React.useState("");
  const [priceErr, setPriceErr] = React.useState("");
  const [gst, setGst] = React.useState("");
  const [gstErr, setGstErr] = React.useState("");

  const [getId, setGetId] = React.useState("");
  const [getcatId, setGetcatId] = React.useState("");

  const [getlabId, setGetlabId] = React.useState("");
  const [usedId, setUsedId] = React.useState("");

  const [submitted, setSubmitted] = React.useState(false);
  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");

  const [itemsNew, setItemsNew] = React.useState([]);
  const [items1New, setItems1New] = React.useState([]);
  const [items2New, setItems2New] = React.useState([]);
  const [simpleSelectNew, setSimpleSelectNew] = React.useState("");
  const [simpleSelect1New, setSimpleSelect1New] = React.useState("");
  const [simpleSelect2New, setSimpleSelect2New] = React.useState("");
  const [getIdNew, setGetIdNew] = React.useState("");
  const [getcatIdNew, setGetcatIdNew] = React.useState("");

  // const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  const classes = useStyles();
  const classe = useStyle();
  const classDrop = useStyleDrop();
  const history = useHistory();
  const classSweet = useStyleSweet();

  const { search } = useLocation();
  const adminid = new URLSearchParams(search).get("adminid");

  async function getUsers() {
    const api = headApi + `/category/catbyuser/1`;
    const result = await fetch(api, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getResult = await result.json();
    setItems(getResult.CategoriesData);
    // console.log(getResult);
  }

  async function getUser() {
    const api = localApi + `/category/list/byAdminId/${adminid}`;
    const result = await fetch(api, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getResult = await result.json();
    setItemsNew(getResult && getResult);
    // console.log(getResult);
  }

  useEffect(() => {
    getUsers();
    getUser();
  }, []);

  const handleSimple = (event) => {
    // setSimpleSelect(event.target.value);
    const { value } = event.target;
    const item = items.find((item) => item.id == value);
    setSimpleSelect(value);
    setGetId(item.id);
    axios
      .get(headApi + `/category/size-mm?catid=${item.id}&userid=1`, {
        headers: {
            Authorization: `Basic ${credentials}`,
        }
    })
      .then((res) => setItems1(res && res.data.cat1SizemmData));
  };

  const handleSimple1 = (event) => {
    // setSimpleSelect(event.target.value);
    const { value } = event.target;
    const itemm = items1.find((itemm) => itemm.id == value);
    // console.log(itemm);
    setSimpleSelect1(value);
    setGetcatId(itemm.id);
    axios
      .get(headApi + `/category/labour?cat1id=${itemm.id}&userid=1`, {
        headers: {
            Authorization: `Basic ${credentials}`,
        }
    })
      .then((res) => setItems2(res && res.data.cat2labourData));
  };

  const handleSimple2 = (event) => {
    // setSimpleSelect(event.target.value);
    const { value } = event.target;
    const itemms = items2.find((itemms) => itemms.id == value);
    // console.log(itemms.id);
    setSimpleSelect2(value);
    setLabourDetails(itemms.description);
    setHsnCode(itemms.hsncode);
    setPrice(itemms.price);
    setGst(itemms.ligst);
    setGetlabId(itemms.id);
    axios
      .get(headApi + `/products/labourCount/${itemms.id}`, {
        headers: {
            Authorization: `Basic ${credentials}`,
        }
    })
      .then((res) => setUsedId(res && res.data));
  };

  const handleSimpleNew = (event) => {
    // setSimpleSelect(event.target.value);
    const { value } = event.target;
    const item = itemsNew.find((item) => item.id == value);
    setSimpleSelectNew(value);
    setGetIdNew(item.id);
    setItems1New(item.parent);
  };

  const handleSimple1New = (event) => {
    // setSimpleSelect(event.target.value);
    const { value } = event.target;
    const itemm = items1New.find((itemm) => itemm.id == value);
    setSimpleSelect1New(value);
    setGetcatIdNew(itemm.id);
    setItems2New(itemm.child);
  };

  const SubmitAddLabourRate = (e) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Basic ${credentials}`,
      },
      body: JSON.stringify({
        title: labourDetails,
        hsncode: hsnCode,
        price: price,
        taxPercentage: gst,
      }),
    };
    fetch(
      localApi + `/child/add/ByAdminAndParentId/${adminid}/${getcatIdNew}`,
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        if(data.message === "Data Inserted Successfully !!!"){
          window.location.reload(false);
        }
      });
  };

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={6}>
          <form>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <BiEdit />
                </CardIcon>
                <h3 className={classes.cardIconTitle}>
                  <small>Add Labour & Rate</small>
                </h3>
              </CardHeader>
              <CardBody>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={12} md={12}>
                    <label>Category Old Data</label>
                    <FormControl
                      fullWidth
                      className={classDrop.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="simple-select"
                        className={classDrop.selectLabel}
                      >
                        Select Category Name...
                      </InputLabel>

                      <Select
                        MenuProps={{
                          className: classDrop.selectMenu,
                        }}
                        classes={{
                          select: classDrop.select,
                        }}
                        value={simpleSelect}
                        onChange={handleSimple}
                        inputProps={{
                          name: "simpleSelect",
                          id: "simple-select",
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classDrop.selectMenuItem,
                          }}
                        >
                          Select Category Name..
                        </MenuItem>
                        {items &&
                          items.map((item) => (
                            <MenuItem
                              key={item.id}
                              value={item.id}
                              classes={{
                                root: classDrop.selectMenuItem,
                                selected: classDrop.selectMenuItemSelected,
                              }}
                            >
                              {item.description}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </GridItem>{" "}
                  <GridItem xs={12} sm={12} md={12}>
                    <label>Category New Data</label>
                    <FormControl
                      fullWidth
                      className={classDrop.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="simple-select"
                        className={classDrop.selectLabel}
                      >
                        Select Category Name...
                      </InputLabel>

                      <Select
                        MenuProps={{
                          className: classDrop.selectMenu,
                        }}
                        classes={{
                          select: classDrop.select,
                        }}
                        value={simpleSelectNew}
                        onChange={handleSimpleNew}
                        inputProps={{
                          name: "simpleSelect",
                          id: "simple-select",
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classDrop.selectMenuItem,
                          }}
                        >
                          Select Category Name..
                        </MenuItem>
                        {itemsNew &&
                          itemsNew.map((item) => (
                            <MenuItem
                              key={item.id}
                              value={item.id}
                              classes={{
                                root: classDrop.selectMenuItem,
                                selected: classDrop.selectMenuItemSelected,
                              }}
                            >
                              {item.title}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </GridItem>{" "}
                  <GridItem xs={12} sm={12} md={12}>
                    <label>Size MM Old Data</label>
                    <FormControl
                      fullWidth
                      className={classDrop.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="simple-select"
                        className={classDrop.selectLabel}
                      >
                        Select Bangles Size-MM...
                      </InputLabel>

                      <Select
                        MenuProps={{
                          className: classDrop.selectMenu,
                        }}
                        classes={{
                          select: classDrop.select,
                        }}
                        value={simpleSelect1}
                        onChange={handleSimple1}
                        inputProps={{
                          name: "simpleSelect1",
                          id: "simple-select1",
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classDrop.selectMenuItem,
                          }}
                        >
                          Select Bangles Size-MM...
                        </MenuItem>
                        {items1 &&
                          items1.map((item1) => (
                            <MenuItem
                              key={item1.id}
                              value={item1.id}
                              classes={{
                                root: classDrop.selectMenuItem,
                                selected: classDrop.selectMenuItemSelected,
                              }}
                            >
                              {item1.description}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </GridItem>{" "}
                  <GridItem xs={12} sm={12} md={12}>
                    <label>Size MM New Data</label>
                    <FormControl
                      fullWidth
                      className={classDrop.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="simple-select"
                        className={classDrop.selectLabel}
                      >
                        Select Bangles Size-MM...
                      </InputLabel>

                      <Select
                        MenuProps={{
                          className: classDrop.selectMenu,
                        }}
                        classes={{
                          select: classDrop.select,
                        }}
                        value={simpleSelect1New}
                        onChange={handleSimple1New}
                        inputProps={{
                          name: "simpleSelect1",
                          id: "simple-select1",
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classDrop.selectMenuItem,
                          }}
                        >
                          Select Bangles Size-MM...
                        </MenuItem>
                        {items1New &&
                          items1New.map((item1) => (
                            <MenuItem
                              key={item1.id}
                              value={item1.id}
                              classes={{
                                root: classDrop.selectMenuItem,
                                selected: classDrop.selectMenuItemSelected,
                              }}
                            >
                              {item1.title}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </GridItem>{" "}
                  <GridItem xs={12} sm={12} md={12}>
                    <label>Labour Old Data</label>
                    <FormControl
                      fullWidth
                      className={classDrop.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="simple-select"
                        className={classDrop.selectLabel}
                      >
                        Select Labour & Rate...
                      </InputLabel>

                      <Select
                        MenuProps={{
                          className: classDrop.selectMenu,
                        }}
                        classes={{
                          select: classDrop.select,
                        }}
                        value={simpleSelect2}
                        onChange={handleSimple2}
                        inputProps={{
                          name: "simpleSelect2",
                          id: "simple-select2",
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classDrop.selectMenuItem,
                          }}
                        >
                          Select Labour & Rate...
                        </MenuItem>
                        {items2 &&
                          items2.map((item2) => (
                            <MenuItem
                              key={item2.id}
                              value={item2.id}
                              classes={{
                                root: classDrop.selectMenuItem,
                                selected: classDrop.selectMenuItemSelected,
                              }}
                            >
                              {item2.description}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </GridItem>{" "}
                  {simpleSelect2 ? (
                    <>
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText="Labour Name"
                          id="labourDetails"
                          inputProps={{
                            placeholder: "Labouor Name",
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          name="labourDetails"
                          value={labourDetails}
                          onChange={(e) => setLabourDetails(e.target.value)}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText="HSN Code"
                          id="hsnCode"
                          inputProps={{
                            placeholder: "HSN Code",
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          name="hsnCode"
                          value={hsnCode}
                          onChange={(e) => setHsnCode(e.target.value)}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText="Price"
                          id="price"
                          inputProps={{
                            placeholder: "Price",
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          name="price"
                          value={price}
                          onChange={(e) => setPrice(e.target.value)}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText="GST @5%"
                          id="gst"
                          inputProps={{
                            placeholder: "GST @5%",
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          name="gst"
                          value={gst}
                          onChange={(e) => setGst(e.target.value)}
                        />
                      </GridItem>
                      <Button
                        color="rose"
                        onClick={SubmitAddLabourRate}
                        className={classes.updateProfileButton}
                      >
                        Save
                      </Button>
                    </>
                  ) : (
                    ""
                  )}
                </GridContainer>
              </CardBody>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}

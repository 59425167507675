import React from "react";
import { useHistory } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import { localApi } from "utils/imgApi";
import EmailIcon from "@material-ui/icons/Email";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import NavPills from "components/NavPills/NavPills.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { credentials } from "utils/imgApi";

const style = {
  cardTitle,
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center",
  },
  cardCategory: {
    margin: "0",
    color: "#999999",
  },
};

const useStyles = makeStyles(styles);
const useStyle = makeStyles(style);

export default function LoginPage() {
  const [mobile, setMobile] = React.useState("");
  const [mobileErr, setMobileErr] = React.useState("");
  const [otpNumber, setOtpNumber] = React.useState("");
  const [otpNumberErr, setOtpNumberErr] = React.useState("");
  const [otpScreen, setOtpScreen] = React.useState(false);
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [otp, setOtp] = React.useState("");
  const [reSend, setReSend] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [emailErr, setEmailErr] = React.useState("");

  // const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  React.useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });

  const classes = useStyles();
  const classe = useStyle();
  const history = useHistory();

  const currentSession = sessionStorage.getItem("sessionData");
  if (currentSession) {
    history.push("/admin/dashboard");
  }

  const handleMobileNumber = (mobile) => {
    setMobile(mobile);
    // setMobileErr("");
    fetch(localApi + `/admin/find/byMobile/${mobile}`, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log(result);
          // setMobileErr(result.message);
          if (result.message === "Mobile Already Registered") {
            setMobileErr("");
          } else if (result.message === "Please Enter Valid Number") {
            setMobileErr("Please Enter Valid Mobile Number");
          } else if (result.message === "") {
            setMobileErr("Mobile Number Not Found");
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const handleEmail = (e) => {
    const { value } = e.target;
    setEmail(value);
    fetch(localApi + `/admin/find/byEmail/${value}`, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log(result);
          //  setEmailErr(result.message);
          if (result.message === "Email Id Already Registered") {
            setEmailErr("");
          } else if (result.message === "Please Enter Valid Email ID") {
            setEmailErr("Please Enter Valid Email");
          } else if (result.message === "") {
            setEmailErr("Email Not Found");
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const handleSendOtpWithMobile = () => {
    fetch(
      localApi +
        `/otp/admin/send/byMobile?mobile=${mobile}&title=Admin&templateID=1207161762762132153`,
      {
        method: "PUT",
        headers: {
          Authorization: `Basic ${credentials}`,
          "content-type": "application/json",
          accept: "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        // console.log(response);
        setMobileErr(response.message);
        if (response && response.error === true) {
          setOtpScreen({ otpScreen: true });
          setOtp(response.message);
        }
        // "Sender Not Found to +919967457786"
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleResendOtpWithMobile = () => {
    fetch(
      localApi +
        `/otp/admin/resend/byMobile?mobile=${mobile}&title=Admin&templateID=1207161762762132153`,
      {
        method: "PUT",
        headers: {
          Authorization: `Basic ${credentials}`,
          "content-type": "application/json",
          accept: "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        // console.log(response.message);
        setReSend(response.message);
        // setMobileErr(response.message);
        // "Sender Not Found to +919967457786"
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSendOtpWithEmail = () => {
    fetch(
      localApi +
        `/otp/admin/send/byEmail?title=Admin&email=noreply@inshabangles.com`,
      {
        method: "POST",
        headers: {
          Authorization: `Basic ${credentials}`,
          "content-type": "application/json",
          accept: "application/json",
        },
        body: JSON.stringify({
          to: email,
          subject: "API CREATION SUBJECT",
        }),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        // console.log(response);
        setEmailErr(response.message);
        if (response && response.error === true) {
          setOtpScreen({ otpScreen: true });
          setOtp(response.message);
        }
        // "Sender Not Found to +919967457786"
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleResendOtpWithEmail = () => {
    fetch(
      localApi +
        `/otp/admin/resend/byEmail?title=Admin&email=noreply@inshabangles.com`,
      {
        method: "POST",
        headers: {
          Authorization: `Basic ${credentials}`,
          "content-type": "application/json",
          accept: "application/json",
        },
        body: JSON.stringify({
          to: email,
          subject: "API CREATION SUBJECT",
        }),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        // console.log(response);
        // setEmailErr(response.message);
        setReSend(response.message);
        // "Sender Not Found to +919967457786"
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleLoginWithMobile = () => {
    let errorCount = 0;
    if (otpNumber === "" || !otpNumber) {
      errorCount++;
      setOtpNumberErr("Enter Otp Number");
    }
    if (errorCount === 0) {
      fetch(
        localApi + `/admin/recieved/byMobile?mobile=${mobile}&otp=${otpNumber}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Basic ${credentials}`,
            "content-type": "application/json",
            accept: "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((response) => {
          // console.log(response);

          setOtpNumberErr(response.message);
          if (response.errorDetails.error === true) {
            const userObj = response.adminSession;
            localStorage.setItem("userObj", JSON.stringify(userObj));
            sessionStorage.setItem("sessionData", JSON.stringify(userObj));
            history.push("/admin/dashboard");
          }
          // if (response.apiResponse.error !== "false") return false;
          // const userObj = response.user;
          // localStorage.setItem("userObj", JSON.stringify(userObj));
          // sessionStorage.setItem("sessionData", JSON.stringify(userObj));
          // history.push("/admin/dashboard");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleLoginWithEmail = () => {
    let errorCount = 0;
    if (otpNumber === "" || !otpNumber) {
      errorCount++;
      setOtpNumberErr("Enter Otp Number");
    }
    if (errorCount === 0) {
      fetch(
        localApi + `/admin/recieved/byEmail?email=${email}&otp=${otpNumber}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Basic ${credentials}`,
            "content-type": "application/json",
            accept: "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((response) => {
          // console.log(response);
          setOtpNumberErr(response.message);
          if (response.errorDetails.error === true) {
            const userObj = response.adminSession;
            localStorage.setItem("userObj", JSON.stringify(userObj));
            sessionStorage.setItem("sessionData", JSON.stringify(userObj));
            history.push("/admin/dashboard");
          }
          // if (response.apiResponse.error !== "false") return false;
          // const userObj = response.user;
          // localStorage.setItem("userObj", JSON.stringify(userObj));
          // sessionStorage.setItem("sessionData", JSON.stringify(userObj));
          // history.push("/admin/dashboard");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={10} md={4}>
          {!otpScreen ? (
            <form>
              <Card login className={classes[cardAnimaton]}>
                <CardHeader
                  className={`${classes.cardHeader} ${classes.textCenter}`}
                  color="rose"
                >
                  <h4 className={classes.cardTitle}>
                    <b>Log in</b>
                  </h4>
                  <div className={classes.socialLine}>
                    {["fab fa-facebook-square"].map((prop, key) => {
                      // return (
                      //   <Button
                      //     color="transparent"
                      //     justIcon
                      //     key={key}
                      //     className={classes.customButtonClass}
                      //   >
                      //     <i className={prop} />
                      //   </Button>
                      // );
                    })}
                  </div>
                  <h6 className={classes.cardTitle}>
                    Please provide your phone number below to login on
                    BANGLES.ORG
                  </h6>
                </CardHeader>
                <CardBody>
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12}>
                      <NavPills
                        color="rose"
                        alignCenter
                        tabs={[
                          {
                            tabButton: "Mobile",
                            tabIcon: PhoneAndroidIcon,
                            tabContent: (
                              <Card>
                                <CardBody>
                                  <PhoneInput
                                    inputStyle={{
                                      width: "100%",
                                      border: "none",
                                      borderBottom: "1px solid #040000 ",
                                      borderRadius: "0",
                                    }}
                                    country={"in"}
                                    name="mobile"
                                    value={mobile}
                                    onChange={(mobile) => {
                                      handleMobileNumber(mobile);
                                    }}
                                    required={true}
                                  />
                                  <span style={{ color: "red" }}>
                                    {mobileErr}
                                  </span>
                                </CardBody>
                                <CardFooter
                                  className={classe.justifyContentCenter}
                                >
                                  <Button
                                    color="rose"
                                    simple
                                    // size="lg"
                                    block
                                    onClick={handleSendOtpWithMobile}
                                  >
                                    Login
                                  </Button>
                                </CardFooter>
                              </Card>
                            ),
                          },
                          {
                            tabButton: "Email",
                            tabIcon: EmailIcon,
                            tabContent: (
                              <Card>
                                <CardBody>
                                  <CustomInput
                                    formControlProps={{
                                      fullWidth: true,
                                      className:
                                        classes.customFormControlClasses,
                                    }}
                                    inputProps={{
                                      startAdornment: (
                                        <InputAdornment
                                          position="start"
                                          className={classes.inputAdornment}
                                        >
                                          <EmailIcon
                                            className={
                                              classes.inputAdornmentIcon
                                            }
                                          />
                                        </InputAdornment>
                                      ),
                                      placeholder: "Email...",
                                    }}
                                    id="email"
                                    name="email"
                                    value={email}
                                    onChange={handleEmail}
                                    required={true}
                                  />
                                  <span style={{ color: "red" }}>
                                    {emailErr}
                                  </span>
                                </CardBody>
                                <CardFooter
                                  className={classe.justifyContentCenter}
                                >
                                  <Button
                                    color="rose"
                                    simple
                                    // size="lg"
                                    block
                                    onClick={handleSendOtpWithEmail}
                                  >
                                    Login
                                  </Button>
                                </CardFooter>
                              </Card>
                            ),
                          },
                        ]}
                      />
                    </GridItem>
                  </GridContainer>
                  <Button
                    color="rose"
                    simple
                    // size="lg"
                    block
                    href="/auth/pricing-page"
                  >
                    Create New Account
                  </Button>
                </CardBody>
              </Card>
            </form>
          ) : (
            <form>
              <Card login className={classes[cardAnimaton]}>
                <CardBody>
                  <h5
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    6 digit OTP SMS sent on your {mobile ? "Mobile" : "Email"}
                  </h5>
                  <h5
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {mobile ? mobile : email}
                  </h5>

                  {/* {reSend ? 
                  <h5 style={{color: "red"}}>{reSend}</h5> : <h5>{otp}</h5> } */}

                  <CustomInput
                    labelText="Enter OTP Here"
                    id="otpNumber"
                    value={otpNumber}
                    onChange={(e) => {
                      setOtpNumber(e.target.value);
                      setOtpNumberErr("");
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputAdornmentIcon}>
                            lock_outline
                          </Icon>
                        </InputAdornment>
                      ),
                      type: "text",
                      autoComplete: "off",
                    }}
                  />
                  <span
                    className="text-danger"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "red",
                    }}
                    required={true}
                  >
                    {otpNumberErr}
                  </span>
                </CardBody>
                <CardFooter className={classes.justifyContentCenter}>
                  <Button
                    color="rose"
                    simple
                    // size="lg"
                    block
                    onClick={
                      mobile ? handleLoginWithMobile : handleLoginWithEmail
                    }
                  >
                    Login
                  </Button>
                </CardFooter>
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "red",
                  }}
                >
                  Didn't receive the OTP yet?{" "}
                  <span style={{ marginLeft: "10px" }}>
                    <a
                      href="#"
                      onClick={
                        mobile
                          ? handleResendOtpWithMobile
                          : handleResendOtpWithEmail
                      }
                    >
                      Resend OTP
                    </a>
                  </span>
                </p>
              </Card>
            </form>
          )}
        </GridItem>
      </GridContainer>
    </div>
  );
}
